.skin1 .home-banner-group .banner {
    background: #b70505;
    position: relative;
    overflow: hidden;

  }
  .skin1 .home-banner-group .banner:hover {
    background: #000;
    position: relative;
    overflow: hidden;

  }


.skin1 .home-banner-group .banner .footer-deals {
  color: white;
  text-align: center;

}

.skin1 .home-banner-group .banner .list-inline {
  padding: 0 0 0 0 !important;
  height: 1.5em;
}

/* podpurne*/
.white
{
  background: #fff;


}

.pattern {
  background: url('/image/catalog/protetovani/bg.jpg') top no-repeat;

}
.rounded{
  border-radius: 7px;

}

/*labelly*/
 .product-image .onhot, .product-image .onsale1,  .product-image .onsale2, .product-image .ondiscount {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

  .product-image .onsale1,   .product-image .onsale2, .product-image .onsale3, .product-image .onhot{
  line-height: 1;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  font-size: 0.7em;
  padding: 0.5833em 0.6333em;
}

  .product-image .ondiscount{
    line-height: 1;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 0.8571em;
    padding: 0.5833em 0.6333em;
    font-weight: bold;
  }
  .product-image .onhot {
    background: #62b959;
    left: 0.8em;
    top: 3em;
  }

  .product-icons ul.icons-list {
    display: flex;
    flex-direction: column;
}

  .product-icons li.onsale1.text  {
    position:relative;
    margin-bottom: 5px;
}


.product-icons li.onsale1.image {
  position:relative;
  padding: unset;

}

.product-image .ondiscount {
  background: #b9f005;
  right: 0.8em;
  bottom:0.8em;
  padding-top: 1.1em;
}


/* slideshow content top homepage */
div[id^="slideshow"] .owl-nav {
  width: 100%;
  top: 50%;
}

div[id^="slideshow"] .item div {
  background-position: center center;
  background-size: cover;
  display: block;
    border-radius: 0.5em;
    text-wrap: pretty;
    word-wrap: break-word;

}

div[id^="slideshow"] .owl-stage-outer {
  margin-right: 10px;
  margin-left: 10px;
}


div[id^="slideshow"] .owl-next, div[id^="slideshow"] .owl-prev {
  position: absolute;
}

div[id^="slideshow"] .owl-prev {
  margin-left: 15px;
  display:none;
}

div[id^="slideshow"] .owl-next {
  margin-right: 15px;
  display:none;
}



div[id^="slideshow"] .owl-prev, .owl-next {
  opacity: 1 !important;
}
.owl-carousel:active .owl-controls .owl-buttons .owl-prev {opacity: 1 !important;}
.owl-carousel:active .owl-controls .owl-buttons .owl-next {opacity: 1 !important;}



#banner-separator {
  background: transparent;
}

#banner-separator .item {
  text-align: center;
}

#banner-separator .item h2 {
  line-height: 1.2;
  font-size: 4.2857em;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;
  text-transform: uppercase;
  color: white;
    top: 50%;
    position: absolute;
    left: 25%;
}

#banner-separator img.img-responsive{
    display: block;
    width: 100%;
    height: auto;
}
div#banner-separator.owl-loaded {
    display:block;
}


#banner-separator .item h4 {
  font-size: 1.5714em;
  font-weight: 300;
  margin: 0 0 14px 0;
  color: white;
    top: 60%;
    position: absolute;
    left: 25%;
}

/* Partners Carousel */
.brands-carousel-container {
	padding:0 20px;
}

.brands-carousel-container.no-padding {
	padding:0;
}

.brand a {
	display:block;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	position: relative;
	text-align: center;
}

.owl-item .brand img {
	display:inline-block;
	max-width:none;
	width:100%;
	height: auto;
}

.brands-carousel-container .owl-carousel .owl-item .brand img {
	display:inline-block;
	max-width:100%;
	width:auto;
	height: auto;
}

.product-filter {
	padding: 0px;
	margin: 0px 0px 14px 0px;
}

	.product-filter .sort {
		padding: 0px 0px 0px 0px;
		float: left;
		width: auto;
		font-size: 13px;
	}

		.product-filter .sort select {
			margin: 0px 0px 0px 5px;
			width: auto;
			padding: 0px;
			position: relative;
			height: 26px;
			border: 1px solid #ccc;
			background: #fff;
		}

	.product-filter .pagination {
		float: right;
		clear: none;
		margin: 0px 0px -5px 0px;
		max-width: 350px;
	}

	.product-filter .limit {
		padding: 0px 0px 0px 15px;
		float: right;
		width: auto;
		font-size: 13px;
	}

		.product-filter .limit select {
			margin: 0px 4px 0px 5px;
			width: auto;
			padding: 0px;
			position: relative;
			height: 26px;
			border: 1px solid #ccc;
			background: #fff;
		}

	.product-filter .product-compare {
		float: left;
		width: auto;
		font-size: 13px;
		padding: 2px 0px 0px 15px;
	}

	.product-filter .options {
		float: left;
		width: auto;
		padding: 0px 0px 0px 12px;
	}

		.product-filter .options .button-group {
			float: left;
			width: auto;
		}

			.product-filter .options .button-group button {
				border: 1px solid #ccc;
				border-radius: 3px;
				color: #ccc;
				display: inline-block;
				font-family: "Elusive-Icons";
				width: 26px;
				height: 26px;
				line-height: 24px;
				margin-left: 3px;
				text-decoration: none;
				text-align: center;
				-webkit-transition: all 0.25s ease 0s;
				-moz-transition: all 0.25s ease 0s;
				transition: all 0.25s ease 0s;
				background: transparent;
				vertical-align: top;
				padding: 0px;
			}

				.product-filter .options .button-group button:hover,
				.product-filter .options .button-group .active {
					background:#b70505;
					color: #fff;
					border-color: #b70505;
				}

				#grid:before {
				    content: "\f20b";
				    font-size: 14px;
				}

				#list:before {
				    content: "\f20a";
				    font-size: 14px;
				}

	@media (max-width: 1024px) {

		.product-filter .product-compare {
			display: none;
		}

		.product-filter .limit {
			float: left;
		}
	}

  @media (max-width: 1200px) {
    .product-filter .pagination {
			clear: both;
			float: none;
			display: block;
			padding-top: 10px;
			margin-left: -5px;
			max-width: none;
		}
    .vertical ul.megamenu > li.click > a:before, .vertical ul.megamenu > li.hover > a:before {
      display: none;
    }

  }




  /* kategorie filtr submenu*/

.catlist {
 overflow-x:hidden;
 margin:0 0 50px;
}


.catlist .subC  {
 width:20%;
 float:left;
 display:block;
 text-decoration:none;
 -webkit-box-sizing:border-box;
 box-sizing:border-box;
 position:relative;
 padding:2px;
font-size: 1.2rem;
}

  @media (max-width: 992px) {
    .catlist .subC  {
     width:33.3%;
    }


  }


.catlist .subC .container {
 background:#f8f8f8;
 border:1px solid #efefef;
 border-bottom:2px solid #efefef;
 border-radius:5px;
 -webkit-border-radius:5px;
 -moz-border-radius:5px;
 width: 100%;
 display: block;
 padding: 0 5px 0 8px;
}

 .catlist .subC .container:hover
  {
 background:#e6e4e4;
}



.catlist .subCategories .container {
 -webkit-transition:all .2s ease-in-out;
 -moz-transition:all .2s ease-in-out;
 -o-transition:all .2s ease-in-out;
 transition:all .2s ease-in-out
}





/*.catlist .subC .img  {
 float:left;
 margin:7px 5px 6px 4px;
 height:32px;
 width:32px;
 display:block
}*/

.catlist .subC .name  {
 display:table-cell;
 vertical-align:middle;
 text-decoration:none;
 padding:0 15px 0 3px;
 font-weight:700;
 height:47px;
 line-height:1em;
 /*overflow:hidden;*/
 color:#6b6a6a;
 font-size: 1em;
}

/*sipky*/


@media (min-width: 767px) {
 .catlist .subC .container:before {
   color:#6b6a6a;
   text-decoration:none;
   display:inline-block;
   font:normal normal normal 14px/1 FontAwesome;
   font-size:inherit;
   text-rendering:auto;
   -webkit-font-smoothing:antialiased;
   -moz-osx-font-smoothing:grayscale;
   content:"\f0da";
   float: right;
   line-height:47px;
   margin-right:5px
  }
}

.alert {
  border: unset;
  margin: unset;
  border-radius: unset;
}

#mini-cart .dropdown-cart-content .table {
  border: unset;
}

#mini-cart .dropdown-cart-content .table tbody>tr>td {
  border: unset;
  vertical-align: middle;
}

#mini-cart .dropdown-cart-content .table .btn {
  background-color: #da4f49;
}

/* Studia */
#input-kraj, #search-studia input, #search-studia select {
  display: inline-block;
    padding: 0 5px;
    width: auto;
    text-transform: capitalize;
    background-color: #f7f7f7;
}

.product-block .image {
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  #studios .product-block .bottom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fffffff5;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }

  #studios .product-block .kontakt {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

  }

  #studios .product-block .image:hover .bottom {
    visibility: visible;
    opacity: 1;
  }

  .notification .gift {

	font-size: 15px

}

/* popup windows buy */

#notification {
  max-width: 500px;
  padding: 0;
  margin: auto;
  border-radius: .3em;
  box-shadow: 0 0.1em 0.4em rgba(0,0,0,.3);
  overflow: unset !important;
  background: white;
}

/*
.fancybox-opened .fancybox-skin {
    -webkit-box-shadow: 0 0 0 !important;
       -moz-box-shadow: 0 0 0 !important;
            box-shadow: 0 0 0 !important;
}

.fancybox-skin {background: transparent !important}


#notification {
    background-color: white;
    margin: auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 10px 25px rgba(0,0,0,.5);
    -moz-box-shadow: 0 10px 25px rgba(0,0,0,.5);
    box-shadow: 0 10px 25px rgba(0,0,0,.5);
    width: 476px;

}*/

#related{
  width: 50%;
  padding: 0px;
  margin: auto;
  overflow: unset !important;
  top: 20px;
  position: relative;
}

.padding-top {
    padding-top: 20px;
}

.related-container {
    padding: 15px;
    border-radius: .3em;
    /* box-shadow: 0 0.1em 0.4em rgba(0,0,0,.3); */
    background-color: white;
}

.notification-container {
  text-align: center;
}

#popup {
    background: transparent;
    overflow: unset;
}
.opened {display: block !important;}
.closed {display: none !important;}

.popup-close {
    right: unset !important;
    top: unset !important;
    position: unset !important;
    float: right;
    position: relative !important;
}

/* laptop */
@media screen and (min-width: 1024px) and (max-width: 1440) {
  .fb_iframe_widget iframe {
      position: relative;
      width: 100%;
      height: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  a.quickview-icon {
    display: hide !important;
  }

  h2.slider-title {
      text-align: center;
  }

  .auction .product {
    margin-bottom: 20px;
  }

  .widget .widget-title {
    text-align: center;
  }
  .post-item .post-excerpt {
    max-width: 100%;
  }

  .post-item{
    text-align: center;
  }
}

.live-search {
  min-width: 100%;
  position:absolute;
  z-index:1000;
  padding:5px 0px;
  background-color:#FFF;
  border: 1px solid #DDD;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 2px #DDDDDD;
  left: auto;
  right: 0;
  top: 100%;
  display:none;
}
.live-search .loading{
  display:block;
  margin-left:auto;
  margin-right:auto;
}
.live-search ul {
  list-style-type: none;
  margin:0px;
  padding:0px;
}
.live-search ul li {
  cursor:pointer;
  padding:5px;
  margin:0px 5px;
  background-color:#FFF;
  min-height:40px;
  clear:both;
}
.live-search ul li:nth-child(even) {
  background-color: #FFF;
}
.live-search ul li:hover {
  background-color:#F0F7FA;
}
.live-search ul li a{
  text-decoration: none;
  display:block;
}
.live-search ul li .product-image{
  float:left;
  margin-right:5px;
}

.live-search ul li .product-text{
  width: 85%;
  /* height: auto; */
  /* display: block; */
  float: right;
  margin: auto;
  /* position: relative; */
  }


.live-search ul li .product-name p{
  font-weight: normal;
  font-style: italic;
  font-size: 10px;
  color:#555555;
  margin:0px;
  padding:0px;
}
.live-search ul li .product-name{
  font-weight: bold;
  float: left;
  max-width:100%;
  margin-left: 15px;
  font-size: 12px;
}
.live-search ul li .product-price {
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  float:right;
  /*width:25%;*/
}

.live-search ul li .product-price .price-special{
  color: #333333;
  display:block;

  font-size: 1.4286em;
  margin: -0.25em 0 0.75em;
  color: #444;
  line-height: 1;
  font-weight: 600;
}

.live-search ul li .product-price span.price{
  color: #333333;
  display:block;
  margin-top: 20px;
}
.live-search ul li .product-price .special{
  color: #FF0000;
  text-decoration: line-through;
  display:block;
}

.live-search .product-price > .special {
  margin-top: -5px;
}

.live-search .result-text{
  font-weight: bold;
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  color:#555555;
  background-color:#e6ffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.live-search .view-all-results:hover {
  color: #23527c;
}
.live-search .view-all-results {
  color: #23a1d1;
}

.category-block.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.quantity-adder .add-action {
    width: 38px;
    height: 17px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 7px;
    line-height: 20px;
    text-align: center;
    background-color: #2f2f2f;
    color: #FFFFFF;
}

.quantity-adder .add-up {
  margin-bottom: 1px;
}

.quantity-adder .quantity-number {
  width: 70%;
  display: inline-block;
  float:left;
  border-radius: unset;
}

.quantity-adder .quantity-title {
  margin-right: 20px;
  line-height: 34px;
}








.quantity  .quantity-number {
    font-size: 14px !important;
    line-height: 1.42857;
    color: rgb(85, 85, 85);
    background-color: rgb(255, 255, 255);
    background-image: none;
    box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px inset;
    padding: 6px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-image: initial;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    width: 2.5em;
    height: 2.7143em;
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 3px !important;
}

.quantity .add-up {
    display: block;
    position: absolute;
    top: 1px;
    right: 18px;
    text-align: center;
    display: block;
    padding: 0 0 0 1px;
    border-radius: 3px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    font-size: 9px;
    font-weight: normal;
    width: 15px;
    height: 16px;
    line-height: 14px;
}

.quantity .add-down {
    display: block;
    position: absolute;
    top: 22px;
    right: 18px;
    text-align: center;
    display: block;
    padding: 0 0 0 1px;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 9px;
    font-weight: normal;
    width: 15px;
    height: 16px;
    line-height: 16px;
}

 .update_post  {

    min-height: 50vh;
}

.product-info #tab-description h1 {

    font-size: 24px;
    margin-top: 0;
    margin: unset;
}

.product-info #tab-description p {

    margin: unset;
}


/* =============================================
-------------------  1.General ---------------
==============================================*/
/* Overwrite bootstrap values */
@media (min-width: 1440px){
	.container {
	    width: 1418px;
	}
}
header {
	position: relative;
	/*background-color: #9ac93d;*/
	color: #888;
}
nav#top {
	position: relative;
	z-index: 1;
	font-size: 0.7857em;
	background-color: #950707;
}


.container-fluid {
	padding:0 30px;
}
.row {
	margin-left:-10px;
	margin-right:-10px;
}

.row.row-lg {
	margin-left:-15px;
	margin-right: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-sm-25, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-right: 10px;
  padding-left: 10px;
}

[class^="col-"].padding-lg {
	padding:0 15px;
}

#wrapper.boxed.boxed-short .row {
	margin-left:-8px;
	margin-right:-8px;
}

#wrapper.boxed.boxed-short [class^="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}


/* fix outlines on focus */
*:focus {
	outline:none;
}

html {
	min-height:100%;
}

body {
	font: 400 14px/22px "Open Sans", Arial, sans-serif;
	color: #333;
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x:hidden;
}

body.index5 {
	background-color: #e6e6e3;
	background-image: url('../images/body_bg.png');
	background-repeat: repeat-x;
	background-size: auto;
	background-position: center top;
}

body.index9 {
	background-color: #f5f5f5;
}

@media (min-width: 1320px) {
	.skin13 .container {
		width:1280px;
	}
}

#wrapper {
	width: 100%;
	position: relative;
}

#wrapper.boxed,
#wrapper.boxed-long {
	width:100%;
	margin:0;
}

@media (min-width: 768px) {

	#wrapper.boxed {
		width: 750px;
		margin: 26px auto;
	}

	#wrapper.boxed-long {
		width:750px;
		margin:0 auto;
	}
}

@media (min-width: 992px) {

	#wrapper.boxed {
		width: 970px;
		margin: 32px auto;
	}

	#wrapper.boxed-long,
	.custom-wrapper.boxed-long,
	.boxed .sticky-menu.fixed .container,
	.boxed-long .sticky-menu.fixed .container {
		width:970px;
		margin:0 auto;
	}

	.custom-wrapper {
		padding-top:22px;
	}

	#wrapper.boxed.boxed-short,
	#wrapper.boxed.boxed-short .container {
		width:940px;
		margin:0 auto;
	}
}

@media (min-width: 1054px) {
	#wrapper.boxed.boxed-short,
	#wrapper.boxed.boxed-short .container {
		width:994px;
		margin:0 auto;
	}
}

@media (min-width: 991px) {
	#wrapper.boxed.boxed-short .main-content .container,
	#wrapper.boxed.boxed-short header .container,
	#wrapper.boxed.boxed-short .page-top .container {
		padding: 0px;
	}
}

@media (min-width: 991px) {
	#wrapper.boxed-long .main-content .container {
		padding: 0px;
	}
}

@media (min-width: 1200px) {

	#wrapper.boxed {
		width:1170px;
	}

	#wrapper.boxed-long,
	.custom-wrapper.boxed-long,
	.boxed .sticky-menu.fixed .container,
	.boxed-long .sticky-menu.fixed .container {
		width:1170px;
	}
}

::selection {
    background-color: #9ac93d;
    color: #fff;
}

::-moz-selection {
	background-color: #9ac93d;
    color: #fff;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    font-weight: 200;
    letter-spacing: normal;
	color:#0e0e0e;
	margin:0 0 14px;
}

h1,
.h1 {
    font-size: 2.4em;
    line-height: 44px;
    margin: 0 0 44px;
    letter-spacing: -1px;
}

h2,
.h2 {
    font-size: 1.7em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 20px;
    letter-spacing: -1px;
}

h3,
.h3 {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 20px;
}

h4,
.h4 {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 27px;
}

h5,
.h5 {
    font-size: 1em;
    font-weight: 600;
    line-height: 18px;
}

h6,
.h6 {
    font-size: 1em;
    font-weight: 400;
    line-height: 18px;
    margin-bottom:14px;
}

h1.big {
    font-size: 4.2em;
    line-height: 54px;
}

@media (max-width: 767px) {
    h1.big {
        font-size: 3.2em;
        line-height: 42px;
    }
}

h1.short {
    margin-bottom: 15px;
}

h2.short {
    margin-bottom: 15px;
}

h3.short, h4.short, h5.short, h6.short {
    margin-bottom: 9px;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

ul,ol {
	list-style-type:square;
	margin:0 0 25px;
}

p {
	line-height: 24px;
	margin: 0 0 20px;
}

.lead {
	font-size: 16px;
	font-weight: 400;
}

@media (min-width: 768px) {
	.lead {
		font-size: 16px;
	}
}

a,
a:hover,
a:focus {
    color: #777;
    cursor: pointer;
}

a:active,
a:hover,
a:focus {
	text-decoration:none;
	color: #9ac93d;
}

a.thumbnail.active, a.thumbnail:focus, a.thumbnail:hover {
	text-decoration:none;
	color: #9ac93d !important;
}

a:active,
a:focus,
input,
select,
textarea {
	outline:none;
}

iframe {
	border:none;
}

select {
    padding: 6px 0 6px 2px;
}

textarea {
    resize: vertical;
}

form label {
    font-weight: normal;
}

img.pull-right {
	margin: 7px 0 7px 24px;
}

img.pull-left {
	margin: 7px 24px 7px 0;
}

hr {
	background-image: - webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
	border: 0;
	height: 1px;
	margin: 22px 0;
}

.widget-block hr {
	margin: 35px 0;
}

blockquote {
	position: relative;
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 1em;
	border-left: 5px solid #eee;
}


/* =============================================
----------------- 2.Helper Classes -------------
==============================================*/

.xss-margin,
.xs-margin,
.sm-margin,
.md-margin,
.lg-margin,
.xlg-margin,
.md-margin2x,
.lg-margin2x,
.xlg-margin2x,
.md-margin3x,
.lg-margin3x,
.xlg-margin3x {
	min-height:1px;
}

.xss-margin {
	margin-bottom:5px;
}

.xs-margin {
	margin-bottom:10px;
}

.xs-margin.half {
	margin-bottom:15px;
}

.sm-margin {
	margin-bottom:20px;
}

.sm-margin.half {
	margin-bottom:25px;
}

.md-margin {
	margin-bottom:30px;
}

.md-margin.half {
	margin-bottom:35px;
}

.lg-margin {
	margin-bottom:40px;
}

.lg-margin.half {
	margin-bottom:45px;
}

.xlg-margin {
	margin-bottom:50px;
}

.xlg-margin.half {
	margin-bottom:55px;
}

.md-margin2x {
	margin-bottom:60px;
}

.md-margin2x.quarter {
	margin-bottom:65px;
}

.md-margin2x.half {
	margin-bottom:70px;
}

.lg-margin2x {
	margin-bottom:80px;
}

.xlg-margin2x {
	margin-bottom:100px;
}

.xlg-margin2x.half {
	margin-bottom:110px;
}

.md-margin3x {
	margin-bottom:90px;
}

.lg-margin3x {
	margin-bottom:120px;
}

.xlg-margin3x {
	margin-bottom:150px;
}

.clear-margin {
	margin:0 !important;
}

@media (max-width: 580px) {
	.nav-mini .hidden-xss {
		display:none;
	}
}

@media (max-width: 480px) {
	.hidden-xss {
		display:none !important;
	}
	/* helper grid class*/
	.col-xss-12 {
		float:none !important;
		width:100% !important;
	}

	.col-xss-6 {
		width:50% !important;
	}
}

@media (max-width: 300px) {
	.col-xss-6 {
		float:none !important;
		width:100% !important;
	}
}



/* =============================================
---------  4.Mobile Nav + Header/Menu  ---------
==============================================*/

#mobile-menu {
	position: fixed;
	left:-250px;
	top:0;
	bottom:0;
	background-color: #1e1e1e;
	width:250px;
	z-index:1600;
	color:#fff;
	transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	visibility: hidden;
    font-size: 0.9em;
    overflow-x: hidden;
    overflow-y: scroll;
}

body.opened-menu #mobile-menu {
	left:0;
	visibility: visible;
}

 #main-content {
	position: relative;
	left:0;
	right: 0;
	transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
}
body.opened-menu #main-content {
	position: relative;
	left:250px;
}

#mobile-menu-overlay {
	position: fixed;
	right:0;
	left:0;
	top:0;
	bottom:0;
	background-color: rgba(0,0,0, 0.35);
	z-index:1550;
	cursor:pointer;
	transition: opacity 0.2s ease-in-out 0s;
	-webkit-transition:opacity 0.2s ease-in-out 0s;
	opacity:0;
	visibility: hidden;
}

body.opened-menu #mobile-menu-overlay  {
	opacity:1;
	visibility: visible;
}

#mobile-menu .welcome-msg {
	color:#fff;
    text-align: center;
    display: block;
    margin: 15px 0;
    padding: 0 10px;
}

#mobile-menu .menu-wrap {
    margin: 20px 0;
    padding: 0 10px;
}

#mobile-menu .accordion-menu {
	font-size:0.8571em;
	margin:0;
}

/* Mobile Menu */
#mobile-menu .mobile-menu.accordion-menu {
	font-size:1em;
}

.accordion-menu li {
	line-height: 24px;
}

.accordion-menu > li {
	position: relative;
}

.accordion-menu li a {
    position: relative;
    display: block;
    outline: medium none;
    text-decoration: none;
    margin: 1px 0 0;
    padding: 5px 6px;
    line-height: 25px;
    font-weight: 400;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
    color:#fff;
}

.accordion-menu li.open > a,
.accordion-menu li > a:hover {
	background-color: #2b2b2b;
}

.accordion-menu > li > a {
	padding-right: 33px;
}

.accordion-menu li a img {
	display: inline-block;
	margin: 7px 4px 0px 0px;
	vertical-align: top;
}

.accordion-menu .arrow {
    border-radius: 3px;
    position: absolute;
    top: 6px;
    right: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-align: center;
    font-family: "FontAwesome";
    line-height: 25px;
    vertical-align: top;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
    color:#fff;
}

.accordion-menu .arrow:before {
    content: "\f067";
}

.accordion-menu .open > a > .arrow:before {
    content: "\f068";
}

.accordion-menu ul {
	display: none;
	padding-left:10px;
}

@media (max-width: 767px) {
	.container {
		width: 100%;
	}
}


/* Header */
#header {
	position: relative;
/*	background-color: #9ac93d;*/
	color:#777;
}

#header.header-absolute {
	position: absolute;
	top:0;
	left:0;
	right:0;
	background-color: transparent;
	z-index:1030;
	border-bottom:none;
}


#header #header-top .container,
#header #header-inner .container {
    position: relative;
    display: table;
}

.skin17 #header #header-inner .container {
	width:100%;
	padding:12px 30px 12px 45px;
}

#header #header-top .container > div,
#header #header-inner .container > div,
#header #header-inner .container-fluid > div {
    display: table-cell;
    position: relative;
    vertical-align: middle;
}


#header .header-left {
    text-align: left;
}

#header .header-center {
    text-align: left;
}

#header .header-right {
    text-align: right;
}

#header #header-inner .container {
    min-height: 68px;
}


#header #header-inner .container .header-left {
    padding: 30px 0;
}

@media (max-width: 1199px) {
    #header #header-inner .container .header-left {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media (max-width: 767px) {
    #header #header-inner .container .header-left {
        padding-top: 15px;
        padding-bottom: 15px;
        display: inline-block;
    }
}

#header #header-inner .container .header-center {
    padding: 30px 40px;
}

@media (max-width: 1199px) {
    #header #header-inner .container .header-center {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media (max-width: 767px) {
    #header #header-inner .container .header-center {
        padding-top: 15px;
        padding-bottom: 15px;
        display: inline-block;
    }
}

#header #header-inner .container .header-right {
    padding: 30px 0;
}

@media (max-width: 1199px) {
    #header #header-inner .container .header-right {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}
@media (max-width: 767px) {
    #header #header-inner .container .header-right {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

#header #header-top {
    position: relative;
    z-index: 1;
   /* background-color:#d94f19;*/

}

#header .separator {
	margin:0 8px;
	font-weight: 300;
}

#header .currency-switcher,
#header .view-switcher,
#header .top-links {
    font-size: 0.7857em;
    display: inline-block;
    vertical-align: middle;
    margin: 3px 0;
}

#header #header-top .currency-switcher,
#header #header-top .view-switcher,
#header #header-top .top-links {
	margin:0;
	font-size:1em;
}

#header .currency-switcher li,
#header .view-switcher li {
	position: relative;
	display: inline-block;
	line-height: 24px;
}

#header .currency-switcher > li,
#header .view-switcher > li,
#header .top-links > li {
	float:left;
}

#header .view-switcher img{
	display: inline-block;
	margin: 7px 3px 0 0;
	vertical-align: top;
}

.header_10 .view-switcher ul img {
	margin-top: 3px;
}

#header .currency-switcher a,
#header .view-switcher a,
#header .top-links a {
	color:#333;
}

#header .top-links a:hover {
	color:#eee;
}

#header .currency-switcher li > a > i,
#header .view-switcher li > a > i {
	margin:1px 0 -1px 5px;
}

#header .welcome-msg {
    display: inline-block;
    vertical-align: middle;
}

#header #header-top .welcome-msg {
	line-height: 36px;
}

#header .switcher-wrap {
    display: inline-block;
    white-space: nowrap;
}

#header .switcher-wrap ul:first-child {
    margin-left: 0;
}

#header .currency-switcher,
#header .view-switcher,
#header .top-links {
    font-size: 0.7857em;
    display: inline-block;
    vertical-align: middle;
    margin: 3px 0;
}

#header .currency-switcher li > ul,
#header .view-switcher li > ul {
	display:none;
	position: absolute;
	left:0;
	top:100%;
	z-index: 900;
	padding:5px;
	background-color: #fff;
	min-width: 94px;
	border-radius: 0 0 6px 6px;
    border:1px solid #ccc;
}

#header .currency-switcher li:hover > ul,
#header .view-switcher li:hover > ul {
	display: block;
}


#header .currency-switcher li li,
#header .view-switcher li li {
	display: block;
}

#header .currency-switcher li:hover > a,
#header .view-switcher li:hover > a {
	background-color: #9ac93d;
	color:#fff;

}

#header .currency-switcher li ul a,
#header .view-switcher li ul a {
	display: inline-block;
	font-size: 1em;
    padding: 0 7px;
    line-height: 25px;
    border-bottom-width: 0;
    text-transform: uppercase;

}

#header .currency-switcher li li:hover > a,
#header .view-switcher li li:hover > a {
	background-color: #9ac93d;
	color:#fff;

}


#header .top-links > li:after {
    content: "|";
    font-weight: 300;
    position: relative;
    top: -1px;
    margin: 0 2px 0 4px;
}

#header .top-links > li:last-child:after {
    display: none;
}

#header .currency-switcher > li + li,
#header .view-switcher > li+ li,
#header .top-links > li + li {
    margin-left: 0;
}

#header .currency-switcher > li > a,
#header .view-switcher > li > a,
#header .top-links > li > a {
	display: inline-block;
    font-size: 1em;
    font-weight: normal;
    padding: 0 5px;
    border-radius: 4px;
    line-height: 26px;
    text-transform: uppercase;
}


#header .currency-switcher,
#header .view-switcher {
    margin-left: 11px;
}

#header .top-links {
    white-space: nowrap;
}

@media (max-width: 991px) {
    #header .top-links:not(.visible-top-links) {
        display: none;
    }
}

#header .top-links > li {
    display: inline-block;
    margin-left: -2px;
    float: none;
}

#header .top-links > li.active > a,
#header .top-links > li:hover > a,
#header .top-links > li.active > h5,
#header .top-links > li:hover > h5 {
    background: transparent;
    color:#84ac34;
}


#header #header-top .currency-switcher > li > a,
#header #header-top .view-switcher > li > a,
#header #header-top .top-links > li > a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 0 !important;
}

@media (max-width: 1199px) {
    #header #header-top .currency-switcher > li > a,
    #header #header-top .view-switcher > li > a,
    #header #header-top .top-links > li > a {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
}

#header #header-top .welcome-msg {
    line-height: 36px;
}

@media (max-width: 1199px) {
    #header #header-top .welcome-msg {
        line-height: 32px;
    }
}

.logo {
	width: 150px;
	margin:0;
}

@media (max-width: 480px) {
    #header .logo {
        text-align: center;
        margin: -15px 0 0;
    }
}

#header .logo a {
    display: inline-block;
    max-width: 100%;
}

#header .logo img {
    display: inline-block;
    color: transparent;
    max-width: 100%;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    min-width: 110px;
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center;
}

@media (max-width: 480px) {
    #header .logo img {
        max-width: 200px;
    }
}

#header #header-inner .header-minicart {
	position: relative;
	padding-right: 60px;
}

#header #header-inner .header-contact {
	color:#fff;
    margin: 0px 20px 0px 0px;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}

#header .header-contact i {
	display: inline-block;
	margin:0 5px;
}

#header #header-inner .header-contact .separator {
	margin:0 11px;
}

#header #header-inner .header-contact a {
	color:#fff;
	font-size:0.8571em;
}

#header #header-inner .header-contact a:hover  {
	color:#eee;
}

#mini-cart {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
    height: 44px;
    margin: -22px auto;
}

#mini-cart .cart-head {
    position: relative;
    cursor: pointer;
    margin-top: 0;
    min-width: 50px;
    text-align: center;
}


#mini-cart .minicart-icon {
    font-size: 60px;
    top:-10px;
    display: inline-block;
    color:  #9ac93d;;
	position: relative;
}

#mini-cart .cart-items {
    position: absolute;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    bottom: 0px;
    left: 0;
    right: 0;
    top: 10px;
    text-align: center;
    display: inline-block;
    color:#fff;
}
#mini-cart .cart-price {
  position: absolute;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  bottom: 0px;
  left: 0px;
  right: 0;
  top: 25px;
  text-align: center;
  display: inline-block;
  color:#000;
}

#mini-cart.dropdown .dropdown-menu {
    width: 640px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    left: auto;
    right: 0;
    padding: 0;
    font-size: 1em;
    border-radius: 0px;
    border: none;
    border-top: 1px solid #cacaca;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

#mini-cart.dropdown:hover .dropdown-menu {
	display: block;
}

#mini-cart.dropdown .dropdown-menu ul {
	margin-bottom: 0;
}

#mini-cart.dropdown .dropdown-menu ul li.empty {
	padding:10px 0;
	text-align: center;
}

@media (max-width: 767px) {
    #mini-cart.dropdown .dropdown-menu {
        width: 490px;
    }
}

@media (max-width: 600px) {
    #mini-cart.dropdown .dropdown-menu {
        width: 290px;
    }
}
#mini-cart.dropdown .dropdown-menu:before {
    content: '';
    position: absolute;
    top: 0px;
    right: 17px;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-bottom: 0px solid #950707;
}

#mini-cart.dropdown .dropdown-menu:after {
    content: '';
    position: absolute;
    top: -17px;
    right: 0;
    left: 0;
    display: block;
    height: 17px;
}

.header-minicart-box #mini-cart.dropdown .dropdown-menu:before {
    right: 25px;
}

#mini-cart .dropdown-cart-content {
    background: #fff;
    color: #777;
    border-radius: 0px;
    margin-top: 0px;
    padding: 15px;
}


#header .searchform {
    border: 1px solid #9ac93d;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    line-height: 40px;
    font-size: 13px;
    padding: 0;
    vertical-align: middle;
    width: 468px;
	background-color: #9ac93d;
}

#header .searchform:before,
#header .searchform:after {
    content: " ";
    display: table;
}

#header .searchform:after {
    clear: both;
}

#header .searchform:before {
    content: "";
    display: none;
    position: absolute;
    left: 27px;
    top: -23px;
    width: 22px;
    height: 22px;
    border: 11px solid transparent;
    border-bottom-color: inherit;
}

#header .searchform fieldset {
    padding:0;
    margin:0 -2px 0 0;
    border:0;
    min-width: 0;
}

@media (max-width: 1199px) {
    #header .searchform {
        width: 368px;
    }
}

@media (max-width: 991px) {
    #header .searchform {
        border-width: 5px;
        border-radius: 25px;
        width: 378px;
        display: none;
        position: absolute;
        left: -30px;
        top: 35px;
        z-index: 111;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    }

    #header .searchform:before {
        display: block;
    }
}

#header .searchform .text,
#header .searchform .cat,
#header .searchform .button-wrap {
	float: left;
	display: inline-block;
}

#header .searchform input,
#header .searchform select,
#header .searchform button {
    background: transparent;
    border-radius: 0;
    float: left;
    color: #fff;
    border: none;
    height: 40px;
    line-height: 40px;
    outline: none;
    box-shadow: none;
    margin-bottom: 0;
}

#header .searchform input {
    padding: 0 15px 0 20px;
    border-radius: 20px 0 0 20px;
    width: 400px;
}

#header .searchform input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #fff;
}
#header .searchform input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #fff;
   opacity:  1;
}
#header .searchform input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #fff;
   opacity:  1;
}
#header .searchform input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #fff;
}

@media (max-width: 1199px) {
    #header .searchform input {
        width: 320px;
    }
}

@media (max-width: 1199px) {
    #header .searchform input {
        /* width: 190px; */
    }
}

#header .searchform button {
    font-size: 14px;
    padding: 0 20px 0 13px;
    border-radius: 0 20px 20px 0;
    text-shadow: none;
    color:#fff;
}

#header .searchform select {
    padding: 0 0 0 10px;
    width: 130px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

#header .searchform select option {
    background: #fff;
    color: #777;
}

#header .searchform input,
#header .searchform select {
    border-right: 1px solid #fff;
}


#header .searchform-popup {
    display: inline-block;
    position: relative;
}

#header .searchform-popup .search-toggle {
    cursor: pointer;
    display: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    z-index: 112;
    color:#84ac34;
}
@media (max-width: 991px) {
    #header .searchform-popup .search-toggle {
        display: block;
    }
}

#header .searchform.opened {
	display: block !important;
	border-color:#cccccc;
}

#header .show-menu-search .searchform-popup {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    margin: auto;
}

@media (max-width: 991px) {
    #header .show-menu-search .searchform-popup {
        position: relative;
        left: 0;
    }
}

#header .mobile-toggle {
	color:#84ac34;;
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: none;
}

@media (max-width: 991px) {
    #header .mobile-toggle {
        display: inline-block;
    }
}

@media (max-width: 480px) {
    #header .mobile-toggle {
        position: absolute;
        left: 15px;
        top: 6px;
    }
}


@media (max-width: 480px) {
    #header #header-inner .container {
        display: block;
        padding-top: 28px;
        padding-bottom: 15px;
        min-height: 43px;
    }
    #header #header-inner .container > * {
        display: inline !important;
        padding: 0 !important;
        position: static !important;
    }

    #header .searchform-popup {
        position: absolute;
        left: 45px;
        top: 6px;
        bottom: auto;
    }

    #header .searchform {
    	width:288px;
    	border-width: 3px;
    }

    #header .searchform select {
    	width:116px;
    	padding:0 0 0 5px;
    }

	#header .searchform:before {
        width: 14px;
        border-width: 7px;
        left: 34px;
        right: auto;
        top: -23px;
	}

    #header .searchform input {
    	width:120px;
    	padding:0 15px;
    }

    #header #mini-cart {
    	background-color: #930000;
        font-size: 0.8462em;
        width: auto;
        height: auto;
        position: absolute;
        right: 15px;
        top: 9px;
        bottom: auto;
        margin: 0;
        border-radius: 4px;
        border-width: 0;
    }

    #header #mini-cart .minicart-icon {
        font-size: 1.2727em;
        vertical-align: baseline;
				display: none;
    }

    #header #mini-cart .cart-head {
        padding: 0 10px;
        line-height: 26px;
        height: 26px;
        margin: 0;
        white-space: nowrap;
    }

    #header #mini-cart .cart-items {
        font-size: 1em;
        margin-left: 3px;
        position: relative;
        top: -3px;
        vertical-align: middle;
        color:#fff;
    }

    #header #mini-cart.dropdown .dropdown-menu {
        margin-top: 8px;
        font-size:14px;
    }

    #header #mini-cart.dropdown .dropdown-menu:before {
        right: 18px;
    }
}

/* Header2 - index3.html*/
#header.fixed-header{
	position: relative;
	left:0;
	top:0;
	right:0;
	z-index: 999;
}

#header.fixed-header .sub-menu {
	display: block !important;
}

.common-home #header.fixed-header{
	position: fixed;
	left:0;
	top:0;
	right:0;
	z-index: 999;
}


#header.header2 {
	background-color: #1e1e1e;
}

.common-home #header.header2 {
	background: rgba(0,0,0,.5);
}

#header.fixed-header #header-inner .container > div {
	padding-top:15px;
	padding-bottom: 15px;
}

@media (max-width: 767px) {
	#header.fixed-header #header-inner .container > div {
		padding-top:8px;
		padding-bottom: 8px;
	}
}

#header.header2 #header-inner .container .header-center {
	position: static;
}

#header.header2 .menu {
	position: static;
	vertical-align: middle;
}

@media (min-width: 992px) {
	#header.header2 #header-center-wrapper {
		display: inline-block;
		white-space: nowrap;
	}
}

.header2 .menu > li.open > a > i,
.header2 .menu > li.active > a > i{
	color:#fff;
}

.header2 .menu > li.open > a,
.header2 .menu > li.active > a {
	background-color: #000;
	color:#fff;
}

.header2 .menu > li > a {
	padding:9px 12px 8px;
	border-radius: 4px;
}

@media (max-width: 1199px) {
	.header2 .menu > li > a {
		padding:9px 9px 8px;
	}

}

.header2 .menu ul,
.header2 .menu .megamenu {
	border-color: #000;
}

.header2 .menu .megamenu-container .megamenu {
	left:15px;
	right:15px;
	width:auto;
	top:auto;
}

.header2 .menu > li > a > .tip {
	top:-9px;
	right:10px;
}

.block-nowrap {
	display: inline-block;
	white-space: nowrap;
}

#header.header2 .search-toggle {
	display:inline-block;
}

#header.header2 .searchform {
	position: absolute;
	display: none;
	border-color:#cccccc;
	border-width: 5px;
	left:auto;
	right:-27px;
	background-color: #fff;
	z-index: 900;
}

#header.header2 .searchform input,
#header.header2 .searchform select,
#header.header2 .searchform button  {
	color:#999;
	border-color:#ccc;
}

#header.header2 .searchform button:hover {
	color:#3b3b3b;
}

#header.header2 .searchform {
	width:378px;
}

@media (min-width: 1200px) {
	#header.header2 .searchform {
		width:478px;
	}
}

@media (max-width: 767px) {
	#header.header2 .searchform {
		width:380px;
	}
}

#header.header2 .searchform:before {
	display: block;
	left:auto;
	right:27px;
}

@media (max-width: 480px) {

	.header-minicart-inline {
		line-height: 1;height: 0;
	}
	#header.header2 .searchform {
		right:auto;
		left:-27px;
		width:290px;
	}


	#header.header2 .searchform:before {
		right:auto;
		left:27px;
	}
}

#mini-cart.minicart-inline {
	background-color: #000 !important;
    font-size: 0.7857em;
    width: auto;
    height: auto;
    position: relative;
    top: auto;
    bottom: auto;
    margin: 3px 0 3px 7px;
    border-radius: 4px;
}

#mini-cart.minicart-inline .minicart-icon {
    font-size: 1.2727em;
    vertical-align: top;
}

#mini-cart.minicart-inline .cart-items {
    font-size: 1em;
    margin-left: 3px;
    position: relative;
    top: -1px;
}

#mini-cart.minicart-inline .cart-head {
    padding: 0 10px;
    line-height: 26px;
    height: 26px;
    white-space: nowrap;
    margin-top: 1px;
    margin-bottom: -1px;
}

#mini-cart.minicart-inline .dropdown-menu {
	border-color:#000;
	margin-top:8px;
	font-size: 14px;
}

#mini-cart.minicart-inline .cart-items {
	color:#fff;
}

#mini-cart.dropdown.minicart-inline .dropdown-menu:before {
	border-bottom-color:#000;
}

#header.header2 .view-switcher {
	margin-left:8px;
}

#header.header2 .currency-switcher,
#header.header2 .view-switcher {
	text-align: left;
}

#header.header2 .currency-switcher > li > a,
#header.header2 .view-switcher > li > a {
	border-radius: 4px 4px 0 0;
	background-color: #000;
}

#header.header2 .currency-switcher > li:hover > a,
#header.header2 .view-switcher > li:hover > a,
#header.header2 .currency-switcher li > ul,
#header.header2 .view-switcher li > ul,
#header.header2 .view-switcher > li li:hover > a,
#header.header2 .currency-switcher > li li:hover > a {
	background-color: #000;
}

/* Header3 - index4.html*/
#header.header3 {
	background-color: #fff;
	border-top:4px solid #9ac93d;
}

#header.header3 #header-top {
	background-color: #f0f0ed;
}

#header.header3 #menu-container {
	background-color: transparent;
	padding-bottom:15px;
}

#header.header3 .menu {
	background-color: #f0f0ed;
	border-radius: 6px;
}

#header.header3 .menu > li > a {
	padding:11px 13px 9px 13px;
}

#header.header3 .menu > li > a,
#header.header3 .menu > li > a > i {
	color:#9ac93d;
}

#header.header3 .menu > li.open > a,
#header.header3 .menu > li.open > a > i,
#header.header3 .menu > li.active > a,
#header.header3 .menu > li.active > a > i {
	color:#fff;
}

#header.header3 .menu > li.pull-right.open > a,
#header.header3 .menu > li.pull-right.open > a i {
	color:#950707;
	background-color: transparent;
}


#header.header3 .menu > li.open > a,
#header.header3 .menu > li.active > a {
	background-color: #9ac93d;
	border-radius: 4px;
}

#header.header3 .menu > li.open > a {
	border-radius: 4px 4px 0 0;
}

#header.header3 .menu > li > a > .tip {
	right:13px;
	top:-4px;
}

#header.header3 .currency-switcher > li > a,
#header.header3 .view-switcher > li > a,
.header3 #header-top .welcome-msg,
.header3 #header-top .separator,
#header.header3 .top-links {
	color:#777;
}

#header.header3 .currency-switcher > li:hover > a,
#header.header3 .view-switcher > li:hover > a {
	color:#fff;
	background-color: #9ac93d;
}

#header.header3 .currency-switcher > li > ul,
#header.header3 .view-switcher > li ul {
	background-color: #9ac93d;
}

#header.header3 .currency-switcher li li:hover > a,
#header.header3 .view-switcher li li:hover > a {
	background-color: #0099e6;
}

#header.header3 .top-links a,
#header.header3 #header-inner .header-contact a,
#header.header3 #mini-cart .minicart-icon,
#header.header3 .searchform button:hover,
#header.header3 .searchform-popup .search-toggle,
#header.header3 .mobile-toggle {
	color:#9ac93d;
}

#header.header3 .top-links a:hover,
#header.header3 #header-inner .header-contact a:hover,
#header.header3 .searchform-popup .search-toggle:hover,
#header.header3 .mobile-toggle:hover {
	color:#0099e6;
}

#header.header3 #header-inner .header-contact {
	color:#777;
}

#header.header3 #mini-cart .cart-items {
	color:#fff;
}

#header.header3 .searchform {
	background-color: #fff;
	border-color:#ccc;
}

#header.header3 .searchform input,
#header.header3 .searchform select {
	border-color:#ccc;
	color:#777;
}

#header.header3 .searchform button {
	color:#777;
}

#header.header3 #mini-cart.dropdown .dropdown-menu {
	border-color:#9ac93d;
}

#header.header3 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #9ac93d;
}

@media (max-width: 480px) {
	#header.header3 #mini-cart {
		background-color: transparent;
	}

	#header.header3 #mini-cart .cart-items {
		color:#9ac93d;
	}
}

/* Header4 - index5.html */
.skin5 {
	border-top:4px solid #9ac93d;
}

#header.header4 {
	background-color: transparent;
}

@media (min-width: 768px) {
	#header.header4 .container {
		padding-right:0;
		padding-left:0;
	}
}

#header.header4 #header-top {
	background-color: transparent;
}

#header.header4 #menu-container {
	background-color: #9ac93d;
}


#header.header4 .menu li a {
	border-radius: 0 !important;
}

#header.header4 .menu > li > a {
	padding:11px 20px 9px 20px;
}


#header.header4 .menu > li > a,
#header.header4 .menu > li > a > i {
	color:#fff;
}

#header.header4 .menu > li.open > a,
#header.header4 .menu > li.open > a > i,
#header.header4 .menu > li.active > a,
#header.header4 .menu > li.active > a > i {
	color:#9ac93d;
}

#header.header4 .menu > li.pull-right.open > a,
#header.header4 .menu > li.pull-right.open > a i {
	color:#eee;
	background-color: transparent;
}

#header.header4 .menu > li.open > a,
#header.header4 .menu > li.active > a {
	background-color: #fff;
}

#header.header4 .tip {
	border-radius: 0;
}

#header.header4 .menu > li > a > .tip {
	right:24px;
	top:-4px;
}

#header.header4 .menu ul,
#header.header4 .menu .megamenu {
	border-top:none;
	border-radius: 0;
}

#header.header4 .menu .megamenu .megamenu-footer {
	border-radius: 0;
}

#header.header4 .currency-switcher > li > a,
#header.header4 .view-switcher > li > a,
.header4 #header-top .welcome-msg,
.header4 #header-top .separator,
#header.header4 .top-links {
	color:#777;
}

#header.header4 .currency-switcher > li:hover > a,
#header.header4 .view-switcher > li:hover > a {
	color:#fff;
	background-color: #9ac93d;
}

#header.header4 .currency-switcher > li > ul,
#header.header4 .view-switcher > li ul {
	background-color: #9ac93d;
	border-radius: 0;
}

#header.header4 .currency-switcher li li:hover > a,
#header.header4 .view-switcher li li:hover > a {
	background-color: #0099e6;
}

#header.header4 .top-links a,
#header.header4 #header-inner .header-contact a,
#header.header4 #mini-cart .minicart-icon,
#header.header4 .searchform button:hover,
#header.header4 .searchform-popup .search-toggle,
#header.header4 .mobile-toggle {
	color:#9ac93d;
}

#header.header4 .top-links a:hover,
#header.header4 #header-inner .header-contact a:hover,
#header.header4 .searchform-popup .search-toggle:hover,
#header.header4 .mobile-toggle:hover {
	color:#0099e6;
}

#header.header4 #header-inner .header-contact {
	color:#777;
}

#header.header4 #mini-cart .cart-items {
	color:#fff;
}

#header.header4 .searchform {
	background-color: #fff;
	border-color:#ccc;
	border-radius: 0;
	line-height: 32px;
}


#header.header4 .searchform input {
	width:302px;
	padding:0 10px 0 10px;
}

@media (max-width: 1053px) {
	#header.header4 .searchform {
		width:368px;
	}


	#header.header4 .searchform input {
		width:202px;
	}
}

@media (max-width: 991px) {
	#header.header4 .searchform {
		width:378px;
	}
}

@media (max-width: 767px) {
	#header.header4 .searchform {
		width:338px;
	}

	#header.header4 .searchform input {
		width:162px;
	}
}

@media (max-width: 480px) {
	#header.header4 .searchform {
		width:288px;
	}

	#header.header4 .searchform input {
		width:132px;
		padding:0 15px;
	}
}


#header.header4 .searchform input,
#header.header4 .searchform select {
	border-color:#ccc !important;
	color:#777;
}

#header.header4 .searchform select {
	padding:0 0 0 5px;
}

#header.header4 .searchform button {
	color:#777;
	padding:0 11px 0 10px;
}

#header.header4 .searchform input,
#header.header4 .searchform select,
#header.header4 .searchform button {
	height:32px;
	line-height:32px;
}

#header.header4 #mini-cart.dropdown .dropdown-menu {
	border-color:#9ac93d;
}

#header.header4 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #9ac93d;
}

#header.header4 #mini-cart .dropdown-cart-content,
#header.header4 #mini-cart.dropdown .dropdown-menu {
	border-radius: 0;
}

@media (max-width: 480px) {
	#header.header4 #mini-cart {
		background-color: transparent;
	}

	#header.header4 #mini-cart .cart-items {
		color:#9ac93d;
	}
}


/* header5 - index6.html */
#header.header5 {
	background-color: transparent;
}

#header.header5.fixed-header {
	position: absolute;
}

#header.header5 .container{
	width:100%;
}

@media (max-width: 991px) {
	#header.header5 #header-inner .container .header-left {
		display: none !important;
	}
}

#header.header5 .menu li a,
#header.header5 #mini-cart .dropdown-cart-content,
#header.header5 #mini-cart.dropdown .dropdown-menu,
#header.header5 .menu ul,
#header.header5 .menu .megamenu {
	border-radius: 0 !important;
}

#header.header5 .menu > li > a {
	padding:9px 12px 8px;
}

#header.header5 .menu > li > a,
#header.header5 .menu > li > a > i {
	color:#fff;
}

#header.header5 .menu > li.open > a,
#header.header5 .menu > li.open > a > i,
#header.header5 .menu > li.active > a,
#header.header5 .menu > li.active > a > i {
	color:#777;
}

#header.header5 .menu > li.open > a,
#header.header5 .menu > li.active > a {
	background-color: #fff;
}

#header.header5 .menu > li.megamenu-container > .megamenu {
	width:1140px;
}

@media (max-width: 1199px) {
	#header.header5 .menu > li.megamenu-container > .megamenu {
		width:940px;
	}
}

#header.header5 #mini-cart.dropdown .dropdown-menu {
	border-color:#000;
}

#header.header5 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #000;
}

#header.header5 .menu ul,
#header.header5 .menu .megamenu {
	border-top:none;
}


#header.header5 .menu > li > a > .tip {
	right:10px;
	top:-9px;
	border-radius: 0;
}

#header.header5 .search-toggle {
	display: inline-block;
}

#header.header5 .search-toggle:hover {
	color:#eee;
}

#header.header5 .searchform {
	display: none;
	position: absolute;
	left:auto;
	right:-27px;
	top:35px;
	background-color: #fff;
	border-color:#ccc;
	border-width: 5px;
	width:478px;
	border-radius: 0;
	z-index: 100;
}

#header.header5 .searchform:before {
	display: block;
	left:auto;
	right:27px;
}

#header.header5 .searchform input {
	width:302px;
	padding:0 10px 0 10px;
	border-color:#ccc;
}

#header.header5 .searchform select {
	padding:0 0 0 5px;
	border-color:#ccc;
}

#header.header5 .searchform input,
#header.header5 .searchform select,
#header.header5 .searchform button {
	height:32px;
	line-height: 32px;
	color:#777;
}

#header.header5 .searchform button {
	height:32px;
	line-height: 32px;
	padding:0 11px 0 10px;
}

@media (max-width: 1199px) {

	#header.header5 .searchform {
		width:378px;
	}

	#header.header5 .searchform input {
		width:202px;
	}
}

@media (max-width: 767px) {

	#header.header5 .searchform {
		width:338px;
	}


	#header.header5 .searchform input {
		width:162px;
	}
}

@media (max-width: 480px) {

	#header.header5 .searchform {
		width:288px;
		right:auto;
		left:-30px;
	}

	#header.header5 .searchform:before {
        width: 14px;
        border-width: 7px;
        left: 34px;
        right: auto;
        top: -23px;
        border-color: transparent;
        border-bottom-color: inherit;
	}

	#header.header5 .searchform input {
		width:126px;
		padding:0 15px;
	}
}

#header.header5 .search-popup .searchform:before {
	display: block;
}

#header.header5 #mini-cart.minicart-inline,
#header.header5 .currency-switcher > li > a,
#header.header5 .view-switcher > li > a {
	border-radius: 0;
	background-color: #fff !important;
}

#header.header5 #mini-cart .minicart-icon,
#header.header5 #mini-cart.minicart-inline .cart-items,
#header.header5 .currency-switcher > li > a,
#header.header5 .view-switcher > li > a {
	color:#777;
}

#header.header5 .currency-switcher > li > ul,
#header.header5 .view-switcher >li ul {
	background-color: #000;
	border-radius: 0;
	text-align: left;
}

#header.header5 .currency-switcher > li:hover > a,
#header.header5 .view-switcher > li:hover > a {
	background-color: #000 !important;
	color:#fff;
}

#header.header5 .currency-switcher li li:hover > a,
#header.header5 .view-switcher li li:hover > a {
	background-color: #000 !important;
}

/* header6 - index7.html */
#header.header6 {
	background-color: transparent;
	border-top:4px solid #56738e;
}

#header.header6 #header-top {
	background-color: #65829d;
}

#header.header6 .menu li a,
#header.header6 #mini-cart .dropdown-cart-content,
#header.header6 #mini-cart.dropdown .dropdown-menu,
#header.header6 .menu ul,
#header.header6 .menu .megamenu {
	border-radius: 0 !important;
}

#header.header6 .menu > li > a {
	padding:9px 12px 8px;
}

#header.header6 .menu > li > a,
#header.header6 .menu > li > a > i {
	color:#65829d;
}

#header.header6 .menu > li.open > a,
#header.header6 .menu > li.open > a > i,
#header.header6 .menu > li.active > a,
#header.header6 .menu > li.active > a > i {
	color:#fff;
}

#header.header6 .menu > li.open > a,
#header.header6 .menu > li.active > a {
	background-color: #65829d;
}

#header.header6 #mini-cart.dropdown .dropdown-menu,
#header.header6 .menu ul,
#header.header6 .menu .megamenu {
	border-color:#65829d;
}

@media (min-width: 992px) {
	#header.header6 .menu {
		display:inline-block;
		vertical-align: middle;
	}
}

#header.header6 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #65829d;
}

#header.header6 .tip {
	border-radius: 0;
}

#header.header6 .menu > li > a > .tip {
	right:10px;
	top:-9px;
}

#header.header6 #header-inner .container > .header-center,
#header.header6 .menu {
	position: static;
}

#header.header6 .menu .megamenu-container .megamenu {
	width:auto;
	left:15px;
	right:15px;
	top:auto;
}

#header.header6 .menu .megamenu .megamenu-footer {
	border-radius: 0;
}

#header.header6 .currency-switcher > li:hover > a,
#header.header6 .view-switcher > li:hover > a {
	color:#fff;
	background-color: #65829d;
}

#header.header6 .currency-switcher > li > ul,
#header.header6 .view-switcher > li ul {
	background-color: #65829d;
	border-radius: 0;
}

#header.header6 .currency-switcher li li:hover > a,
#header.header6 .view-switcher li li:hover > a {
	background-color: #758fa7;
}

#header.header6 #header-inner .header-contact a,
#header.header6 #mini-cart .minicart-icon {
	color:#65829d;
}

#header.header6 .searchform button:hover,
#header.header6 #header-inner .header-contact a:hover,
#header.header6 .searchform-popup .search-toggle:hover,
#header.header6 .mobile-toggle:hover {
	color:#65829d;
}

#header.header6 .searchform-popup .search-toggle,
#header.header6 .mobile-toggle,
#header.header6 #header-inner .header-contact {
	color:#777;
}

#header.header6 #mini-cart .cart-items {
	color:#fff;
}

#header.header6 .searchform-popup .search-toggle {
	display: inline-block;
}

#header.header6 .searchform-popup {
    display: inline-block;
    vertical-align: middle;
    z-index: 0;
}

#header.header6 .searchform {
	display: none;
	background-color: #fff;
	border-color:#ccc;
	border-radius: 0;
	line-height: 32px;
	border-width: 5px;
    position: absolute;
    left: -30px;
    top: 35px;
    z-index: 100;
}

#header.header6 .searchform:before {
	display: block;
}

#header.header6 .searchform input,
#header.header6 .searchform select {
	border-color:#ccc;
	color:#777;
}

#header.header6 .searchform select {
	padding:0 0 0 5px;
}

#header.header6 .searchform button {
	color:#777;
	padding:0 11px 0 10px;
}

#header.header6 .searchform input,
#header.header6 .searchform select,
#header.header6 .searchform button {
	height:32px;
	line-height:32px;
}

@media (max-width: 480px) {
	#header.header6 #mini-cart {
		background-color: transparent;
	}

	#header.header6 #mini-cart .cart-items {
		color:#65829d;
	}
}

/* header7 - index8.html */
#header.header7 {
	background-color: transparent;
}

#header.header7 #header-top {
	background-color: #f7f7f7;
}

#header.header7 #menu-container {
	background-color: transparent;
}

#header.header7 #header-inner .container .header-left,
#header.header7 #header-inner .container .header-right,
#header.header5 #header-inner .container .header-left,
#header.header5 #header-inner .container .header-right {
	width:42%;
}

#header.header7 #header-inner .container .header-right,
#header.header5 #header-inner .container .header-right {
	padding-right:0;
	padding-left: 20px;
}

#header.header7 #header-inner .container .header-left,
#header.header5 #header-inner .container .header-left {
	padding-right:20px;
	padding-left: 0;
}

#header.header7 #header-inner .container .header-center,
#header.header5 #header-inner .container .header-center {
	width:16%;
	padding-right:0;
	padding-left: 0;
	text-align: center;
}

@media (max-width: 991px) {
	#header.header7 #header-inner .container .header-left,
	#header.header7 #header-inner .container .header-center,
	#header.header7 #header-inner .container .header-right,
	#header.header5 #header-inner .container .header-left,
	#header.header5 #header-inner .container .header-center,
	#header.header5 #header-inner .container .header-right {
		width:auto;
	}

	#header.header7 #header-inner .container .header-left,
	#header.header7 #header-inner .container .header-center,
	#header.header5 #header-inner .container .header-left,
	#header.header5 #header-inner .container .header-center {
		display: inline-block;
	}
}


#header.header7 .menu li a,
#header.header7 #mini-cart .dropdown-cart-content,
#header.header7 #mini-cart.dropdown .dropdown-menu,
#header.header7 .menu ul,
#header.header7 .menu .megamenu {
	border-radius: 0 !important;
}

#header.header7 .menu {
	text-align: center;
}

#header.header7 .menu > li,
#header.header7 .menu > li.pull-right {
	float:none !important;
	display: inline-block;
}

#header.header7 .menu > li > a {
	padding:14px 26px 12px 0px;
}

#header.header7 .menu > li > a,
#header.header7 .menu > li > a > i {
	color:#828385;
}

#header.header7 .menu > li.open > a,
#header.header7 .menu > li.open > a > i,
#header.header7 .menu > li.active > a,
#header.header7 .menu > li.active > a > i {
	color:#e13b3f;
}

#header.header7 #mini-cart.dropdown .dropdown-menu,
#header.header7 .menu ul,
#header.header7 .menu .megamenu {
	border-color:#e13b3f;
}

#header.header7 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #e13b3f;
}

#header.header7 .tip {
	border-radius: 0;
}

#header.header7 .menu > li > a > .tip {
	right:26px;
	top:-1px;
}

#header.header7 .menu .megamenu .megamenu-footer {
	border-radius: 0;
}

#header.header7 #header-top,
#header.header7 .currency-switcher > li > a,
#header.header7 .view-switcher > li > a,
#header.header7 .top-links li:hover > a {
	color:#777;
}

#header.header7 .currency-switcher > li:hover > a,
#header.header7 .view-switcher > li:hover > a {
	color:#fff;
	background-color: #e13b3f;
}

#header.header7 .currency-switcher > li > ul,
#header.header7 .view-switcher > li ul {
	background-color: #e13b3f;
	border-radius: 0;
}

#header.header7 .currency-switcher li li:hover > a,
#header.header7 .view-switcher li li:hover > a {
	background-color: #e45155;
}

#header.header7 #header-inner .header-contact a,
#header.header7 #mini-cart .minicart-icon,
#header.header7 .top-links li  a,
#header.header7 .searchform-popup .search-toggle,
#header.header7 .mobile-toggle {
	color:#e13b3f;
}

#header.header7 .searchform button:hover {
	color:#e13b3f;
}

#header.header7 #header-inner .header-contact a:hover,
#header.header7 .searchform-popup .search-toggle:hover,
#header.header7 .mobile-toggle:hover,
#header.header7 #header-inner .header-contact {
	color:#777;
}

#header.header7 #mini-cart .cart-items {
	color:#fff;
}

#header.header7 .searchform {
	background-color: #fff;
	border-color:#ccc;
	border-radius: 0;
	line-height: 32px;
	width:348px;
}

#header.header7 .searchform input {
	width:182px;
	padding:0 10px 0 10px;
}

@media (max-width: 1199px) {
	#header.header7 .searchform {
		width:288px;
	}

	#header.header7 .searchform input {
		width:122px;
	}
}

@media (max-width: 991px) {
	#header .header-left .searchform {
		top:-6px;
		left:40px;
		width:298px;
	}

	#header .header-left .searchform:before {
        border-bottom-color: transparent;
        border-right-color: inherit;
        left: -23px;
        top: 5px;
    }

	#header.header7 .searchform input {
		width:124px;
	}
}

@media (max-width: 767px) {
	#header.header7 .searchform {
		width:338px;
	}

	#header.header7 .searchform input {
		width:162px;
	}
}

@media (max-width: 480px) {
	#header.header7 .searchform {
		width:288px;
		right:auto;
		left:-30px;
		top:35px;
	}

	#header .header-left .searchform:before {
		width: 14px;
		border-width: 7px;
		left: 34px;
		right: auto;
		top: -23px;
		border-color: transparent;
		border-bottom-color: inherit;
    }

	#header.header7 .searchform input {
		width:132px;
	}
}

#header.header7 .searchform input,
#header.header7 .searchform select {
	border-color:#ccc;
	color:#777;
}

#header.header7 .searchform select {
	padding:0 0 0 5px;
}

#header.header7 .searchform button {
	color:#777;
	padding:0 11px 0 10px;
}

#header.header7 .searchform input,
#header.header7 .searchform select,
#header.header7 .searchform button {
	height:32px;
	line-height:32px;
}


@media (max-width: 480px) {
	#header.header7 #mini-cart {
		background-color: transparent;
	}

	#header.header7 #mini-cart .cart-items {
		color:#e13b3f;
	}
}

/* header8 - index9.html */
#header.header8 {
	background-color: transparent;
}

#header.header8 #header-top {
	background-color: #f5f5f5;
}

#header.header8 #header-inner {
	background-color: #d0205e;
}

#header.header8 #menu-container {
	background-color: #fff;
	border-bottom:1px solid #eee;
}

#header.header8 .menu li a,
#header.header8 #mini-cart .dropdown-cart-content,
#header.header8 #mini-cart.dropdown .dropdown-menu,
#header.header8 .menu ul,
#header.header8 .menu .megamenu {
	border-radius: 0 !important;
}

#header.header8 #mini-cart.dropdown .dropdown-menu {
	border-color:#303030;
}

#header.header8 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #303030;
}

#header.header8 .menu .megamenu .megamenu-footer {
	border-radius: 0;
}

#header.header8 .tip {
	border-radius: 0;
}

#header.header8 #header-top,
#header.header8 .currency-switcher li a,
#header.header8 .view-switcher li a,
#header.header8 .top-links li a {
	color:#777;
}

#header.header8 .currency-switcher li:hover a,
#header.header8 .view-switcher li:hover a,
#header.header8 .currency-switcher li li a,
#header.header8 .view-switcher li li a {
	color: #fff !important;
}

#header.header8 .currency-switcher li li a,
#header.header8 .view-switcher li li a {
	color:#d0205e;
}

#header.header8 .currency-switcher > li:hover > a,
#header.header8 .view-switcher > li:hover > a {
	color:#d0205e;
	background-color: #f5f5f5;
}

#header.header8 .top-links li a:hover,
#header.header8 .searchform button:hover {
	color:#d0205e;
}

#header.header8 .currency-switcher > li > ul,
#header.header8 .view-switcher > li ul {
	background-color: #f5f5f5;
	border-radius: 0;
}

#header.header8 .currency-switcher li li:hover > a,
#header.header8 .view-switcher li li:hover > a {
	background-color: #fff;
}

#header.header8 #header-inner .header-contact a,
#header.header8 #mini-cart .minicart-icon {
	color:#fff;
}

#header.header8 #header-inner .header-contact a:hover,
#header.header8 .searchform-popup .search-toggle:hover,
#header.header8 .mobile-toggle:hover {
	color:#fff;
}

#header.header8 .searchform-popup .search-toggle,
#header.header8 .mobile-toggle,
#header.header8 #header-inner .header-contact {
	color:#fff;
}

#header.header8 #mini-cart .cart-items {
	color:#000;
}

#header.header8 .searchform {
	background-color: #fff;
	border-color:#ccc;
	border-radius: 0;
	line-height: 32px;
	width:468px;
}

#header.header8 .searchform input  {
	width:302px;
	padding:0 10px 0 10px;
}

@media (max-width: 1199px) {
	#header.header8 .searchform {
		width:368px;
	}

	#header.header8 .searchform input  {
		width:202px;
	}
}

@media (max-width: 991px) {
	#header.header8 .searchform {
		width:378px;
	}

	#header.header8 .searchform input  {
		width:202px;
	}
}

@media (max-width: 767px) {
	#header.header8 .searchform {
		width:338px;
	}

	#header.header8 .searchform input  {
		width:162px;
	}
}

@media (max-width: 480px) {
	#header.header8 .searchform {
		width:288px;
	}

	#header.header8 .searchform input  {
		width:132px;
	}
}

#header.header8 .searchform input,
#header.header8 .searchform select {
	border-color:#ccc;
	color:#777;
}

#header.header8 .searchform select {
	padding:0 0 0 5px;
}

#header.header8 .searchform button {
	color:#777;
	padding:0 11px 0 10px;
}

#header.header8 .searchform input,
#header.header8 .searchform select,
#header.header8 .searchform button {
	height:32px;
	line-height:32px;
}

@media (max-width: 480px) {
	#header.header8 #mini-cart {
		background-color: transparent;
	}

	#header.header8 #mini-cart .cart-items {
		color:#fff;
	}
}

.header8 .sidebar-menu {
	border-bottom:5px solid #303030;
}

.header8 .sidebar-menu > li.active > a {
	color:#d0205e;
}

.header8 .sidebar-menu.menu > li:hover {
	background-color: #d0205e;
	color:#fff;
	border-color:#d0205e;
}

.header8 .sidebar-menu > li > a > .arrow:before {
	border-left-color:#333;
}

.header8 .sidebar-menu.menu > li:hover > a > .arrow:before {
	border-left-color:#fff;
}

.header8 .sidebar-menu.menu li li:hover > a {
	background-color: #d0205e;
}

.header8 .sidebar-menu.menu > li:hover > a {
	background-color: #d0205e;
	color:#fff;
	border-color:#d0205e;
}

.header8 .sidebar-menu li ul,
.header8 .sidebar-menu li .megamenu {
	border-color:#d0205e;
}

.header8 .sidebar-menu.menu li ul {
	background-color: #d0205e;
}

.header8 .sidebar-menu.menu li li:hover > a {
	background-color: #de2b6a;
}

.sidebar-menu.menu li li:last-child  > a{
	border-bottom:none;
}

.menu-custom-block {
    padding: 10px 0 9px;
    line-height: 21px;
    font-weight: 600;
    font-size: 0.8571em;
    margin-left: -10px;
}

.menu-custom-block a,
.menu-custom-block span {
    display: inline-block;
    padding: 0 15px;
    position: relative;
    color:#777;
    text-transform: uppercase;
}

.menu-custom-block a:hover {
	color:#d0205e;
}

.menu-custom-block a .tip {
	top:-14px;
	right:10px;
	font-style: normal;
}

#main-toggle-menu {
	position: relative;
}

#main-toggle-menu .menu-title {
    color: #fff;
    cursor: pointer;
    background: #303030;
    font-size: 0.8571em;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 15px;
    margin: 0;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

#main-toggle-menu .menu-title:hover {
    background-color: #555;
}

#main-toggle-menu .menu-title .toggle {
    display: inline-block;
    font-family: "FontAwesome";
    margin: 0 5px 0 0;
}

#main-toggle-menu .menu-title .toggle:before {
    content: "\f0c9";
}

#main-toggle-menu.show-always .toggle-menu-wrap {
	display: block;
	height:auto;
}

.toggle-menu-wrap {
	display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .4);
}


/* header9 - index10.html */
#header.header9 {
	background-color: rgba(56,55,52,0.4);
}

#header.header-side-nav .side-top > .container {
	display:block;
	min-height: 0 !important;
	position: static;
}

#header.header-side-nav .side-top > .container > * {
	display: inline-block !important;
	padding:0 !important;
}

#header.header9 .currency-switcher > li > a,
#header.header9 .view-switcher > li > a {
	background-color: #000 !important;
	color:#fff;
}

#header.header9 .currency-switcher > li > ul,
#header.header9 .view-switcher > li ul {
	background-color: #000;
}

#header.header9  .currency-switcher > li:hover > a,
#header.header9  .view-switcher > li:hover > a {
	background-color: #000 !important;
	color:#fff;
	border-radius: 4px 4px 0 0;
}

#header.header9  .currency-switcher li li:hover > a,
#header.header9  .view-switcher li li:hover > a {
	background-color: #000 !important;
}

.header9 .sidebar-menu .megamenu-container {
	position: relative !important;
}

.header9 .sidebar-menu  li a {
	border-color:#484848;
}

.header9 .sidebar-menu > li.active > a,
.header9 .sidebar-menu > li > a  {
	color:#fff;
}

.header9 .sidebar-menu.menu > li:hover {
	background-color: #3b3b3b;
	color:#fff;
	border-color:#3b3b3b;
}

.header9 .sidebar-menu > li > a > .arrow:before {
	border-left-color:#fff;
}

.header9 .sidebar-menu.menu > li:hover > a > .arrow:before {
	border-left-color:#fff;
}

.header9 .sidebar-menu.menu li li:hover > a {
	background-color: #3b3b3b;
}

.header9 .sidebar-menu.menu > li:hover > a {
	background-color: #3b3b3b;
	color:#fff;
	border-color:#3b3b3b;
}

.header9 .sidebar-menu.menu .megamenu-list > li > a {
	border:none;
}

.header9 .sidebar-menu.menu .megamenu-list > li:hover > a{
	background-color: #f4f4f4;
	color:#777;
}

.header9 .sidebar-menu li ul,
.header9 .sidebar-menu li .megamenu {
	border-color:#3b3b3b;
}

.header9 .sidebar-menu.menu li ul {
	background-color: #3b3b3b;
}

.header9 .sidebar-menu.menu li li:hover > a {
	background-color: #484848;
}

#header.header9 .searchform {
	background-color: #fff;
	border-color:#ccc;
	color:#777;
}

#header.header9 .searchform input {
	color:#777;
}

#header.header9 .searchform button {
	color:#777;
}

#header.header9 .searchform button:hover {
	color:#3b3b3b;
}

#header.header-side-nav #header-inner > .container {
	width:100%;
}

@media (min-width:992px) {
	#header.header-side-nav {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 256px;
	    padding: 10px 15px 145px;
	    min-height: 100vh;
	}

	#header.header-side-nav .side-top > .container {
        padding: 0;
        width: 100%;
    }

	#header.header-side-nav #header-inner {
		position: static;
	}

    #header.header-side-nav #header-inner > .container {
        position: static;
        padding: 0;
        width: 100%;
        display: block;
    }

    #header.header-side-nav #header-inner > .container > * {
        position: static;
        display: block;
        padding: 0 !important;
    }

    #header.header-side-nav .logo {
        text-align: center;
        margin: 30px 0;
    }

    #header.header-side-nav .searchform {
        margin-bottom: 20px;
        width: 226px;
        border-radius: 5px;
    }

    #header.header-side-nav .searchform input {
        padding: 0 10px;
        border-radius: 5px 0 0 5px;
        border-width: 0;
        width: 190px;
    }

    #header.header-side-nav .searchform button {
        padding: 0 10px;
        border-radius: 0 5px 5px 0;
    }

    #header.header-side-nav .top-links {
        display: block;
        font-size: 0.8em;
        margin-bottom: 20px;
    }

    #header.header-side-nav .top-links li {
        display: block;
        float: none;
        margin: 0;
    }

    #header.header-side-nav .top-links li:after {
        display: none;
    }

    #header.header-side-nav .top-links li > a {
    	display: block;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 0;
        border-top: 1px solid #484848;
        text-transform: capitalize;
    }

    #header.header-side-nav .top-links li:first-child > a,
    #header.header-side-nav .top-links li:first-child > h5 {
        border-top-width: 0;
    }

    #header.header-side-nav #header-inner .header-contact {
        margin: 0 0 8px;
    }

    #header.header-side-nav .header-copyright {
        font-size: 0.9em;
    }

     #header.header-side-nav #mini-cart.minicart-inline .dropdown-menu {
        left: 0;
        right: auto;
    }

     #header.header-side-nav #mini-cart.minicart-inline .dropdown-menu:before {
        right: auto;
        left: 10px;
    }

     #header.header-side-nav .side-bottom {
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 20px 10px;
    }

}

#header.header-side-nav .header-copyright {
    color:#777;
}

#header.header-side-nav .social-icons {
	margin-bottom: 8px;
}

#header.header-side-nav .social-icon {
	background-color: #9e9e9e;
	margin:5px;
	line-height: 30px;
}

#header.header-side-nav .sidebar-menu {
    margin-bottom: 20px;
}

#header.header-side-nav .sidebar-menu > li > a {
    margin-left: 0;
    margin-right: 0;
}

#header.header-side-nav .sidebar-menu > li > .arrow {
    right: -5px;
}

#header.header-side-nav .sidebar-menu > li:last-child:hover {
    border-radius: 0;
}

#header.header-side-nav .menu-custom-block a {
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px;
    padding-right: 5px;
}

@media (max-width: 991px) {

    #header.header-side-nav .side-top {
        padding-top: 0;
    }

    #header.header-side-nav .side-top > .container > * {
        display: none !important;
    }

	#header.header-side-nav #header-inner .side-top > .container {
		position: static;
	}

    #header.header-side-nav #header-inner .side-top > .container > #mini-cart {
        display: block !important;
        position: absolute !important;
        top: 50%;
        bottom: 50%;
        height: 26px;
        margin-top: -12px;
        right: 15px;
        z-index: 1;
    }

    #header.header-side-nav #header .logo {
        margin: 0;
    }

    #header.header-side-nav .social-icons,
    #header.header-side-nav .header-copyright {
        display: none;
    }

    #header.header-side-nav #header-inner .header-contact {
        margin-right: 100px;
    }

    #header.header-side-nav .searchform input {
    	width:320px;
    	border-color:#ccc;
    }
}

@media (max-width: 767px) {
	#header.header-side-nav #header-inner .header-contact {
        display: none;
    }
}


@media (max-width: 480px) {
	#header.header-side-nav #header-inner .side-top .container {
		padding:0 !important;
	}

	#header.header-side-nav  #header-inner .side-top .container > * {
        display: none !important;
    }

    #header.header-side-nav #header-inner .side-top > .container > #mini-cart {
    	top:9px;
    	right:15px;
    	bottom:auto;
    	margin-top:0;
    }

    #header.header-side-nav .searchform input {
    	width:236px;
    }
}


/* header10 - index11.html */
#header.header10 {
	border-top:5px solid #7aa93c;
	border-bottom:1px solid #e1e1e1;
	background-color: transparent;
}

#header.header10 #header-top {
	background-color: #f7f7f7;
}

.v2 #header .top-links > li:after {
	margin:0 5px 0 6px;
}

#header.header10 #menu-container {
	background-color: transparent;
	margin-bottom: 10px;
}

#header.header10 #header-inner .container .header-left,
#header.header10 #header-inner .container .header-right {
	width:42%;
}

#header.header10 #header-inner .container .header-right {
	padding-right:0;
	padding-left: 20px;
}


#header.header10 #header-inner .container .header-left {
	padding-right:20px;
	padding-left: 0;
}

#header.header10 #header-inner .container .header-center{
	width:16%;
	padding:20px 0;
	text-align: center;
}

@media (max-width: 991px) {
	#header.header10 #header-inner .container .header-left,
	#header.header10 #header-inner .container .header-center,
	#header.header10 #header-inner .container .header-right {
		width: 33.33%;
		display:table-cell;
	}
}

#header.header10 .menu > li,
#header.header10 .menu > li.pull-right {
	float:none !important;
	display: inline-block;
}

#header.header10 .menu > li +li {
	margin-left:3px;
}

#header.header10 .menu > li > a {
	padding:9px 14px;
}

#header.header10 .menu > li > a,
#header.header10 .menu > li > a > i {
	color:#333;
}

#header.header10 .menu > li.open > a,
#header.header10 .menu > li.open > a > i,
#header.header10 .menu > li.active > a,
#header.header10 .menu > li.active > a > i {
	color:#fff;
	background-color: #7aa93c;
	border-radius: 5px;
}

#header .menu > li.has-sub.open > a,
#header .menu > li.has-sub.open > a {
	border-radius:5px 5px 0 0;
}

#header.header10 #mini-cart.dropdown .dropdown-menu,
#header.header10 .menu ul,
#header.header10 .menu .megamenu {
	border-color:#7aa93c;
}

#header.header10 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color: #7aa93c;
}

#header.header10 .menu > li > a > .tip {
	right:10px;
	top:-7px;
}

.v2 #header .welcome-msg {
	margin-right: 7px;
}

#header.header10 #header-top,
#header.header10 .currency-switcher > li > a,
#header.header10 .view-switcher > li > a,
#header.header10 .top-links li:hover > a,
#header.header10 .currency-switcher > li:hover > a,
#header.header10 .view-switcher > li:hover > a,
#header.header10 .currency-switcher li li > a,
#header.header10 .view-switcher li li > a {
	color:#777;
}

#header.header10 .currency-switcher > li:hover > a,
#header.header10 .view-switcher > li:hover > a,
#header.header10 .currency-switcher li li > a,
#header.header10 .view-switcher li li > a  {
	color: #fff;
}

#header.header10 .currency-switcher > li:hover > a,
#header.header10 .view-switcher > li:hover > a {
	background-color: transparent;
}

.v2 #header .currency-switcher > li > ul,
.v2 #header .view-switcher > li ul {
	background-color: #fff !important;
	border:1px solid #ccc;
	margin-top:-6px;
	border-radius: 3px;
	box-shadow:0 0 2px rgba(0,0,0,0.1);
	padding:4px 0;
}

.v2 #header .currency-switcher > li ul > li,
.v2 #header .view-switcher > li ul > li {
	padding:0 9px;
	line-height: 17px;
}

.v2 #header .view-switcher li li img {
	margin-top:0;
}

.v2 #header .currency-switcher li li > a,
.v2 #header .view-switcher li li > a {
	padding:0;
	line-height: 1;
	color:#777;
	background: none !important;
}

	.v2 #header .currency-switcher li li:hover > a,
	.v2 #header .view-switcher li li:hover > a  {
		background: none !important;
	}

.v2 #header .currency-switcher li li:hover,
.v2 #header .view-switcher li li:hover,
.v2 #header .currency-switcher li li:hover > a,
.v2 #header .view-switcher li li:hover > a {
	color:#fff;
	background-color: #ccc;
}

.v2 #header .top-links li:hover > a {
	text-decoration: underline;
}


.v2 #header .top-links li > a > i {
	margin:0 0.2em 0 0;
}

#header.header10 #header-inner .header-contact a,
#header.header10 #mini-cart .minicart-icon,
#header.header10 .top-links li  a,
#header.header10 .top-links li:hover > a,
#header.header10 .searchform-popup .search-toggle,
#header.header10 .mobile-toggle {
	color:#7aa93c;
}

#header.header10 #header-inner .header-contact .separator {
	margin:0 8px;
}

.v2 #header #mini-cart.dropdown .dropdown-menu {
	width:300px;
	box-shadow:0 2px 8px rgba(0,0,0,0.5);
}

#header.header10 #mini-cart.dropdown .dropdown-menu ul li.empty {
	padding:5px 0;
}

#header.header10 .searchform button:hover {
	color:#7aa93c;
}

#header.header10 #header-inner .header-contact a:hover,
#header.header10 .searchform-popup .search-toggle:hover,
#header.header10 .mobile-toggle:hover,
#header.header10 #header-inner .header-contact {
	color:#777;
}

#header.header10 #mini-cart .cart-items {
	color:#fff;
}

#header.header10 .searchform {
	background-color: #fff;
	border-color:#ccc;
	line-height: 36px;
	width:366px;
}

#header.header10 .searchform input {
	width:198px;
	padding:0 15px;
}

@media (max-width: 1199px) {
	#header.header10 .searchform {
		width:288px;
	}

	#header.header10 .searchform input {
		width:122px;
	}
}

@media (max-width: 991px) {
	#header .header-left .searchform {
		top:-6px;
		left:40px;
		width:298px;
	}

	#header .header-left .searchform:before {
        border-bottom-color: transparent;
        border-right-color: inherit;
        left: -23px;
        top: 5px;
    }

	#header.header10 .searchform input {
		width:124px;
	}
}

@media (max-width: 767px) {
	#header.header10 .searchform {
		width:338px;
	}

	#header.header10 .searchform input {
		width:162px;
	}
}

@media (max-width: 480px) {
	#header.header10 .searchform {
		width:288px;
		right:auto;
		left:-30px;
		top:35px;
	}

	#header .header-left .searchform:before {
		width: 14px;
		border-width: 7px;
		left: 34px;
		right: auto;
		top: -23px;
		border-color: transparent;
		border-bottom-color: inherit;
    }

	#header.header10 .searchform input {
		width:132px;
	}
}

#header.header10 .searchform input,
#header.header10 .searchform select {
	border-color:#ccc !important;
	color:#777;
}

#header.header10 .searchform select {
	padding:0 10px 0 10px;
}

.v2 #header .searchform select {
	-webkit-appearance: none;
	-moz-appearance:none;
	appearance:none;
}

.v2 select::-ms-expand {
    display: none;
}

#header.header10 .searchform button {
	color:#777;
	padding:0 11px 0 10px;
}

#header.header10 .searchform input,
#header.header10 .searchform select,
#header.header10 .searchform button {
	height:34px;
	line-height:34px;
}


@media (max-width: 480px) {
	#header.header10 #mini-cart {
		background-color: transparent;
	}

	#header.header10 #mini-cart .cart-items {
		color:#7aa93c;
	}
}


/* Header 11 */
#header.header11 {
	background-color: #fff;
}

#header.header11 #header-top {
	background-color: #ee3d43;
	color:#fff;
}

#header.header11 #header-top .top-links > li:hover > a {
	color:#fff;
}

#header.header11 #header-top .view-switcher > li:hover > a,
#header.header11 #header-top .currency-switcher > li:hover > a {
	background-color: transparent;
	color:#fff;
}

#header.header11 #header-inner .header-contact,
#header.header11 #header-inner .header-contact a {
	color:#333745;
}


#header.header11 #mini-cart .minicart-icon {
	color:#393f4f;
}

#header.header11 #mini-cart .cart-items {
	color:#fff;
}

.v2 #header.header11 #header-inner .header-contact .separator {
	margin:0 8px;
}

#header.header11 #menu-container {
	background-color: #393f4f;
}

#header.header11 .menu > li > a > .tip {
	right:10px;
	top:-7px;
	margin:0;
}

#header.header11 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color:#ee3d43;
}

#header.header11 #mini-cart.dropdown .dropdown-menu{
	border-top-color:#ee3d43;
}

#header.header11 .searchform {
	border-color:#cececd;
	background-color:#fff;
	width:450px;
}

#header.header11  .searchform input {
	width:278px;
	padding-left:15px;
	padding-right:15px;
}

#header.header11 .searchform input,
#header.header11 .searchform select,
#header.header11  .searchform button {
	border-color:#ccc !important;
	color:#333;
	height:38px;
	line-height: 38px;
}

#header.header11  .searchform button {
	padding-right:13px;
	padding-left:13px;
}

#header.header11 #header-inner .container .header-center {
	padding-left:25px;
}

#header.header11 .mobile-toggle,
#header.header11 .searchform-popup .search-toggle{
	color:#333;
	vertical-align: middle;
}

#header.header11 .mobile-toggle {
	font-size: 20px;
	margin-top:-2px;
}

@media (max-width: 991px) {
	#header.header11 #header-inner .container .header-center {
		position: absolute;
		right:76px;
		top:50%;
		padding:0;
		margin-top:-15px;
		z-index: 999;
	}

	#header.header11 .searchform {
		left:auto;
		right:-60px;
	}

	#header.header11 .searchform:before {
		left:auto;
		right:60px;
	}

	#header.header11  .searchform input {
		width:270px;
	}
}


@media (max-width: 480px) {
	#header.header11 #mini-cart {
		background-color: #ee3d43;
	}

	#header.header11 #mini-cart .minicart-icon {
		color:#fff;
	}

	#header.header11 .searchform {
		right:auto;
		left:-30px;
		width:300px;
	}

	#header.header11 .searchform:before {
		right:auto;
		left:30px;
	}

	#header.header11  .searchform input {
		width:140px;
	}

	#header.header11 .mobile-toggle {
		margin-top:0;
	}
}


/* Header 12 - index13 */
#header.header12 {
	background-color: transparent;
}

#header.header12 #header-top,
#header.header12  .currency-switcher a,
#header.header12  .view-switcher a,
#header.header12  .top-links a,
#header.header12 #header-top .view-switcher > li:hover > a,
#header.header12 #header-top .currency-switcher > li:hover > a {
	background-color: transparent !important;
	color:#777;
}

#header.header12 #header-top .top-links > li > a,
#header.header12 #header-top .top-links > li:hover > a {
	color:#000;
	background-color: transparent;
}

#header.header12 #header-top .view-switcher > li li:hover > a,
#header.header12 #header-top .currency-switcher > li li:hover > a {
	background-color: transparent;
	color:#fff;
}

#header.header12 #header-inner .header-contact {
	color:#777;
}

#header.header12 #header-inner .header-contact a {
	color:#000;
}

#header.header12 #mini-cart .minicart-icon {
	color:#000;
}

#header.header12 #mini-cart .cart-items {
	color:#fff;
}

.v2 #header.header12 #header-inner .header-contact .separator {
	margin:0 8px;
	color:#ccc;
}

#header.header12 .separator {
	color:#ccc;
}

#header.header12 #menu-container {
	background-color: #54555e;
	margin-bottom: 20px;
	position: relative;
}

#header.header12 #menu-container:after {
	content:'';
	position: absolute;
	top:100%;
	left:0;
	right:0;
	z-index: 1;
	display: block;
	height:4px;
	background:#3f4048;
}

#header.header12 .menu > li > a > .tip {
	right:10px;
	top:-7px;
	margin:0;
}

#header.header12 .menu > li > a {
	padding:17px 20px;
}

#header.header12 .menu > li.open > a,
#header.header12 .menu > li.open > a > i,
#header.header12 .menu > li.active > a,
#header.header12 .menu > li.active > a > i {
	color:#fff;
	background-color: #000;
}

#header.header12 .menu > li.open > a,
#header.header12 .menu > li.active > a {
	position: relative;
	padding-bottom:21px;
	margin-bottom:-4px;
	z-index: 10;
}

#header.header12 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color:#000;
}

#header.header12 #mini-cart.dropdown .dropdown-menu,
#header.header12 .menu ul,
#header.header12 .menu .megamenu {
	border-top-color:#000;
}

.v2 #header.header12 .currency-switcher > li > ul,
.v2 #header.header12 .view-switcher > li ul,
#header.header12 #mini-cart.dropdown .dropdown-menu,
#header.header12 .menu ul,
#header.header12 .menu .megamenu,
#header.header12 #mini-cart .dropdown-cart-content,
#header.header12 .tip,
#header.header12 .searchform {
	border-radius: 0;
}

.header12 #mini-cart.dropdown .dropdown-menu ul li.empty {
	padding:0;
}

#header.header12 .menu li > ul {
	background-color: #fff;
}

#header.header12 .menu li > ul li a {
	color:#777;
}

#header.header12 .menu .megamenu li:hover > a {
	background-color: transparent;
	color:#777;
	text-decoration: underline;
}

#header.header12 .searchform {
	border-color:#ccc;
	background-color:#fff;
	width:450px;
}

#header.header12 .searchform input {
	width:278px;
	padding-left:15px;
	padding-right:15px;
}

#header.header12 .searchform input,
#header.header12 .searchform select,
#header.header12  .searchform button {
	border-color:#ccc !important;
	color:#777;
	height:38px;
	line-height: 38px;
}

#header.header12  .searchform button {
	padding-right:13px;
	padding-left:13px;
}

#header.header12 #header-inner .container .header-center {
	padding-left:25px;
}

#header.header12 .mobile-toggle,
#header.header12 .searchform-popup .search-toggle{
	color:#333;
	vertical-align: middle;
}

#header.header12 .mobile-toggle {
	font-size: 20px;
	margin-top:-2px;
}

@media (max-width: 991px) {
	#header.header12 #header-inner .container .header-center,
	#header.header14 #header-inner .container .header-center,
	#header.header15 #header-inner .container .header-center {
		position: absolute;
		right:76px;
		top:50%;
		padding:0;
		margin-top:-15px;
		z-index: 999;
	}

	#header.header14 #header-inner .container .header-center {
		right:131px;
	}

	#header.header12 .searchform,
	#header.header15 .searchform {
		left:auto;
		right:-60px;
	}

	#header.header14 .searchform {
		left:auto;
		right:-118px;
	}

	#header.header12 .searchform:before,
	#header.header15 .searchform:before {
		left:auto;
		right:60px;
	}

	#header.header14 .searchform:before{
		left:auto;
		right:116px;
	}

	#header.header12 .searchform input {
		width:270px;
	}
}

@media (max-width: 767px) {
	#header.header14 #header-inner .container .header-center {
		right:86px;
	}
	#header.header14 .searchform {
		left:auto;
		right:-81px;
	}

	#header.header14 .searchform:before{
		left:auto;
		right:81px;
	}
}

@media (max-width: 480px) {
	#header.header12 #mini-cart {
		background-color: #000;
		border-radius: 0;
	}

	#header.header12 #mini-cart .minicart-icon {
		color:#fff;
	}

	#header.header12 .searchform,
	#header.header15 .searchform,
	#header.header14 .searchform   {
		right:auto;
		left:-30px;
		width:300px;
	}

	#header.header12 .searchform:before,
	#header.header15 .searchform:before,
	#header.header14 .searchform:before {
		right:auto;
		left:30px;
	}

	#header.header14  #mini-cart .cart-head {
		background-color: transparent;
	}

	#header.header12 .searchform input {
		width:140px;
	}


	#header.header12 .mobile-toggle {
		margin-top:0;
	}
}


/* header13 - index14.html*/
#header.header13 {
	background-color: #fff;
}

#header.header13 #header-inner .container > div {
	padding-top:15px;
	padding-bottom: 15px;
}

@media (max-width: 767px) {
	#header.fixed-header #header-inner .container > div {
		padding-top:8px;
		padding-bottom: 8px;
	}
}

#header.header13 #header-inner .container .header-center {
	position: static;
	padding-left:30px;
}

#header.header13 .menu {
	position: static;
	vertical-align: middle;
}

@media (min-width: 992px) {
	#header.header13 #header-center-wrapper {
		display: inline-block;
		white-space: nowrap;
	}
}

.header13 .menu > li.open > a > i,
.header13 .menu > li.active > a > i{
	color:#fff;
}

.header13 .menu > li.open > a,
.header13 .menu > li.active > a {
	background-color: #9ac93d;
	color:#fff;
}

.header13 .menu > li > a {
	padding:7px 12px 6px;
	border-radius: 4px;
	color:#9ac93d;
	font-weight: 600;
}

.header13 .menu > li > a > i {
	color:#9ac93d;
}

@media (max-width: 1199px) {
	.header13 .menu > li > a {
		padding:9px 9px 8px;
	}

}

.header13 .menu .megamenu-container .megamenu {
	left:15px;
	right:15px;
	width:auto;
	top:auto;
}

.header13 .menu > li > a > .tip {
	top:-7px;
	right:10px;
}

#header.header13 .search-toggle {
	display:inline-block;
}

#header.header13 .top-links > li:after,
#header.header13 .separator,
#header.header13 .currency-switcher a,
#header.header13 .view-switcher a,
#header.header13 .top-links a,
#header.header13 .searchform-popup .search-toggle,
#header.header13 .currency-switcher li:hover > a,
#header.header13 .view-switcher li:hover > a {
	color:#777;
}

#header.header13 .currency-switcher a,
#header.header13 .view-switcher a {
	padding-left:10px;
	padding-right:10px;
}

#header.header13 .top-links a {
	font-weight: 300;
}

#header.header13 .searchform {
	position: absolute;
	display: none;
	border-color:#cccccc;
	border-width: 5px;
	left:auto;
	right:-29px;
	background-color: #fff;
	z-index: 900;
	border-radius: 25px;
	width:450px;
}

#header.header13 .searchform input,
#header.header13 .searchform select,
#header.header13 .searchform button,
#header.header13 .mobile-toggle  {
	color:#777;
	border-color:#ccc;
}

#header.header13 .searchform button:hover {
	color:#3b3b3b;
}

#header.header13 .searchform {
	width:378px;
}

.header13 .block-nowrap {
	margin-bottom: 2px;
}

@media (min-width: 1200px) {
	#header.header13 .searchform {
		width:450px;
	}

	#header.header13 .searchform input {
		width:265px;
	}
}

@media (min-width: 992px) {
	#header.header13 .searchform {
		margin-top:6px;
	}
}

@media (max-width: 767px) {
	#header.header13 .searchform {
		width:380px;
	}
}

#header.header13 .searchform:before {
	display: block;
	left:auto;
	right:27px;
}

@media (max-width: 480px) {

	.header-minicart-inline {
		line-height: 1.6 !important;height: 0;
	}
	#header.header13 .searchform {
		right:auto;
		left:-27px;
		width:290px;
	}

	#header.header13 .searchform button {
		padding-right: 0;
	}


	#header.header13 .searchform:before {
		right:auto;
		left:27px;
	}
}

.header13 #mini-cart.minicart-inline {
	background-color: #eee !important;
	margin-left:4px;
    border-radius: 2px;
    color:#777;
    padding-left:7px;
    padding-right: 7px;
}

#header.header13 #mini-cart.minicart-inline .dropdown-menu {
	margin-top:8px;
	font-size: 14px;
	border-top-color:#9ac93d;
}

#header.header13 #mini-cart.minicart-inline .cart-items,
#header.header13 #mini-cart .minicart-icon {
	color:#777;
	text-transform: uppercase;
}


#header.header13 #mini-cart.minicart-inline .cart-items {
	font-weight: 400;
}

.header13 #mini-cart.dropdown .dropdown-menu ul li.empty {
	padding:0;
}

#header.header13 #mini-cart.dropdown.minicart-inline .dropdown-menu:before {
	border-bottom-color:#9ac93d;
}

#header.header13 .view-switcher {
	margin-left:4px;
}

#header.header13 .currency-switcher,
#header.header13 .view-switcher {
	text-align: left;
}

#header.header13 .header-link {
	display: inline-block;
	padding:4px 7px;
	line-height:18px;
	border-radius: 2px;
	font-size: 11px;
	text-transform: uppercase;
	color:#777;
	margin:0 0 0 4px;
}

#header.header13 .header-link:hover {
	text-decoration: underline;
}

#header.header13 .header-link > i {
	margin-right: 2px;
}

#header.header13 .currency-switcher > li > a,
#header.header13 .view-switcher > li > a,
#header.header13 .header-link  {
	border-radius: 2px;
	background-color: #eeeeee;
}

#header.header13 .currency-switcher > li:hover > a,
#header.header13 .view-switcher > li:hover > a{
	background-color: #eeeeee;
}

#header.header13 .currency-switcher li > ul,
#header.header13 .view-switcher li > ul {
	background-color: #fff;
	border:none;
	margin-top:0;
	min-width: 100%;
}



/* header14 - index15.html */
#header.header14 {
	background-color: transparent;
	border-top:4px solid #08c;
	color:#777;
	margin-bottom: 20px;
}

#header.header14 #header-top {
	background-color: #f0f0ed;
}

#header.header14 #menu-container {
	background-color: #f0f0ed;
	border-radius: 5px;
}

#header.header14 .currency-switcher li:hover > a,
#header.header14 .view-switcher li:hover > a {
	background-color: transparent;
}

#header.header14 .currency-switcher li:hover > a,
#header.header14 .view-switcher li:hover > a,
#header.header14 .currency-switcher a,
#header.header14 .view-switcher a,
#header.header14 .header-left .top-links a  {
	color:#777;
}

#header.header14 .top-links a {
	color:#08c;
}

#header.header14 .searchform {
	width:450px;
	background-color: #fff;
	border-color:#ccc;
}


#header.header14 .searchform input {
	width:278px;
}

#header.header14 .searchform input,
#header.header14 .searchform select {
	border-color:#ccc;
	color:#777;
}

#header.header14 .searchform-popup .search-toggle,
#header.header14 .mobile-toggle {
	color:#08c;
}

#header.header14 .searchform button {
	padding-right:10px;
	color:#777;
}

#header.header14 .searchform input,
#header.header14 .searchform select,
#header.header14 .searchform button {
	height:38px;
	line-height: 38px;
}

#header.header14 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color:#08c;
}

#header.header14 #mini-cart.dropdown .dropdown-menu,
#header.header14 .menu ul,
#header.header14 .menu .megamenu {
	border-top-color:#08c;
}

#header.header14 .menu > li + li {
	margin-left: 2px;
}

#header.header14 .menu > li > a {
	padding:10px 20px;
	border-radius:5px;
}

#header.header14 .menu > li > a > .tip {
	top:-5px;
	right:15px;
}

#header.header14 .menu > li > a,
#header.header14 .menu > li > a > i {
	color:#08c;
}

#header.header14 .menu > li.open > a,
#header.header14 .menu > li.open > a > i,
#header.header14 .menu > li.active > a,
#header.header14 .menu > li.active > a > i {
	color:#fff;
	background-color: #08c;
}

#header.header14 .menu li .megamenu li a:hover {
	background-color: transparent;
	text-decoration: underline;
}

#header.header14 #header-inner .container .header-right {
	position: relative;
}

#header.header14 #mini-cart {
	padding:7px 0;
	border-radius: 5px;
}

#header.header14 #mini-cart.minicart-inline {
	background-color: #08c !important;
}

#header.header14 #mini-cart .minicart-icon {
	color: #fff;
	font-size: 18px;
}

#header.header14 #mini-cart .cart-items {
	color:#fff;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	vertical-align: middle;
}

.icon-group {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}

.icon-group i{
	font-size:20px;
	color:#fff;
	float:left;
	border-radius:50%;
	width:40px;
	height:40px;
	text-align:center;
	line-height:40px;
	background-color:#000;
}

.icon-group i.red {
	background-color:#ee3d43;
}

.icon-group i.green {
	background-color:#33b7ad;
}

.icon-group h3{
	color:#000;
	font-size:14px;
	margin:0;
	line-height:1.5;
	margin-left:48px;
	font-weight: 600;
}

.icon-group p{
	font-size:11px;
	margin:0;
	margin-left:48px;
	line-height:1;
	color:#878787;
}

.icon-group + .icon-group {
	margin-left:30px;
}

@media (min-width: 992px) {
	#header.header14 #mini-cart {
		position: absolute;
		top:100%;
		right:-2px;
		bottom:auto;
		z-index: 999;
		margin:0;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#header.header14 .searchform {
		width:370px;
	}

	#header.header14 .searchform input {
		width:198px;
	}
}

@media (max-width: 991px) {
	#header.header14 .searchform {
		width:370px;
	}

	#header.header14 .searchform input {
		width:190px;
	}

}

@media (min-width: 768px) and (max-width: 991px) {
	.icon-group-container {
		position: absolute;
		left:-220px;
		top:50%;
		white-space: nowrap;
		transform:translateY(-50%);
		-webkit-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
	}
}

@media (max-width: 767px) {
	.icon-group {
		display: none;
	}
}
@media (max-width: 480px) {
	#header.header14 #mini-cart {
		padding:0;
	}

	#header.header14 .searchform {
		width:300px;
	}

	#header.header14 .searchform input {
		width:138px;
	}
}

/* header15- index16.html*/
#header.header15,
#header.header15 #menu-container {
	background-color: transparent;
}

#header.header15 #header-top {
	background-color: #b7a788;
}

#header.header15 #header-inner .container .header-center {
	padding-left:25px;
}

#header.header15 .currency-switcher li:hover > a,
#header.header15 .view-switcher li:hover > a {
	background-color: transparent;
}

.v2 #header.header15  .currency-switcher > li > ul,
.v2 #header.header15 .view-switcher > li ul {
	border-radius: 0;
}

#header.header15 .top-links a:hover {
	color:#fff;
}

.v2 #header.header15 .currency-switcher > li > ul,
.v2 #header.header15 .view-switcher > li ul,
#header.header15 #mini-cart.dropdown .dropdown-menu,
#header.header15 .menu ul,
#header.header15 .menu .megamenu,
#header.header15 #mini-cart .dropdown-cart-content,
#header.header15 .tip,
#header.header15 .searchform {
	border-radius: 0;
}

#header.header15 #header-inner .header-contact {
	color:#777;
}

#header.header15 #header-inner .header-contact a,
#header.header15 #header-inner .header-contact a:hover {
	color:#333745;
}

#header.header15 #header-inner .header-contact a:hover {
	text-decoration: underline;
}


#header.header15 #mini-cart .minicart-icon {
	color:#39333f;
}

#header.header15 #mini-cart .cart-items {
	color:#fff;
}

#header.header15 .searchform-popup .search-toggle,
#header.header15 .mobile-toggle {
	color:#333;
}

.v2 #header.header15 #header-inner .header-contact .separator {
	margin:0 8px;
	color:#999;
}

#header.header15 #mini-cart.dropdown .dropdown-menu:before {
	border-bottom-color:#b7a788;
}

#header.header15 #mini-cart.dropdown .dropdown-menu,
#header.header15 .menu ul,
#header.header15 .menu .megamenu {
	border-top-color:#b7a788;
}

.header15 #mini-cart.dropdown .dropdown-menu ul li.empty {
	padding:0;
}

#header.header15 .searchform {
	background-color: #fff;
	border:3px solid #b7a788;
	width:450px;
}

#header.header15 .searchform input,
#header.header15 .searchform button {
	height:38px;
	line-height: 38px;
}

#header.header15 .searchform input {
	padding-right:15px;
	padding-left:15px;
	color:#777;
}

#header.header15 .searchform button {
	color:#333;
}

#header.header15 .searchform .text {
	width: calc(100% -  40px);
}

#header.header15 .searchform .button-wrap {
	width:40px;
}

#header.header15 .searchform input {
	width:100%;
	border-right:none;
}

#header.header15 .searchform button {
	padding:0 10px;
}

.v2 #header:not(.header13) .currency-switcher > li > a {
	padding-left:0;
}

@media (max-width: 767px) {
	#header.header15 .searchform {
		width:300px;
	}
}

@media (max-width: 480px) {
	#header.header15 #mini-cart .cart-head {
		border-radius: 0;
		background: #b7a788;
	}

	#header.header15 #mini-cart .minicart-icon {
		color:#fff;
	}
}

/* Top Newsletter */
#top-newsletter {
	background-color: #2b262f;
	padding:10px 0;
	color:#fff;
}

#top-newsletter .lside {
	font-size: 16px;
	line-height: 30px;
	font-family: Georgia, Verdana;
}

#top-newsletter .rside > div {
	display: inline-block;
	vertical-align: middle;
}

#top-newsletter .rside-text p {
	margin-bottom: 0;
	line-height: 15px;
}

#top-newsletter .rside-text p:first-child {
	font-size:15px;
	font-weight: 700;
	font-family: Georgia, Verdana;
}

#top-newsletter .rside-text p:last-child {
	color:#a7a7a7;
	font-size:12px;
}

#top-newsletter .rside-form {
	padding-left:20px;
	position: relative;
}

#top-newsletter .rside-form input {
	width:300px;
	height:30px;
	font-size: 13px;
	line-height: 16px;
	padding:6px 8px;
	border:1px solid #ccc;
	color:#777;
}

#top-newsletter .rside-form button  {
	display: block;
	height:30px;
	font-size:14px;
	padding:0 15px;
	line-height: 30px;
	color:#000;
	position: absolute;
	right:0;
	top:0;
	border:none;
	background:transparent;
	text-transform:uppercase;
}

@media (min-width: 992px) {
	#top-newsletter .rside  {
		text-align: right;
	}
}

@media (max-width: 991px) {
	#top-newsletter .rside-form input  {
		width:160px;
	}
}

@media (max-width: 480px) {
	#top-newsletter {
		display: none;
	}
}


/* header13 - absolute- index17.html*/
#header.header13.header-absolute {
	background-color: transparent;
	border-bottom: 1px solid rgba(255,255,255,.25);
}

#header.header13.header-absolute #header-inner .container .header-center {
	padding-left: 8px;
	padding-right: 0;
}

.header13.header-absolute .menu > li.open > a > i,
.header13.header-absolute .menu > li.active > a > i{
	color:#fff;
}

.header13.header-absolute .menu > li.open > a,
.header13.header-absolute .menu > li.active > a {
	background-color: transparent;
	color:#fff;
}

.header13.header-absolute .menu > li > a {
	padding:7px 12.5px 6px;
	border-radius: 0;
	color:#fff;
	font-weight: 700;
}

.header13.header-absolute .menu > li > a > i,
#header.header13.header-absolute .mobile-toggle,
#header.header13.header-absolute .top-links a,
#header.header13.header-absolute .searchform-popup .search-toggle {
	color:#fff;
}

.header13.header-absolute .menu .megamenu-container .megamenu {
	left:45px;
	right:45px;
}

.header13.header-absolute .menu ul,
.header13.header-absolute .menu .megamenu {
	border-top-color: #3f4658;
}

#header.header13.header-absolute .top-links > li:after {
	color:#ccc;
}

#header.header13.header-absolute .separator,
#header.header13.header-absolute .currency-switcher a,
#header.header13.header-absolute .view-switcher a,
#header.header13.header-absolute .currency-switcher li:hover > a,
#header.header13.header-absolute .view-switcher li:hover > a {
	color:#101010;
}

#header.header13.header-absolute .top-links a {
	font-weight: 400;
}

#header.header13.header-absolute .searchform button:hover {
	color:#777;
}

.header13.header-absolute #mini-cart.minicart-inline {
	background-color: #fff !important;
    border-radius: 3px;
    color:#101010;
}

#header.header13.header-absolute #mini-cart.minicart-inline .dropdown-menu {
	border-top-color:#3f4658;
}

#header.header13.header-absolute #mini-cart.minicart-inline .cart-items,
#header.header13.header-absolute #mini-cart .minicart-icon {
	color:#101010;
	text-transform: uppercase;
}

#header.header13.header-absolute #mini-cart.dropdown.minicart-inline .dropdown-menu:before {
	border-bottom-color:#3f4658;
}

#header.header13.header-absolute .header-link {
	color:#fff;
}

#header.header13.header-absolute .header-link:hover {
	text-decoration: underline;
}

#header.header13.header-absolute .currency-switcher > li > a,
#header.header13.header-absolute .view-switcher > li > a,
#header.header13.header-absolute .header-link  {
	border-radius: 3px;
	background-color: #fff;
}

#header.header13.header-absolute .currency-switcher > li:hover > a,
#header.header13.header-absolute .view-switcher > li:hover > a{
	background-color: #fff;
}

#header.header13.header-absolute .switcher-wrap {
	margin-left:5px;
}

@media (max-width: 767px) {
	.skin17 #header #header-inner .container {
		padding-left:15px;
		padding-right:15px;
	}
}
@media (max-width: 480px) {
	#header.header13.header-absolute #header-inner .container {
		padding-top:30px;
		padding-bottom: 20px;
	}

	.header13.header-absolute #mini-cart .dropdown-cart-content {
		padding:30px 15px;
	}
}


/* =============================================
------------------ 5.Content ---------------
==============================================*/

/* Parallax class will trigger plugin for parallax bg */
/* You can use parallax-fixed class for fixed background parallax */
.parallax,
.parallax-fixed {
  position: relative;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 0;
  /* to prevent empy bg area*/
  background-repeat: repeat-y;
  overflow: hidden;
}

/* vertical center with display table */
.vcenter-container {
  display:table;
  table-layout: fixed;
  width:100%;
  height:100%;
}

.vcenter {
  display:table-cell;
  vertical-align: middle;
}


/* -------------------------
Tabs  -------------------- */
.product-tab {
	margin-bottom: 3em;
}

.product-center .product-tab {
	margin-bottom: 1em;
	margin-top: 0.5em;
}

.nav-tabs {
	border-bottom: 1px solid #eee;
	margin-bottom:0;
}

.nav-tabs > li  {

}

.nav-tabs > li > a {
	color:#606060;
	transition: background 0.5s ease, color 0.04s;
	-webkit-transition: background 0.5s ease, color 0.04s;
}

.nav-tabs > li > a {
	display: inline-block;
    float: none;
    font-size: 1em;
    font-weight: normal;
    line-height: 24px;
    margin-right: 1px;
    border-radius: 5px 5px 0 0;
    padding: 10px 15px;
    color:#9ac93d;
    background: #f4f4f4;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 3px solid #eee;
}

.nav-tabs > li > a > i {
	margin-right:10px;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus  {
	border-bottom-color: transparent;
    border-top: 3px solid #9ac93d;
    box-shadow: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	color: #9ac93d;
	background: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 3px solid #9ac93d;
    padding: 10px 15px 11px !important;
}

@media (max-width: 767px) {
	.nav-tabs > li {
		float:none;
		width:100%;
	}

	.nav-tabs > li > a,
	.nav-tabs > li.active > a {
		display: block;
		border-radius: 0;
		border-top-width: 1px;
		border-color:#ddd;
	}

	.nav-tabs > li > a:hover,
	.nav-tabs > li > a:focus,
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		border-top:1px solid #ddd !important;
		border-color:#ddd;
	}
}


/* Breadcrumb */
.page-top {
	/*-color: #e1e1e1;*/
	margin-bottom: 20px;
	z-index: 2;
	position: relative;
/*border-top:1px solid #e1e1e1;
	border-bottom:1px solid #e1e1e1;*/
}

.skin5 .page-top {
	background: none;
	margin: 0px;
}

.breadcrumb {
	background-color: transparent;
	padding: 7.5px 0 5.5px 0;
	margin-bottom: 0;
	border-radius: 0;
	font-size:0;
}

.breadcrumb > li {
	font-size:12px;
	line-height: 24px;
	font-weight: 600;
}

/*
.breadcrumb > li + li:before {
	display: inline-block;
	content: "\f054";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 0.8em;
	width:25px;
	text-align: center;
	color:#666;
} */

.skin5 .breadcrumb > li + li:before {
	color: #777;
}

.breadcrumb > li > a:hover {
	color: #088a08;
}

.breadcrumb > li > a,
.breadcrumb > .active {
	/*color:#fff;*/
	color:#0aad0a;
}

.skin5 .breadcrumb > li > a,
.skin5 .breadcrumb > .active {
	color: #777777;
}


/* =============================================
---------------  6.Homepages  -------------
==============================================*/



/* -------------  6.1-index.html  -----------
--------------------------------------------*/


.sidebar {
	padding-bottom: 40px;
}

.widget {
    margin-bottom: 30px;
}

.widget:before,
.widget:after {
    content: " ";
    display: table;
}

.widget:after {
    clear: both;
}


.sidebar .widget .widget-title,
#content .widget .widget-title {
	line-height: 1.4;
	font-weight: 700;
	font-size:1.1429em;
	color: #313131;
	text-transform: uppercase;
	text-decoration: none;
	margin-bottom: 15px;
}

.widget .testimonial-carousel {
	margin-bottom: 0;
}

.skin1 blockquote.testimonial {
	background-color: #9ac93d;
	border-radius: 8px;
}

.skin1 .testimonial-arrow-down {
	border-top-color:#9ac93d;
}

.skin1 .owl-dot {
	background-color: #9ac93d;
	margin-bottom: 0;
}

.sidebar .testimonial-carousel .img-thumbnail {
	border-radius: 8px;
}

.skin1 .post-item .post-date .month {
	background-color: #9ac93d;
}

.skin1 .post-item .post-date .day,
.skin1 .post-item h4 a,
.skin1 .post-item h4 a:hover,
.skin1 .post-item a,
.skin1 .post-item a:hover{
	color: #9ac93d;
}

.widget-banner-slider a {
	display: block;
}

.widget-banner-slider img {
	display:block;
	width:100%;
	height:auto;
}

.widget-banner-slider.owl-carousel .owl-dots {
	margin-top:10px;
}

.widget.newsletter-widget-box {
    background: #f5f5f5;
    background-color: rgba(0, 0, 0, 0);
    background: - webkit-linear-gradient(top, #fff 1%, #f9f9f9 98%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    background: linear-gradient(to bottom, #fff 1%, #f9f9f9 98%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-radius: 8px;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    min-height: 100px;
    position: relative;
    text-align: center;
    z-index: 1;
}


.widget.newsletter-widget-box .box-content {
	border-radius: 8px;
	border-top: 3px solid #9ac93d;
	border-top-width: 4px;
	padding: 30px 25px 25px 25px;
}

.widget.newsletter-widget-box .box-content h3 {
	margin-bottom: 10px;
	text-align: center;
}

.widget.newsletter-widget-box .box-content p {
    font-size: 0.9286em;
    margin-bottom: 10px;
}

.widget.newsletter-widget-box .box-content input[type="email"],
.widget.newsletter-widget-box .box-content input[type="text"] {
	width: 100%;
	max-width: 262px;
	text-align: center;
    color: #777;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-radius: 3px;
    outline: none;
    border:1px solid #ccc;
    padding:6px 8px;
}

.widget.newsletter-widget-box .box-content input[type="email"]:focus,
.widget.newsletter-widget-box .box-content input[type="text"]:focus {
	box-shadow:none;
}

.widget.newsletter-widget-box .box-content input[type="submit"] {
	margin:20px auto 0;
	background-color: #9ac93d;
	border-color:#9ac93d;
    border: 1px solid #9ac93d;
    color: #fff;
    padding: 6px 10px;
    border-radius: 3px;
    line-height: 1.42857;
}

.widget.newsletter-widget-box .box-content input[type="submit"]:hover,
.widget.newsletter-widget-box .box-content input[type="submit"]:focus {
	background-color: #0099e6;
	border-color:#0099e6;
}


.skin1 .owl-prev,
.skin1 .owl-next {
	color:#9ac93d;
}

.skin1 .owl-prev:hover,
.skin1 .owl-next:hover {
	color:#0099e6;
}

ul.product_list_widget,
.widget ul.product_list_widget {
    list-style: none outside;
    padding: 0;
    margin: 0;
    border-width: 0 !important;
}

ul.product_list_widget li,
.widget ul.product_list_widget li {
    border-width: 0;
    position: relative;
    padding: 0.5714em 0 0.5714em 0;
    margin: 0;
    list-style: none;
    *zoom: 1;
}

ul.product_list_widget li:before,
.widget ul.product_list_widget li:before,
ul.product_list_widget li:after,
.widget ul.product_list_widget li:after {
    content: " ";
    display: table;
}

ul.product_list_widget li:after, .widget ul.product_list_widget li:after {
    clear: both;
}

ul.product_list_widget li:first-child, .widget ul.product_list_widget li:first-child {
    padding-top: 0;
}

ul.product_list_widget li:last-child, .widget ul.product_list_widget li:last-child {
    padding-bottom: 0;
}

ul.product_list_widget li .product-image,
.widget ul.product_list_widget li .product-image {
    float: left;
    width: 96px;
    padding: 2px;
    margin-bottom: 0;
    border-radius: 5px;
}

ul.product_list_widget li .product-image img,
.widget ul.product_list_widget li .product-image img {
    width: 100%;
    height: auto;
    border-radius: 3px;
}

ul.product_list_widget li .product-image img,
.widget ul.product_list_widget li .product-image img {
    position: relative;
    opacity: 1;
}

ul.product_list_widget li .product-image .product-image-hover,
.widget ul.product_list_widget li .product-image .product-image-hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

ul.product_list_widget li .product-image:hover .product-image-normal,
.widget ul.product_list_widget li .product-image:hover .product-image-normal {
    opacity: 0;
}

.widget .product-image .inner {
	position: relative;
}

ul.product_list_widget li .product-image:hover .product-image-hover,
 .widget ul.product_list_widget li .product-image:hover .product-image-hover {
    opacity: 1;
}

ul.product_list_widget li .product-details,
.widget ul.product_list_widget li .product-details {
    position: relative;
    padding-top: 3px;
    margin-left:108px;
    margin-bottom: 0;
}

ul.product_list_widget li .product-details a,
.widget ul.product_list_widget li .product-details a {
    display: block;
    color: #777;
    text-decoration: none;
}

ul.product_list_widget li .product-details a:hover,
.widget ul.product_list_widget li .product-details a:hover {
	color:#9ac93d;
}

ul.product_list_widget li .product-details .amount,
.widget ul.product_list_widget li .product-details .amount {
    color: #444;
    font-size: 1.0714em;
    font-weight: 600;
    line-height: 2.3;
    vertical-align: middle;
}

ul.product_list_widget li .product-details .amount .currency,
.widget ul.product_list_widget li .product-details .amount .currency,
ul.product_list_widget li .product-details .amount .decimal,
.widget ul.product_list_widget li .product-details .amount .decimal {
    font-size: 0.75em;
    font-weight: 400;
}

ul.product_list_widget li .product-details .amount .currency .decimal,
.widget ul.product_list_widget li .product-details .amount .currency .decimal {
    font-size: 1em;
}

ul.product_list_widget li .product-details ins,
.widget ul.product_list_widget li .product-details ins {
    text-decoration: none;
    vertical-align: baseline;
}

ul.product_list_widget li .product-details del,
.widget ul.product_list_widget li .product-details del,
ul.product_list_widget li .product-details .from,
.widget ul.product_list_widget li .product-details .from {
    color: #a7a7a7;
    font-size: 0.8em;
    margin-right: 3px;
    vertical-align: baseline;
}

ul.product_list_widget li .product-details del .amount,
.widget ul.product_list_widget li .product-details del .amount,
ul.product_list_widget li .product-details .from .amount,
.widget ul.product_list_widget li .product-details .from .amount {
    color: #a7a7a7;
}

ul.product_list_widget li dl, .widget ul.product_list_widget li dl {
    margin: 0;
    padding-left: 1em;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    *zoom: 1;
}
ul.product_list_widget li dl:before,
.widget ul.product_list_widget li dl:before,
ul.product_list_widget li dl:after,
.widget ul.product_list_widget li dl:after {
    content: " ";
    display: table;
}

ul.product_list_widget li dl:after, .widget ul.product_list_widget li dl:after {
    clear: both;
}

ul.product_list_widget li dl dt, .widget ul.product_list_widget li dl dt, ul.product_list_widget li dl dd, .widget ul.product_list_widget li dl dd {
    display: inline-block;
    float: left;
    margin-bottom: 1em;
}

ul.product_list_widget li dl dt, .widget ul.product_list_widget li dl dt {
    font-weight: 700;
    padding: 0 0 0.25em 0;
    margin: 0 4px 0 0;
    clear: left;
}

ul.product_list_widget li dl dd, .widget ul.product_list_widget li dl dd {
    padding: 0 0 0.25em 0;
}

ul.product_list_widget li dl dd p:last-child,
.widget ul.product_list_widget li dl dd p:last-child {
    margin-bottom: 0;
}

ul.product_list_widget li .star-rating,
.widget ul.product_list_widget li .star-rating {
    margin: 5px 0 0;
}

.widget .star-rating:before,
.widget .star-rating span:before{
	left:0;
}


.vinfo-boxes-container .vinfo-box {
	padding:0;
}

.vinfo-box-icon.vinfo-box-circle {
	display: inline-block;
	width:64px;
	height:64px;
	line-height: 60px;
	border:2px solid #ccc;
	font-size:28px;
	border-radius: 50%;
	color:#333;
}

.widget .info-box {
	text-align: left;
	padding:0;
	margin-bottom:25px;
}

.widget .info-box-icon {
	display: inline-block;
	width:45px;
	height:45px;
	line-height: 41px;
	border:2px solid #ccc;
	font-size:25px;
	color:#000;
	border-radius: 50%;
	text-align: center;
	margin-right: 15px;
}

.slider-title.text-center > .inline-title {
	padding:0 20px;
}

.slider-title.text-center .line {
	background-image: - webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
}


.skin1 .home-banner-group .banner {
	margin-bottom: 35px;
}

.skin1 .banner,
.skin1 .banner img,
.skin1 .banner a:after {
	/*border-radius: 7px !important */
}

	.body-header-type-2 .banner,
	.body-header-type-2 .banner img,
	.body-header-type-2 .banner a:after,
	.skin5 .banner,
	.skin5 .banner img,
	.skin5 .banner a:after,
	.skin8 .banner,
	.skin8 .banner img,
	.skin8 .banner a:after,
	.skin15 .banner,
	.skin15 .banner img,
	.skin15 .banner a:after,
	.skin16 .banner,
	.skin16 .banner img,
	.skin16 .banner a:after {
		border-radius: 0 !important;
	}

@media (max-width: 767px) {
	.home-banner-group .banner {
		max-width: 270px;
		margin-right:auto;
		margin-left:auto;
	}
}

@media (max-width: 992px) {
	.widget-banner-slider {
		max-width: 270px;
		margin-right:auto;
		margin-left:auto;
	}
}



/* -------------  6.2-index2.html  -----------
--------------------------------------------*/

.links-block {
	border: 1px solid #ddd;
	border-radius: 7px;
	background: #fbfbfb;
}

.links-block-title {
	color: #000;
	border-radius: 7px 7px 0 0;
	background: #f5f5f5;
	border-bottom: 1px solid #ddd;
	font-size: 0.9286em;
	font-weight: 700;
	line-height: 20px;
	padding: 11px 15px 9px;
	margin: 0;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}


.links-block-title i,
.links-block-content ul li i {
	margin-right: 5px;
}

.links-block-content ul {
	margin:0;
}

.links-block-content ul li {
	display: block;
}

.links-block-content ul li > a,
.links-block-content ul li > span {
    color: #777;
    display: block;
    font-size: 1em;
    line-height: 19px;
    padding: 11px 5px;
    margin: 0 10px -1px;
    border-top: 1px solid #ddd;
}

.links-block-content ul li:first-child > a,
.links-block-content ul li:first-child > span {
    border-top: none;
}

.links-block-content ul li > a:hover {
	color:#9ac93d;
}

@media (max-width: 1199px) {

	.links-block {
        font-size: 13px;
    }

    .links-block-title {
        line-height: 20px;
        padding: 8px 12px 6px;
    }

    .links-block-content ul li > a,
	.links-block-content ul li > span {
        padding: 7px 5px;
        line-height: 19px;
        margin: 0 7px -1px;
	}
}

.bg-gray {
	background-color: #f0f0ed;
}

.bg-lightgray	{
	background-color: #f9f9f9;
}

.bg-white {
	background-color: #fff;
}

.clean-row {
	margin:0;
}

.info-boxes-container {
	margin:20px 0 20px;
    background: #fbfbfb;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}

.info-box {
	display: block;
	text-align: center;
	margin: 0;
	padding: 20px 0;
	font-size: 0;
}
.info-box-container:hover {

background-color: #ccc;
}

.info-box-icon,
.info-box-content {
	display: inline-block;
	vertical-align: middle;
}

.info-box-icon {
	font-size:32px;
	color:#000;
	margin:0 10px 0 0;
	width:1em;
	height:1em;
	line-height: 1em;
	text-align: center;
}

.info-box-content {
	text-align: left;
}


.info-box-content .info-box-title {
	font:600 16px/1.1 'Open Sans', Verdana, sans-serif;
	color:#313131;
	margin:0;
}

.info-box-content .info-box-desc {
	margin:3px 0 0 0;
	line-height: 1.2;
	font-size:12px;
	color:#313131;
}

@media (max-width: 991px) {
	.info-box-content {
		min-width: 215px;
	}

}

@media (min-width: 992px) {
	.info-box-container {
		border-right: 1px solid #e1e1e1;
	}

	.info-box-container:last-child {
		border-right:none;
	}

	.info-box-icon {
		text-align: right;
	}
}

#banner-container .banner {
	width:100%;
	margin-bottom: 1.98%;
	padding:0 11px;
}

.banner-row {
	margin-left:-11px;
	margin-right: -11px;
}

.banner a {
	display: block;
	position: relative;
}

.banner img {
	display: block;
	width:100%;
	height:auto;
	transition: all 0.35s ease 0s;
	-webkit-transition: all 0.35s ease 0s;
}

.banner a:after {
	content:'';
	display:block;
	position: absolute;
	height:100%;
	width:100%;
	left:0;
	top:0;
	transition: all 0.35s ease 0s;
	-webkit-transition: all 0.35s ease 0s;
	background-color: rgba(0,0,0, 0.15);
	opacity:0;
	z-index: 99;
	-webkit-transform: translate3d(0,0,0);
}

.banner:hover a:after {
	opacity:1;
}

.banner:hover img {
	opacity:0.9;
}

#banner-container .grid-sizer,
#banner-container .banner {
	width: 25%;
}

#banner-container .banner.w2 {
	width: 50%;
}

@media (max-width: 767px) {
	#banner-container .grid-sizer,
	#banner-container .banner,
	#banner-container .banner.w2 {
		width: 100%;
	}

}

.title-group {
	position: relative;
}

.slider-title {
	position: relative;
	margin: 0 0 1.25em;
	font-size: 1.1429em;
	line-height: 1.4;
	font-weight: 700;
	color: #313131;
	text-transform: uppercase;
}

.slider-title .inline-title {
	background-color: #fff;
	padding-right: 20px;
	position: relative;
	z-index: 2;
}

.skin5 .slider-title .inline-title {
	background: #fff;
}

.skin9 .slider-title .inline-title {
	background: #fff;
}

.slider-title .line,
.title-group .line {
	display: block;
/*	height: 1px;
	position: relative;
	width: calc( 100% - 50px);
	left:0;
	bottom: 0.7em;
	background-image: - webkit-linear-gradient(left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0) 100%);
    background: #9ac93d;
height: 3px;
width: 60%;
margin: 12px 0 0;
	z-index: 1;                         */
}

.title-group .line  {
	position: absolute;
	left:0;
	top:50%;
	z-index: 1;
}

@media (max-width: 480px) {
	.slider-title .line,
	.title-group .line {
		display: none;
	}
}


/* -------------  6.3-index4.html  -----------
--------------------------------------------*/

.content-info-box-wrapper {
	background-color: #fbfbfb;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
	margin-bottom: 35px;
}

.content-info-box-wrapper .info-boxes-container {
	margin:0;
	border:none;
}

.skin4 .banner,
.skin4 .banner img,
.skin4 .banner a:after {
	border-radius:7px !important;
}

.skin4 .banner a:after {
	background-color: rgba(0,0,0, 0.04);
}


.icon-box {
	margin-bottom: 30px;
}


.icon-box .icon-container {
	display: block;
	float:left;
	width:64px;
	height:64px;
	border:2px solid #ccc;
	border-radius: 50%;
	line-height: 60px;
	font-size:28px;
	color:#333;
	text-align: center;
}

.icon-box .icon-box-block {
	margin-left:79px;
}


.icon-box .icon-box-block h3 {
	font:600 20px/1.2em 'Open Sans', Verdana;
	color:#000;
	margin:0;
}

.icon-box .icon-box-block h4 {
	color:#888;
	font-weight:300;
	font-size:11px;
	font-style:normal;
	line-height:1;
	margin-top:2px;
	margin-bottom:15px;
	text-transform: uppercase;
}

.icon-box .icon-box-block p {
	color:#666;
	margin:10px 0 0;
}

.skin4 .owl-dot {
	background-color:#9ac93d;
}

.thumb-info {
	display: block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    max-width: 100%;
}

.thumbnail {
    display: block;
    padding: 0px;
    margin-bottom: 0px;
    line-height: 1.42857;
    background-color: #fff;
    border: 0px solid #ddd;
    border-radius: 0px;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.2s;
    -webkit-transition:all 0.2s;
}

.owl-carousel .thumbnail,
.owl-carousel .img-thumbnail {
	max-width: 99.5%;
}

.thumb-info:hover img {
	transform:scale(1.1, 1.1);
	-webkit-transform:scale(1.1, 1.1);
	-ms-transform:scale(1.1, 1.1);
}

.skin4 .post-item .post-date .month {
	background-color: #9ac93d;
}

.skin4 .post-item .post-date .day,
.skin4 .post-item h4 a,
.skin4 .post-item h4 a:hover,
.skin4 .post-item a,
.skin4 .post-item a:hover{
	color: #9ac93d;
}


.parallax-info {
	position: relative;
	background-image: url('../images/parallaxbg.jpg');
}

.parallax-info.parallax-info2 {
	background-image: url('../images/parallaxbg2.jpg');
}

.parallax-info.parallax-info.v3 {
	background-image: url('../images/parallaxbg7.jpg');
}

.parallax-info .overlay {
	background-color: rgba(32,32,32,0.82);
	position: absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	z-index:20;
}

.parallax-info.parallax-info2 .overlay {
	background-color: rgba(0,0,0, 0.75);
}

.parallax-info .container {
	position: relative;
	z-index: 30;
}

.info-carousel-block {
	text-align: center;
}

.info-carousel-block h2,
.info-carousel-block h4 {
	font-family: 'Open Sans', Verdana;
	color:#fff;
}

.info-carousel-block h2 {
	line-height: 1.2;
	font-size: 4.2857em;
	font-weight: 600;
	letter-spacing: -1px;
	margin:0;
	text-transform: uppercase;
}

.parallax-info2 .info-carousel-block h2 {
	margin-bottom: 5px;
}

.parallax-info2 .info-carousel-block h2 strong {
	font-weight: 800;
}

.info-carousel-block h4 {
	font-size:1.5714em;
	font-weight: 300;
	margin:0 0 14px 0;
}

.info-carousel-block .btn-link {
	font-size:1.1429em;
	padding:0;
	margin:15px 0;
}

.parallax-info2 .info-carousel-block .btn-link {
	margin-bottom: 0;
}

.info-carousel-block .btn-link,
.info-carousel-block .btn-link:hover,
.info-carousel-block .btn-link:focus {
	color:#fff;
}

.info-carousel-block .btn-link:hover,
.info-carousel-block .btn-link:focus {
	text-decoration: underline
}


.parallax-info .owl-dot {
	background-color: #fff;
	width:11px;
	height:11px;
	margin:0 6px;
}

.parallax-info.parallax-info2 .owl-dot {
	width:8px;
	height:8px;
	background-color: #a0a09f;
	margin:0 7px;
	opacity:1;
}

.skin11 .parallax-info.parallax-info2 .owl-dot:hover,
.skin11 .parallax-info.parallax-info2 .owl-dot.active {
	background-color: #7aa93c;
}

/* -------------  6.4-index5.html  -----------
--------------------------------------------*/

@media (min-width: 768px) {
	.container.no-padding {
		padding:0;
	}
}

.container-heading {
	padding:15px 0;
}

.container-heading h3 {
	font-size:16px;
	color:#333;
	text-align: center;
	font-family: 'Open Sans', Verdana;
	font-weight: 700;
	line-height: 27px;
	margin: 0;
}

.product-slider {
	padding-bottom:35px;
}

.skin5 .product-image .onhot,
.skin5 .product-image .onsale,
.skin5 .onhot,
.skin5 .onsale,
.skin5 .product-action .product-btn   {
    border-radius: 0;
}

.skin5 .product-image,
.skin5 .product-image img{
	padding:0;
	border:none;
	border-radius: 0;
}

.product-slider .product-image {
	font-size:1.2em;
}

.skin5  .owl-dot {
	background-color:#9ac93d;
}

.skin5 .banner a:after {
	background-color: rgba(0,0,0, 0.05);
}

@media (max-width: 480px) {
	.col-xss-12 {
		float:none;
		width:100%;
	}
}

.product-group-container {
	padding-top:15px;
	padding-bottom: 35px;
}

.product-group-container .product {
	margin-bottom: 30px;
}

.vinfo-box {
	text-align: center;
	padding:0 50px;
	margin-bottom: 30px;
}

.vinfo-box-icon {
	display: inline-block;
	font-size: 28px;
	color:#333;
}

.vinfo-box-title {
	font-family: 'Open Sans', Verdana;
	font-size:15px;
	font-weight: 600;
	margin:25px 0 15px;
	line-height: 1.2em;
	color:#000;
}


.vinfo-box-desc {
	color:#666;
	margin:0 auto;
}

.icon-read {
	display: block;
	padding:10px 0;
	color:#9ac93d;
}

.icon-read:hover,
.icon-read:focus {
	color:#0099e6;
	text-decoration: underline;
}


/* Partners Carousel */
.brands-carousel-container {
	padding:0 20px;
}

.brands-carousel-container.no-padding {
	padding:0;
}

.brand a {
	display:block;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	position: relative;
	text-align: center;
}

.owl-item .brand img {
	display:inline-block;
	max-width:none;
	width:100%;
	height: auto;
}

.brands-carousel-container .owl-carousel .owl-item .brand img {
	display:inline-block;
	max-width:100%;
	width:auto;
	height: auto;
}


/* -------------  6.5-index6.html  -----------
--------------------------------------------*/

.banner-group-row .container-fluid {
	padding:0 10px;
}

.banner-group-row .row {
	margin:0 -10px;
}

.banner-group-row [class^="col-"],
.fullwidth-row [class^="col-"] {
	padding:0;
}

.skin6 .banner a:after {
	background-color: rgba(0, 136, 204, 0.15);
}


.fullwidth-row .row {
	margin:0;
	position: relative;
}

.fullwidth-row .special-col {
	padding-top: 4% !important;
	padding-right: 6% !important;
	padding-bottom: 4% !important;
	padding-left: 6% !important;
	background-color: #f9f9f9;
}

.bg-col-container {
	position: static;
}

.fullwidth-row .bg-col {
	background-size: cover;
	background-position: center center;
	background-color: rgb(249, 249, 249);
	background-repeat: no-repeat;
	position: absolute;
	width:50%;
	left:0;
	right:0;
	bottom:0;
	margin-right: 50%;
	top:0;
}

.fullwidth-row .special-col + .bg-col-container > .bg-col {
	margin-left:50%;
	margin-right: 0;
	right:0;
}

.fullwidth-row .bg-col.bg-1 {
	background-image: url('../images/background1.jpg');
}


.fullwidth-row .bg-col.bg-2 {
	background-image: url('../images/background2.jpg');
}

.title {
	color:#000;
	font:600 28px/34px 'Open Sans', Verdana;
	margin-bottom: 5px;
}

.title-desc {
	margin-bottom: 25px;
	font-weight: 400;
}

.title.spaced {
	margin:35px 0 25px;
}

@media (max-width: 480px) {
	.title {
		font-size:20px;
	}
}

.skin6 .product-image .onhot,
.skin6 .product-image .onsale {
    border-radius: 0;
}

.skin6 .product-image,
.skin6 .product-image img{
	padding:0;
	border:none;
	border-radius: 0;
}

.skin6 .product h3 a:hover,
.skin6 .product h3 a:focus {
	color:#000;
}

.product .product-image .product-btn {
	border-radius:0;
	background-color: #fff;
	position: absolute;
	bottom:16px;
	padding:0;
	width:2.2em;
}

.product:hover .product-image .product-btn.btn-add-cart,
.product .product-image .product-btn.btn-add-cart {
	border-radius:0;
	border-color: #000000;
	color:#000000;
	background-color: #fff;
	position: absolute;
	left:0.8em;
	opacity:0;
}

.product .product-image .product-btn.btn-add-cart i {
	margin:0;
}

.product .product-image .product-btn.btn-add-cart:hover,
.product .product-image .product-btn.btn-add-cart:focus {
	background-color: #000;
	border-color: #000000;
	color:#fff;
}

.product .product-image .product-btn.btn-wishlist {
	right:0.8em;
}

.product .product-image .product-btn.btn-quickview {
	font-size: 0.9em;
	position: absolute;
	top: 0;
	right: 0;
	left: auto !important;
	bottom:auto !important;
	width: auto;
	height: auto;
	line-height: 1.1;
	margin: 0;
	padding:0.7937em;
	text-indent: 0;
	border-radius: 0 0 0 0 !important;
	background: #52b9b5;
	color: #fff;
	-webkit-transition: all 0.25s ease 0s;
	-moz-transition: all 0.25s ease 0s;
	transition: all 0.25s ease 0s;
}

.product .product-image .product-btn.btn-quickview i {
	margin-right: 0.5714em;
}

.product .product-image .product-btn.btn-wishlist,
.product .product-image .product-btn.btn-quickview {
	opacity:0;
	transform:translateX(0);
	-webkit-transform:translateX(0);
	-ms-transform:translateX(0);
}

.product .product-image .product-btn.btn-quickview:hover,
.product .product-image .product-btn.btn-quickview:focus {
	background-color: #64c0bd;
}

.product:hover .product-image .product-btn.btn-add-cart,
.product:hover .product-image .product-btn.btn-wishlist,
.product:hover .product-image .product-btn.btn-quickview {
	opacity:1;
}


/* -------------  6.6 index7.html  -----------
--------------------------------------------*/

.banner-home-group  {
	margin-bottom: 25px;
}

@media (max-width: 991px) {
	.banner-home-group  {
		margin-bottom: 15px;
	}
}

.banner-home-group [class^="col-"] {
	padding:0 4px;
}

.banner-home-group .row {
	margin-left:-4px;
	margin-right:-4px;
}

.banner-home-group .banner {
	margin-bottom: 8px;
}

.skin7 .banner a:after {
	background-color: rgba(0,0,0, 0.1);
}

.skin7 .product h3 a:hover {
	color:#65829d;
}

.skin7 .product-image .onhot,
.skin7 .product-image .onsale,
.skin7 .onhot,
.skin7 .onsale,
.skin7 .product-action .product-btn   {
    border-radius: 0;
}

.skin7 .product-image,
.skin7 .product-image img{
	padding:0;
	border:none;
	border-radius: 0;
}

.skin7 .owl-dot {
	background-color:#65829d;
}

.skin7 .product:hover .product-btn.btn-add-cart {
	background-color: #65829d;
	border-color:#65829d;
}

.skin7 #main-content .product .product-image .product-btn.btn-add-cart {
	background-color: #fff;
	color:#65829d;
}

.skin7 #main-content .product .product-image .product-btn.btn-add-cart:hover {
	background-color: #65829d;
	border-color:#65829d;
	color:#fff;
}

.skin7 .owl-prev,
.skin7 .owl-next {
	color:#65829d;
}

.skin7 .owl-prev:hover,
.skin7 .owl-next:hover {
	color:#758fa7;
}

.banners-carousel {
	margin-bottom: 50px;
}

.banners-carousel .banner {
	margin-bottom:0;
	overflow: hidden;
}

.banners-carousel .banner a:after {
	opacity:0;
}

.banners-carousel .banner img {
	transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
}

.banners-carousel .banner:hover img {
	transform:scale(1.08);
	-webkit-transform:scale(1.08);
	-ms-transform:scale(1.08);
	opacity:1;
}

.banner-info {
    bottom: 10%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

.banner-info h3 {
    display: inline-block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: rgba(36, 27, 28, 0.9);
    color: #fff;
    font-size: 18px;
    line-height: 1em;
    font-weight: 700;
    letter-spacing: -1px;
    padding: 9px 11px 9px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    z-index: 1;
    margin:0;
}


.col-md-2 .product {
	margin-bottom: 20px;
}

.post-carousel {
	margin-bottom: 30px;
}

.post-item .post-date {
	float:left;
	margin:3px 10px 8px 0;
	width:40px;
	text-align: center;
}

.post-item h4 {
	margin-bottom: 7px;
}

.post-item h4 a,
.post-item h4 a:hover,
.post-item a,
.post-item a:hover {
	color:#65829d;
}

.post-item h4 a:hover,
.post-item a:hover {
	text-decoration: underline;
}

.post-item .post-date .day {
	color: #65829d;
	background-color: #f4f4f4;
	border-radius: 0 0 0 0;
	display: block;
	font-size: 16px;
	font-weight: 500;
	font-weight: bold;
	padding: 10px 0;
}

.post-item .post-date .month {
	background-color: #65829d;
	border-radius: 0 0 0 0;
	box-shadow:inset 0 -1px 0 0 rgba(0, 0, 0, 0.07);
	color: #fff;
	font-size: 0.9em;
	padding: 0 0 2px;
	display: block;
}

.post-item .post-excerpt {
	margin-bottom: 10px;
	max-width: 270px;
}

.testimonial-carousel {
	margin-bottom: 30px;
}

blockquote.testimonial {
    background: #758fa7;
    border-radius: 0;
    border: 0;
    color: #666;
    font-family: Georgia, serif;
    font-style: italic;
    margin: 0;
    padding: 10px 50px;
    position: relative;
}

	.skin7 blockquote.testimonial {
		border-radius: 0;
	}

blockquote.testimonial:before {
    content: "\201C";
    left: 10px;
    top: 0;
    color: #fff;
    font-size: 80px;
    font-style: normal;
    line-height: 1;
    position: absolute;
}

blockquote.testimonial:after {
    color: #fff;
    font-size: 80px;
    font-style: normal;
    line-height: 1;
    position: absolute;
    bottom: -0.5em;
    content: "\201D";
    right: 10px;
}

blockquote.testimonial p {
    color: #fff;
    font-family: Georgia, serif;
    font-style: italic;
    font-size: 1.2em;
    line-height: 1.3;
}

.testimonial-arrow-down {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #758fa7;
    height: 0;
    margin: 0 0 0 25px;
    width: 0;
}

.testimonial-author {
    margin: 8px 0 0 8px;
}

.testimonial-author .img-thumbnail {
    float: left;
    margin-right: 10px;
    width: auto;
}

.testimonial-author .img-thumbnail img {
    max-width: 60px;
    height: auto;
}

.testimonial-author a {
    text-decoration: none;
}

.testimonial-author strong {
    color: #111;
    display: block;
    padding-top: 7px;
}

.testimonial-author span {
    color: #666;
    display: block;
    font-size: 12px;
}

.testimonial-author p {
    color: #999;
    margin: 0 0 0 25px;
    text-align: left;
}

.owl-carousel.close-dots .owl-dots {
	margin-top:0;
}

.owl-carousel.testimonial-carousel.close-dots .owl-dots {
	margin-top:10px;
}

/* -------------  6.7-index8.html  -----------
--------------------------------------------*/

.category-box {
	margin-bottom: 30px;
	overflow: hidden;
}

.category-box h4 {
	font:600 17px/27px 'Open Sans', Verdana;
	margin:0 0 14px 0;
	text-align: center;
	color:#818385;
}

.category-box .banner {
	position: relative;
}

.category-box .banner a:after {
	background-color: rgba(0,0,0, 0.2);
}

.category-box .banner-footer {
	position: absolute;
	left:0;
	right:0;
	top: auto;
	bottom: 0;
	z-index: 99;
	height: auto;
	background: #a1a2a3;
	color: #fff;
	-webkit-transition: -webkit-transform .35s;
	transition: transform .35s;
	-webkit-transform: translate3d(0,100%,0);
	-moz-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0);
	text-align:center;
}

.category-box:hover .banner-footer,
.category-box:hover .banner-footer h2 {
	-ms-transform:translateY(0);
	-webkit-transform:translateY(0);
	transform:translateY(0);
}

.category-box .banner-footer h2 {
	margin:0;
	color:#fff;
	padding: 15px;
	font-size:14px;
	line-height: 1.35em;
	font-weight: normal;
	letter-spacing: normal;
	-webkit-transition: -webkit-transform .35s;
	transition: transform .35s;
	-webkit-transform: translate3d(0,100%,0);
	-moz-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0);
	transition-delay:0.05s;
	-webkit-transition-delay:0.05s;
}


.skin8 .product h3 a:hover {
	color:#e13b3f;
}

.skin8 .product-image .onhot,
.skin8 .product-image .onsale,
.skin8 .onhot,
.skin8 .onsale,
.skin8 .product-action .product-btn   {
    border-radius: 0;
}

.skin8 .product-image,
.skin8 .product-image img{
	padding:0;
	border:none;
	border-radius: 0;
}

.skin8 .owl-dot {
	background-color:#e13b3f;
}

.small-title {
	font:600 17px/27px 'Open Sans', Verdana;
	margin:0 0 14px 0;
	color:#818385;
}

.skin8 .product .product-image .product-btn.btn-wishlist,
.skin8 .product .product-image .product-btn.btn-add-cart {
	background-color: #fff;
	color:#ed4949;
	border-color:#ed4949;
}

.skin8 .product .product-image .product-btn.btn-wishlist:hover,
.skin8 .product .product-image .product-btn.btn-add-cart:hover {
	background-color: #ed4949;
	color:#fff;
	border-color:#ed4949;
}


/* -------------  6.8-index9.html  -----------
--------------------------------------------*/

.skin9 .row {
	margin-left:-8px;
	margin-right:-8px;
}

.skin9 [class^="col-"] {
	padding:0 8px;
}

#slider-wrapper {
	padding:0 0 44.5px;
}


.content-info-box-wrapper.white {
	background-color: #fff;
	border:none;
	margin-bottom: 0;
}

.content-info-box-wrapper.white .info-box-container {
	background-color: #fff;
	border-color:#f5f5f5 !important;
}

.skin9 .content-info-box-wrapper.white .row.clean-row {
	margin-left:0px;
	margin-right: 0px;
	border-top: 1px solid #f5f5f5;
	border-bottom: 1px solid #f5f5f5;
}

.content-info-box-wrapper.white [class^=col-] {
	padding:0 15px;
}


.skin9 .banner a:after {
	background-color: rgba(0,0,0, 0.04);
}

.skin9 .container > .banner a:after {
	background-color: rgba(0,0,0, 0.005);
}

.skin9 .product h3 a:hover {
	color:#e13b3f;
}

.skin9 .product-image .onhot,
.skin9 .product-image .onsale,
.skin9 .onhot,
.skin9 .onsale,
.skin9 .product-action .product-btn   {
    border-radius: 0;
}

.skin9 .product-image,
.skin9 .product-image img{
	padding:0;
	border:none;
	border-radius: 0;
}

@media (max-width: 767px) {
	.col-xs-6 .banner {
		margin-bottom: 15px;
	}
}



/* -------------  6.9-index11.html  -----------
--------------------------------------------*/

.skin11 ul.product_list_widget li .product-details a:hover,
.skin11 .widget ul.product_list_widget li .product-details a:hover,
.skin11 .product h3 a:hover {
	color:#7aa93c;
}

.skin11 .owl-prev,
.skin11 .owl-next {
	color:#7aa93c;
	opacity:1;
}

.skin11 .owl-prev:hover,
.skin11 .owl-next:hover {
	color:#7aa93c;
	opacity:0.9;
}

.content-widgets.content-widgets2 {
	margin-bottom: 55px;
	font-size:12px;
}

.nav-ralign .owl-nav {
	margin-right:-9px;
}

#content .content-widgets2 .widget .widget-title {
	font-size:1.3em;
}

.v2 .sidebar ul.product_list_widget li .product-image,
.v2 .sidebar .widget ul.product_list_widget li .product-image,
.content-widgets2 ul.product_list_widget li .product-image,
.content-widgets2 .widget ul.product_list_widget li .product-image {
	width:86px;
}

.v2 .sidebar ul.product_list_widget li .product-details,
.v2 .sidebar .widget ul.product_list_widget li .product-details,
.content-widgets2 ul.product_list_widget li .product-details,
.content-widgets2 .widget ul.product_list_widget li .product-details {
	margin-left:98px;
}

.v2 .star-rating:before {
	color:#c3c5c9;
}

.v2 .sidebar ul.product_list_widget li .product-details .amount,
.v2 .sidebar .widget ul.product_list_widget li .product-details .amount,
.content-widgets2 ul.product_list_widget li .product-details .amount,
.content-widgets2 .widget ul.product_list_widget li .product-details .amount {
	font-size:1.25em;
}

.v2 .sidebar ul.product_list_widget li .star-rating,
.v2 .sidebar .widget ul.product_list_widget li .star-rating ,
.content-widgets2 ul.product_list_widget li .star-rating,
.content-widgets2 .widget ul.product_list_widget li .star-rating {
	margin-top:0;
	margin-bottom: 8px;
}

.skin11 .product .product-image .product-btn.btn-quickview {
	background: #7aa93c;
	border-color:#7aa93c;
}

.v2 .product .product-image .product-btn.btn-quickview {
	border-radius: 0 5px !important;
	font-size: 0.85em;
	line-height: 1.35;
}

.v2 .product .product-image .product-btn.btn-quickview:hover {
	text-decoration: underline;
	opacity:0.9;
}

.title-group  {
	margin-bottom: 10px;
	line-height: 0;
}

.title-group .nav-tabs.nav-links {
	display: inline-block;
	padding-right: 20px;
	background-color: #fff;
	position: relative;
	z-index: 5;
}

.nav-tabs.nav-links {
	border:none;
	margin-bottom: 0;
}

.nav-tabs.nav-links > li {
	line-height: 43px;
}

.nav-tabs.nav-links > li > a {
	background-color: transparent;
	border:none;
	color:#313131;
	padding:0;
	padding-left:15px;
	margin-left:12px;
	border-left:1px solid #ddd;
	font:700 16px/22px 'Open Sans', Verdana;
	text-transform: uppercase;
}

.nav-tabs.nav-links > li.active > a {
	padding:0 0 0 7px !important;
	padding-left:15px !important;
	margin-left:12px;
	border:none;
	border-left:1px solid #ddd;
}

.nav-tabs.nav-links > li:first-child > a,
.nav-tabs.nav-links > li.active :first-child > a  {
	padding-left:0 !important;
	margin-left:0 !important;
	border-left:none;
}

.nav-tabs.nav-links > li.active > a,
.nav-tabs.nav-links > li.active > a:hover,
.nav-tabs.nav-links  > li.active > a:focus {
	background-color: transparent;
	color:#7aa93c;
}

.nav-tabs.nav-links > li > a:hover,
.nav-tabs.nav-links  > li > a:focus {
	text-decoration: underline;
}


.tabs-container .tab-content {
	padding:0;
	border:none;
	box-shadow: none;
	-webkit-box-shadow:none;
}

@media (max-width: 767px) {
	.nav-tabs.nav-links > li {
		float:left;
		width:auto;
	}

	.nav-tabs.nav-links > li.active > a,
	.nav-tabs.nav-links > li.active > a:hover,
	.nav-tabs.nav-links > li.active > a:focus,
	.nav-tabs.nav-links > li > a:hover,
	.nav-tabs.nav-links > li > a:focus {
		border-top:none !important;
	}

	 .title-group {
	 	margin-bottom: 22px;
	 }
}

@media (max-width: 480px) {
	.title-group .nav-tabs.nav-links {
		padding-right: 0;
	}
}


/* -------------  6.10 - index12.html  -----------
-----------------------------------------------*/

.top-container {
	padding:29px 0 35px;
	background-color: #f4f4f4;
}

.top-container .row {
	margin-left:-8px;
	margin-right:-8px;
}

.top-container .slider-area,
.top-container  .side-area {
	padding:0 8px;
}

.top-container .side-area .row {
	margin:0;
}

@media (min-width: 992px) {

	.top-container .slider-area {
		width:72.3%;
	}

	.top-container  .side-area {
		width:27.7%;
	}

	.top-container  .slider-area,
	.top-container  .side-area  {
		float:left;
	}

	.top-container  .side-area .banner {
		margin-bottom: 10px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.top-container  .side-area .banner {
		float:left;
		width:33.3%;
		padding:8px;
	}

	.top-container  .side-area .row {
			margin:0 -8px;
	}
}

@media (max-width: 767px) {
	.top-container  .side-area .banner {
		padding:8px 0 0;
	}
}


.top-container .owl-carousel .owl-controls {
	position: absolute;
	bottom:20px;
	width:100%;
	margin:0;
}

.top-container .owl-carousel .owl-dots {
	margin:0;
	line-height: 0;
}

.skin12 .owl-carousel .owl-dot,
.top-container .owl-carousel .owl-dot {
	width:8px;
	height:8px;
	opacity:1;
	background-color: #a0a09f;
	margin:5px 7px;
}

.skin12 .owl-carousel .owl-dot:hover,
.skin12 .owl-carousel .owl-dot.active,
.skin12 .top-container .owl-carousel .owl-dot:hover,
.skin12 .top-container .owl-carousel .owl-dot.active {
	background-color: #ee3d43;
}

.tabs-container.grid .product {
	margin-bottom: 10px;
}

@media (min-width: 1200px) {
	.tabs-container.grid .product {
		margin-bottom: 30px;
	}
}

.grid-banners {
	margin:10px 0 30px;
}

.grid-banners .banner {
	margin-bottom: 10px;
}

.skin12 .product h3 > a:hover,
.skin12 .nav-tabs.nav-links > li.active > a,
.skin12 .nav-tabs.nav-links > li.active > a:hover,
.skin12 .nav-tabs.nav-links > li.active > a:focus,
.skin12 .post-item h4 a, .post-item h4 a:hover,
.skin12 .post-item a,
.skin12 .post-item a:hover,
.skin12 .post-item .post-date .day {
	color:#ee3d43;
}

.skin12 .post-item .post-date .month,
.skin12 .product .product-image .product-btn.btn-quickview {
	background: #ee3d43;
	border-color:#ee3d43;
}

.skin12 .product:hover .product-image .product-btn.btn-add-cart,
.skin12 .product .product-image .product-btn.btn-add-cart {
	background-color: #fff;
	color:#ee3d43;
	border-color:#ee3d43;
}

.skin12 .product .product-image .product-btn.btn-add-cart {
	border-radius: 4px;
}


.sidebar .widget-block .widget {
	margin:0;
}

.sidebar .widget-block {
	padding-bottom: 37px;
	margin-bottom: 30px;
	background: url('../images/border.png') center bottom no-repeat;
}

.custom-text-widget h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1;
	color:#000;
	margin-bottom: 15px;
}

.custom-text-widget h5 {
	font:400 11px/1 Arial, Verdana;
	color:#878787;
	margin-bottom: 13px;
}

.custom-text-widget p {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42;
	color:#666;
}


/* -------------  6.11 - index13.html  -----------
-----------------------------------------------*/

.skin13 .info-boxes-container {
	border-radius: 0;
}

.info-boxes-sm .info-box {
	padding:13px 0;
}

.info-boxes-sm .info-box-icon {
	font-size: 34px;
}

.info-boxes-sm .info-box-content .info-box-title {
	font-size: 14px;
}

.skin13 .product h3 > a:hover,
.skin13 .nav-tabs.nav-links > li.active > a,
.skin13 .nav-tabs.nav-links > li.active > a:hover,
.skin13 .nav-tabs.nav-links > li.active > a:focus,
.skin13 .post-item h4 a, .post-item h4 a:hover,
.skin13 .post-item a,
.skin13 .post-item a:hover,
.skin13 .post-item .post-date .day,
.skin13 .product-categories li a:hover {
	color:#000;
}

.skin13 .owl-carousel .owl-dot:hover,
.skin13 .owl-carousel .owl-dot.active,
.skin13 .top-container .owl-carousel .owl-dot:hover,
.skin13 .top-container .owl-carousel .owl-dot.active {
	background-color: #000;
}

.skin13 .owl-prev,
.skin13 .owl-next {
	color:#000;
	opacity:1;
}

.skin13 .owl-prev:hover,
.skin13 .owl-next:hover {
	color:#000;
	opacity:0.9;
}

.skin13 .product-image {
	padding:0;
	border:none;
}

.skin13 .product-image,
.skin13 .product .product-image .product-btn.btn-quickview,
.skin13 .product-image img {
	border-radius: 0 !important;
}

.skin13 .product .product-image .product-btn.btn-quickview {
	background: #000;
	border-color:#000;
}

@media (min-width: 1200px) {
	.col-lg-2-4 {
		width:20%;
	}

	.col-lg-9-6 {
		width:80%;
	}
}

.skin13 .product .product-image .product-btn.btn-add-cart,
.skin13 .product .product-image .product-btn.btn-wishlist,
.skin13 .product .product-image .product-btn.btn-compare {
	bottom:16px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
}

.skin13 .product .product-image .product-btn.btn-add-cart {
	left:46px;
	bottom:16px;
}

.skin13 .product .product-image .product-btn.btn-wishlist {
	right:auto;
	left:10px;
	bottom:16px;
}

.skin13 .product .product-image .product-btn.btn-compare {
	right:auto;
	left:82px;
	bottom:16px;
	opacity:0;
	border-color:#52b9b5;
	color: #52b9b5;
}

.skin13 .product .product-image .product-btn.btn-compare:hover {
	background-color: #52b9b5;
	color:#fff;
}

.skin13 .product:hover .product-image .product-btn.btn-compare {
	opacity:1;
}

.skin13 .product .product-image .product-btn {
	width:1.95em;
}

.skin13 .product .product-image .product-btn.btn-quickview {
	width:auto;
}

.skin13 #content .sidebar .widget .widget-title,
.skin13 .sidebar .widget .widget-title {
	color:#333;
	font-size: 15px;
	font-weight: 600;
	padding:8px 0;
	border-bottom: 1px solid #ddd;
}

.sidebar .widget .widget-title i {
	margin-right: 5px;
	font-size: 18px;
}

.feature-box-widget p {
	font-size: 14px;
	line-height: 1.5;
	margin-bottom: 10px;
}

.feature-box-widget a,
.feature-box-widget a:hover {
	color:#000;
}

.feature-box-widget a:hover {
	text-decoration: underline;
}

.category-shop-widget .product-categories {
	padding:0;
	margin-bottom: 5px;
}

.category-shop-widget .product-categories li {
	line-height: 40px;
	padding-left:12px;
	border-bottom:1px solid #ddd;
}

.category-shop-widget .product-categories li a {
	font-size: 15px;
	padding:0;
}

.skin13 #content .sidebar .widget.widget-shop2 .widget-title,
.skin13 .sidebar .widget.widget-shop2 .widget-title {
	padding-bottom:18px;
	margin-bottom: 0;
}

.widget.widget-shop2:first-child {
	margin-top:-8px;
}

.skin13 .slider-title {
	font-size: 15px;
	font-weight: 600;
}

/* -------------  6.12 - index14.html  -----------
-----------------------------------------------*/

.fullscreen {
	height:100vh;
}

#parallax-1 {
	background-image: url('../images/parallaxbg3.jpg');
}

#parallax-2 {
	background-image: url('../images/parallaxbg4.jpg');
}

#parallax-3 {
	background-image: url('../images/parallaxbg5.jpg');
}

#parallax-4 {
	background-image: url('../images/parallaxbg6.jpg');
}

.parallax-content {
	display: inline-block;
}

.parallax-content  h2,
.parallax-content  h3,
.parallax-content  h4 {
	color:#fff;
	line-height: 1;
}

.parallax-content.dark  h2,
.parallax-content.dark  h3,
.parallax-content.dark  h4 {
	color:#333;
}

.parallax-content  h2 {
	font-size: 79px;
	font-weight: 800;
	margin:5px 0 10px;
}

.parallax-lg .parallax-content h2 {
	letter-spacing: -6px;
	margin:0;
}

.parallax-content.smaller h2 {
	font-size: 61px;
	letter-spacing: -3px;
}

.parallax-content  h3 {
	font-weight: 300;
	font-size: 26px;
	text-transform: uppercase;
	margin:0;
}

.parallax-lg .parallax-content h3 {
	font-weight: 600;
}

.parallax-content  h3 * {
	vertical-align: middle;
}

.parallax-content  h3 > span {
	margin:0 15px;
}

.parallax-content  h4 {
	font-weight: 300;
	font-size: 23px;
	margin:0;
}

.parallax-content.smaller h4 {
	font-size: 18px;
	margin-bottom: 15px;
}

.parallax-content  h4 > span {
	display: inline-block;
	vertical-align: top;
	margin-top:5px;
}

.parallax-content img {
	display: inline-block;
	max-width: 100%;
	height:auto;
}

.parallax-lg .parallax-content h4 {
	font-weight: 400;
}

.parallax-content  h4 strong {
	font-weight: 800;
}

.parallax-lg {
	padding:250px 0;
}

.parallax-content .btn {
	font-size: 16px;
	padding:9px 27px;
	vertical-align: top;
	margin:5px 0 0 17px;
	font-weight: 600;
	transition: all 0.3s;
}

.parallax-content.smaller .btn {
	padding:7px 34px;
	font-weight: 400;
}

.btn.no-radius {
	border-radius: 0;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus {
	background-color: #333;
	color:#fff;
}

.btn-white,
.btn-white:hover,
.btn-white:focus {
	background-color: #fff;
	color:#333;
}

.btn-white:hover,
.btn-white:focus,
.btn-dark:hover,
.btn-dark:focus {
	opacity:0.9;
}

.parallax-section .slider-title {
	font-size: 15px;
	margin:3px 0 15px;
	color:#fff;
	font-weight: 600;
}

.skin14.common-home .product-image {
	padding:0;
	border:none;
}

.skin14.common-home .product h3 > a,
.skin14.common-home .product h3 > a:hover,
.skin14.common-home .star-rating:before,
.skin14.common-home .price  {
	color:#fff;
}

.skin14 .light .rating-wrap .rating-before .rating-line {
    background-image: - webkit-linear-gradient(right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 40%, transparent);
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 40%, transparent);
}

.skin14 .light .rating-wrap .rating-after .rating-line {
    background-image: - webkit-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 40%, transparent);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 40%, transparent);
}

.parallax-section .dark .slider-title,
.skin14 .dark .product h3 > a,
.skin14 .dark .product h3 > a:hover,
.skin14 .dark .star-rating:before,
.skin14 .dark .price {
	color:#333;
}

.skin14 .owl-carousel .owl-dot {
	width:8px;
	height:8px;
	opacity:1;
	background-color: #a0a09f;
	margin:5px 7px;
}

.skin14 .owl-carousel .owl-dot:hover,
.skin14 .owl-carousel .owl-dot.active,
.skin14 .top-container .owl-carousel .owl-dot:hover,
.skin14 .top-container .owl-carousel .owl-dot.active {
	background-color: #08c;
}

.skin14.common-home .product-image,
.skin14.common-home .product .product-image .product-btn.btn-quickview,
.skin14.common-home .product-image img {
	border-radius: 0 !important;
}

.skin14 .product .product-image .product-btn.btn-quickview {
	background: #08c;
	border-color:#08c;
}


@media (max-width: 991px) {
	.parallax-lg {
		padding:150px 0;
	}
}

@media (max-width: 767px) {
	.parallax-lg {
		padding:50px 0;
	}

	.parallax-content h3 > img {
		width:20px;
	}


	.parallax-content  h2 {
		font-size: 35px;
		margin:4px 0 7px;
	}

	.parallax-lg .parallax-content h2 {
		font-size: 30px;
		letter-spacing: -1px;
		margin:0;
	}

	.parallax-content.smaller h2 {
		font-size: 22px;
		letter-spacing: -1px;
	}

	.parallax-content  h3 {
		font-size: 12px;
	}

	.parallax-content  h3 > span {
		margin:0 10px;
	}

	.parallax-content  h4 {
		font-size: 11px;
	}
	.parallax-content  h4 > span {
		display: block;
	}

	.parallax-content.smaller h4 {
		font-size: 10px;
	}

	.parallax-content .btn {
		font-size: 10px;
		padding:5px 12px;
		margin:8px 0 0 0;
	}

	.parallax-content .btn,
	.parallax-content .btn.pull-right {
		float:none !important;
	}

	.parallax-content.smaller .btn {
		padding:4px 19px;
		margin:0;
	}
}

/* -------------  6.13 - index15.html  ----------
-----------------------------------------------*/

.grid-banners.smaller .row {
	margin:0 -4px;
}

.grid-banners.smaller [class^="col-"]{
	padding:0 4px;
}

.skin15 .product:hover .product-image .product-btn.btn-add-cart,
.skin15 .product .product-image .product-btn.btn-add-cart {
	border-color:#08c;
	color:#08c;
	border-radius:5px;
}

.skin15 .product .product-image .product-btn.btn-add-cart:hover {
	background-color: #08c;
	color:#fff;
}

.skin15 .product h3 > a:hover,
.skin15 .nav-tabs.nav-links > li.active > a,
.skin15 .nav-tabs.nav-links > li.active > a:hover,
.skin15 .nav-tabs.nav-links > li.active > a:focus,
.skin15 .post-item h4 a, .post-item h4 a:hover,
.skin15 .post-item a,
.skin15 .post-item a:hover,
.skin15 .post-item .post-date .day,
.skin15 .product-categories li a:hover {
	color:#08c;
}

.skin15 .owl-carousel .owl-dot {
	width:8px;
	height:8px;
	opacity:1;
	background-color: #a0a09f;
	margin:5px 7px;
}

.skin15 .owl-carousel .owl-dot:hover,
.skin15 .owl-carousel .owl-dot.active,
.skin15 .top-container .owl-carousel .owl-dot:hover,
.skin15 .top-container .owl-carousel .owl-dot.active {
	background-color: #08c;
}

.skin15 .product .product-image .product-btn.btn-quickview {
	background: #08c;
	border-color:#08c;
}

.skin15 .owl-prev,
.skin15 .owl-next {
	color:#08c;
	opacity:1;
}

.skin15 .owl-prev:hover,
.skin15 .owl-next:hover {
	color:#08c;
	opacity:0.9;
}

.skin15 .post-item .post-date .month,
.skin15 .product .product-image .product-btn.btn-quickview {
	background: #08c;
	border-color:#08c;
}

.skin15 .product:hover .product-image .product-btn.btn-add-cart,
.skin15 .product .product-image .product-btn.btn-add-cart {
	background-color: #fff;
	color:#08c;
	border-color:#08c;
}

.v2 .sidebar .widget .product_list_widget {
	font-size: 12px;
	margin-bottom: 6px;
}

.widget:first-child .icon-group:first-child {
	margin-top:10px;
}

.widget .icon-group {
	margin-bottom: 20px;
	min-height: 50px;
	display: block;
}
.widget .icon-group + .icon-group {
	margin-left:0;
}

.widget .icon-group i {
	width:50px;
	height:50px;
	font-size: 32px;
	line-height: 48px;
	border:1px solid #bbb;
	color:#000;
	background-color: transparent;
	margin-left:-5px;
}

.widget .icon-group h3 {
	color:#313131;
	font-size:16px;
	margin:0;
	line-height:1.5;
	margin-left:50px;
	padding-top:4px;
}

.widget .icon-group p {
	font-size:12px;
	margin:0;
	margin-left:50px;
	line-height:1;
}

@media (min-width: 1200px) {
	.grid-banners.smaller {
		margin-top:0;
	}
	.tabs-container.grid .product {
		margin-bottom: 20px;
	}
}

/* -------------  6.14 - index16.html  -----------
-----------------------------------------------*/
.banner-group .row {
	margin:0 -8px;
}

.banner-group [class^="col-"]{
	padding:0 8px;
}

.banner-group .banner {
	margin-bottom: 16px;
	position: relative;
}

.banner-content {
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: 100;
	padding:0 25px;
	font-family: Georgia, sans-serif;
	font-size: 15px;
	line-height: 1.5;
	background-color: #b7a788;
	color:#564a34;
	text-align: left;
}

.banner-content.light {
	background-color: #2b262f;
	color:#ada4b5;
}

.banner-content h3 {
	font-size: 24px;
	color:#2b262e;
	line-height: 1;
	margin:0 0 5px;
	font-family: Georgia, sans-serif;
}

.banner-content.light h3 {
	color:#f2f4f3;
}

.banner-content hr {
	display: inline-block;
	background-image: none;
	border-top:1px solid #564a34;
	margin:15px 0;
	width:90px;
}

.banner-content.light hr {
	border-top-color:#ada4b5;
}

.banner-content p {
	line-height: 1.5;
	margin-bottom: 15px;
}

.banner-content a {
	color:#2b262f;
	text-transform: uppercase;
}

.banner-content a:after {
	display: none;
}

.banner-content a:hover,
.banner-content a:focus {
	color:#2b262f;
	text-decoration: underline;
}

.banner-content.light a,
.banner-content.light a:hover,
.banner-content.light a:focus {
	color:#fff;
}

@media (min-width: 768px)  and (max-width: 991px) {

	.banner-content {
		font-size: 10px;
		line-height: 1.5;
		padding:0 16px;
	}

	.banner-content h3 {
		font-size: 16px;
	}

	.banner-content hr  {
		width:60px;
	}

}

@media (max-width: 767px) {
	.blank-img {
		width:100%;
		max-height: 480px;
	}
}


/* -------------  6.15 - index17.html  -----------
-----------------------------------------------*/

.banner-side-group .row {
	margin-left:-15px;
	margin-right:-15px;
}

.banner-side-group [class^="col-"] {
	padding:0 15px;
}

.banner-side-group .banner {
	position: relative;
	margin-bottom: 20px;
}

.banner .category-title {
	display:block;
	position:absolute;
	left:-10px;
	bottom:20px;
	color:#fff;
	padding:15px 17px;
	font-size:15px;
	font-weight:600;
	line-height:1;
	min-width:174px;
	text-align:left;
	background-color:#3f4658;
}

.banner .category-title:after {
	content:"";
	width:10px;
	height:10px;
	border:5px solid transparent;
	border-right-color:#3f4658;
	border-top-color:#3f4658;
	display:block;
	position:absolute;
	left:0;
	top:100%;
}

.skin17 .product-image {
	padding:0;
	border:none;
	border-radius: 0;
}

	.skin17 .popup-gallery .product-image {
		padding: 0.2381em;
	}

.skin17 .product h3 a:hover {
	color:#3f4658;
}

.skin17 .product .product-image .product-btn.btn-quickview {
	background-color: #3f4658;
	border-color: #3f4658;
}

.skin17 .slider-title,
.skin17 .sidebar .widget .widget-title,
.skin17 #content .widget .widget-title {
	color:#3f4658;
	font-weight: 600;
	font-size: 19px;
	line-height: 26px;
	letter-spacing: 0;
	text-align: center;
	margin: 0 0 1.25em;
}

.skin17 .owl-carousel .post-item .post-excerpt {
	margin-right:0;
	text-indent: 0;
	max-width: none;
}

.skin17 .post-item h4  {
	font-size: 20px;
}

.skin17 .thumbnail {
	padding:2px;
	border-radius: 5px;
}

.skin17 .thumbnail a > img {
	border-radius: 4px;
}

.skin17 .thumb-info:hover img {
	transform:scale(1, 1);
	-webkit-transform:scale(1, 1);
	-ms-transform:scale(1, 1);
}

.skin17 .post-item a,
.skin17 .post-item h4 a,
.skin17 .post-item h4 a:hover,
.skin17 .post-item .post-date .day {
	color:#3f4658;
}

.skin17 .post-item h4 a:hover {
	text-decoration: none;
}

.skin17 .post-item .post-date {
	width:45px;
}

.skin17 .post-item .post-date .month {
	line-height: 18px;
	background-color: #3f4658;
}

.skin17 .owl-carousel .post-item  h4 {
	min-height: 58px;
	margin-bottom: 15px;
}

.skin17 .post-item .post-date {
	margin-top:0;
}

/* =============================================
-----------------  7.Category -------------
==============================================*/

.shop-loop-before,
.shop-loop-after {
	font-size:13px;
}

.shop-loop-before .product-ordering,
.shop-loop-before .pagination-container > *,
.shop-loop-before .gridlist-toggle {
	margin-bottom: 17px;
}

.shop-loop-before .product-ordering {
	float:left;
	margin-right: 20px;
}

.product-ordering {
	font-size:0.9286em;
}

.shop-loop-before label,
.shop-loop-after label {
    margin-bottom: 0;
    vertical-align: middle;
}

.product-ordering select,
.product-viewing select {
	margin-left: 7px;
	padding: 0 0 0 0.22em;
	height: 2.2em;
    box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset;
    font-size:1em;
    line-height: 1.42857;
    vertical-align: middle;
    border:1px solid #ccc;
    border-radius: 3px;
}

.product-viewing,
.product-viewing select {
    display: inline-block;
    vertical-align: top;
}

.gridlist-toggle {
	float:left;
	vertical-align: top;
	font-size:0.9286em;
}

.gridlist-toggle > a {
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #ccc;
    display: inline-block;
    font-family: "Elusive-Icons";
    width: 2.2em;
    height: 2.2em;
    line-height: 2.15em;
    margin-right: 4px;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

.gridlist-toggle > a:before {
    font-size: 1.231em;
}

.gridlist-toggle > a:hover,
.gridlist-toggle > a:focus,
.gridlist-toggle > a.active {
    color: #fff;
}
.gridlist-toggle #grid:before {
    content: "\f20b";
}
.gridlist-toggle #list:before {
    content: "\f20a";
}

.gridlist-toggle > a:hover,
.gridlist-toggle > a:focus,
.gridlist-toggle > a.active {
  background-color: #9ac93d;
  border-color: #9ac93d;
}

form label {
	display: inline-block;
	font-weight: normal;
}

.shop-loop-before .pagination-container {
	margin-bottom: -10px;
	font-size:0.9286em;
	text-align: right;
	float:right;
	white-space: nowrap;
}

.shop-loop-after .pagination-container {
	float:none;
	text-align: center;
}

.pagination-container {
	position: relative;
}

.pagination {
	margin: 0 ;
	border-radius: 0;
    display: inline-block;
    white-space: nowrap;
    padding: 0;
    margin: 0 0 15px 10px;
    clear: both;
}

.pagination > li {
    float: left;
    margin-left: 5px;
    display: inline;
    padding: 0;
}

.pagination > li > a,
.pagination > li > span {
    border: 1px solid #ccc;
    border-radius: 3px;
    display: block;
    margin: 0 0 5px;
    padding: 0 0.5em;
    line-height: 24px;
    min-width: 26px;
    height: 26px;
    text-decoration: none;
    text-align: center;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
	border-radius:3px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius:3px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #fff;
	background-color: #9ac93d;
	border-color: #9ac93d;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	color: #fff;
	background-color: #9ac93d;
	border-color: #9ac93d;
}

@media (max-width: 1199px) {
	.shop-loop-before .pagination-container {
		float:none;
		clear:both;
		margin-top: 15px;
		text-align: center;
	}

	.shop-loop-before .product-viewing {
		position: absolute;
        top: -2.2em;
        right: 0;
        margin-top: -15px;
	}
}

@media (max-width: 767px) {
	.shop-loop-before .product-ordering {
		float:none;
		text-align: right;
		margin-right:0;
	}
}

/* Category Sidebar */

.widget.widget-shop {
	border: 1px solid #ddd;
	border-radius: 7px;
	background: #fbfbfb;
	overflow: hidden;
	margin-bottom: 1.0714em;
	margin-top: 0;
}

.sidebar .widget .widget-title.boxed-title,
#content .widget .widget-title.boxed-title {
    color: #777;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 0.9286em;
    font-weight: 700;
    line-height: 1.4;
    padding: 0.7143em 1.0714em;
    margin: 0;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

.widget-title .toggle {
	color: #ccc;
    display: inline-block;
    width: 1.8571em;
    height: 1.8571em;
    line-height: 1.7572em;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: absolute;
    right: 1.0714em;
    top: 1px;
    bottom: 0;
    margin:auto;
    font-family: "FontAwesome";
    text-align: center;
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

.widget-title .toggle:hover {
	color: #fff;
	background-color: #9ac93d;
	border-color:#9ac93d;
}


.widget-title .toggle:before {
    content: "\f0d8";
}

.widget-title .toggle.collapsed:before {
    content: "\f0d7";
}

.filter-color-container,
.filter-size-container  {
	padding:11px;
	font-size: 0.9286em;
	margin:0;
}

.filter-color-container li,
.filter-size-container li {
	float:left;
	width: 14.2857%;
	min-width: 34px;
	max-width: 35px;
	text-align: center;
	line-height: 10px;
}

.filter-color-box,
.filter-size-box {
	display: inline-block;
    border-width: 0;
    width: 26px;
    height: 26px;
    margin: 4px;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, .35);
    border-radius: 5px;
}

.filter-size-box {
	border-radius: 5px;
    min-width: 2em;
    text-align: center;
    margin: 0.3846em;
    color: #777;
    border: 1px solid #ccc;
    background: #efefef;
    padding: 0.3846em;
    line-height: 1.2;
    text-transform: uppercase;
}


.filter-size-box.active,
.filter-size-box:hover,
.filter-size-box:focus {
	color:#fff;
	border-color:#9ac93d;
	background-color: #9ac93d;
	cursor: pointer;
}

.filter-color-box.active {
	position: relative;
	font: 13px/37px FontAwesome;
	text-align: center;
}

.filter-color-box.active:before {
	content:"\f00c";
	color:#fff;
	position: absolute;
	margin-left:-6px;
}

.filter-color-box:hover {
	border:1px solid #444;
}


.sidebar .filter-group-widget .panel-body {
	padding:35px 20px 25px;
}

/* noUiSlider Styles */
noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;

}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}

.noUi-handle {
	position: relative;
	z-index: 1;
}

.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}

.noUi-stacking + .noUi-origin {
/* Fix stacking order in IE7, which incorrectly
   creates a new context for the origins. */
	*z-index: -1;
}

.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
	cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 0.5em;
    margin-top: 1.4286em;
    margin-bottom: 2.1428em;
}

.noUi-horizontal .noUi-handle {
	position: absolute;
	left: 0;
	top: -3px;
	width: 0.9286em;
    height: 1.2857em;
    cursor: pointer;
    outline: none;
    top: -0.40715em;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
}

.noUi-horizontal .noUi-handle.noUi-handle-upper {
	margin-left:-13px;
}

.noUi-horizontal.noUi-extended {
	padding: 0 15px;
}

.noUi-horizontal.noUi-extended .noUi-origin  {
	right: -15px;
}

/* Styling */
.noUi-background {
	background: #eee;
}

.noUi-connect {
	background: #9ac93d;
	-webkit-transition: background 450ms;
	transition: background 450ms;
}

.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;*/
.noUi-dragable {
	cursor: w-resize;
}

.noUi-handle {
	background: #9ac93d;
	cursor: default;
}

/* Blocked state */
.noUi-state-blocked.noUi-connect,
.noUi-state-blocked .noUi-connect {
	background: #4FDACF;
}

/* fix for display:inline-block auto margin for child elements*/
.filter-slider {
	padding:0.7143em 1.0714em;
}

.filter-slider-action {
	margin-bottom: 4px;
}

.filter-slider-action .filter-slider-value {
	float:right;
    text-align: right;
    line-height: 2.4em;
    font-size: 0.8751em;
}

.filter-slider-value > span {
	font-weight: 600;
}

.filter-slider-action  .btn {
    padding: 0.2143em 0.7143em;
    text-transform: uppercase;
    float: left;
	border-color: #9ac93d;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
	background-color: #9ac93d;
}

.filter-slider-action .btn:hover {
	border-color: #0099e6;
	background-color: #0099e6;
}

.product-categories {
	font-size: 0.9286em;
	padding:0.7143em 1.0714em;
	margin:0;
}

.product-categories li  {
	position: relative;
	margin: 0;
	padding: 0;
	line-height: 24px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.product-categories li a {
	display: inline-block;
	padding:4px 0;
	color: #777;
}

.product-categories li a:hover {
	color:#0099e6;
}

.products .product {
	margin-bottom: 30px;
}


/* =============================================
-------------------  17. Products --------------
==============================================*/

.product {
	position: relative;
	overflow: hidden;
	/*text-align: center; */
}

.product-image {
    display: block;
    position: relative;
    background: #fff;
    border: 0px solid #ddd;
    border-radius: 7px;
    width: 100%;
    padding: 0.2381em;
    overflow: hidden;
    margin-bottom: 1em;
}

.product-image a {
	display: block;
}

.product-image img {
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    height: auto;
    -webkit-transition: opacity 0.3s ease-in-out 0s;
    -moz-transition: opacity 0.3s ease-in-out 0s;
    transition: opacity 0.3s ease-in-out 0s;
    position: relative;
	opacity:1;
}

.product-image .inner-image {
	position: relative;
}

.product-image .inner-image {
	overflow: hidden;

}

.product-image .inner-image .zoom-image-effect {
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
}

.product-image:hover .inner-image .zoom-image-effect {
	transform: scale(1.07);
	-moz-transform: scale(1.07);
	-webkit-transform: scale(1.07);
}

.product .product-image .product-image-hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

.product-image:hover .product-image-normal {
	opacity:0;
}

.product-image:hover .product-image-hover {
	opacity:1;
}

.product-image .onhot,
.product-image .onsale,
.product-info .onsale,
.product-info .onsale1
 {
    line-height: 1;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    top: 0.8em;
    border-radius: 4px;
    font-size: 0.8571em;
    padding: 0.5833em 0.6333em;
}

.product-image .onhot {
    background: #62b959;
    left: 0.8em;
}

.product-image .onsale,
.product-info .onsale {
    background: #e27c7c;
    right: 0.8em;
}

.product-image .viewcart {
    font-size: 1.8em;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    text-align: center;
    width: 2.2em;
    height: 2.2em;
    line-height: 2.1em;
    display: none;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

.product-image .viewcart:before {
    font-family: "minicart-font" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e800";
    font-size: 0.9em;
    vertical-align: top;
}

.product-image .viewcart:hover {
    color: #fff;
}

.product h3 {
	color: #777;
	font-size: 1em;
	line-height: 1.35;
	margin-bottom: 0.7143em;
	min-height: 5rem;
    max-height: 5.5rem;
    overflow: hidden;
}

  @media (max-width: 768px) {
        .product h3 {
        	font-size: 1em;
        	line-height: 1.1;
        	margin-bottom: 0.7143em;
        	min-height: 5rem;
            max-height: 5rem;
            overflow: hidden;
        }


  }

.product h3 a {
	font-size: 1em;
}

.product h3 a:hover {
    color: #9ac93d;
}

.rating-wrap {
	position: relative;
	display: table;
	font-size: 0.8571em;
	margin: 0 auto 1.2em;
	height: 1px;
}

.rating-wrap .rating-before,
.rating-wrap .rating-after {
	display: table-cell;
	width: 50%;
	height: 1px;
	vertical-align: middle;
}

.rating-wrap .rating-content {
    display: table-cell;
}

.rating-wrap .star-rating {
    width: 6.7em;
    margin-right: -2px;
}

.rating-wrap .rating-line {
    display: block;
    height: 1px;
    width: 100%;
    margin-top: 1px;
}

.rating-wrap .rating-before .rating-line {
    background-image: - webkit-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 40%, transparent);
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 40%, transparent);
}

.rating-wrap .rating-after .rating-line {
    background-image: - webkit-linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 40%, transparent);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 40%, transparent);
}

.star-rating {
	position: relative;
	height: 1em;
	width: 5.4em;
	overflow: hidden;
	font-family: 'Elusive-Icons';
    font-size: 1em;
    line-height: 1em;
	letter-spacing: 0.1em;
	text-align: center;
	cursor: pointer;
}

.star-rating:before {
    color: #ddd;
    color: rgba(0, 0, 0, 0.13);
    content: "\f1fe" "\f1fe" "\f1fe" "\f1fe" "\f1fe";
    float: left;
    position: absolute;
    top: 0;
	left: 0.6em;
}

.star-rating span {
    float: left;
    left: 0;
    overflow: hidden;
    padding-top: 1.5em;
    position: absolute;
    top: 0;
    width:100%;
}

.star-rating span:before {
    color: #ffc600;
    content: "\f1fe" "\f1fe" "\f1fe" "\f1fe" "\f1fe";
    left: 0.6em;
    position: absolute;
    top: 0;
}

.price {
	display: block;
    font-size: 1.4286em;
    margin: -0.25em 0 0.75em;
    color: #444;
    line-height: 1;
    font-weight: 600;
}

.price del {
	display: inline-block;
	color: #a7a7a7;
	font-size: 0.8em;
	margin-right: 0.2143em;
	vertical-align: baseline;
}

.price .amount {
	vertical-align: middle;
}

.price ins {
	display: inline-block;
	text-decoration: none;
	vertical-align: baseline;
}

.product-image .product-action {
	margin:0;
}

.product-action {
	margin-bottom: 5px;
	white-space: nowrap;
}

.product-action .product-btn {
	display: inline-block;
	background: transparent;
	border: 1px solid #333;
	padding: 0 0.7143em;
	font-size:1em;
	line-height: 2.0571em;
	z-index: 1;
	color: #333;
	white-space: nowrap;
	text-shadow: none;
}

.product-btn.btn-add-cart {
	position: relative;
	z-index: 2;
}

.product-btn.btn-add-cart.small i {
	margin-right: 0;
}

.product-btn.btn-add-cart.small > span {
	display: none;
}

.product-btn.btn-add-cart i {
	margin-right:0.5714em;
}

.product-btn.btn-quickview,
.product-btn.btn-wishlist {
	-webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
    position: relative;
    z-index: 1;
}

.six-products .product-btn.btn-wishlist {
	display: none !important;
}

.product-btn.btn-quickview {
	position: relative;
    width: 2.2em;
    padding: 0;
    border: 1px solid #333;
    background: transparent;
    color: #333;
    opacity:0;
    transform:translateX(-60px);
    -webkit-transform:translateX(-60px);
    -ms-transform:translateX(-60px);
    margin-left: 3px;
}

.six-products .product-btn.btn-quickview {
	display: none !important;
}

.product-btn.btn-wishlist {
	width: 2.2em;
    padding: 0;
    border: 1px solid #333;
    color: #333;
    opacity:0;
    margin-right: 3px;
    transform:translateX(60px);
    -webkit-transform:translateX(60px);
    -ms-transform:translateX(60px);
}

@media (max-width: 480px) {
	.product-btn.btn-quickview,
	.product-btn.btn-wishlist {
		display: none;
	}
}

.product-btn.btn-quickview:hover,
.product-btn.btn-quickview:focus {
    background-color: #52b9b5;
    color:#fff;
}

.product-btn.btn-wishlist:hover,
.product-btn.btn-wishlist:focus {
    background-color: #ed4949;
    color:#fff;
}

.product:hover .product-btn.btn-add-cart {
	background-color: #9ac93d;
	border-color: #9ac93d;
	color:#fff;
}

.product:hover .product-btn.btn-wishlist,
.product:hover .product-btn.btn-quickview {
    opacity:1;
    transform:translateX(0);
    -webkit-transform:translateX(0);
    -ms-transform:translateX(0);
}


/* Product Singe (product.html) ---------
---------------------------------------*/

.widget-block2.widget  {
	margin-top:15px;
}

.widget-block2 hr:last-child{
	margin-bottom: 20px;
}

.product-gallery-container {
	margin-bottom: 40px;
}


@media (min-width: 992px) {
	.product-gallery-container {
		margin-bottom: 65px;
	}
}

.product-gallery-container .product-top {
	border: 1px solid #ddd;
	border-radius: 7px;
	padding: 3px;
	margin:0 0 8px 0;
}

.pgallery-wrapper {
	border: 1px solid #ddd;
	border-radius: 7px;
	padding: 4px;
}

.product-gallery-container img {
	display:block;
	width:100%;
	height:auto;
}

.product-details {
	padding-right: 4px;
}

.product-details .product-title {
	color: #555;
	font-size: 2em;
	font-weight: 600;
	margin-bottom: 0.3571em;
}

.product-rating-container {
    color: #bdbdbd;
    margin-bottom: 1.4286em;
}

.product-rating-container .star-rating {
	font-size:1.2857em;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.7143em;
    text-align: left;
}

.product-rating-container .star-rating:before,
.product-rating-container .star-rating span:before {
	left:0;
}

.product-rating-container  .review-link {
	display: inline-block;
}

.review-link a {
    color: #bdbdbd;
    display: inline-block;
    vertical-align: bottom;
    padding: 0 0.7143em;
}

.review-link a:first-child {
	padding-left: 0;
}

.review-link a:hover, .review-link a:active {
    color: #888;
}

.product-details .price {
	margin-bottom: 0.5556em;
	color: #9ac93d;
    line-height: 1;
    font-weight: 600;
    font-size: 2.5714em;
}

.product-details .description {
    margin-bottom: 1.7857em;
    font-size: 1.1429em;
}

.product-details .cart {
	   border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 1.7857em 0;
    margin-bottom: 1.7857em;
}

.product-details .single_add_to_cart_button {
    height: 2.7143em;
    padding: 0 2.1429em;
    text-shadow: none;
    background-color: #9ac93d;
    border-color:#9ac93d;
    color:#fff;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
}

.product-details .single_add_to_cart_button:hover {
	border-color: #0099e6;
	background-color: #0099e6;
}

@media (max-width: 991px) {
    .product-details .single_add_to_cart_button {
        padding: 0 1.4286em;
    }
}

@media (max-width: 480px) {
    .product-details .single_add_to_cart_button {
        padding: 0 0.7143em;
    }
}


.product-details .single_add_to_cart_button:before {
    display: inline-block;
    content: "\f07a";
    font-family: "FontAwesome";
    margin-right: 0.7143em;
}
.product-details a.single_add_to_cart_button {
    line-height: 2.5714em;
}
.product-details .stock {
    font-size: 1.1429em;
    font-weight: 600;
}

.product-center .single-add-to-wishlist a {
    width: auto;
    text-indent: 0;
    overflow: visible;
    padding-left: 2.8em;
    position: relative;
    color:inherit;
    display: inline-block;
    cursor: pointer;
    font-size: inherit;
    height: 2.2em;
    line-height: 2.2em;
    text-decoration: none;
    text-align: center;
    vertical-align: top;
    border-radius: 5px !important;
    text-shadow: none;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}
.product-center .single-add-to-wishlist a:before {
	font-family: "FontAwesome";
	content:"\f08a";
    position: absolute;
    left: 0;
    right: 0;
    text-indent: 0;
    font-size: 1.1em;
    border: 1px solid #ed4949;
    color: #ed4949;
    border-radius: 5px;
    width: 2em;
    top: 0;
    bottom: 0;
}

.product-center .single-add-to-wishlist a:hover {
    color: #ed4949;
}

.product-center .single-add-to-wishlist a:hover:before {
    color: #fff;
    background-color: #ed4949;
}


.product-center .single-add-to-compare a {
    width: auto;
    text-indent: 0;
    overflow: visible;
    padding-left: 2.8em;
    position: relative;
    color:inherit;
    display: inline-block;
    cursor: pointer;
    font-size: inherit;
    height: 2.2em;
    line-height: 2.2em;
    text-decoration: none;
    text-align: center;
    vertical-align: top;
    border-radius: 5px !important;
    text-shadow: none;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}
.product-center .single-add-to-compare a:before {
	font-family: "FontAwesome";
	content: "\f0ec";
    position: absolute;
    left: 0;
    right: 0;
    text-indent: 0;
    font-size: 1.1em;
    border: 1px solid #52b9b5;
    color: #52b9b5;
    border-radius: 5px;
    width: 2em;
    top: 0;
    bottom: 0;
}

.product-center .single-add-to-compare a:hover {
    color: #52b9b5;
}

.product-center .single-add-to-compare a:hover:before {
    color: #fff;
    background-color: #52b9b5;
}

.product-details .quantity {
	display: inline-block;
    position: relative;
    width: 4.1429em;
    margin-right: 0.8571em;
    vertical-align: top;
}

/*
 *  Bootstrap TouchSpin - v3.0.1
 *  A mobile and touch friendly input spinner component for Bootstrap 3.
 *  http://www.virtuosoft.eu/code/bootstrap-touchspin/
 *
 *  Made by István Ujj-Mészáros
 *  Under Apache License v2.0 License
 */

.bootstrap-touchspin .form-control.vertical-spinner {
	border-radius: 3px !important;
	-moz-appearance: textfield;
	text-align: center;
	width: 2.5em;
	height: 2.7143em;
	padding-left: 0;
	padding-right: 0;
	display: inline-block !important;
	vertical-align: middle;
}

.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 15px;
	vertical-align: middle;
	display: inline-block;
	margin-left:5px;
	vertical-align: middle;
	margin-top:-29px;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding:0;
	width:15px;
	height:1.1428em;
	font-size:14px;
	line-height: 1;
	position: relative;
	border-radius: 3px;
	margin-bottom:5px;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn:last-child {
	margin-bottom: 0;
}

.customup:before,
.customdown:before {
	position: absolute;
	left:50%;
	top:50%;
	margin-top:-5px;
	font-size:14px;
	line-height: 1;
	z-index: 99;
}


.customup:before {
	content:'+';
	margin-left: 1px;
}

.customdown:before {
	content:'-';
	margin-left: 2px;
	margin-top:-6px;

}

.bootstrap-touchspin .btn.btn-default {
	border-color:#ccc;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn.btn-default:hover,
.bootstrap-touchspin .input-group-btn-vertical > .btn.btn-default:focus {
	border-color:#dadada;
}

.bootstrap-touchspin-up.btn,
.bootstrap-touchspin-down.btn {
	padding:9px 12px;
	border-radius:0 4px 4px 0;
	text-align: center;
}

.bootstrap-touchspin-down.btn {
	margin-right: -1px !important ;
	border-radius: 4px 0 0 4px;
}


.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 5px;
	left: 50%;
	margin-left:-4.5px;
	font-size: 9px;
	font-weight: normal;
}

.product-details .product_meta {
    margin: 1.2143em 0 0;
}
.product-details .product_meta span span,
.product-details .product_meta span a {
    display: inline-block;
}

.product-details .product_meta span a {
    color:#9ac93d;
}

.product-details .product_meta span a:hover {
	text-decoration: underline;
}

.product-details .share-links {
    margin: 2.2143em 0 0;
}

.product-details .posted_in,
.product-details .sku_wrapper,
.product-details .tags {
    display: block;
    margin-bottom: 0.5em;
}

.product-details .social-icons {
	font-size:14px;
	margin:2.2143em 0 1.0714em;
}

.product-details .social-icons .social-icon {
	margin:0 3.5px 0 0;
	width:2.2857em;
	height:2.2857em;
	line-height: 2.2857em;
	border-radius: 4px;
}

/* sidebar */
.sidebar .widget-banner {
	margin-bottom:15px;
}

.widget-banner {
	text-align: center;
	background-color: #fafafa;
	padding:7px 25px 14px;
}

.widget-banner img {
	display:inline-block;
	max-width: 100%;
	height: auto;
}

.widget-banner hr {
	margin-top:0;
	margin-bottom: 20px;
	border-color:#e6e6e6;
}

.widget-banner h5 {
	font-size:12px;
}

.widget-banner h5 a:hover {
	color:#262626;
}

.widget-products {
	padding:32px 28px;
	border:1px solid #f0f0f0;
}

.sidebar .widget-products .widget-title {
	border-bottom:none;
	padding-bottom:0;
	margin-bottom: 17px;
}


.sidebar .widget-products .product-list .product-title {
	font-size:14px;
	line-height: 18px;
	font-weight: 500;
	margin-top:-3px;
}

.sidebar .widget-products .product-list .product-price,
.sidebar .widget-products .product-list .product-old-price {
	font-size:13px;
}

.sidebar .widget-products.widget .product-list li {
	padding-bottom:0;
	border-bottom:none;
}

.product-details-tab {
	margin-bottom: 35px;
}

@media (min-width: 768px) {
	.product-details-tab {
		margin-bottom: 50px;
	}
}

.product-details-tab h5 {
	margin-bottom:19px;
}

.product-details-tab p,
.panel-group p {
	line-height: 26px;
	margin-bottom: 18px
}

.product-details-tab ul li,
.panel-group ul li {
	line-height: 30px;
}

.product-details-tab ul.fa-ul,
.panel-group ul.fa-ul {
	margin-left:25px;
}

.product-details-tab ul.fa-ul li,
.panel-group ul.fa-ul li {
	margin-bottom: 0;
}

.product-details-tab ul.fa-ul .fa-li,
.panel-group ul.fa-ul .fa-li {
	top:9px;
	left:-22px;
	font-size:12px;
	color:#a8a8a8;
}



/* =============================================
-------------------- 19. Footer -----------------
==============================================*/

#footer {

    font-size: 0.9em;
    padding-bottom: 100px;
    position: relative;
    color:#fff;
      background: url(../../../../../image/catalog/magic/bg.jpg) bottom  repeat-x #9ac93d;

}

#wrapper.boxed.boxed-short #footer {
	margin-top:15px;
}

#footer a,
#footer a:hover {
	color:#fff;
}

#footer a:hover {
    text-decoration: underline;
}

#footer .footer-ribbon {
    background: #9ac93d;
    position: absolute;
    margin: -70px 0 0 0;
    padding: 10px 20px 6px 20px;
    color: #fff;
    font:400 1.6em/ 22px "Shadows Into Light", Verdana;
}

#footer .footer-ribbon a:hover {
	text-decoration: none !important
}

.v2 #footer .footer-ribbon {
	margin-top:-56px;
}

#footer .footer-ribbon:before {
    border-right: 10px solid #005580;
    left: -10px;
    border-top: 16px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 7px;
}

#footer > .container {
    padding-top: 40px;
    padding-bottom: 10px;
}

#footer.footer-ribbon > .container {
	padding-top:54px;
}

.v2 #footer.footer-ribbon > .container {
	padding-top:40px;
}
/*
.widget {
	margin-top:30px;
	margin-bottom: 30px;
} */

.widget .widget-title {
	line-height: 1.4;
	font-weight: 400;
	font-size:1.2698em;
	margin-bottom: 15px;
	text-transform: uppercase;
}

#footer .widget-title {
	color:#fff;
   text-transform: uppercase;
}

#footer .widget > div > ul,
#footer .widget > ul {
	font-size:1em;
}

#footer .widget .contact-info,
#footer .widget .contact-details {
    margin-top: -6px;
}

.widget .tagcloud {
	margin:-0.3846em 0 -0.3846em;
}

.widget .tagcloud a {
    margin: 0.3846em 0.3846em 0.3846em 0;
    color: #777;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #efefef;
    padding: 0.3846em;
    line-height: 1.2;
    display: inline-block;
    text-decoration: none;
    font-size: 0.8571em !important;
}

#footer .widget .tagcloud a {
    text-decoration: none;
    border-color:rgba(119,119,119,0.3);
    background-color: transparent;
}

.widget .tagcloud a:hover,
#footer .widget .tagcloud a:hover {
	color: #fff;
	border-color:#9ac93d;
	background-color:#9ac93d;
}

.widget.contact-info ul,
.widget.contact-info li {
    margin: 0;
    padding: 0;
}

.widget.contact-info p {
    margin-bottom: 10px;
}

.widget.contact-info li i {
    text-align: center;
    width: 16px;
    top: -2px;
    margin: 7px 6px 0 0;
    font-size: 1.2em;
}

.widget.contact-info .contact-details span {
    display: block;
    padding-left: 25px;
    line-height: 18px;
    padding-bottom: 10px;
}

.v2 .widget.contact-info .contact-details span {
	padding-bottom: 5px;
}

.post-item-small {
    margin: 0 10px;
    padding: 15px 0;
    *zoom: 1;
    border-top: 1px solid #ececec;
}

.post-item-small:before,
.post-item-small:after {
    content: " ";
    display: table;
    clear: both;
}

.post-item-small:first-child {
    padding-top: 0;
    border-top-width: 0;
}

.post-item-small .post-image {
    float: left;
    margin: 0 10px 0 0;
}

.post-item-small .post-image img {
    width: 50px;
    height: auto;
}

.post-item-small .post-date {
    font-size: 0.9286em;
}

#footer .post-item-small {
	border-color:rgba(119,119,119,0.3);
}

#footer .post-item-small .post-date {
	display:block;
	color:#888;
}

#footer .thumbnail,
#footer .img-thumbnail {
	padding:0;
	border:none;
	background-color: transparent;
}

#footer .thumbnail img,
#footer .img-thumbnail img {
	border-radius: 3px;
}

#footer .widget p {
	margin-top:-3px;
	margin-bottom: 13px;
}

.newsletter-widget input[type="text"],
.newsletter-widget input[type="email"] {
	display: block;
	color:#777;
	background-color: #fff;
	box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset;
	line-height: 1.42857;
	padding:6px 8px;
	font-size:1em;
	border:1px solid #ccc;
	border-radius:3px;
	width: 100%;
	max-width: 262px;
}

#footer .container input:focus {
	box-shadow: none;
}

.newsletter-widget input::-moz-placeholder {
	color: #777;
}

.newsletter-widget input:-ms-input-placeholder {
	color: #777;
}

.newsletter-widget input::-webkit-input-placeholder {
	color: #777;
}

.newsletter-widget input[type="submit"] {
	display:block;
	margin-top:20px;
    background: #9ac93d;
    border: 1px solid #9ac93d;
    color: #fff;
    padding: 6px 11.5px;
    border-radius: 3px;
    line-height: 1.42857;
}

.newsletter-widget input[type="submit"]:hover,
.newsletter-widget input[type="submit"]:active {
	background: #0099e6;
	border-color: #0099e6;
}

#footer .input-box {
	margin-top:45px;
}

#footer .newsletter-widget .input-box input[type="text"],
.newsletter-widget .input-box  input[type="email"] {
	background-color: #dcdcdc;
	height:30px;
	padding:5px 10px;
	border-radius: 5px;
}

#footer .input-box-wrapper {
	position: relative;
	max-width: 300px;
}

#footer .newsletter-widget .input-box-wrapper input[type="submit"] {
	position: absolute;
	right:0;
	top:0;
	padding:4.5px 13.5px;
	margin:0;
	border-radius:0 5px 5px 0;
	font-size:14px;
}


#footer .widget .label {
	display: block;
	font-size:13px;
	line-height: 19px;
	font-weight: 400;
	padding:0;
	text-align: left;
	margin:0 0 15px;
	color:#777;
}

#footer .widget .links {
	margin:-5px 0 0;
}

#footer .widget .links li {
	display: block;
	position: relative;
	padding:10px 0;
	line-height: 1;
}

#footer .widget .links li > i {
	margin-right:6px;
}

#footer #footer-bottom {
	padding:15px 0;
	background-color: #060606;
}

#footer #footer-bottom a,
#footer #footer-bottom a:hover {
	color:#777;
}

#footer #footer-bottom address {
	margin: 0px;
	text-align: center;
	font-size: 12px;
	line-height: 40px;
	border-top: 1px solid #0f0f0f;
	margin-top: -15px;
	padding-top: 15px;
}

#footer.fixed {
	position: fixed;
	left:0;
	right:0;
	bottom:0;
	z-index: 999;
	background-color: transparent;
}

#footer.fixed #footer-bottom {
	padding:0;
	background-color: rgba(255,255,255,0.85);
}

#footer-bottom > .container .footer-left {
	padding: 10px 8px 10px 0;
    text-align: left;
}

#footer-bottom > .container .footer-center {
	padding: 10px 8px;
}

#footer-bottom > .container .footer-right {
    padding: 10px 0 10px 8px;
    text-align: right;
}

#footer.fixed #footer-bottom > .container .footer-left,
#footer.fixed #footer-bottom > .container .footer-center,
#footer.fixed #footer-bottom > .container .footer-right {
    padding-top:8px;
    padding-bottom: 8px;
}

#footer-bottom .footer-menu {
	margin:0;
	display: inline-block;
}

#footer-bottom .footer-menu li {
	display: inline-block;
	padding:0 5px 0 0;
	line-height: 24px;
	letter-spacing: 0.4px;
}

#footer-bottom .footer-menu li:last-child {
	padding:0;
}

#footer-bottom .footer-menu li + li:before {
    content: "|";
    font-weight: 300;
    position: relative;
    top: -1px;
    margin: 0 9.5px 0 0;
}

.v2 #footer-bottom .logo {
	min-width: 0;
}


@media (max-width: 480px) {
	#footer.fixed #footer-bottom > .container .footer-left {
		text-align: center;
	}

	#footer.fixed #footer-bottom > .container .footer-right {
		display:none;
	}
}

@media (max-width: 991px) {
    #footer .logo {
        display: block;
        margin-bottom: 15px;
        margin-right: 0;
    }

    #footer-bottom > .container .footer-left,
	#footer-bottom > .container .footer-center,
	#footer-bottom > .container .footer-right  {
    	padding: 10px 0;

    }

    #footer.fixed #footer-bottom .social-icons {
    	margin: 5px 0;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
	.v2 #footer-bottom > .container .footer-left {
    	margin-right:60px;
    }

    .v2 #footer-bottom > .container .footer-left .logo {
		margin-right:45px;
    }

	.v2 #footer-bottom > .container .footer-left > *,
    .v2 #footer-bottom > .container .footer-left,
	.v2 #footer-bottom > .container .footer-center {
		float:left;
	}

	.v2 #footer-bottom > .container .footer-right {
    	float:right;
    }
}

@media (max-width: 767px) {

	.v2 #footer .logo  {
		margin-bottom: 5px;
	}

	.v2 #footer #footer-bottom img.img-responsive {
    	display: inline-block;
    }

	.v2 #footer-bottom > .container .footer-left {
		padding-bottom: 0;
	}

    .v2 #footer-bottom > .container .footer-left,
	.v2 #footer-bottom > .container .footer-center,
	.v2 #footer-bottom > .container .footer-right {
    	text-align: center !important;
    	float:none;
    }

    .v2 #footer-bottom > .container .footer-right {
    	padding-top:0;
    }
}

@media (min-width: 481px) and (max-width: 991px) {
	#footer.fixed #footer-bottom > .container {
		display: table;
		table-layout: fixed;
	}

	#footer.fixed #footer-bottom > .container .footer-left,
	#footer.fixed #footer-bottom > .container .footer-right {
		display: table-cell;
		vertical-align: middle;
		width:50%;
		float:none;
	}
}

@media (min-width: 992px) {

	#footer-bottom .logo,
	#footer-bottom .social-icons  {
	    display: inline-block;
	    margin-right: 25px;
	}

	.v2 #footer-bottom .logo {
		margin-right: 45px;
	}

	#footer.fixed #footer-bottom .social-icons  {
	    margin-right: 10px;
	}

	.skin7 #footer #footer-bottom .social-icons  {
	    margin-right: 0;
	}

	#footer-bottom > .container {
		display: table;
	}

	#footer-bottom > .container .footer-left,
	#footer-bottom > .container .footer-center,
	#footer-bottom > .container .footer-right {
		display: table-cell;
		vertical-align: middle;
	}

	#footer-bottom > .container .footer-left * {
		vertical-align: middle;
	}

	#footer-bottom > .container .footer-center {
	    text-align: center;
	    width:350px;
	}

	.v2 #footer-bottom > .container .footer-center {
		width:360px;
		padding-left:0;
		padding-right:0;
	}

	#footer-bottom .footer-menu {
		margin-left:-7px;
	}

}

/* Skin5 Footer */
.skin5 #footer,
.skin5 #footer #footer-bottom {
	background-color: #fff;
	color:#777;
}

.skin5 #footer a,
.skin5 #footer a:hover,
.skin5 #footer .widget-title {
	color:#9ac93d;
}

.skin5 #footer .newsletter-widget input[type="text"],
.skin5 #footer .newsletter-widget input[type="email"],
.skin5 #footer .widget .tagcloud a,
.skin5 #footer.newsletter-widget input[type="submit"] {
	border-radius: 0;
}

.skin5 #footer  .thumbnail img,
.skin5 #footer .img-thumbnail img {
	border-radius: 0;
}


/* Footer top */
#footer-top {
    background: #08c;
    border: 1px solid #08c;
    margin-left: -10px !important;
    margin-right: -10px !important;
    padding: 10px 0;
    border-radius: 0;
    color:#fff;
}

.info-boxes-fullwidth-container {
	padding: 11px 0;
    border-radius: 0;
    color:#fff;
    background-color: #171717;
    margin-bottom: 40px;
}

#footer-top .info-boxes-container,
.info-boxes-fullwidth-container .info-boxes-container {
	background-color: transparent;
	border:none;
	margin:0;
}

.info-boxes-fullwidth-container .info-box-content .info-box-title,
.info-boxes-fullwidth-container .info-box-icon,
#footer-top .info-box-content .info-box-title,
#footer-top .info-box-icon {
	color:#fff;
}

@media (min-width: 992px) {
	#footer-top .info-box-container {
		border-right: 1px solid #149ce0;
	}

	.info-boxes-fullwidth-container .info-box-container {
		border-right: 1px solid #353535;
	}

	.info-boxes-fullwidth-container .info-box-container:last-child,
	#footer-top .info-box-container:last-child {
		border-right: none;
	}
}

/* Skin6 Footer */
.skin6 #footer .container {
	width:100%;
}

.skin6 #footer {
	background-color: #f6f6f6;
}

.skin6 #footer #footer-bottom {
	background-color: #fff;
	color:#777;
}

.skin6 #footer a,
.skin6 #footer a:hover,
.skin6 #footer .widget-title {
	color:#9ac93d;
}

.skin6 #footer .newsletter-widget input[type="text"],
.skin6 #footer .newsletter-widget input[type="email"],
.skin6 #footer .widget .tagcloud a,
.skin6 #footer .newsletter-widget input[type="submit"] {
	border-radius: 0;
}

.skin6 #footer .newsletter-widget input[type="submit"] {
	background-color: #000;
	border-color:#000;
}

.skin6 #footer  .thumbnail img,
.skin6 #footer  .img-thumbnail img {
	border-radius: 0;
}

/* Skin7 Footer */
.skin7 #footer {
	background-color: #121214;
}

.skin7 #footer #footer-bottom {
	background-color: #0c0c0c;
	color:#777;
}

.skin7 #footer a,
.skin7 #footer a:hover,
.skin7 #footer .widget-title {
	color:#fff;
}

.skin7 #footer .newsletter-widget input[type="text"],
.skin7 #footer .newsletter-widget input[type="email"],
.skin7 #footer .widget .tagcloud a,
.skin7 #footer .newsletter-widget input[type="submit"] {
	border-radius: 0;
}

.skin7 #footer .widget .tagcloud a:hover,
.skin7 #footer .newsletter-widget input[type="submit"] {
	background-color: #65829d;
	border-color:#65829d;
}

.skin7 #footer .newsletter-widget input[type="submit"]:hover,
.skin7 #footer .newsletter-widget input[type="submit"]:active {
	background-color: #758fa7;
	border-color:#758fa7;
}

.skin7 #footer .thumbnail img,
.skin7 #footer .img-thumbnail img {
	border-radius: 0;
}

/* Skin8 */
.skin8 #footer {
	background-color: #f7f7f7;
}

.skin8 #footer  #footer-bottom {
	background-color: #fff;
	color:#777;
}

.skin8 #footer a,
.skin8 #footer a:hover {
	color:#777;
}

.skin8 #footer .widget-title {
	color:#e13b3f;
}

.skin8 #footer  .newsletter-widget input[type="text"],
.skin8 #footer .newsletter-widget input[type="email"],
.skin8 #footer  .widget .tagcloud a,
.skin8 #footer .newsletter-widget input[type="submit"] {
	border-radius: 0;
}

.skin8 #footer  .widget .tagcloud a:hover,
.skin8 #footer  .newsletter-widget input[type="submit"] {
	background-color: #e13b3f;
	border-color:#e13b3f;
}

.skin8 #footer  .newsletter-widget input[type="submit"]:hover,
.skin8 #footer  .newsletter-widget input[type="submit"]:active {
	background-color: #e45155;
	border-color:#e45155;
}

.skin8 #footer  .thumbnail img,
.skin8 #footer  .img-thumbnail img {
	border-radius: 0;
}

/* Skin9 Footer */
.skin9 #footer {
	background-color: #fff;
}

.skin9 #footer #footer-bottom {
	background-color: #f7f7f7;
	color:#777;
}

.skin9 #footer a,
.skin9 #footer a:hover {
	color:#777;
}

.skin9 #footer .widget-title {
	color:#333;
}

.skin9 #footer .newsletter-widget input[type="text"],
.skin9 #footer .newsletter-widget input[type="email"],
.skin9 #footer .widget .tagcloud a,
.skin9 #footer .newsletter-widget input[type="submit"] {
	border-radius: 0;
}

.skin9 #footer .widget .tagcloud a:hover,
.skin9 #footer .newsletter-widget input[type="submit"] {
	background-color: #d0205e;
	border-color:#d0205e;
}

.skin9 #footer .newsletter-widget input[type="submit"]:hover,
.skin9 #footer .newsletter-widget input[type="submit"]:active {
	background-color: #de2b6a;
	border-color:#de2b6a;
}

.skin9 #footer .thumbnail img,
.skin9 #footer .img-thumbnail img {
	border-radius: 0;
}

@media (min-width: 1200px) {
	.skin9 #footer #footer-bottom > .container .footer-left,
	.skin9 #footer #footer-bottom > .container .footer-right {
		width: 395px;
	}
}


/* V2 Skin */
.v2 #footer  {
	background-color: #121214;
}

.v2 #footer #footer-bottom {
	padding:16.5px 0;
	background-color: #0c0c0c;
}


/* Skin11 - index11*/
.skin11 #footer .footer-ribbon {
	background-color: #7aa93c;
}

.skin11 #footer .footer-ribbon:before {
	border-right-color:#6a9234;
}

.skin11 .newsletter-widget input[type="submit"] {
    background: #7aa93c;
    border: 1px solid #7aa93c;
}

.skin11.newsletter-widget input[type="submit"]:hover,
.skin11 .newsletter-widget input[type="submit"]:active {
	background: #84ae4c;
	border-color: #84ae4c;
}

.skin11 #footer .widget .links li > i {
	color:#7aa93c;
}

.skin11 #scroll-top,
.skin11 #scroll-top:hover {
	color:#7aa93c;
}


/* Skin12 - index12 */
.skin12 #footer .footer-ribbon {
	background-color: #ee3d43;
}

.skin12 #footer .footer-ribbon:before {
	border-right-color:#ca3136;
}

.skin12 .newsletter-widget input[type="submit"] {
    background: #ee3d43;
    border: 1px solid #ee3d43;
}

.skin12 .newsletter-widget input[type="submit"]:hover,
.skin12 .newsletter-widget input[type="submit"]:active {
	background: #ec4d53;
	border-color: #ec4d53;
}

.skin12 #footer .widget .links li > i {
	color:#ee3d43;
}

.skin12 #scroll-top,
.skin12 #scroll-top:hover {
	color:#ee3d43;
}


/* Skin13 - index13 */
.skin13 #footer {
	background-color: #50515a;
	color:#bbb;
}

.skin13 #footer a,
.skin13 #footer a:hover {
	color:#bbb;
}

.skin13 #footer .widget-title {
	text-transform: uppercase;
	margin-bottom: 20px;
}

.contact-info.info2 p {
	line-height: 20px;
}

.skin13 .contact-info .social-icons {
	margin-left:20px;
}

.skin13 .contact-info .social-icons .social-icon {
	text-align: center;
}

#footer-inner {
	padding:40px 0 10px;
}

.skin13 #footer ul.product_list_widget li .product-image,
.skin13 #footer .widget ul.product_list_widget li .product-image {
	border-radius: 0;
	padding:1px;
	width:84px;
}

.skin13 #footer ul.product_list_widget li .product-details,
.skin13 #footer .widget ul.product_list_widget li .product-details {
	margin-left:98px;
	padding-top:7px;
}

@media (min-width: 768px) and (max-width: 991px) {
	.skin13 #footer ul.product_list_widget li .product-image,
	.skin13 #footer .widget ul.product_list_widget li .product-image {
		width:44px;
	}

	.skin13 #footer ul.product_list_widget li .product-details,
	.skin13 #footer .widget ul.product_list_widget li .product-details {
		margin-left:54px;
		padding-top:0;
	}
}

.skin13 #footer ul.product_list_widget li .product-details a,
.skin13 #footer ul.product_list_widget li .product-details a:hover {
	color:#fff;
}

.skin13 #footer .star-rating:before {
	color:#c3c5c9;
}

.skin13 #footer ul.product_list_widget li .star-rating,
.skin13 #footer .widget ul.product_list_widget li .star-rating {
	margin-top:2px;
	margin-bottom: 10px;
}

#footer-inner .widget .tagcloud {
	font-size:0;
}

#footer-inner .widget .tagcloud a {
	border:none;
	font-size: 12px !important;
	line-height: 25px;
	background-color: #000;
	padding:0 7px;
	color:#fff;
	margin:0 3px 3px 0;
}

#footer-inner .contact-details > li {
	margin-bottom: 4px;
}

#footer-inner .widget .tagcloud a:hover,
#footer-inner .widget .tagcloud a:hover {
	color:#fff;
	background-color: #000;
	text-decoration: underline;
}

.skin13 #footer ul.product_list_widget li .product-details del .amount,
.skin13 #footer .widget ul.product_list_widget li .product-details del .amount,
.skin13 #footer ul.product_list_widget li .product-details .from .amount,
.skin13 #footer .widget ul.product_list_widget li .product-details .from .amount,
.skin13 #footer ul.product_list_widget li .product-details .amount,
.skin13 #footer .widget ul.product_list_widget li .product-details .amount {
	color:#fff;
}

.skin13 #footer ul.product_list_widget li .product-details del .amount,
.skin13 #footer .widget ul.product_list_widget li .product-details del .amount {
	font-size: 12px;
}

.skin13 #footer ul.product_list_widget li .product-details ins .amount,
.skin13 #footer .widget ul.product_list_widget li .product-details ins.amount  {
	font-size: 15px;
}

.skin13 #footer-inner {
	background-color: #50515a;
}

.skin13 #footer .widget .label {
	color:#bbb;
}

.skin13 #footer #footer-bottom {
	background-color: #45464e;
}

.skin13 #footer .newsletter-widget .input-box input[type="text"],
.skin13 .newsletter-widget .input-box input[type="email"] {
	border-radius: 0;
	background-color: #45464e;
	border-color:#45464e;
	color:#fff;
	height:36px;
	padding:8px 10px;
	box-shadow:none;
	-webkit-box-shadow:none;
}

.skin13 #footer .newsletter-widget .input-box-wrapper input[type="submit"] {
	border-radius: 0;
    padding:7.5px 13.5px;
}

.skin13 .newsletter-widget input[type="submit"] {
    background: #000;
    border: 1px solid #000;
    text-transform: uppercase;
}

.skin13 .newsletter-widget input[type="submit"]:hover,
.skin13 .newsletter-widget input[type="submit"]:active {
	background: #000;
	border-color: #000;
	color:#eee;
}

.skin13 #footer .widget .links li > i,
.skin13 .widget.contact-info li i {
	color:#bbb !important;
}

.skin13 #footer-bottom > .container .footer-left {
	padding-right: 0;
}


@media (min-width: 992px) {
	.skin13.v2 #footer-bottom .logo {
		margin-right: 0;
	}
}


/* Skin14 - index14 */
.skin14.v2 #footer #footer-bottom {
	background-color: #fff;
}

.skin14.v2 #footer #footer-bottom .logo {
	margin-right: 15px;
}

/* Skin15 - index15 */

.skin15 .newsletter-widget input[type="submit"] {
    border: 1px solid #08c;
}


/* Skin16 - index16 */
.skin16 #footer,
.skin16 #footer #footer-bottom {
	background-color: transparent;
}

.skin16 #footer,
.skin16 #footer a,
.skin16 #footer a:hover {
	color:#777;
}

.skin16 #footer > .container {
	padding:0 15px;
}

.skin16 #footer .widget .widget-title {
	color:#313131;
}

.skin16 #footer .widget .links li > i {
	color:#333 !important;
}

.skin16 #footer #footer-wrapper {
	padding:45px 15px 10px;
}

.skin16 #footer #footer-wrapper {
	background-color: #e7e8e9;
}

@media (min-width: 768px) {
	.skin16 #footer-bottom .container {
		padding:0 45px;
	}

	.skin16 #footer-bottom .social-icons {
		margin-right:0;
	}

	.skin16 #footer #footer-wrapper {
		padding-left:30px;
		padding-right:30px;
	}
}

@media (max-width: 767px) {
	.skin16 #footer > .container {
		padding:0;
	}
}


/* Skin17 - index17 */
.skin17 #footer {
	background-color: #f5f5f5;
}

.skin17 #footer #footer-bottom {
	background-color: #fff;
}

.skin17 #footer,
.skin17 #footer a,
.skin17 #footer a:hover {
	color:#777;
}

.skin17 #footer .widget .widget-title {
	color:#313131;
	text-transform: uppercase;
	font-weight: bold;
}

.skin17 #footer .widget .links li > i {
	color:#777777 ;
}

.skin17 .newsletter-widget input[type="submit"] {
	background-color: #3f4658;
	border-color:#3f4658;
}

.skin17 .newsletter-widget input[type="submit"]:hover,
.skin17 .newsletter-widget input[type="submit"]:focus {
	opacity: 0.9;
}

.skin17 #scroll-top {
	background-color: #404040;
	color:#fff;
}

.skin17 #scroll-top:hover {
	background-color:#555;
}


/* =============================================
-----------------  20. Social Icons -------------
==============================================*/

.social-icons {
	font-size:0;
}

.social-icon {
	display:inline-block;
width: 50px;
height: 50px;
font-size: 40px;
line-height: 52px;
	margin:0 7.5px 15px;
	color:#95ca48  !important;
	background-color: #fff;
	margin:0.2857em 0.5714em 0.2857em 0;
	text-align: center;
	border-radius: 5px;
	transition:all 0.25s ease 0s;
	-webkit-transition:all 0.25s ease 0s
}


.social-icon.first {
	margin-left:0;
}

.social-icon.icon-facebook:hover,
.social-icons2 .social-icon.icon-facebook {
	background-color:#3b5998 !important;
}

.social-icon.icon-twitter:hover,
.social-icons2 .social-icon.icon-twitter {
	background-color:#55acee !important;
}

.social-icon.icon-linkedin:hover,
.social-icons2 .social-icon.icon-linkedin {
	background-color:#0976b4 !important;
}

.social-icon.icon-google-plus:hover,
.social-icons2 .social-icon.icon-google-plus {
	background-color:#dd4b39 !important;
}

.social-icon.icon-pinterest:hover,
.social-icons2 .social-icon.pinterest {
	background-color:#cc2127 !important;
}

.social-icon.icon-envelope:hover,
.social-icons2 .social-icon.icon-envelope {
	background-color:#dd4b39 !important;
}


.social-icons2 .social-icon.icon-facebook:hover,
.social-icons2 .social-icon.icon-twitter:hover,
.social-icons2 .social-icon.icon-linkedin:hover,
.social-icons2 .social-icon.icon-google-plus:hover,
.social-icons2 .social-icon.icon-envelope:hover {
	opacity:0.9;
}

@media (max-width: 767px) {
	.product-gallery-container {
		margin-bottom: 30px;
	}
}


/* =============================================
--------  21.Owl Carousel - Animate Plugin -----
==============================================*/

/* Change duration for animation speed for product sliders */
.owl-carousel .animated {
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
	display: none;
}
.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
}
.owl-carousel .owl-item img {
	display: block;
	width: 100%;
	-webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
	transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/* Nav Dots*/
.owl-dots {
	text-align: center;
	margin-top:20px;
}

.owl-dot {
	display:inline-block;
	width:10px;
	height:10px;
	background-color: #000000;
	margin:5px 7px;
	border-radius: 50%;
	opacity:0.5;
}

.owl-dot:hover,
.owl-dot.active {
	opacity:1;
}

/* Owl carousel Controls */

/* To not crush dragging
styled buttons not container for vertical centered navs*/
.owl-nav {
	position: absolute;
	bottom:auto;
	top:-3em;
	left:auto;
	right:0;
}

.owl-prev,
.owl-next {
	position: static;
	display:inline-block;
	font-size:1.2857em;
	line-height:1;
	color:#9ac93d;
	padding:1px 9px;
	opacity:1;
}

.owl-prev {
	left:0;
	text-align: left;
	padding-right:5px;
}

.owl-next {
	right:0;
	text-align: right;
	padding-left:5px;
}

.owl-prev:hover,
.owl-next:hover {
	color:#ff5400;
}


/* =============================================
-----------------  22. 404 Page  ---------------
==============================================*/

.error-content {
	text-align: center;
	padding:35px 0 40px;
}

.error-title {
	font:400 150px/1 'Montserrat', Verdana;
	color:#343434;
	margin-bottom:11px;
}

.error-title > span {
	display:block;
	font:300 48px/1 'Raleway', Verdana;
	margin-top:-3px;
}

.error-content p {
	font-weight: 400;
}

.error-content p > a {
	font-weight: 600;
	text-decoration: underline;
}

.error-content p > a:hover,
.error-content p > a:focus {
	color:#2e2e2e;
	text-decoration: underline;
}

@media (min-width: 768px) {
	.error-content {
		padding:50px 0 60px;
	}

	.error-title {
		font-size:300px;
	}

	.error-title > span {
		font-size:70px;
		margin-top:-3px;
	}

}


@media (min-width: 768px) {
	.error-content {
		padding:80px 0 90px;
	}
}



/* =============================================
----------------  23. Coming Soon --------------
==============================================*/

#coming-soon {
	width:100%;
	height:100%;
	position: relative;
	color:#b6b6b6;
	font:400 15px/22px 'Raleway', Verdana;
	text-align: center;
	background-color: #f8f8f8;
}

#coming-soon.coming-soonbg {
	background-image: url('../images/comingsoon.html');
	background-position: center center;
	-webkit-background-size: cover;
	background-size: cover;
}

#coming-soon .logo {
	position: absolute;
	top:40px;
	left:0;
	right:0;
}

#coming-soon .logo > a {
	display: inline-block;
}

#coming-soon-wrapper  {
	background-color: #fff;
	padding:30px 0 40px;
}

#coming-soon h2 {
	font: 700 48px/1 'Montserrat', Verdana;
	text-transform: uppercase;
	color:#ffffff;
	margin-bottom: 11px;
}

#coming-soon #coming-soon-wrapper h2 {
	color:#262626;
}

#coming-soon p {
	max-width:560px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 36px;
}

#coming-soon form {
	max-width: 435px;
	margin-right: auto;
	margin-left: auto;
	text-align: left;
}

#countdown {
	margin-bottom:25px;
}

#coming-soon-wrapper #countdown {
	margin-bottom:48px;
}

/* jQuery Countdown styles 2.0.0.
.countdown-rtl {
	direction: rtl;
}

.countdown-row {
	width: 100%;
	font-size:0;
}

.countdown-section {
	display:inline-block;
	position: relative;
	padding-right:50px;
	margin-right:60px;
	font-family: 'Montserrat', Verdana;
	font-weight: 700;
	text-transform: uppercase;
}

.countdown-section:last-child {
	padding-right: 0;
	margin-right: 0;
}

.countdown-section:after {
	content:'';
	position: absolute;
	right:0;
	top:50%;
	margin-top:-30px;
	display:block;
	height:60px;
	width:1px;
	background-color: rgba(255, 255, 255, 0.3);
}

#coming-soon-wrapper .countdown-section:after {
	background-color: #dcdcdc;
}

.countdown-section:last-child:after {
	display: none;
}

.countdown-amount {
	display: block;
    font-size:72px;
    line-height: 70px;
    font-weight: 400;
    color:#fff;
}

#coming-soon #coming-soon-wrapper .countdown-amount {
	color:#262626;
}

.countdown-period {
    display: block;
    position: relative;
    font-size:15px;
    line-height:1;
    color:#edc32f;
}

#coming-soon #coming-soon-wrapper .countdown-period {
	color:#c8c8c8;
}

.countdown-descr {
	display: block;
	width: 100%;
}

#coming-soon .input-desc {
	color:#313131;
	font-size:13px;
	line-height: 1;
	margin-bottom: 9px;
}

#coming-soon .input-group {
	margin-bottom: 0;
}

#coming-soon .input-group .form-control {
	background-color: rgba(255, 255, 255, 0.08);
	border-color:#828282;
	color:#ffffff;
}

#coming-soon .input-group .form-control::-moz-placeholder {
	color: #ffffff;
}

#coming-soon .input-group .form-control:-ms-input-placeholder {
	color: #ffffff;
}

#coming-soon .input-group .form-control::-webkit-input-placeholder {
	color: #ffffff;
}

#coming-soon #coming-soon-wrapper .input-group .form-control {
	background-color: transparent;
	border-color:#e5e5e5;
	color:#c2c2c2;
}

#coming-soon #coming-soon-wrapper .input-group .form-control:focus {
	border-color:#888;
}

#coming-soon #coming-soon-wrapper .input-group .form-control::-moz-placeholder {
	color: #c2c2c2;
}

#coming-soon #coming-soon-wrapper .input-group .form-control:-ms-input-placeholder {
	color: #c2c2c2;
}

#coming-soon #coming-soon-wrapper .input-group .form-control::-webkit-input-placeholder {
	color: #c2c2c2;
}

#coming-soon .btn.btn-custom {
	background-color: #d7af23;
	border-color:#d7af23;
	letter-spacing: 1px;
	padding-left:23px;
	padding-right: 23px;
}

#coming-soon .btn.btn-custom:hover,
#coming-soon .btn.btn-custom:focus {
	background-color: #262626;
	border-color: #262626;
}


#coming-soon footer {
	color:#cccccc;
	font-weight: 400;
	font-size:14px;
	line-height:30px;
	position: absolute;
	bottom:42px;
	left:0;
	right:0;
}

#coming-soon footer.dark {
	color:#8c8c8c;
}

#coming-soon  footer a {
	color:#d7af23;
	font-weight: 600;
}

#coming-soon  footer a:hover {
	color:#262626;
}

@media (max-width: 768px) {

	#coming-soon h2 {
		font-size:36px;
		margin-bottom:48px
	}

	#coming-soon p {
		display: none;
		margin-bottom:50px;
	}

	.countdown-section {
		padding-right:0;
		margin-right:25px;
	}

	.countdown-section:after {
		display: none;
	}

	.countdown-amount {
		font-size:36px;
		line-height: 35px;
	}

	.countdown-period {
	    font-size:13px;
	}
}

@media (max-width: 360px) {
	.countdown-section {
		margin-right:15px;
	}
}

@media (max-height: 400px) {
	#coming-soon .logo {
		top:10px;
	}

	#coming-soon footer {
		bottom:10px;
	}

	#coming-soon h2 {
		font-size:28px;
		margin:25px 0 30px;
	}

	#countdown {
		margin-bottom: 10px;
	}

	#coming-soon #coming-soon-wrapper #countdown {
		margin-bottom:25px;
	}

	#coming-soon #coming-soon-wrapper h2 {
		font-size:28px;
		margin:10px 0 30px;
	}

	#coming-soon-wrapper  {
		padding:10px 0 25px;
	}
}

@media (min-width: 768px) {
	#coming-soon .logo {
		top:30px;
	}

	.countdown-section {
		min-width: 120px;
		margin-right:40px;
	}

	#coming-soon footer {
		bottom:32px;
	}

	#coming-soon.simple .logo {
		top:20px;
	}

	#coming-soon.simple footer {
		bottom:22px;
	}
}

@media (min-width: 992px) {

	.countdown-section {
		min-width: 135px;
		margin-right:60px;
	}
}


@media (min-width: 1199px) {
	#coming-soon .logo {
		top:60px;
	}

	#coming-soon footer {
		bottom:52px;
	}

	#coming-soon.simple .logo {
		top:30px;
	}

	#coming-soon.simple footer {
		bottom:27px;
	}

}

@media (min-height: 800px) {
	#coming-soon-wrapper  {
		padding:65px 0 70px;
	}

	#coming-soon.simple .logo {
		top:60px;
	}

	#coming-soon.simple footer {
		bottom:52px;
	}
}

*/

/* =============================================
-------------  25. Scroll Top Button  ----------
==============================================*/

#scroll-top {
	position:fixed;
	bottom:0;
	right:20px;
	display: none;
	cursor:pointer;
	background: #404040;
    background: rgba(64, 64, 64, 0.75);
    border-radius: 4px 4px 0 0;
    color: #fff;
    height: 9px;
    padding: 7px 10px 30px;
    text-align: center;
    text-decoration: none;
    min-width: 40px;
}

#scroll-top:hover {
	background:#404040;
}

#scroll-top.fixed {
	opacity:1;
	visibility:visible;
}

/* @group Custom styles */

.currency_language_form {
	display: inline-block;
}

	.currency_language_form .arrow {
		display: none;
	}

/* @end */

.main-content {
	/*padding-bottom: 40px; */
	min-height: 400px;
}

.body-header-type-1.common-home header .container-megamenu {
	display: none;
}

/* @group Camera slider */

.camera_slider {
	margin-bottom: 20px;
}

	.skin5 .camera_slider {
		margin-bottom: 15px;
	}

	.camera_slider .owl-dots {
		position: absolute;
		left: 0;
		right: 0;
		margin-top: -40px;
	}

	.camera_slider img {
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
	}

	.skin5 .camera_slider img,
	.skin8 .camera_slider img,
	.skin12 .camera_slider img {
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
	}

/* @end */

/* @group Mobile menu */

#mobile-menu .fa-caret-down {
	display: none;
}

#mobile-menu .currency_language_form {
	display: block;
}

#mobile-menu .arrow {
	display: block;
}

/* @end */

/* @group Cart block */

#mini-cart .dropdown-menu {
	color: #777;
}

#mini-cart .checkout {
	padding: 0px 20px 20px 20px;
}

#mini-cart .checkout:before,
#mini-cart .checkout:after {
  display: table;
  content: " ";
}
#mini-cart .checkout:after {
  clear: both;
}

	#mini-cart .checkout .btn {
		display: block;
		width: 48%;
		float: left;
	}

		#mini-cart .checkout .btn:last-of-type {
			float: right;
		}

#mini-cart .mini-cart-info {

}

	#mini-cart .mini-cart-info table tr {
		padding: 20px 20px 0px 20px;
		display: block;
	}

		#mini-cart .mini-cart-info table td {
			vertical-align: top;
		}

			#mini-cart .mini-cart-info table td.image {
				padding-right: 10px;
			}

				#mini-cart .mini-cart-info table td.image img {
					display: block;
					margin: 0px auto;
					border-radius: 5px;
					-webkit-border-radius: 5px;
					padding: 3px;
					-moz-border-radius: 5px;
					border: 1px solid #ddd;
				}

		#mini-cart .mini-cart-info table td.name {
			padding-top: 10px;
			width: 200px;
		}

			#mini-cart .mini-cart-info table td.name .price2 {
				color: #9ac93d;
				padding-top: 5px;
			}

		#mini-cart .mini-cart-info table td.remove {
			font-weight: bold;
			width: 100px;
			padding-top: 8px;
			text-align: right;
		}

#mini-cart .mini-cart-total {
	padding: 13px 16px;
}

	#mini-cart .mini-cart-total tr {
		display: none;
	}

		#mini-cart .mini-cart-total tr td {
			width: 300px;
			padding-left: 4px;
			padding-right: 4px;
		}

			#mini-cart .mini-cart-total tr td:first-child {
				text-align: right;
				font-size: 18px;
				font-weight: bold;
			}

			#mini-cart .mini-cart-total tr td:last-child {
				font-size: 18px;
				color: #08c;
				font-weight: 700;
			}

		#mini-cart .mini-cart-total tr:last-child {
			display: block;
		}

/* @end */

/* @group Buttons */

.btn-default,
.btn,
.button {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	    touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
    color: #fff;
    font-weight: 400;
    background-color: #9ac93d;
    text-shadow: none;
    border: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
}

	.btn-default:hover,
	.btn:hover,
	.button:hover {
		background: #ed4949;
		color: #fff;
	}

/* @end */

/* @group Quickview */

a.quickview-icon {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #000;
    color: #fff;
    padding: 10px;
    z-index: 9;
    border-radius: 0 5px;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s all;
    font-size: 12px;
    line-height: 1.4;
}

	.product:hover a.quickview-icon {
		opacity: 1;
		visibility: visible;
	}

		.product:hover a.quickview-icon:hover {
			opacity: 0.9;
		}

/* @end */

/* @group Megamenu */

.container-megamenu + .widget {
	margin-top: 20px;
}

@media (max-width: 991px) {
	.mobile-disabled {
		display: none !important;
	}
}

.megamenu .megamenu-footer {
    background-color: #f6f6f6;
    padding: 0;
    border: 2px solid #fff;
    color: #000000;
    font-weight: 400;
    margin-left: -30px;
    margin-bottom: -30px;
    margin-right: -30px;
    border-radius: 0 0 6px 6px;
}

	.megamenu .megamenu-footer .info-box-container {
	    border-right: 2px solid #fff;
	}

	.megamenu .megamenu-footer .info-box-container:last-child {
		border: none;
	}
/* @end */

/* @group Product category page */

.product-grid .product {
	padding-bottom: 30px;
}

/* @end */

/* @group 22. Magnific popup */

.mfp-iframe-scaler iframe {
     background: url("../images/loader.gif") no-repeat scroll center center #ffffff !important;
}

.quickview {
}

     .quickview body {
          background: #fff;
          padding: 30px 30px 0px 30px;
     }

     .quickview .mfp-iframe-holder .mfp-content {
          max-width: 900px;
          height:620px;
     }

     .quickview .mfp-iframe {
          opacity: 0;
          transition: all 0.2s ease-in-out;
          -webkit-transform: scale(0.7);
          -moz-transform: scale(0.7);
          -ms-transform: scale(0.7);
          transform: scale(0.7);
     }

     .quickview.mfp-bg {
          opacity: 0.01;
          transition: all 0.2s ease-out;
     }

     .quickview.mfp-ready .mfp-iframe {
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
     }

     .quickview.mfp-ready.mfp-bg {
          opacity: 0.75;
     }

     .quickview.mfp-removing .mfp-iframe {
          -webkit-transform: scale(0.7);
          -moz-transform: scale(0.7);
          -ms-transform: scale(0.7);
          transform: scale(0.7);
          opacity: 0;
     }

     .quickview.mfp-removing.mfp-bg {
          opacity: 0;
     }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* @end */

/* @group OpenCart styles */

.success,
.warning,
.attention,
.information {
	padding: 10px 10px 10px 40px;
	margin:0px 0px 20px 0px;
	clear: both;
	border-radius:2px;
	-webkit-border-radius:2px;
	-moz-border-radius:2px;
}

	.success {
		background: #EAF7D9 url('../images/success.png') 13px center no-repeat;
		border: 1px solid #BBDF8D;
	}

	.warning {
		background: #FFD1D1 url('../images/warning.png') 13px center no-repeat;
		border: 1px solid #F8ACAC;
	}

	.attention {
		background: #FFF5CC url('../images/attention.png') 13px center no-repeat;
		border: 1px solid #F2DD8C;
	}

	.information {
		background: #E8F6FF url('../images/information.html') 13px center no-repeat;
		border: 1px solid #B8E2FB;
	}

/* @group Review */

.review-list .author,
.review-list .rating {
	display: inline-block;
}

	.review-list .rating {
		float: right;
		width: auto;
		padding-top: 8px;
	}

	.review-list .author span {
		font-size: 10px;
		opacity: 0.8;
		font-style: italic;
		padding-left: 3px;
	}

.review-list .text {
	margin: 15px 0px 20px 0px;
	padding: 20px;
	position: relative;
	background: #fff;
	border: 1px solid #e6e6e6;
	border-radius:         2px;
	-moz-border-radius:    2px;
	-webkit-border-radius: 2px;
}

	.review-list .text:before {
		position: absolute;
		top: -9px;
		left: 9px;
		display: inline-block;
		border-right: 9px solid transparent;
		border-bottom: 9px solid #ccc;
		border-left: 9px solid transparent;
		border-bottom-color: #e6e6e6;
		content: '';
	}

	.review-list .text:after {
		position: absolute;
		top: -8px;
		left: 10px;
		display: inline-block;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #ffffff;
		border-left: 8px solid transparent;
		content: '';
	}

/* @end */

/* @group Tables
------------------*/

table.attribute,
table.list,
.wishlist-product table,
.wishlist-info table,
.compare-info,
.cart-info table,
.checkout-product table,
.table {
	border-collapse: separate;
	border-spacing: 0;
    border: solid #ddd 1px;
    width: 100%;
    margin: 0px 0px 20px 0px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

	table.attribute tr,
	table.list tr,
	.wishlist-product table tr,
	.wishlist-info table tr,
	.compare-info tr,
	.cart-info table tr,
	.checkout-product table tr,
	.table {
	    background: #fff;
	}

		table.attribute tr:hover,
		table.list tr:hover,
		.wishlist-product table tr:hover,
		.wishlist-info table tr:hover,
		.compare-info tr:hover,
		.cart-info table tr:hover,
		.checkout-product table tr:hover,
		.table tr:hover {
		    background: #f7f7f7;
		    -webkit-transition: all 0.1s ease-in-out;
		    -moz-transition: all 0.1s ease-in-out;
		    transition: all 0.1s ease-in-out;
		}

	table.attribute td,
	table.list td,
	.wishlist-product table td,
	.wishlist-info table td,
	.compare-info td,
	.cart-info table td,
	.checkout-product table td,
	.table td {
	    border-left: 1px solid #ddd;
	    border-top: 1px solid #ddd;
	    padding: 10px;
	    text-align: left;
	    vertical-align: top;
	}

		.table td.text-center {
			text-align: center;
		}

			.table td.text-center .input-group {
				margin: 0px auto;
			}

		.table td.text-right {
			text-align: right;
		}

	table.attribute thead td,
	table.list thead td,
	.wishlist-product table thead td,
	.wishlist-info table thead td,
	.compare-info thead td,
	.cart-info table thead td,
	.checkout-product table thead td,
	.table thead td {
	    background-color: #f5f5f5;
	    border-radius: 0 !important;
	    -webkit-border-radius: 0 !important;
	    -moz-border-radius: 0 !important;
	    color: #777;
	    font-size: 0.9286em;
	    font-weight: 700;
	    line-height: 1.4;
	    text-transform: uppercase;
	}

		table.attribute thead:first-child td,
		table.list thead:first-child td,
		.wishlist-product table thead:first-child td,
		.wishlist-info table thead:first-child td,
		.compare-info thead:first-child td,
		.cart-info table thead:first-child td,
		.checkout-product table thead:first-child td,
		.table thead:first-child td {
			border-top: none;
		}

	table.attribute td:first-child,
	table.list td:first-child,
	.wishlist-product table td:first-child,
	.wishlist-info table td:first-child,
	.compare-info td:first-child,
	.cart-info table td:first-child,
	.checkout-product table td:first-child,
	.table td:first-child {
	    border-left: none;
	}

	table.attribute thead td:first-child,
	table.list thead td:first-child,
	.wishlist-product table thead td:first-child,
	.wishlist-info table thead td:first-child,
	.compare-info thead td:first-child,
	.cart-info table thead td:first-child,
	.checkout-product table thead td:first-child,
	.table thead td:first-child {
	    -moz-border-radius: 5px 0 0 0;
	    -webkit-border-radius: 5px 0 0 0;
	    border-radius: 5px 0 0 0;
	}

	table.attribute thead td:last-child,
	table.list thead td:last-child,
	.wishlist-product table thead td:last-child,
	.wishlist-info table thead td:last-child,
	.compare-info thead td:last-child,
	.cart-info table thead td:last-child,
	.checkout-product table thead td:last-child,
	.table thead td:last-child {
	    -moz-border-radius: 0 5px 0 0;
	    -webkit-border-radius: 0 5px 0 0;
	    border-radius: 0 5px 0 0;
	}

	table.attribute thead td:only-child,
	table.list thead td:only-child,
	.wishlist-product table thead td:only-child,
	.wishlist-info table thead td:only-child,
	.compare-info thead td:only-child,
	.cart-info table thead td:only-child,
	.checkout-product table thead td:only-child,
	.table thead td:only-child {
	    -moz-border-radius: 5px 5px 0 0;
	    -webkit-border-radius: 5px 5px 0 0;
	    border-radius: 5px 5px 0 0;
	}

	table.attribute tr:last-child td:first-child,
	table.list tr:last-child td:first-child,
	.wishlist-product table tr:last-child td:first-child,
	.wishlist-info table tr:last-child td:first-child,
	.compare-info tr:last-child td:first-child,
	.cart-info table tr:last-child td:first-child,
	.checkout-product table tr:last-child td:first-child,
	.table tr:last-child td:first-child {
	    -moz-border-radius: 0 0 0 5px;
	    -webkit-border-radius: 0 0 0 5px;
	    border-radius: 0 0 0 5px;
	}

	table.attribute tr:last-child td:last-child,
	table.list tr:last-child td:last-child,
	.wishlist-product table tr:last-child td:last-child,
	.wishlist-info table tr:last-child td:last-child,
	.compare-info tr:last-child td:last-child,
	.cart-info table tr:last-child td:last-child,
	.checkout-product table tr:last-child td:last-child,
	.table tr:last-child td:last-child {
	    -moz-border-radius: 0 0 5px 0;
	    -webkit-border-radius: 0 0 5px 0;
	    border-radius: 0 0 5px 0;
	}

/* @end */

/* @group Wishlist info */

.wishlist-info table td {
	vertical-align: middle;
	text-align: center;
}

/* @end */

/* @group Cart info */

.cart-info table td {
	vertical-align: middle;
	text-align: center;
}

/* @end */

/* @group Price Compare */

.compare-info .price-new {
	color: #ff6b6b;
}

	.compare-info .price-old {
		color: #808080;
		text-decoration: line-through;
		padding-right: 2px;
	}

/* @end */

/* @group Table img */

table img {
	max-width: none;
}

/* @end */

/* @group Cart Total */

.cart-total {
	overflow: auto;
	margin-bottom: 20px;
	padding-top: 10px;
}

	.cart-total table {
		float: right;
	}

		.cart-total td {
			padding: 0px 0px 0px 20px;
			text-align: right;
			font-weight: normal;
		}

			.cart-total td strong {
				font-weight: normal;
			}

		.cart-total table tr td:last-child {
			color: #444;
			font-weight: 600;
		}

/* @end */

/* @group Download List, Orders */

.download-list,
.order-list {
	overflow:auto;
}

	.download-list .download-id,
	.order-list .order-id {
		width: 49%;
		float: left;
		margin-bottom: 2px;
	}

	.download-list .download-status,
	.order-list .order-status {
		width: 49%;
		float: right;
		text-align: right;
		margin-bottom: 2px;
	}

	.download-list .download-content,
	.order-list .order-content {
		padding: 10px 0px;
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
	}

	.download-list .download-content div,
	.order-list .order-content div {
		float: left;
		width: 33.3%;
	}

	.download-list .download-info,
	.order-list .order-info {
		text-align: right;
	}

/* @end */

/* @group manufacturer */

.manufacturer-list {
	border: 1px solid #ddd;
	border-radius:         6px;
	-moz-border-radius:    6px;
	-webkit-border-radius: 6px;
	padding: 0px;
	overflow: auto;
	margin-bottom:20px;
	background: #fff;
}

	.manufacturer-heading {
		background: #f3f4f5;
		border-bottom: 1px solid #ddd;
		padding: 10px;
	}

	.manufacturer-content {
		padding: 10px;
		overflow:auto;
	}

	.manufacturer-list ul {
		float: left;
		width: 25%;
		margin: 0 0 0 20px;
		padding: 0;
	}

/* @end */

/* @group checkout */

.center-column .panel {
	position:relative;
	border: none;
	margin: 0px 0px 20px 0px;
}

	.center-column .panel-heading {
		border-radius:6px;
		-moz-border-radius:6px;
		-webkit-border-radius:6px;
		background: #f4f4f4;
		border: 1px solid #ddd;
		padding: 10px;
	}

		.center-column .panel-heading h4 {
			padding: 0px;
			margin: 0px;
			font-size: 13px !important;
			color: #777;
			    font-size: 0.9286em;
			    font-weight: 700;
			    line-height: 1.4;
			    text-transform: uppercase;
		}

	.center-column .panel-body {
		border-radius:0px 0px 2px 2px;
		-moz-border-radius:0px 0px 2px 2px;
		-webkit-border-radius:0px 0px 2px 2px;
		position:relative;
		border: 1px solid #e6e6e6;
		background: #fff;
		padding:20px;
		position: relative;
		margin-top: -3px;
	}

		.center-column .panel-body .buttons {
			margin: 0px;
		}

		.center-column h2 {
			margin-bottom: 10px;
		}

			.center-column h2.slider-title {
				margin-bottom: 20px;
			}

/* @end */

/* @group Returns */

.return-list {
}

	.return-list .return-id {
		width: 49%;
		float: left;
		margin-bottom: 2px;
	}

	.return-list .return-status {
		width: 49%;
		float: right;
		text-align: right;
		margin-bottom: 2px;
	}

	.return-list .return-content {
		padding: 10px 0px;
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
	}

	.return-list .return-content div {
		float: left;
		width: 33.3%;
	}

	.return-list .return-info {
		text-align: right;
	}

.return-product {
	overflow: auto;
	margin-top: 10px;
	margin-bottom: 20px;
}

.return-detail {
}

	.return-opened textarea {
		width: 100%;
	}

/* @end */

/* @group Filter */

ul.box-filter {
	list-style: none;
	margin: 0px;
	padding: 0px 0px 5px 0px;
}

	ul.box-filter > li {
		padding: 0px 0px 15px 0px;
	}

		ul.box-filter > li > span {
			font-weight: bold;
		}

	ul.box-filter ul {
		list-style: none;
		padding: 0px;
	}

/* @end */

/* @end */

/* @group 14. Facebook Widget  */

.facebook_left {
    width: 308px;
    position: fixed;
	left: -308px;
	top: 150px;
	z-index: 999;
}

.facebook_right {
    width: 308px;
    position: fixed;
	right: -308px;
	top: 150px;
	z-index: 999;
}

	.facebook_left .facebook-icon,
	.facebook_right .facebook-icon {
		height: 50px;
	    width: 50px;
		right: -50px;
	    background: url(../images/facebook-icon.png) no-repeat;
		cursor: pointer;
		position: absolute;
	    z-index: 999;
	}

	.facebook_right .facebook-icon {
		left: -50px;
		right: auto;
	}

	.facebook_right .facebook-content,
	.facebook_left .facebook-content {
	    background-color: #FFFFFF;
		border: 4px solid #4b6baf;
		position: relative;
	    z-index: 999;
	}

/* @end */

/* @group 15. Twitter Widget  */

.twitter_left {
    width: 250px;
    position: fixed;
	left: -250px;
	top: 150px;
	z-index: 998;
}

.twitter_right {
    width: 250px;
    position: fixed;
	right: -250px;
	top: 150px;
	z-index: 998;
}

	.facebook_right + .twitter_right,
	.facebook_left + .twitter_left {
		top: 210px;
	}

	.twitter_left .twitter-icon,
	.twitter_right .twitter-icon {
		height: 50px;
	    width: 50px;
		right: -50px;
	    background: url(../images/twitter-icon.png) no-repeat;
		cursor: pointer;
		position: absolute;
	    z-index: 998;
	}

	.twitter_right .twitter-icon {
		left: -50px;
		right: auto;
	}

	.twitter_right .twitter-content,
	.twitter_left .twitter-content {
	    background-color: #FFFFFF;
		border: 4px solid #36a7e5;
		position: relative;
	    z-index: 998;
	}

/* @end */

/* @group 16. Custom Widget  */

.custom_left {
    width: 250px;
    position: fixed;
	left: -250px;
	top: 150px;
	z-index: 996;
}

.custom_right {
    width: 250px;
    position: fixed;
	right: -250px;
	top: 150px;
	z-index: 996;
}

	.facebook_right + .twitter_right + .custom_right,
	.facebook_left + .twitter_left + .custom_left {
		top: 270px;
	}

	.facebook_right + .custom_right,
	.twitter_right + .custom_right,
	.twitter_left + .custom_left,
	.facebook_left + .custom_left {
		top: 210px;
	}

	.custom_left .custom-icon,
	.custom_right .custom-icon {
		height: 50px;
	    width: 50px;
		right: -50px;
	    background: url(../images/custom-icon.png) no-repeat;
		cursor: pointer;
		position: absolute;
	    z-index: 996;
	}

		.custom_left .custom-icon {
			background: url(../images/custom-icon-right.png) no-repeat;
		}

	.custom_right .custom-icon {
		left: -50px;
		right: auto;
	}

	.custom_right .custom-content,
	.custom_left .custom-content {
	    background-color: #FFFFFF;
		border: 4px solid #ea2e49;
		position: relative;
	    z-index: 996;
	    padding: 10px;
	}

/* @end */

/* @group 17. Opencart 2.0 */

.picker-switch .btn {
	background: none !important;
	color: #808080 !important;
	text-shadow: none !important;
	-webkit-text-shadow: none !important;
	-moz-text-shadow: none !important;
}

.timepicker-picker .btn {
	background: none !important;
	color: #808080 !important;
	text-shadow: none !important;
	-webkit-text-shadow: none !important;
	-moz-text-shadow: none !important;
}

div.required .control-label:before {
	content: '* ';
	color: #F00;
	font-weight: bold;
}

/* @end */

/* @group 18. Carousel Brands */

.carousel-brands {
	background: #fff;
	margin: 30px 0px 0px 0px !important;
	padding: 10px 70px;
	border: 1px solid #e6e6e7;
	border-radius:         2px;
	-moz-border-radius:    2px;
	-webkit-border-radius: 2px;
	position: relative;
}

@media (max-width: 767px) {
	.responsive .carousel-brands {
		margin-top: 20px !important;
	}
}

     /** Carousel Controls **/

     .carousel-brands .owl-prev,
     .carousel-brands .owl-next {
     	position: absolute;
     	top: 50%;
     	margin-top: -14px;
     	right: 20px;
     	cursor: pointer;
     	width: 28px;
     	height: 28px;
     	border-radius:         2px;
     	-moz-border-radius:    2px;
     	-webkit-border-radius: 2px;
     	background: #43c6bc; /* Old browsers */
     	background: -moz-linear-gradient(top, #43c6bc 0%, #59d4cc 0%, #43c6bc 99%); /* FF3.6+ */
     	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#43c6bc), color-stop(0%,#59d4cc), color-stop(99%,#43c6bc)); /* Chrome,Safari4+ */
     	background: -webkit-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* Chrome10+,Safari5.1+ */
     	background: -o-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* Opera 11.10+ */
     	background: -ms-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* IE10+ */
     	background: linear-gradient(to bottom, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* W3C */
     }

     	.carousel-brands .owl-next:before {
     		content: "";
     		background: url(../images/right.html) no-repeat;
     		width: 8px;
     		height: 14px;
     		margin: 8px auto 0px auto;
     		display: block;
     	}

     .carousel-brands .owl-prev {
     	left: 20px;
     }

     	.carousel-brands .owl-prev:before {
     		content: "";
     		background: url(../images/left.html) no-repeat;
     		width: 8px;
     		height: 14px;
     		margin: 8px auto 0px auto;
     		display: block;
     	}

/* @end */

/* @group Header notice */

.header-notice {
     background: #0083c1;
     color: #fff;
     font-size: 12px;
     font-weight: 600;
     display: none;
}

     .header-notice a {
          color: #fff;
     }

     .header-notice p {
          padding: 14px 0px 18px 0px;
          margin: 0px;
     }

     .header-notice a.close-notice {
          display: block;
          float: right;
          width: 36px;
          height: 36px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          background: #fff;
          margin: 6px 10px;
          text-align: center;
          padding: 9px 0px 0px 0px;
          color: #0083c1;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          -o-transition: all 0.2s ease-out;
          -ms-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
     }

          .header-notice a.close-notice:before {
                 content: "\f00d";
                 font: normal normal normal 14px/1 FontAwesome;
                 text-rendering: auto;
                 -webkit-font-smoothing: antialiased;
                 -moz-osx-font-smoothing: grayscale;
          }

          .header-notice a.close-notice:hover {
               background: #333333;
               color: #fff;
          }

/* @end */

/* @group Popup Module */

.popup {
     padding: 40px 40px 40px 40px;
     text-align: left;
     max-width: 750px;
     margin: 40px auto;
     position: relative;
     border-top: 0px solid #08c;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     background: #fff;
     -webkit-box-shadow: 0 10px 25px rgba(0,0,0,0.5);
     -moz-box-shadow: 0 10px 25px rgba(0,0,0,0.5);
     box-shadow: 0 10px 25px rgba(0,0,0,0.5);
}

body .popup-module .mfp-close {
     top: -4px;
     right: -6px;
     text-align: right;
     padding-right: 19px;
     width: 100%;
     color: #08c;
     font-size: 22px;
     opacity: 1;
     font-weight: bold;
}

	body .popup-module .mfp-close:hover {
		opacity: 0.85;
	}

     body .popup-module.without-button-close .mfp-close {
          display: none;
     }

     .popup h4 {
     	padding: 0px 0px 29px 0px;
     	margin: 0px;
     	font-weight: 700;
     	color: #313131;
     	font-size: 16px;
     	line-height: 1;
     }

     .popup .content {
          padding: 0px 0px 10px 0px;
          margin: 0px;
     }

     .popup .contact-label {
     	padding-bottom: 20px;
     }

     .popup .btn-primary {
     	margin: 10px 0 0px 0;
     	display: block;
     }

     /* @group Newsletter */

     .popup .newsletter-content {
     	max-width: 312px;
     	text-align: center;
     	padding: 6px 0px 0px 0px;
     }

     	.popup .newsletter-content h2 {
     		padding: 29px 0px 0px 0px;
     		margin: 0px;
     		font-weight: 700;
     		color: #313131;
     		font-size: 16px;
     		line-height: 1;
     	}

     	.popup .newsletter-content p {
     		padding: 12px 10px 0px 10px;
     		margin: 0px;
     		font-size: 13px;
     		line-height: 1.4;
     		color: #444;
     	}

     .popup .newsletter {
     	position: relative;
     	max-width: 300px;
     	margin: 15px 0px 0px 0px;
     }

     	.popup .newsletter input {
     		display: block;
     		width: 100%;
     		background: #fff;
     		border: 1px solid #ccc;
     		color: #777;
     		font-size: 13px;
     		height: 34px;
     		padding: 2px 13px;
     		line-height: 30px;
     		border-radius: 3px;
     	}

     	.popup .newsletter .buton {
     		display: block;
     		border: 0;
     		padding: 0 15px;
     		font-weight: normal;
     		font-size: 14px;
     		text-align: center;
     		white-space: nowrap;
     		color: #fff;
     		line-height: 38px;
     		position: absolute;
     		top: 0;
     		right: 0;
     		height: 34px;
     		color: #555;
     		line-height: 34px;
     		border-left: 1px solid #ccc;
     	}

     .popup .dont-show-label {
     	font-size: 11px;
     	position: relative;
     	margin: 15px 0px -21px 0px;
     }

     	.popup .dont-show-label span {
     		font-weight: normal;
     		padding-left: 2px;
     	}

     /* @end */

     /* @group Buttons yes no */

     .popup .buttons-yes-no {
          padding: 20px 0px 0px 0px;
     }

          .popup .buttons-yes-no .button:first-child {
               margin-right: 20px;
          }

     /* @end */

     /* @group Form contact */

     .popup .form-contact {

     }

          .popup .form-contact .error {
               border-color: red;
          }

     /* @end */

/* @end */

/* @group Product info */

.product-info {
}

	.popup-gallery {
		padding-bottom: 30px !important;
	}

	/* Product info - Image */

	.product-info .product-image {
		margin: 0px 0px 10px 0px;
		border: 1px solid #ddd;
		background: #fff;
		border-radius:         6px;
		-moz-border-radius:    6px;
		-webkit-border-radius: 6px;
		text-align: center;
	}

		.product-info .product-image a {
			display: block;
		}

		.product-info .product-image img {
			display: block;
			cursor: pointer;
			margin: 0px auto;
		}


	/* Product info - thumbnails */

	.product-info .thumbnails {
	}

		.product-info .thumbnails ul {
			list-style: none;
			margin: 0px 0px 0px -20px;
			position: relative;
			padding: 0px;
		}

			.product-info .thumbnails ul li {
			}

				.product-info .thumbnails li p {
					margin: 0px;
					padding: 0px 0px 20px 20px;
				}

				.product-info .thumbnails li img,
				.product-info .thumbnails-carousel img {
				/*	border: 1px solid #ddd; */
					display: block;
					border-radius:         6px;
					-moz-border-radius:    6px;
					-webkit-border-radius: 6px;
					margin: 0px auto;
					padding: 2px;
					background: #fff;
				}

	/* Product info - thumbnails carousel */

	.overflow-thumbnails-carousel .owl-controls {
		display: none;
	}

	.tags_product {
		margin: 0px 0px 20px 0px;
	}

	/* Product center */

	.product-center {
		padding-bottom: 30px;
	}

	.product-center .product-title {
		color: #555;
		    font-size: 2em;
		    font-weight: 600;
		    margin-bottom: 0.3571em;
	}

	.product-center .review {
		padding-bottom: 18px;
		position: relative;
		margin-top: -4px;
	}

		.product-center .review .rating i {
			font-size: 16px;
		}

	.product-center .description {
		padding-bottom: 15px;
		line-height: 1.7;
	}

	.product-center .price {
		padding: 0px;
		margin: 0px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.6;
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 22px;
		color: #777;
	}

		.product-center .price .price-new {
			color: #9ac93d;
			font-weight: 600;
			font-size: 2.5714em;
		}

		.product-center .price .price-old {
			display: inline-block;
			color: #a7a7a7;
			font-size: 2em;
			vertical-align: top;
			font-weight: 600;
			text-decoration: line-through;
			margin-left: 15px;
			margin-top: 6px;
		}


		.product-center .options h2 {
			line-height: 1.4;
			font-weight: 700;
			font-size: 1.1429em;
			color: #313131;
			text-transform: uppercase;
			text-decoration: none;
			margin-bottom: 15px;
		}

	.product-center .add-to-cart {
		border-bottom: 1px solid #ebebeb;
		padding: 30px 0px 30px 0;
	}

		.product-center .add-to-cart p {
			display: none;
		}

		.product-center .add-to-cart .quantity {
			float: left;
			position: relative;
			padding-right: 38px;
		}

			.product-center .add-to-cart .quantity input {
				font-size: 14px;
				line-height: 1.42857;
				color: rgb(85, 85, 85);
				background-color: rgb(255, 255, 255);
				background-image: none;
				box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px inset;
				padding: 6px 12px;
				border-width: 1px;
				border-style: solid;
				border-color: rgb(204, 204, 204);
				border-image: initial;
				border-radius: 4px;
				transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				text-align: center;
				width: 2.5em;
				height: 2.7143em;
				padding-left: 0px;
				padding-right: 0px;
				vertical-align: middle;
				display: inline-block;
				border-radius: 3px !important;
			}

			.product-center .add-to-cart .quantity #q_up {
				display: block;
				position: absolute;
				top: 1px;
				right: 18px;
				text-align: center;
				display: block;
				padding: 0 0 0 1px;
				border-radius: 3px;
				margin-bottom: 5px;
				border: 1px solid #ccc;
				font-size: 9px;
				font-weight: normal;
				width: 15px;
				height: 16px;
				line-height: 14px;
			}

			.product-center .add-to-cart .quantity #q_down {
				display: block;
				position: absolute;
				top: 22px;
				right: 18px;
				text-align: center;
				display: block;
				padding: 0 0 0 1px;
				border-radius: 3px;
				border: 1px solid #ccc;
				font-size: 9px;
				font-weight: normal;
				width: 15px;
				height: 16px;
				line-height: 16px;
			}

			.product-center .add-to-cart .button {
				height: 2.7143em;
				padding: 0 2.1429em;
				text-shadow: none;
				color: #fff;
				border: 1px solid transparent;
				white-space: nowrap;
				font-size: 14px;
				line-height: 1.42857;
				border-radius: 4px;
			}

		.product-center .share {
			padding-top: 25px;
		}

		.product-center .minimum {
			padding-top: 20px;
		}

	.product-center .radio-type-button {
	     display: inline-block;
	     vertical-align: top;
	     width: auto;
	     margin: 10px 7px 5px 0px;
	     padding: 0px;
	}

		.product-center .radio-type-button label {
			margin: 0px;
			padding: 0px;
		}

	     .product-center .radio-type-button input {
	          visibility: hidden;
	          position: absolute;
	     }

	     .product-center .radio-type-button span {
	          display: block;
	          border-radius: 4px;
	          -webkit-border-radius: 4px;
	          -moz-border-radius: 4px;
	          border: 1px solid transparent;
	     }

	          .product-center .radio-type-button span:hover {
	               border: 1px solid #cecece;
	          }

	          .product-center .radio-type-button span.active {
	               border: 1px solid #000;
	          }

	     .product-center .radio-type-button img {
	          display: block;
	          border-radius: 4px;
	          -webkit-border-radius: 4px;
	          -moz-border-radius: 4px;
	          box-shadow: 1px 1px 0 rgba(0, 0, 0, .35);
	          -webkit-box-shadow: 1px 1px 0 rgba(0, 0, 0, .35);
	          -moz-box-shadow: 1px 1px 0 rgba(0, 0, 0, .35);
	     }

	.product-center .radio-type-button2 {
	     display: inline-block;
	     vertical-align: top;
	     width: auto;
	     margin: 10px 7px 5px 0px;
	     padding: 0px;
	}

		.product-center .radio-type-button2 label {
			padding: 0px;
			margin: 0px;
		}

	     .product-center .radio-type-button2 input {
	          visibility: hidden;
	          position: absolute;
	     }

	     .product-center .radio-type-button2 span {
	          background: #fff;
	          border: 1px solid #ccc;
	          border-radius: 4px;
	          -webkit-border-radius: 4px;
	          -moz-border-radius: 4px;
	          height: 30px;
	          min-width: 30px;
	          display: block;
	          text-align: center;
	          padding: 4px 7px;
	     }

	          .product-center .radio-type-button2 span:hover {
	               background: #9ac93d;
	               color: #fff;
	               border-color: #9ac93d;
	          }

	          .product-center .radio-type-button2 span.active {
	              background: #9ac93d;
	              color: #fff;
	              border-color: #9ac93d;
	          }

	.prev-next-products {
	    float: right;
	    padding: 0px;
	    margin: 0px;
	    margin-top: -8px;
	    position: relative;
	}

		.prev-next-products .product-nav {
		    display: inline-block;
		    vertical-align: middle;
		    position: relative;
		}

		.prev-next-products a {
		    color: #555;
		    display: inline-block;
		    font-size: 22px;
		    padding: 0px 7px 0px 8px;
		    line-height: 58px;
		}

		.prev-next-products .product-nav.product-prev .product-pop {
		    right: auto;
		    left: 0;
		    margin-left: -17.5px;
		}

		.prev-next-products .product-nav .product-pop {
		    border-top: 3px solid #08c;
		    position: absolute;
		    left: 0;
		    top: 100%;
		    padding: 10px;
		    background-color: #fff;
		    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
		    z-index: 1;
		    visibility: hidden;
		    opacity: 0;
		    transition: all 0.2s;
		    text-align: center;
		    width: 100px;
		    border-radius: 4px;
		}

			.prev-next-products .product-nav:hover .product-pop {
				visibility: visible;
				opacity: 1;
			}

		.prev-next-products .product-nav .product-pop .product-name {
		    margin: 0;
		    font-weight: 600;
		    font-size: 11px;
		    color: #777;
		}

		.prev-next-products .product-nav .product-pop img {
		    margin-bottom: 10px;
		    display: block;
		}

		.prev-next-products .product-nav.product-next .product-pop {
		    left: auto;
		    right: 0;
		    margin-right: -17.5px;
		}

		.prev-next-products .product-nav .product-pop:before {
		    content: "";
		    width: 5px;
		    height: 2px;
		    border: 5px solid transparent;
		    border-bottom-color: #08c;
		    display: block;
		    position: absolute;
		    top: -13px;
		    left: 0;
		}

			.prev-next-products .product-nav.product-prev .product-pop:before {
			    left: 32px;
			    right: auto;
			}

			.prev-next-products .product-nav.product-next .product-pop:before {
			    right: 32px;
			    left: auto;
			}

	.product-center .button-product-question {
		margin-top: 20px;
		padding-top: 8px !important;
	}

	.product-center .button-enquiry {
		padding-top: 8px !important;
	}

	.product-center .button.review-link {
		padding-top: 8px !important;
	}

	/* @group Ratings */

	.rating i {
		margin: 0px 1px 0px 2px;
		font-size: 14px;
		color: #dedede;
	}

		.rating i.active {
			 color: #ffc600;
		}

		.set-rating i {
			cursor: pointer;
			padding-top: 7px;
			padding-right: 5px;
			margin-right: -6px;
		}

	/* @end */

#mfilter-content-container .product-list {
	position: absolute;
	width: 100%;
	max-height: 100px;
	overflow: hidden;
	opacity: 0;
	z-index: -10;
	margin-bottom: 10px;
}

	#mfilter-content-container .product-list.active {
		position: relative;
		visibility: visible;
		max-height: none;
		overflow: visible;
		opacity: 1;
		z-index: 0;
	}

 #mfilter-content-container .product-grid {
	display: none;
}

	#mfilter-content-container .product-grid.active {
		display: block;
	}

	#mfilter-content-container > span {
		background: none !important;
	}

		#mfilter-content-container > span:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: #fff;
			filter: alpha(opacity=40);
			filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
			opacity: 0.4;
			-moz-opacity: 0.40;
			left: 0;
			top: 0;
		}

		#mfilter-content-container > span > img {
			top: -90px !important;
			position: relative;
			z-index: 20;
		}

/* @group jQuery countdown */

.is-countdown {
	background: rgba(255,255,255,0.90);
	margin-top: -50px;
	left: 0;
	right: 0;
	padding: 0px 10px;
	position: absolute;
}

.countdown-holding span {
	color: #888;
}

.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px;
	text-align: center;
}

.countdown-show1 .countdown-section {
	width: 98%;
}

.countdown-show2 .countdown-section {
	width: 48%;
}

.countdown-show3 .countdown-section {
	width: 32.5%;
}

.countdown-show4 .countdown-section {
	width: 24.5%;
}

.countdown-show5 .countdown-section {
	width: 19.5%;
}

.countdown-show6 .countdown-section {
	width: 16.25%;
}

.countdown-show7 .countdown-section {
	width: 14%;
}

/*
.countdown-section {
	display: block;
	float: left;
	font-size: 75%;
	text-align: center;
}

.countdown-amount {
    font-size: 200%;
}

.countdown-period {
    display: block;
}

.countdown-descr {
	display: block;
	width: 100%;
}
*/
/* @end */

/* @group Pagination */

div.pagination-results {
	clear:both;
	padding:0px 0px 0px 0px;
	margin:0px 0px 10px 0px;
	position: relative;
	overflow:auto;
	font-size: 13px;
}

	div.pagination-results .text-right {
		padding:6px 0px 10px 10px;
	}

	div.pagination-results .text-left {
		padding:0px 10px 0px 0px;
	}

	div.pagination-results ul {
		padding:0px;
		margin: 0px;
		list-style: none;
	}

		div.pagination-results ul li {
			display:inline-block;
			text-align:center;
			margin: 0px 7px 10px 0px;
		}

	@media (max-width: 767px) {
		div.pagination-results .text-right,
		div.pagination-results .text-left {
			text-align: center;
			padding-left: 0;
			padding-right: 0;
		}

		div.pagination-results ul li {
			margin: 0px 4px 10px 4px;
		}
	}

#mfilter-content-container div.pagination-results {
	text-align: center;
}

#mfilter-content-container div.pagination-results .limit {
	display: inline-block;
	vertical-align: top;
	padding-top: 6px;
	padding-bottom: 14px;
}

#mfilter-content-container div.pagination-results .limit select {
	border: 1px solid #ccc;
	background: #fff;
	height: 26px;
	margin: 0px 4px;
	padding: 0px;
}

#mfilter-content-container div.pagination-results .text-left {
	padding: 6px 0px 14px 0px;
	text-align: center;
	width: auto;
	display: inline-block;
	vertical-align: top;
	float: none;
	position: relative;
	margin-right: -7px;
}

#mfilter-content-container div.pagination-results .text-right {
	display: none;
}

/* @end */

/* @group List Box */

.list-box {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

/* @end */

/* @group Error form */

span.error {
	padding: 5px 0px;
	display: block;
	color: red;
}

	.form span.error {
		display: inline-block;
		padding-left: 20px;
	}

	.product-info span.error {
		padding-top: 0px;
	}

/* @end */

/* @group Help */

.help {
	font-size: 10px;
	display: block;
}

/* @end */

/* @group Table form */

table.form {

}

	table.form td {
		padding: 5px 13px 0px 0px;
	}

		table.form td input,
		table.form td select,
		table.form td textarea {
			margin-left: 7px;
		}

/* @end */

/* @group Category page */

img {
	max-width: 100%;
}

.category-info {
	padding: 0px 0px 0px 0px;
	position: relative;
	margin: 0px 0px 0px 0px;
  /*  margin: 0px -20px 0px 0px;*/
}

	.category-info .image {
		padding: 0px 20px 25px 0px;
		float: left;
		width: auto;
		max-width: 100%;
	}

	     .category-info .image img {
	     }

	.category-info > p {
		padding: 2px 20px 25px 0px;
	}

.refine_search {
	margin-top: 7px !important;
	margin-bottom: 20px !important;
	padding: 0px !important;
}

.category-list {

}

.category-list-text-only {
	margin-bottom: 20px;
}

	.category-list .row > div {
		margin-bottom: 26px;
		text-align: center;
	}

		.category-list-text-only .row > div {
			margin-bottom: 6px;
			text-align: left;
		}

		.category-list img {
			display: block;
			margin-bottom: 15px;
			margin-left: auto;
			margin-right: auto;
			border-radius:         6px;
			-moz-border-radius:    6px;
			padding: 2px;
			border: 1px solid #ddd;
			-webkit-border-radius: 6px;
		}

.product-filter {
	padding: 0px;
	margin: 0px 0px 14px 0px;
}

	.product-filter .sort {
		padding: 0px 0px 0px 0px;
		float: left;
		width: auto;
		font-size: 13px;
	}

		.product-filter .sort select {
			margin: 0px 0px 0px 5px;
			width: auto;
			padding: 0px;
			position: relative;
			height: 26px;
			border: 1px solid #ccc;
			background: #fff;
		}

	.product-filter .pagination {
		float: right;
		clear: none;
		margin: 0px 0px -5px 0px;
		max-width: 200px;
	}

	.product-filter .limit {
		padding: 0px 0px 0px 15px;
		float: right;
		width: auto;
		font-size: 13px;
	}

		.product-filter .limit select {
			margin: 0px 4px 0px 5px;
			width: auto;
			padding: 0px;
			position: relative;
			height: 26px;
			border: 1px solid #ccc;
			background: #fff;
		}

	.product-filter .product-compare {
		float: left;
		width: auto;
		font-size: 13px;
		padding: 2px 0px 0px 15px;
	}

	.product-filter .options {
		float: left;
		width: auto;
		padding: 0px 0px 0px 12px;
	}

		.product-filter .options .button-group {
			float: left;
			width: auto;
		}

			.product-filter .options .button-group button {
				border: 1px solid #ccc;
				border-radius: 3px;
				color: #ccc;
				display: inline-block;
				font-family: "Elusive-Icons";
				width: 26px;
				height: 26px;
				line-height: 24px;
				margin-left: 3px;
				text-decoration: none;
				text-align: center;
				-webkit-transition: all 0.25s ease 0s;
				-moz-transition: all 0.25s ease 0s;
				transition: all 0.25s ease 0s;
				background: transparent;
				vertical-align: top;
				padding: 0px;
			}

				.product-filter .options .button-group button:hover,
				.product-filter .options .button-group .active {
					background: #08c;
					color: #fff;
					border-color: #08c;
				}

				#grid:before {
				    content: "\f20b";
				    font-size: 14px;
				}

				#list:before {
				    content: "\f20a";
				    font-size: 14px;
				}

	@media (max-width: 767px) {
		.product-filter .pagination {
			clear: both;
			float: none;
			display: block;
			padding-top: 10px;
			margin-left: -5px;
			max-width: none;
		}

		.product-filter .product-compare {
			display: none;
		}

		.product-filter .limit {
			float: left;
		}
	}

/* @end */

/* @group Box */

.box {
	margin-bottom: 15px;
	background: #fbfbfb;
	border: 1px solid #ddd;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}

	.box .box-heading {
		color: #777;
		background: #f5f5f5;
		border-bottom: 1px solid #ddd;
		font-size: 0.9286em;
		font-weight: 700;
		line-height: 1.4;
		padding: 0.7143em 1.0714em;
		margin: 0;
		text-transform: uppercase;
		position: relative;
		-webkit-transition: all 0.25s ease 0s;
		-moz-transition: all 0.25s ease 0s;
		transition: all 0.25s ease 0s;
		border-radius: 6px 6px 0 0;
		-webkit-border-radius: 6px 6px 0 0;
		-moz-border-radius: 6px 6px 0 0;
	}

		.box .box-heading .toggle {
			color: #ccc;
			display: inline-block;
			width: 1.8571em;
			height: 1.8571em;
			line-height: 1.7572em;
			background: #fff;
			border: 1px solid #ccc;
			border-radius: 3px;
			position: absolute;
			right: 1.0714em;
			top: 1px;
			bottom: 0;
			margin: auto;
			font-family: "FontAwesome";
			text-align: center;
			-webkit-transition: all 0.25s ease 0s;
			transition: all 0.25s ease 0s;
			z-index: 10;
			cursor: pointer;
		}

			.box .box-heading .toggle:after {
				content: "\f0d8";
			}

				.box .box-heading .toggle.collapsed:after {
					content: "\f0d7";
				}

			.box .box-heading .toggle:hover {
				background: #08c;
				border-color: #08c;
				color: #fff;
			}

	.box .box-content {
		padding: 13px;
	}

	.box-category ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
		line-height: 2.6;
		font-size: 0.9286em;
	}

		.box-category ul ul {
			padding-left: 13px;
		}

		.box-category ul li .head a {
		}

			.box-category ul li .head .plus {
				display: none;
			}

				.box-category ul li .head .collapsed .plus {
					display: block;
				}

				.box-category ul li .head .collapsed .minus {
					display: none;
				}

	.box-category .panel {
		padding: 0px;
		margin: 0px;
		background: none;
		border: none;
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}

		.box .box-category {
			padding: 9px 13px;
		}

			.box .box-category .active {
				color: #08c;
			}

/* @end */

/* @end */

/* @group Product list */

.product-list .item {
    width: 100%;
    padding: 0px 0px 20px 0px;
}

	.product-list .product-image-area {
	    width: 20%;
	    float: left;
	}
	.product-image-area {
	    position: relative;
	    overflow: hidden;
	    z-index: 0;
	}

	.product-image-area a.product-image {
	    overflow: hidden;
	}

	.product-list .details-area {
	    width: 80%;
	    float: left;
	    padding: 0 20px;
	}

	.product-name {
	    margin: 10px 0;
	    font-size: 1em;
	    font-weight: normal;
	}

	.product-list .details-area .product-name {
	    font-size: 18px;
	    line-height: 1;
	}

	.product-list .details-area .short-desc {
	    font-size: 14px;
	    line-height: 1.5;
	    color: #777;
	}

	.product-list .details-area .action-area {
	    margin-top: 15px;
	}

	.product-list .rating-wrap {
		margin: 0px;
		display: block;
		height: 22px;
		margin-left: -7px;
		margin-top: 3px;
	}

.product-list .product-image {
	margin: 0px;
}

.product-list .product-image .product-image-hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

	.product-list .product-image:hover .product-image-hover {
		opacity: 1;
	}

.product-list .product-btn.btn-wishlist,
.product-list .product-btn.btn-quickview {
	opacity: 1;
	visibility: visible;
	width: 32px;
	height: 32px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	margin: 0px 1px;
	padding: 0px;
	left: auto;
	transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	font-size: 16px;
	line-height: 30px;
	vertical-align: top;
}

.product-list .btn-add-cart {
	font-size: 14px;
	padding: 0 10px 0 7px;
	display: inline-block;
	height: 32px;
	line-height: 30px;
	border-radius: 5px;
	border: 1px solid #ccc;
	margin: 0px 1px;
	vertical-align: top;
/*	border-color: #08c;
	    color: #fff;
	    background-color: #08c;    */

	background-color: #9ac93d;
	border-color: #9ac93d;
	color:#fff;
}


a.quickviewlink.quickview-icon {
	position: static;
	padding: 0;
	visibility: visible;
	opacity: 1;
	color: #08c;
	background-color: transparent;
	font-size: 17px;
	display: inline-block;
	height: 32px;
	width: 32px;
	line-height: 30px;
	border: 1px solid #08c;
	border-radius: 5px;
	text-align: center;
	margin: 0px 1px;
}

	a.quickviewlink.quickview-icon:hover {
		background: #08c;
		color: #fff;
	}

.product-list .quickview {
	display: inline-block;
	vertical-align: top;
}

/* @end */

/* @group Category banner */

.category-banner {
    background-color: transparent;
}

	.full-width-image-banner {
	    height: 375px;
	    position: relative;
	}

		.full-width-image-banner .content {
		    margin-top: -36px;
		}

		.full-width-image-banner h2 {
		    margin: 0;
		    font-size: 45px;
		    line-height: 1.1;
		}

		.full-width-image-banner p {
		    margin: 0;
		    font-size: 16px;
		}

	.full-width-image-banner:after {
	    content: "";
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    background-color: #393733;
	    opacity: 0.65;
	    filter: alpha(opacity=65);
	}

	@media (max-width: 640px) {
		.full-width-image-banner {
		    height: 200px;
		}

		.full-width-image-banner p {
			display: none;
		}

		.full-width-image-banner .content {
		    margin-top: -16px;
		}

		.full-width-image-banner h2 {
		    margin: 0;
		    font-size: 30px;
		    line-height: 1.1;
		}
	}

/* @end */

/* @group Popup */

.mfp-bg {
	background: url(../images/fancybox_overlay.png) !important;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 1 !important;
}

/* @end */

/* @group Shopping cart */

.checkout-cart .center-column h2 {
    padding: 20px 0px 14px 0px;
    margin: 0px;
    font-weight: 700;
    color: #313131;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1;
}

/* @end */

/* @group Revolution slider */

/*--------------------------------------
-	Revolution Slider 4.1 Captions -
author:		themepunch
email:     	info@themepunch.com
website:   	http://www.themepunch.com

/-------------------------------------
------ Used for index.html slider
----------------------------------------*/

@media (min-width: 1200px) {
	#revslider-container {
		height:376;
	}
}

#revslider-container {
	width:100%;
	position:relative;
	padding:0;
	overflow:hidden;
	background: #e6e6e3;
	margin-bottom: 20px;
}

#revslider {
	width:100%;
	position:relative;

}

#revslider  >ul >li{
	list-style:none;
	position:absolute;
	visibility:hidden;
}


/*************************
	-	CAPTIONS	-
**************************/

.tp-static-layers	{
	position:absolute;
	z-index:505;
	top:0px;
	left:0px;
}

.tp-hide-revslider,.tp-caption.tp-hidden-caption	{
	visibility:hidden !important;
	display:none !important;
}


.tp-caption {
	z-index:1;
	white-space:nowrap;
}

.tp-caption-demo .tp-caption	{
	position:relative !important;
	display:inline-block;
	margin-bottom:10px;
	margin-right:20px !important;
}

/*Custom*/
.tp-caption.rev-title,
.tp-caption.rev-subtitle,
.tp-caption.rev-stext,
.tp-caption.rev-price {
	position:absolute;
	font-family: "Open Sans", Verdana, sans-serif;
	font-weight: 700;
	color:#fff;
	line-height: normal;
	white-space:nowrap;
}

.tp-caption.rev-title {
	font-weight: 800;
	font-size: 63px;
}

.tp-caption.rev-title.small {
	font-weight: 600;
	font-size:60px;
	color:#08c;
}

.tp-caption.rev-title.small span {
	font-weight: 800;
}

.tp-caption.rev-subtitle {
    font-weight: 300;
    font-size: 24px;
    text-align: center;
}

.tp-caption.rev-subtitle.dark {
	color:#777;
}

.tp-caption.rev-subtitle.dark > span {
	color:#535353;
}

.tp-caption.rev-subtitle > span {
	font-weight: 400;
}

.tp-caption.rev-subtitle.large {
	font-size:33px;
	color:#fff;
	font-weight: 300;
}

.tp-caption.rev-subtitle.subtitle2 {
	font-size:22px;
	font-weight: 300;
}

.tp-caption.rev-subtitle.upper {
	font-size:33px;
	text-transform: uppercase;
	font-weight: 700;
	color:#fff;
}

.tp-caption.rev-text {
	font-weight: 300;
    font-size: 20px;
    color:#fff;
    text-align: center;
}

.tp-caption.rev-text.small {
	font-size:13px;
	font-weight: 300;
	color:#000;
}

.tp-caption.rev-text.small  strong {
	font-weight: bold;
}

.tp-caption.rev-stext {
	font-size:10px;
	color:#777;
	line-height: normal;
	font-weight: 300;
}

.tp-caption.rev-price {
	font-size:26px;
	color:#000;
	line-height: normal;
	font-weight: 800;
}

.tp-caption.rev-btn .slide-btn {
	display:inline-block;
	font-size:17px;
	line-height: 20px;
	padding:2px 25px;
	font-weight: 300;
	color:#08c;
	background-color: transparent;
	border-radius: 0;
}

.tp-caption.rev-btn .slide-btn.light {
	color:#fff;
	font-size:10px;
	padding:9px;
	font-weight: normal;
	border-left:1px solid #fff;
}

.tp-caption.rev-btn .slide-btn.light:hover {
	color:#fff;
}

.tp-caption.rev-btn .slide-btn:hover {
	background-color: transparent;
	color:#08c;
}

/* media queries to overwrite btn padding/font-sizes/widths*/
@media (max-width: 991px) {

	.tp-caption.rev-btn .slide-btn {
		font-size:11px;
		line-height: 18px;
		padding:5px 15px;
	}
}

@media (max-width: 768px) {
	.tp-caption.rev-btn .slide-btn {
		font-size:8px;
		line-height: 16px;
		padding:2px 10px;
		border-radius: 3px;
	}
}

@media (max-width: 480px) {
	.tp-caption.rev-btn .slide-btn {
		font-size:6px;
		line-height: 12px;
		padding:2px 8px;
		border-radius: 2px;
	}


}


.tp_inner_padding	{
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	max-height:none !important;
}


/******************************
	-	BUTTONS	-
*******************************/



@media only screen and (min-width: 768px) and (max-width: 959px) {

	.tp-caption.rev-btn .btn-lg,
	.tp-caption.rev-btn .btn-group-lg > .btn {
		padding:7px 15px;
		font-size:14px;
		line-height:1.5;
	}

	.tp-caption.rev-btn .btn.min-width {
		min-width:130px;
	}
 }



@media only screen and (min-width: 480px) and (max-width: 767px) {
	.tp-caption.rev-btn .btn-lg,
	.tp-caption.rev-btn .btn-group-lg > .btn {
		padding:5px 12px;
		font-size:12px;
		line-height:1.5;
	}

	.tp-caption.rev-btn .btn.min-width {
		min-width:100px;
	}

}

@media only screen and (min-width: 0px) and (max-width: 479px) {
	.tp-caption.rev-btn .btn-lg,
	.tp-caption.rev-btn .btn-group-lg > .btn {
		padding:4px 10px;
		font-size:9px;
		line-height:1.5;
	}

	.tp-caption.rev-btn .btn.min-width {
		min-width:85px;
	}
}





/****************************************************************

	-	SET THE ANIMATION EVEN MORE SMOOTHER ON ANDROID   -

******************************************************************/

/*
.tp-simpleresponsive	{
	-webkit-perspective: 1500px;
	-moz-perspective: 1500px;
	-o-perspective: 1500px;
	-ms-perspective: 1500px;
	perspective: 1500px;
}
*/


/**********************************************
	-	FULLSCREEN AND FULLWIDHT CONTAINERS	-
**********************************************/

.fullscreen-container {
		width:100%;
		position:relative;
		padding:0;
}


.fullwidthbanner-container{
	width:100%;
	position:relative;
	padding:0;
	overflow:hidden;
}

.fullwidthbanner-container .fullwidthbanner{
	width:100%;
	position:relative;
}



/************************************************
	  - SOME CAPTION MODIFICATION AT START  -
*************************************************/
.tp-simpleresponsive .caption,
.tp-simpleresponsive .tp-caption {
	/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";		-moz-opacity: 0;	-khtml-opacity: 0;	opacity: 0; */
	position:absolute;visibility: hidden;
	-webkit-font-smoothing: antialiased !important;
}


.tp-simpleresponsive img	{
	max-width:none;
}



/******************************
	-	IE8 HACKS	-
*******************************/
.noFilterClass {
	filter:none !important;
}


/******************************
	-	SHADOWS		-
******************************/
.tp-bannershadow  {
		position:absolute;
		margin-left:auto;
		margin-right:auto;
		-moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
	}

.tp-bannershadow.tp-shadow1 {
	background:url(../images/revslider/shadow1.png) no-repeat;
	background-size:100% 100%; width:890px; height:60px; bottom:-60px;
}

.tp-bannershadow.tp-shadow2 {
	background:url(../images/revslider/shadow2.png) no-repeat;
	background-size:100% 100%; width:890px; height:60px;bottom:-60px;
}

.tp-bannershadow.tp-shadow3 {	background:url(../images/revslider/shadow3.png) no-repeat;
	background-size:100% 100%; width:890px; height:60px;bottom:-60px;
}


/********************************
	-	FULLSCREEN VIDEO	-
*********************************/
.caption.fullscreenvideo {	left:0px; top:0px; position:absolute;width:100%;height:100%;}
.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video	{ width:100% !important; height:100% !important; display: none}

.tp-caption.fullscreenvideo	{	left:0px; top:0px; position:absolute;width:100%;height:100%;}


.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe video{ width:100% !important; height:100% !important; display: none;}


.fullcoveredvideo video,
.fullscreenvideo video	{	background: #000;}

.fullcoveredvideo .tp-poster {	background-position: center center;
	background-size: cover;width:100%;height:100%;top:0px;left:0px;
}

.html5vid.videoisplaying .tp-poster	{	display: none}

.tp-video-play-button	{
	background:#000;
	background:rgba(0,0,0,0.3);
	padding:5px;
	border-radius:5px;-moz-border-radius:5px;-webkit-border-radius:5px;
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 40px;
	color: #FFF;
	z-index: 3;
	margin-top: -27px;
	margin-left: -28px;
	text-align: center;
	cursor: pointer;
}

.html5vid .tp-revstop	{	width:6px;height:20px;
	border-left:5px solid #fff; border-right:5px solid #fff;
	 position: relative;margin:10px 20px;
}

.html5vid .tp-revstop	{
	display:none;
}
.html5vid.videoisplaying .revicon-right-dir	{ display:none }
.html5vid.videoisplaying .tp-revstop	{	display:block}

.html5vid.videoisplaying .tp-video-play-button	{	display:none}
.html5vid:hover .tp-video-play-button { display:block}

.fullcoveredvideo .tp-video-play-button	{	display:none !important}


/********************************
	-	FULLSCREEN VIDEO ENDS	-
*********************************/


/********************************
	-	DOTTED OVERLAYS	-
*********************************/
.tp-dottedoverlay {	background-repeat:repeat;width:100%;height:100%;
	position:absolute;top:0px;left:0px;z-index:1;
}

.tp-dottedoverlay.twoxtwo				{	background:url(../images/revslider/gridtile.png)}
.tp-dottedoverlay.twoxtwowhite			{	background:url(../images/revslider/gridtile_white.png)}
.tp-dottedoverlay.threexthree			{	background:url(../images/revslider/gridtile_3x3.png)}
.tp-dottedoverlay.threexthreewhite		{	background:url(../images/revslider/gridtile_3x3_white.png)}
/********************************
	-	DOTTED OVERLAYS ENDS	-
*********************************/


/************************
	-	NAVIGATION	-
*************************/

/** BULLETS **/

.tpclear		{	clear:both;}


.tp-bullets									{	z-index:1000; position:absolute;
												-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
												-moz-opacity: 1;
												-khtml-opacity: 1;
												opacity: 1;
												-webkit-transition: opacity 0.2s ease-out; -moz-transition: opacity 0.2s ease-out; -o-transition: opacity 0.2s ease-out; -ms-transition: opacity 0.2s ease-out;-webkit-transform: translateZ(5px);
											}
.tp-bullets.hidebullets					{
												-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
												-moz-opacity: 0;
												-khtml-opacity: 0;
												opacity: 0;
											}


.tp-bullets.simplebullets.navbar						{ 	border:1px solid #666; border-bottom:1px solid #444; background:url(../images/revslider/boxed_bgtile.png); height:40px; padding:0px 10px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px ;}

.tp-bullets.simplebullets.navbar-old					{ 	 background:url(../images/revslider/navigdots_bgtile.png); height:35px; padding:0px 10px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px ;}


.tp-bullets.simplebullets.round .bullet					{	cursor:pointer; position:relative;	background:url(../images/revslider/bullet.png) no-Repeat top left;	width:20px;	height:20px;  margin-right:0px; float:left; margin-top:0px; margin-left:3px;}
.tp-bullets.simplebullets.round .bullet.last			{	margin-right:3px;}

.tp-bullets.simplebullets.round-old .bullet				{	cursor:pointer; position:relative;	background:url(../images/revslider/bullets.png) no-Repeat bottom left;	width:23px;	height:23px;  margin-right:0px; float:left; margin-top:0px;}
.tp-bullets.simplebullets.round-old .bullet.last		{	margin-right:0px;}


/**	SQUARE BULLETS **/
.tp-bullets.simplebullets.square .bullet				{	cursor:pointer; position:relative;	background:url(../images/revslider/bullets2.png) no-Repeat bottom left;	width:19px;	height:19px;  margin-right:0px; float:left; margin-top:0px;}
.tp-bullets.simplebullets.square .bullet.last			{	margin-right:0px;}


/**	SQUARE BULLETS **/
.tp-bullets.simplebullets.square-old .bullet			{	cursor:pointer; position:relative;	background:url(../images/revslider/bullets2.png) no-Repeat bottom left;	width:19px;	height:19px;  margin-right:0px; float:left; margin-top:0px;}
.tp-bullets.simplebullets.square-old .bullet.last		{	margin-right:0px;}


/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar .bullet			{	cursor:pointer; position:relative;	background:url(../images/revslider/bullet_boxed.png) no-Repeat top left;	width:18px;	height:19px;   margin-right:5px; float:left; margin-top:0px;}

.tp-bullets.simplebullets.navbar .bullet.first		{	margin-left:0px !important;}
.tp-bullets.simplebullets.navbar .bullet.last		{	margin-right:0px !important;}



/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar-old .bullet			{	cursor:pointer; position:relative;	background:url(../images/revslider/navigdots.png) no-Repeat bottom left;	width:15px;	height:15px;  margin-left:5px !important; margin-right:5px !important;float:left; margin-top:10px;}
.tp-bullets.simplebullets.navbar-old .bullet.first		{	margin-left:0px !important;}
.tp-bullets.simplebullets.navbar-old .bullet.last		{	margin-right:0px !important;}


.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected				{	background-position:top left; }

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected		{	background-position:bottom left; }



/*************************************
	-	TP ARROWS 	-
**************************************/
.tparrows												{	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
															-moz-opacity: 1;
															-khtml-opacity: 1;
															opacity: 1;
															-webkit-transition: opacity 0.2s ease-out; -moz-transition: opacity 0.2s ease-out; -o-transition: opacity 0.2s ease-out; -ms-transition: opacity 0.2s ease-out;
															-webkit-transform: translateZ(5000px);
															-webkit-transform-style: flat;
															-webkit-backface-visibility: hidden;
															z-index:600;
															position: relative;

														}
.tparrows.hidearrows									{
															-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
															-moz-opacity: 0;
															-khtml-opacity: 0;
															opacity: 0;
														}
.tp-leftarrow											{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/large_left.png) no-Repeat top left;	width:40px;	height:40px;   }
.tp-rightarrow											{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/large_right.png) no-Repeat top left;	width:40px;	height:40px;   }


.tp-leftarrow.round										{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/small_left.png) no-Repeat top left;	width:19px;	height:14px;  margin-right:0px; float:left; margin-top:0px;	}
.tp-rightarrow.round									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/small_right.png) no-Repeat top left;	width:19px;	height:14px;  margin-right:0px; float:left;	margin-top:0px;}


.tp-leftarrow.round-old									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_left.png) no-Repeat top left;	width:26px;	height:26px;  margin-right:0px; float:left; margin-top:0px;	}
.tp-rightarrow.round-old								{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_right.png) no-Repeat top left;	width:26px;	height:26px;  margin-right:0px; float:left;	margin-top:0px;}


.tp-leftarrow.navbar									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/small_left_boxed.png) no-Repeat top left;	width:20px;	height:15px;   float:left;	margin-right:6px; margin-top:12px;}
.tp-rightarrow.navbar									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/small_right_boxed.png) no-Repeat top left;	width:20px;	height:15px;   float:left;	margin-left:6px; margin-top:12px;}


.tp-leftarrow.navbar-old								{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrowleft.png) no-Repeat top left;		width:9px;	height:16px;   float:left;	margin-right:6px; margin-top:10px;}
.tp-rightarrow.navbar-old								{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrowright.png) no-Repeat top left;	width:9px;	height:16px;   float:left;	margin-left:6px; margin-top:10px;}

.tp-leftarrow.navbar-old.thumbswitharrow				{	margin-right:10px; }
.tp-rightarrow.navbar-old.thumbswitharrow				{	margin-left:0px; }

.tp-leftarrow.square									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_left2.png) no-Repeat top left;	width:12px;	height:17px;   float:left;	margin-right:0px; margin-top:0px;}
.tp-rightarrow.square									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_right2.png) no-Repeat top left;	width:12px;	height:17px;   float:left;	margin-left:0px; margin-top:0px;}


.tp-leftarrow.square-old								{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_left2.png) no-Repeat top left;	width:12px;	height:17px;   float:left;	margin-right:0px; margin-top:0px;}
.tp-rightarrow.square-old								{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/arrow_right2.png) no-Repeat top left;	width:12px;	height:17px;   float:left;	margin-left:0px; margin-top:0px;}


.tp-leftarrow.default									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/large_left.png) no-Repeat 0 0;	width:40px;	height:40px;

														}
.tp-rightarrow.default									{	z-index:100;cursor:pointer; position:relative;	background:url(../images/revslider/large_right.png) no-Repeat 0 0;	width:40px;	height:40px;

														}




.tp-leftarrow:hover,
.tp-rightarrow:hover 									{	background-position:bottom left; }






/****************************************************************************************************
	-	TP THUMBS 	-
*****************************************************************************************************

 - tp-thumbs & tp-mask Width is the width of the basic Thumb Container (500px basic settings)

 - .bullet width & height is the dimension of a simple Thumbnail (basic 100px x 50px)

 *****************************************************************************************************/


.tp-bullets.tp-thumbs						{	z-index:1000; position:absolute; padding:3px;background-color:#fff;
												width:500px;height:50px; 			/* THE DIMENSIONS OF THE THUMB CONTAINER */
												margin-top:-50px;
											}


.fullwidthbanner-container .tp-thumbs		{  padding:3px;}

.tp-bullets.tp-thumbs .tp-mask				{	width:500px; height:50px;  			/* THE DIMENSIONS OF THE THUMB CONTAINER */
												overflow:hidden; position:relative;}


.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer	{	width:5000px; position:absolute;}

.tp-bullets.tp-thumbs .bullet				{   width:100px; height:50px; 			/* THE DIMENSION OF A SINGLE THUMB */
												cursor:pointer; overflow:hidden;background:none;margin:0;float:left;
												-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
												/*filter: alpha(opacity=50);	*/
												-moz-opacity: 0.5;
												-khtml-opacity: 0.5;
												opacity: 0.5;

												-webkit-transition: all 0.2s ease-out; -moz-transition: all 0.2s ease-out; -o-transition: all 0.2s ease-out; -ms-transition: all 0.2s ease-out;
											}


.tp-bullets.tp-thumbs .bullet:hover,
.tp-bullets.tp-thumbs .bullet.selected		{ 	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

												-moz-opacity: 1;
												-khtml-opacity: 1;
												opacity: 1;
											}
.tp-thumbs img								{	width:100%; }


/************************************
		-	TP BANNER TIMER		-
*************************************/
.tp-bannertimer								{	width:100%; height:7px; background:rgba(203, 198, 278, 0.6);position:absolute; z-index:200;top:0px;}
.tp-bannertimer.tp-bottom					{	bottom:0px;height:7px; top:auto;}




/***************************************
	-	RESPONSIVE SETTINGS 	-
****************************************/




    @media only screen and (min-width: 0px) and (max-width: 479px) {
				.responsive .tp-bullets	{	display:none}
				.responsive .tparrows	{	display:none;}
	}





/*********************************************

	-	BASIC SETTINGS FOR THE BANNER	-

***********************************************/

 .tp-simpleresponsive img {
		-moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}



.tp-simpleresponsive a{	text-decoration:none;}

.tp-simpleresponsive ul {
	list-style:none;
	padding:0;
	margin:0;
}

.tp-simpleresponsive >ul >li{
	list-stye:none;
	position:absolute;
	visibility:hidden;
}
/*  CAPTION SLIDELINK   **/
.caption.slidelink a div,
.tp-caption.slidelink a div {	width:3000px; height:1500px;  background:url(../images/revslider/coloredbg.png) repeat;}

.tp-caption.slidelink a span	{	background:url(../images/revslider/coloredbg.png) repeat;}



/*****************************************
	-	NAVIGATION FANCY EXAMPLES	-
*****************************************/

.tparrows .tp-arr-imgholder								{ display: none}
.tparrows .tp-arr-titleholder							{ display: none}



/*****************************************
	-	NAVIGATION FANCY EXAMPLES	-
*****************************************/

/* NAVIGATION PREVIEW 1 */
.tparrows.preview1 							{	width:100px;height:100px;-webkit-transform-style: preserve-3d; -webkit-perspective: 1000; -moz-perspective: 1000; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden;background: transparent;}
.tparrows.preview1:after					{	position:absolute; left:0px;top:0px; font-family: "revicons"; color:#fff; font-size:30px; width:100px;height:100px;text-align: center; background:#fff;background:rgba(0,0,0,0.15);z-index:2;line-height:100px; -webkit-transition: background 0.3s, color 0.3s; -moz-transition: background 0.3s, color 0.3s; transition: background 0.3s, color 0.3s;}
.tp-rightarrow.preview1:after				{	content: '\e825';  }
.tp-leftarrow.preview1:after				{	content: '\e824';  }

.tparrows.preview1:hover:after 				{	background:rgba(255,255,255,1); color:#aaa; }

.tparrows.preview1 .tp-arr-imgholder 		{	background-size:cover; background-position:center center; display:block;width:100%;height:100%;position:absolute;top:0px;
												-webkit-transition: -webkit-transform 0.3s;
												transition: transform 0.3s;
												-webkit-backface-visibility: hidden;
												backface-visibility: hidden;
											}
/*.tparrows.preview1 .tp-arr-iwrapper			{	  -webkit-transition: all 0.3s;transition: all 0.3s;
												-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter: alpha(opacity=0);-moz-opacity: 0.0;-khtml-opacity: 0.0;opacity: 0.0;}
.tparrows.preview1:hover .tp-arr-iwrapper	{	  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter: alpha(opacity=100);-moz-opacity: 1;-khtml-opacity: 1;opacity: 1;}*/


.tp-rightarrow.preview1 .tp-arr-imgholder	{	right:100%;
												-webkit-transform: rotateY(-90deg);
												transform: rotateY(-90deg);
												-webkit-transform-origin: 100% 50%;
												transform-origin: 100% 50%;


											}
.tp-leftarrow.preview1 .tp-arr-imgholder	{	left:100%;
												-webkit-transform: rotateY(90deg);
												transform: rotateY(-90deg);
												-webkit-transform-origin: 0% 50%;
												transform-origin: 0% 50%;


											}


.tparrows.preview1:hover .tp-arr-imgholder	{	-webkit-transform: rotateY(0deg);
												transform: rotateY(0deg);
											}


	@media only screen and (min-width: 768px) and (max-width: 979px) {
		.tparrows.preview1,
		.tparrows.preview1:after	{	width:80px; height:80px;line-height:80px; font-size:24px;}

	}

    @media only screen and (min-width: 480px) and (max-width: 767px) {
		.tparrows.preview1,
		.tparrows.preview1:after	{	width:60px; height:60px;line-height:60px;font-size:20px;}

	}



    @media only screen and (min-width: 0px) and (max-width: 479px) {
		.tparrows.preview1,
		.tparrows.preview1:after	{	width:40px; height:40px;line-height:40px; font-size:12px;}
    }

/* PREVIEW 1 BULLETS */

.tp-bullets.preview1 						{ 	height: 21px; }
.tp-bullets.preview1 .bullet 				{	cursor: pointer;
											    position: relative !important;
											    background: rgba(0, 0, 0, 0.15) !important;
											    /*-webkit-border-radius: 10px;
											    border-radius: 10px;*/
											    -webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
											    width: 5px !important;
											    height: 5px !important;
											    border: 8px solid rgba(0, 0, 0, 0) !important;
											    display: inline-block;
											    margin-right: 5px !important;
											    margin-bottom: 0px !important;
											    -webkit-transition: background-color 0.2s, border-color 0.2s;
											    -moz-transition: background-color 0.2s, border-color 0.2s;
											    -o-transition: background-color 0.2s, border-color 0.2s;
											    -ms-transition: background-color 0.2s, border-color 0.2s;
											    transition: background-color 0.2s, border-color 0.2s;
											    float:none !important;
											    box-sizing:content-box;
												-moz-box-sizing:content-box;
												-webkit-box-sizing:content-box;
}
.tp-bullets.preview1 .bullet.last 			{	margin-right: 0px; }
.tp-bullets.preview1 .bullet:hover,
.tp-bullets.preview1 .bullet.selected 		{	-webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
												background: #aaa !important;
												width: 5px !important;
											    height: 5px !important;
											    border: 8px solid rgba(255, 255, 255, 1) !important;
}




/* NAVIGATION PREVIEW 2 */
.tparrows.preview2 							{	min-width:60px; min-height:60px; background:#fff; ;

												border-radius:30px;-moz-border-radius:30px;-webkit-border-radius:30px;
												overflow:hidden;
												-webkit-transition: -webkit-transform 1.3s;
												-webkit-transition: width 0.3s, background-color 0.3s, opacity 0.3s;
												transition: width 0.3s, background-color 0.3s, opacity 0.3s;
												backface-visibility: hidden;
}
.tparrows.preview2:after					{	position:absolute; top:50%; font-family: "revicons"; color:#aaa; font-size:25px; margin-top: -18px; -webkit-transition: color 0.3s; -moz-transition: color 0.3s; transition: color 0.3s; }
.tp-rightarrow.preview2:after				{	content: '\e81e';  right:18px; }
.tp-leftarrow.preview2:after				{	content: '\e81f';  left:18px; }


.tparrows.preview2 .tp-arr-titleholder 		{	background-size:cover; background-position:center center; display:block;width:auto;position:absolute;top:0px;
												-webkit-transition: -webkit-transform 0.3s;
												transition: transform 0.3s;
												-webkit-backface-visibility: hidden;
												backface-visibility: hidden;
												white-space: nowrap;
												color: #000;
												text-transform: uppercase;
												white-space: nowrap;
												letter-spacing: 1px;
												font-weight: 400;
												font-size: 14px;
												line-height: 60px;
												padding:0px 10px;
												box-sizing:border-box;
												-moz-box-sizing:border-box;
												-webkit-box-sizing:border-box;
											}

.tp-rightarrow.preview2 .tp-arr-titleholder	{	 right:50px;
												-webkit-transform: translateX(-100%);
												transform: translateX(-100%);
											}
.tp-leftarrow.preview2 .tp-arr-titleholder	{	left:50px;
												-webkit-transform: translateX(100%);
												transform: translateX(100%);
											}

.tparrows.preview2.hovered					{	width:300px}
.tparrows.preview2:hover					{	background:#fff;}
.tparrows.preview2:hover:after				{	color:#000}
.tparrows.preview2:hover .tp-arr-titleholder{	-webkit-transform: translateX(0px);
													transform: translateX(0px); }

/* PREVIEW 2 BULLETS */

.tp-bullets.preview2 						{ 	height: 17px; }
.tp-bullets.preview2 .bullet 				{	cursor: pointer;
											    position: relative !important;
											    background: rgba(0, 0, 0, 0.5) !important;
											    -webkit-border-radius: 10px;
											    border-radius: 10px;
											    -webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
											    width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 0) !important;
											    display: inline-block;
											    margin-right: 2px !important;
											    margin-bottom: 0px !important;
											    -webkit-transition: background-color 0.2s, border-color 0.2s;
											    -moz-transition: background-color 0.2s, border-color 0.2s;
											    -o-transition: background-color 0.2s, border-color 0.2s;
											    -ms-transition: background-color 0.2s, border-color 0.2s;
											    transition: background-color 0.2s, border-color 0.2s;
											    float:none !important;
											    box-sizing:content-box;
												-moz-box-sizing:content-box;
												-webkit-box-sizing:content-box;
}
.tp-bullets.preview2 .bullet.last 			{	margin-right: 0px; }
.tp-bullets.preview2 .bullet:hover,
.tp-bullets.preview2 .bullet.selected 		{	-webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
												background: rgba(255, 255, 255, 1) !important;
												width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 1) !important;
}



	@media only screen and (min-width: 768px) and (max-width: 979px) {
		.tparrows.preview2 {	min-width:40px; min-height:40px; width:40px;height:40px;
								border-radius:20px;-moz-border-radius:20px;-webkit-border-radius:20px;
							}
		.tparrows.preview2:after					{	position:absolute; top:50%; font-family: "revicons"; font-size:20px; margin-top: -12px;}
		.tp-rightarrow.preview2:after				{	content: '\e81e';  right:11px;}
		.tp-leftarrow.preview2:after				{	content: '\e81f';  left:11px;}
		.tparrows.preview2 .tp-arr-titleholder		{	font-size:12px; line-height:40px; letter-spacing: 0px;}
		.tp-rightarrow.preview2 .tp-arr-titleholder	{	right:35px;}
		.tp-leftarrow.preview2 .tp-arr-titleholder	{	left:35px;}
	}

    @media only screen and (min-width: 480px) and (max-width: 767px) {
   		 .tparrows.preview2 						{	min-width:30px; min-height:30px; width:30px;height:30px;
														border-radius:15px;-moz-border-radius:15px;-webkit-border-radius:15px;
													}
		.tparrows.preview2:after					{	position:absolute; top:50%; font-family: "revicons"; font-size:14px; margin-top: -12px;}
		.tp-rightarrow.preview2:after				{	content: '\e81e';  right:8px;}
		.tp-leftarrow.preview2:after				{	content: '\e81f';  left:8px;}
		.tparrows.preview2 .tp-arr-titleholder		{	font-size:10px; line-height:30px; letter-spacing: 0px;}
		.tp-rightarrow.preview2 .tp-arr-titleholder	{	right:25px;}
		.tp-leftarrow.preview2 .tp-arr-titleholder	{	left:25px;}


	}

    @media only screen and (min-width: 0px) and (max-width: 479px) {
		.tparrows.preview2 							{	min-width:30px; min-height:30px; width:30px;height:30px;
														border-radius:15px;-moz-border-radius:15px;-webkit-border-radius:15px;
													}
		.tparrows.preview2:after					{	position:absolute; top:50%; font-family: "revicons"; font-size:14px; margin-top: -12px;}
		.tp-rightarrow.preview2:after				{	content: '\e81e';  right:8px;}
		.tp-leftarrow.preview2:after				{	content: '\e81f';  left:8px;}
		.tparrows.preview2 .tp-arr-titleholder		{	display:none;visibility:none;}
		.tparrows.preview2:hover					{	width:30px !important; height:30px !important}
    }



/* NAVIGATION PREVIEW 3 */
.tparrows.preview3 							{	width:70px; height:70px; background:#fff; background:rgba(255,255,255,1); -webkit-transform-style: flat; }
.tparrows.preview3:after					{	position:absolute;  line-height: 70px;text-align: center; font-family: "revicons"; color:#aaa; font-size:30px; top:0px;left:0px;;background:#fff; z-index:100; width:70px;height:70px; -webkit-transition: color 0.3s; -moz-transition: color 0.3s; transition: color 0.3s;}
.tparrows.preview3:hover:after					{	color:#000; }
.tp-rightarrow.preview3:after				{	content: '\e825';  }
.tp-leftarrow.preview3:after				{	content: '\e824';  }


.tparrows.preview3 .tp-arr-iwrapper			{
												  -webkit-transform: scale(0,1);
												  transform: scale(0,1);
												  -webkit-transform-origin: 100% 50%;
												  transform-origin: 100% 50%;
												  -webkit-transition: -webkit-transform 0.2s;
												  transition: transform 0.2s;
												  z-index:0;position: absolute; background: #000; background: rgba(0,0,0,0.75);
												  display: table;min-height:90px;top:-10px;}

.tp-leftarrow.preview3 .tp-arr-iwrapper		{	 -webkit-transform: scale(0,1);
												  transform: scale(0,1);
												  -webkit-transform-origin: 0% 50%;
												  transform-origin: 0% 50%;
											}

.tparrows.preview3 .tp-arr-imgholder 		{	display:block;background-size:cover; background-position:center center; display:table-cell;min-width:90px;height:90px;
												position:relative;top:0px; }

.tp-rightarrow.preview3 .tp-arr-iwrapper	{	right:0px;padding-right:70px;}
.tp-leftarrow.preview3 .tp-arr-iwrapper		{	left:0px; direction: rtl;padding-left:70px;}
.tparrows.preview3 .tp-arr-titleholder		{	display:table-cell; padding:30px;font-size:16px; color:#fff;white-space: nowrap; position: relative; clear:right;vertical-align: middle;}

.tparrows.preview3:hover .tp-arr-iwrapper	{
												-webkit-transform: scale(1,1);
												  transform: scale(1,1);

											}

/* PREVIEW 3 BULLETS */
.tp-bullets.preview3 						{ 	height: 17px; }
.tp-bullets.preview3 .bullet 				{	cursor: pointer;
											    position: relative !important;
											    background: rgba(0, 0, 0, 0.5) !important;
											    -webkit-border-radius: 10px;
											    border-radius: 10px;
											    -webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
											    width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 0) !important;
											    display: inline-block;
											    margin-right: 2px !important;
											    margin-bottom: 0px !important;
											    -webkit-transition: background-color 0.2s, border-color 0.2s;
											    -moz-transition: background-color 0.2s, border-color 0.2s;
											    -o-transition: background-color 0.2s, border-color 0.2s;
											    -ms-transition: background-color 0.2s, border-color 0.2s;
											    transition: background-color 0.2s, border-color 0.2s;
											    float:none !important;
											    box-sizing:content-box;
												-moz-box-sizing:content-box;
												-webkit-box-sizing:content-box;
}
.tp-bullets.preview3 .bullet.last 			{	margin-right: 0px; }
.tp-bullets.preview3 .bullet:hover,
.tp-bullets.preview3 .bullet.selected 		{	-webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
												background: rgba(255, 255, 255, 1) !important;
												width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 1) !important;
}


	@media only screen and (min-width: 768px) and (max-width: 979px) {
		.tparrows.preview3:after,
		.tparrows.preview3 							{	width:50px; height:50px; line-height:50px;font-size:20px;}
		.tparrows.preview3 .tp-arr-iwrapper			{	min-height:70px;}
		.tparrows.preview3 .tp-arr-imgholder 		{	min-width:70px;height:70px;}
		.tp-rightarrow.preview3 .tp-arr-iwrapper	{	padding-right:50px;}
		.tp-leftarrow.preview3 .tp-arr-iwrapper		{	padding-left:50px;}
		.tparrows.preview3 .tp-arr-titleholder		{	padding:10px;font-size:16px; }



	}

    @media only screen  and (max-width: 767px) {

		.tparrows.preview3:after,
		.tparrows.preview3 							{	width:50px; height:50px; line-height:50px;font-size:20px;}
		.tparrows.preview3 .tp-arr-iwrapper			{	min-height:70px;}
	}





/* NAVIGATION PREVIEW 4 */
.tparrows.preview4 							{	width:30px; height:110px;  background:transparent;-webkit-transform-style: preserve-3d; -webkit-perspective: 1000; -moz-perspective: 1000;}
.tparrows.preview4:after					{	position:absolute;  line-height: 110px;text-align: center; font-family: "revicons"; color:#fff; font-size:20px; top:0px;left:0px;z-index:0; width:30px;height:110px; background: #000; background: rgba(0,0,0,0.25);
												-webkit-transition: all 0.2s ease-in-out;
											    -moz-transition: all 0.2s ease-in-out;
											    -o-transition: all 0.2s ease-in-out;
											    transition: all 0.2s ease-in-out;
												   -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter: alpha(opacity=100);-moz-opacity: 1;-khtml-opacity: 1;opacity: 1;

											}

.tp-rightarrow.preview4:after				{	content: '\e825';  }
.tp-leftarrow.preview4:after				{	content: '\e824';  }


.tparrows.preview4 .tp-arr-allwrapper		{	visibility:hidden;width:180px;position: absolute;z-index: 1;min-height:120px;top:0px;left:-150px; overflow: hidden;-webkit-perspective: 1000px;-webkit-transform-style: flat;}

.tp-leftarrow.preview4 .tp-arr-allwrapper	{	left:0px;}
.tparrows.preview4 .tp-arr-iwrapper			{	position: relative;}

.tparrows.preview4 .tp-arr-imgholder 		{	display:block;background-size:cover; background-position:center center;width:180px;height:110px;
												position:relative;top:0px;

												-webkit-backface-visibility: hidden;
												backface-visibility: hidden;



											}


.tparrows.preview4 .tp-arr-imgholder2 		{	display:block;background-size:cover; background-position:center center; width:180px;height:110px;
												position:absolute;top:0px; left:180px;
												-webkit-backface-visibility: hidden;
												backface-visibility: hidden;

											}

.tp-leftarrow.preview4 .tp-arr-imgholder2 	{	left:-180px;}




.tparrows.preview4 .tp-arr-titleholder		{	display:block; font-size:12px; line-height:25px; padding:0px 10px;text-align:left;color:#fff; position: relative;
												background: #000;
												color: #FFF;
												text-transform: uppercase;
												white-space: nowrap;
												letter-spacing: 1px;
												font-weight: 700;
												font-size: 11px;
												line-height: 2.75;
												-webkit-transition: all 0.3s;
												transition: all 0.3s;
												-webkit-transform: rotateX(-90deg);
												transform: rotateX(-90deg);
												-webkit-transform-origin: 50% 0;
												transform-origin: 50% 0;
												-webkit-backface-visibility: hidden;
												backface-visibility: hidden;
												  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter: alpha(opacity=0);-moz-opacity: 0.0;-khtml-opacity: 0.0;opacity: 0.0;


}



.tparrows.preview4:after				{	transform-origin: 100% 100%; -webkit-transform-origin: 100% 100%;}
.tp-leftarrow.preview4:after			{	transform-origin: 0% 0%; -webkit-transform-origin: 0% 0%;}




@media only screen and (min-width: 768px)  {
		.tparrows.preview4:hover:after				{	-webkit-transform: rotateY(-90deg); transform:rotateY(-90deg);}
		.tp-leftarrow.preview4:hover:after			{	-webkit-transform: rotateY(90deg); transform:rotateY(90deg);}


		.tparrows.preview4:hover .tp-arr-titleholder	{	-webkit-transition-delay: 0.4s;
															transition-delay: 0.4s;
															-webkit-transform: rotateX(0deg);
															transform: rotateX(0deg);
															-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter: alpha(opacity=100);-moz-opacity: 1;-khtml-opacity: 1;opacity: 1;

														}
}

/* PREVIEW 4 BULLETS */

.tp-bullets.preview4 						{ 	height: 17px; }
.tp-bullets.preview4 .bullet 				{	cursor: pointer;
											    position: relative !important;
											    background: rgba(0, 0, 0, 0.5) !important;
											    -webkit-border-radius: 10px;
											    border-radius: 10px;
											    -webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
											    width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 0) !important;
											    display: inline-block;
											    margin-right: 2px !important;
											    margin-bottom: 0px !important;
											    -webkit-transition: background-color 0.2s, border-color 0.2s;
											    -moz-transition: background-color 0.2s, border-color 0.2s;
											    -o-transition: background-color 0.2s, border-color 0.2s;
											    -ms-transition: background-color 0.2s, border-color 0.2s;
											    transition: background-color 0.2s, border-color 0.2s;
											    float:none !important;
											    box-sizing:content-box;
												-moz-box-sizing:content-box;
												-webkit-box-sizing:content-box;
}
.tp-bullets.preview4 .bullet.last 			{	margin-right: 0px; }
.tp-bullets.preview4 .bullet:hover,
.tp-bullets.preview4 .bullet.selected 		{	-webkit-box-shadow: none;
											    -moz-box-shadow: none;
											    box-shadow: none;
												background: rgba(255, 255, 255, 1) !important;
												width: 6px !important;
											    height: 6px !important;
											    border: 5px solid rgba(0, 0, 0, 1) !important;
}


    @media only screen  and (max-width: 767px) {
   		 .tparrows.preview4 						{	width:20px; height:80px;}
   		 .tparrows.preview4:after					{	width:20px; height:80px; line-height:80px; font-size:14px;}

   		 .tparrows.preview1 .tp-arr-allwrapper,
   		 .tparrows.preview2 .tp-arr-allwrapper,
   		 .tparrows.preview3 .tp-arr-allwrapper,
   		 .tparrows.preview4 .tp-arr-allwrapper		{	display: none !important}
    }



/******************************
	-	LOADER FORMS	-
********************************/

.tp-loader 	{
				top:50%; left:50%;
				z-index:10000;
				position:absolute;


			}

.tp-loader.spinner0 {
  width: 40px;
  height: 40px;
  background:url(../images/revslider/loader.gif) no-repeat center center;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  margin-top:-20px;
  margin-left:-20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}


.tp-loader.spinner1 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  margin-top:-20px;
  margin-left:-20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}



.tp-loader.spinner5 	{	background:url(../images/revslider/loader.gif) no-repeat 10px 10px;
							background-color:#fff;
							margin:-22px -22px;
							width:44px;height:44px;
							border-radius: 3px;
							-moz-border-radius: 3px;
							-webkit-border-radius: 3px;
						}


@-webkit-keyframes tp-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes tp-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


.tp-loader.spinner2 {
  width: 40px;
  height: 40px;
  margin-top:-20px;margin-left:-20px;
  background-color: #ff0000;
   box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  border-radius: 100%;
  -webkit-animation: tp-scaleout 1.0s infinite ease-in-out;
  animation: tp-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes tp-scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes tp-scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}




.tp-loader.spinner3 {
  margin: -9px 0px 0px -35px;
  width: 70px;
  text-align: center;

}

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
  width: 18px;
  height: 18px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: tp-bouncedelay 1.4s infinite ease-in-out;
  animation: tp-bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tp-loader.spinner3 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.tp-loader.spinner3 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes tp-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes tp-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}




.tp-loader.spinner4 {
  margin: -20px 0px 0px -20px;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-animation: tp-rotate 2.0s infinite linear;
  animation: tp-rotate 2.0s infinite linear;
}

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: tp-bounce 2.0s infinite ease-in-out;
  animation: tp-bounce 2.0s infinite ease-in-out;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
}

.tp-loader.spinner4 .dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes tp-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes tp-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes tp-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes tp-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}



.tp-transparentimg {	content:"url(../images/revslider/transparent.png)"}
.tp-3d				{	-webkit-transform-style: preserve-3d;
						 -webkit-transform-origin: 50% 50%;
					}



.tp-caption img {
background: transparent;
-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
zoom: 1;
}


@font-face {
  font-family: 'revicons';
  src: url('../fonts/revicons90c6.html?5510888');
  src: url('../fonts/revicons90c6.html?5510888#iefix') format('embedded-opentype'),
       url('../fonts/revicons90c6-2.html?5510888') format('woff'),
       url('../fonts/revicons90c6-3.html?5510888') format('truetype'),
       url('../fonts/revicons90c6-4.html?5510888#revicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'revicons';
    src: url('../font/revicons.svg?5510888#revicons') format('svg');
  }
}
*/

 [class^="revicon-"]:before, [class*=" revicon-"]:before {
  font-family: "revicons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.revicon-search-1:before { content: '\e802'; } /* 'î ‚' */
.revicon-pencil-1:before { content: '\e831'; } /* 'î ±' */
.revicon-picture-1:before { content: '\e803'; } /* 'î ƒ' */
.revicon-cancel:before { content: '\e80a'; } /* 'î Š' */
.revicon-info-circled:before { content: '\e80f'; } /* 'î ' */
.revicon-trash:before { content: '\e801'; } /* 'î ' */
.revicon-left-dir:before { content: '\e817'; } /* 'î —' */
.revicon-right-dir:before { content: '\e818'; } /* 'î ˜' */
.revicon-down-open:before { content: '\e83b'; } /* 'î »' */
.revicon-left-open:before { content: '\e819'; } /* 'î ™' */
.revicon-right-open:before { content: '\e81a'; } /* 'î š' */
.revicon-angle-left:before { content: '\e820'; } /* 'î  ' */
.revicon-angle-right:before { content: '\e81d'; } /* 'î ' */
.revicon-left-big:before { content: '\e81f'; } /* 'î Ÿ' */
.revicon-right-big:before { content: '\e81e'; } /* 'î ž' */
.revicon-magic:before { content: '\e807'; } /* 'î ‡' */
.revicon-picture:before { content: '\e800'; } /* 'î €' */
.revicon-export:before { content: '\e80b'; } /* 'î ‹' */
.revicon-cog:before { content: '\e832'; } /* 'î ²' */
.revicon-login:before { content: '\e833'; } /* 'î ³' */
.revicon-logout:before { content: '\e834'; } /* 'î ´' */
.revicon-video:before { content: '\e805'; } /* 'î …' */
.revicon-arrow-combo:before { content: '\e827'; } /* 'î §' */
.revicon-left-open-1:before { content: '\e82a'; } /* 'î ª' */
.revicon-right-open-1:before { content: '\e82b'; } /* 'î «' */
.revicon-left-open-mini:before { content: '\e822'; } /* 'î ¢' */
.revicon-right-open-mini:before { content: '\e823'; } /* 'î £' */
.revicon-left-open-big:before { content: '\e824'; } /* 'î ¤' */
.revicon-right-open-big:before { content: '\e825'; } /* 'î ¥' */
.revicon-left:before { content: '\e836'; } /* 'î ¶' */
.revicon-right:before { content: '\e826'; } /* 'î ¦' */
.revicon-ccw:before { content: '\e808'; } /* 'î ˆ' */
.revicon-arrows-ccw:before { content: '\e806'; } /* 'î †' */
.revicon-palette:before { content: '\e829'; } /* 'î ©' */
.revicon-list-add:before { content: '\e80c'; } /* 'î Œ' */
.revicon-doc:before { content: '\e809'; } /* 'î ‰' */
.revicon-left-open-outline:before { content: '\e82e'; } /* 'î ®' */
.revicon-left-open-2:before { content: '\e82c'; } /* 'î ¬' */
.revicon-right-open-outline:before { content: '\e82f'; } /* 'î ¯' */
.revicon-right-open-2:before { content: '\e82d'; } /* 'î ­' */
.revicon-equalizer:before { content: '\e83a'; } /* 'î º' */
.revicon-layers-alt:before { content: '\e804'; } /* 'î „' */
.revicon-popup:before { content: '\e828'; } /* 'î ¨' */


/********************* Custom Navigation Styles For Revslider
========================================================== */

#main-content .tp-leftarrow,
#main-content .tp-rightarrow {
    background: transparent !important;
    border-radius: 0 !important;
    font-family: "FontAwesome";
    font-size: 2.8571em !important;
    text-align: center;
    line-height: 1;
    margin-top: -25px !important;
    top: 50%;
    width: 50px !important;
    height: 50px !important;
	color:#9ac93d !important;
	transition:background-position 0.25s;
	-webkit-transition:background-position 0.25s;
	-moz-transition:background-position 0.25s;
	-ms-transition:background-position 0.25s;
	-o-transition:background-position 0.25s;
	position: absolute;
}

#main-content .tp-leftarrow  {
	left:0 !important;
}

#main-content .tp-rightarrow {
	right:0 !important;
}

#main-content .tp-leftarrow:before,
#main-content .tp-rightarrow:before {
    text-shadow: 0 0 3px #fff;
    font-size: 1.5em;
    line-height: 50px;
    display: block;
}

#main-content .tp-leftarrow:before {
    content: "\f104";
}

#main-content .tp-rightarrow:before {
    content: "\f105";
}


@media (max-width: 767px) {

	#main-content .tp-leftarrow,
	#main-content .tp-rightarrow {
		display:none !important;
	}

}

/* @end */

/* @group Write review */

#form-review h2 {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 10px;
}

/* @end */

/* @group Fixed header */

.fixed-header {
	position: fixed;
	top: -100px;
	left: 0;
	right: 0;
	z-index: 999;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
}

	.fixed-header .sub-menu {
		display: none;
	}

		.fixed-header.active .sub-menu {
			display: block;
		}

	.fixed-header.active {
		top: 0;
	}

/* @end */

/* @group Blog */

/* @group Blog list default */

ul.blog-list-default {
     list-style: none;
     padding: 0px;
     margin: 0px;
     position: relative;
}

     ul.blog-list-default li {
          padding: 21px 0px 20px 0px;
     }

          ul.blog-list-default li:first-child {
               padding-top: 0px;
          }

          ul.blog-list-default li:last-child {
               padding-bottom: 0px;
          }

          ul.blog-list-default li:before {
               content: '';
               position: absolute;
               left: 0;
               right: 0;
               height: 1px;
               background: rgba(0, 0, 0, .1);
               margin-top: -21px;
          }

               ul.blog-list-default li:first-child:before {
                    display: none;
               }

          ul.blog-list-default li .thumb-holder {
               float: left;
               width: auto;
               margin-right: 20px;
          }

               ul.blog-list-default li .thumb-holder img {
                    display: block;
               }

         ul.blog-list-default li  .media-body {
         	display: inline;
         }

          ul.blog-list-default li .date-published {
               padding: 4px 0px 3px 0px;
               font-size: 11px;
               font-weight: bold;
          }

          ul.blog-list-default li h5 {
               padding: 0px;
               margin: 0px;
               font-size: 15px;
               line-height: 18px;
          }

/* @end */

/* @group Tagcloud */

.blog-tags .box-content {
     border-bottom: none !important;
}

.blog-tags .tagcloud {
     line-height: 15px;
     position: relative;
     margin: 0px 0px -10px 0px;
}

     .blog-tags .tagcloud a {
          display: inline-block;
          background: #0083c1;
          font-size: 9px;
          line-height: 15px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          border-radius: 20px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          margin: 0px 7px 9px 0px;
          padding: 1px 10px 1px 10px;
     }

          .blog-tags .tagcloud a:hover {
               background: #333;
          }

/* @end */

/* @group News */

.news {
     position: relative;
     margin-bottom: -30px;
}

     .news .media {
          position: relative;
          margin-bottom: 30px;
     }

     .news .thumb-holder {
          overflow: hidden;
     }

          .news .thumb-holder img {
               display: block;
               width: 100%;
          }

     .news .media-body {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
          width: auto;
     }

          .news .media-body:before {
               content: "";
               background: url('../img/bg-news.png') bottom left repeat-x;
               opacity: 0.6;
               top: 0;
               left: 0;
               bottom: 0;
               right: 0;
               position: absolute;
               z-index: -1;
               transition: all 0.2s ease-in;
               -webkit-transition: all 0.2s ease-in;
               -moz-transition: all 0.2s ease-in;
          }

               .news .media-body:hover:before {
                    opacity: 0.7;
               }

          .news .media-body .tags {
               position: absolute;
               top: 18px;
               left: 20px;
               right: 10px;
               margin: 0px;
               padding: 0px;
               line-height: 1.6;
          }

               .news .media-body .tags a {
                    display: inline-block;
                    background: #0083c1;
                    font-size: 9px;
                    line-height: 15px;
                    color: #fff;
                    font-weight: bold;
                    text-transform: uppercase;
                    border-radius: 20px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    margin: 0px 7px 9px 0px;
                    padding: 1px 10px 1px 10px;
               }

                    .news .media-body .tags a:hover {
                         background: #333;
                    }

          .news .media-body .bottom {
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               text-align: center;
               transition: all 0.2s ease-in;
               -webkit-transition: all 0.2s ease-in;
               -moz-transition: all 0.2s ease-in;
          }

               .news.v1 .media-body .bottom {
                    color: #fff;
               }

               .news .media-body:hover .bottom {
                    bottom: 10px;
               }

               .news .media-body .date-published {
                    display: inline-block;
                    font-size: 12px;
                    font-weight: bold;
                    border-bottom: 1px solid rgba(255, 255, 255, .2);
                    padding-bottom: 9px;
                    padding-top: 25px;
               }

               .news .media-body h5 {
                    padding: 12px 20px 34px 20px;
                    margin: 0px;
                    font-size: 18px;
                    line-height: 23px;
                    text-transform: none;
                    font-weight: 500 !important;
                    color: #fff;
               }

/* @end */

/* @group News V2 */

.news.v2 .media {
     padding-bottom: 53px;
}

.news.v2 .media .thumb-holder img {
     -webkit-transform: scale(1,1);
     -webkit-transition-timing-function: ease-out;
     -webkit-transition-duration: 250ms;
     -moz-transform: scale(1,1);
     -moz-transition-timing-function: ease-out;
     -moz-transition-duration: 250ms;
     transform: scale(1,1);
     transition-timing-function: ease-out;
     transition-duration: 250ms;
}

     .news.v2 .media:hover .thumb-holder img {
          -webkit-transform: scale(1.04,1.04);
          -webkit-transition-timing-function: ease-out;
          -webkit-transition-duration: 250ms;
          -moz-transform: scale(1.04,1.04);
          -moz-transition-timing-function: ease-out;
          -moz-transition-duration: 250ms;
          transform: scale(1.04,1.04);
          transition-timing-function: ease-out;
          transition-duration: 250ms;
     }

.news.v2 .media-body:before {
     display: none !important;
}

.news.v2  .media-body .bottom {
     background: #fff;
     border: 1px solid #e6e6e6;
     border-top: none;
     color: #1349b2;
     bottom: 0 !important;
}

     .news.v2  .media-body .bottom h5 {
     }

     .news.v2 .media-body .date-published {
          border-color: rgba(0, 0, 0, .2);
     }

     .news.v2 .media-body h5 {
          padding-bottom: 33px;
     }

          .news.v2 .media-body h5 a {
               line-height: 23px;
          }

/* @end */

/* @end */

/* @group Align right */

.align-right {
	float: right;
}

@media (max-width: 991px) {
	.align-right {
		float: none;
	}
}

/* @end */

/* @group Quick checkout */

.quickcheckout-checkout #content h1 {
	display: none;
}

.quickcheckout-heading {
	border: 1px solid #ddd;
	border-bottom: none;
	border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
}

body .quickcheckout-content {
	border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	margin-bottom: 30px;
	background: #fff;
}

	body #terms .quickcheckout-content,
	body #voucher .quickcheckout-content {
		background: none;
	}

.or {
	padding: 0px 0 20px 0;
}

.quickcheckout-content label {
	padding-top: 6px;
}

#terms .quickcheckout-content {
	padding: 0px;
	border: none;
	padding-bottom: 0px;
	padding-top: 10px;
	margin-bottom: -30px;
}

#button-payment-method {
	margin-left: 10px;
}

.quickcheckout-content table {
	margin-bottom: 0;
}

.quickcheckout-content table tr:first-child td {
	border-top: none;
}

.quickcheckoutright .quickcheckout-content input[type="radio"],
.quickcheckoutright .quickcheckout-content input[type="checkbox"] {
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 9px;
}

.quickcheckout-content textarea {
	margin-top: 5px;
}

#quickcheckoutconfirm {
	padding-bottom: 40px !important;
}

/* @end */

/* @group Header 2 */

#header.header2 #header-top .container,
#header.header2 #header-inner .container {
	position: static;
	display: block;
}

.header2 ul.megamenu {
	position: static;
	padding-left: 23px;
}

.header2 .container-megamenu,
.header2 .container-megamenu .container {
	position: static;
}

#header.header2 #header-inner .container .header-center {
	width: 800px;
}

.header2 .megamenu-wrapper {
	position: static;
}

#header.header2 #header-inner > .container {
 	position: relative;
}

.header2 ul.megamenu > li {
	margin: 0px 1px !important;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}

	.header2 ul.megamenu > li > a {
		padding: 9px 12px 8px;
		height: 37px;
	}

	.common-home .header2 ul.megamenu > li:first-child,
	.header2 ul.megamenu > li:hover {
		background: #3b3b3b;
	}

	.body-header-type-3 ul.megamenu > li > a:hover,
	.body-header-type-3 ul.megamenu > li.active > a,
	.body-header-type-3 ul.megamenu > li.home > a,
	.body-header-type-3 ul.megamenu > li:hover > a {
		color: #fff;
	}

.header2 ul.megamenu .megamenu-label {
	top: -6px;
	right: 7px;
}

.header2 .separator {
	display: none;
}

@media (max-width: 991px) {
	.header2 .header-center  {
		display: none !important;
	}

	.header2 .header-right {
		width: 100%;
	}
}

.common-home.body-header-type-3 #footer > .container {
	display: none;
}

.common-home.body-header-type-3 .main-content {
	padding-bottom: 0;
}

.common-home.body-header-type-3 #footer-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
	padding: 0 !important;
	background: rgba(255,255,255,0.85) !important;
}

.body-header-type-3 .tp-caption.rev-text,
.body-header-type-4 .tp-caption.rev-text {
	font-weight: 300;
    font-size: 24px;
    color:#fff;
    text-align: center;
}

.body-header-type-3 .tp-caption.rev-text.small,
.body-header-type-4 .tp-caption.rev-text.small {
	font-size:19px;
	font-weight: 600;
	color: #fff;
}

@media (max-width: 991px) {
	.body-header-type-3.common-home .footer-left > * {
		text-align: center;
	}

	.body-header-type-3.common-home .footer-center,
	.body-header-type-3.common-home .footer-right,
	.body-header-type-3.common-home #footer .logo {
		display: none;
	}
}

.body-header-type-3 .megamenu-wrapper {
	background: #1e1e1e;
}

@media (max-width: 991px) {
	.body-header-type-3 #header #header-inner .container .header-right {
		position: absolute;
		right: 20px;
		top: 0;
	}
}

/* @end */

/* @group Header type 4 */

.body-header-type-4 .container-megamenu {
	margin-bottom: 15px;
}

.body-header-type-4 .megamenu-wrapper {
	background: none;
}

.body-header-type-4 ul.megamenu:before,
.body-header-type-4 ul.megamenu:after{
  display: table;
  content: " ";
}
.body-header-type-4 ul.megamenu:after {
  clear: both;
}

.body-header-type-4 ul.megamenu {
	background: #f0f0ed;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}

.body-header-type-4 ul.megamenu > li {
	margin: 0px 2px 0px 0px;
}

.body-header-type-4 ul.megamenu > li.pull-right {
	margin: 0px 0px 0px 2px;
}

.body-header-type-4 ul.megamenu > li > a {
	height: 40px;
	padding: 11px 13px 9px 13px;
	color: #9ac93d;
}

	.body-header-type-4 ul.megamenu > li.home > a,
	.body-header-type-4 ul.megamenu > li:hover > a {
		background: #9ac93d;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		color: #fff;
	}

	.body-header-type-4 ul.megamenu > li:hover > a{
		border-radius: 6px 6px 0 0;
		-webkit-border-radius: 6px 6px 0 0;
		-moz-border-radius: 6px 6px 0 0;
	}

.body-header-type-4 ul.megamenu .megamenu-label {
	top: -4px;
	right: 13px;
}

	.body-header-type-4 .fixed-header ul.megamenu .megamenu-label {
		top: 0;
	}

.body-header-type-4 #header.header3 ul.megamenu .menu {
	background: none;
}

/* @end */

/* @group Index4 */

.parallax-info .btn-default,
.parallax-info .btn,
.parallax-info .button {
	background: none !important;
}

.post-slider.type2 .post-image img {
	width: 100%;
}

@media (max-width: 767px) {
	.post-slider.type2 > div:last-of-type {
		padding-top: 15px;
	}
}

/* @end */

/* @group Index3 */

.index3-slide-btn {
	display:inline-block;
	font-size:14px;
	line-height: 20px;
	padding:7.5px 25px;
	font-weight: 600;
	background-color: #fefefe;
	border-radius: 5px;
	color: #777 !important;
}

.index3-slide-btn:hover {
	background-color: rgba(250, 250, 250, 0.9);
	color:#000;
}

/* @end */

/* @group Header type 5 */

.header4 .megamenu-wrapper {
	background: #9ac93d;
}

	.header4 .megamenu-wrapper ul.megamenu li {
		margin: 0px 2px 0px 0px;
	}

	.header4 .megamenu-wrapper ul.megamenu > li.home > a,
	.header4 .megamenu-wrapper ul.megamenu > li > a:hover {
		background: #fff;
		color: #9ac93d;
	}

	.header4 .megamenu-wrapper ul.megamenu > li > a {
		padding-left: 21px;
		height: 40px;
		padding-top: 11px;
		padding-right: 21px;
	}

/* @end */

/* @group Skin5 */

.skin5 .main-content {
	background: #fff;
	padding: 15px;
}

	@media (max-width: 991px) {
		.skin5 .main-content {
			padding: 15px 0;
		}
	}

	.skin5 .main-content .container {
		width: auto !important;
	}

.common-home.skin5 .main-content {
	padding: 0px;
	background: none;
}

.skin5 .mfilter-heading,
.skin5 .mfilter-content > ul > li,
.skin5 a.quickview-icon,
.skin5 .product-filter .options .button-group button,
.skin5 .nav-tabs > li > a,
.skin5 .product-list .btn-add-cart,
.skin5 .product-list .product-btn.btn-wishlist,
.skin5 .product-list .product-btn.btn-quickview,
.skin5 .product-info .thumbnails li img,
.skin5 .product-info .thumbnails-carousel img,
.skin5 .btn-default,
.skin5 .btn,
.skin5 .button,
.skin5 select,
.skin5 input,
.skin5 textarea,
.skin5 .product-info .product-image,
.skin5 .well,
.skin5 .box,
.skin5 .box .box-heading,
.skin5 table.attribute,
.skin5 table.list,
.skin5 .wishlist-product table,
.skin5 .wishlist-info table,
.skin5 .compare-info,
.skin5 .cart-info table,
.skin5 .checkout-product table,
.skin5 .table,
.skin5 .center-column .panel-heading,
.skin5 .quickcheckout-heading,
body.skin5 .quickcheckout-content,
.skin5 .mfilter-heading .mfilter-head-icon,
.skin5 .box .box-heading .toggle,
.skin5 ul.megamenu li .sub-menu .content,
.skin5 .popup,
.skin5 .posts .post .post-media,
.skin5 .posts .button-more,
.skin5 #header .currency-switcher li > ul,
.skin5 #header .view-switcher li > ul,
.skin5 #scroll-top,
.skin5 .product-center .single-add-to-wishlist a:before,
.skin5 .product-center .add-to-cart .quantity #q_up,
.skin5 .product-center .add-to-cart .quantity #q_down,
.skin5 .product-center .add-to-cart .quantity input,
.skin5 .alert,
.skin5 ul.product_list_widget li .product-image img,
.skin5 .widget ul.product_list_widget li .product-image img,
.skin5 ul.product_list_widget li .product-image,
.skin5 .widget ul.product_list_widget li .product-image,
.skin5 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin5 #mini-cart .mini-cart-info table td.image img,
.skin5 .product-image,
.skin5 .product-image img,
.skin5 .product-action .product-btn,
.skin5 .prev-next-products .product-nav .product-pop,
.skin5 .post .tags a,
.skin5 .pagination > li > a,
.skin5 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

/* @end */

/* @group Skin5 products */

.skin5-products .box {
	padding: 0px;
	border: none;
	margin: 0px;
	background: none;
}

	.skin5-products .box .box-content {
		padding: 0px;
	}

/* @end */

/* @group Skin 6 */

.body-full-width .container {
	width: auto !important;
}

	.common-home.body-full-width .main-content .container {
		padding: 0px;
	}

	.skin6 #footer-bottom > .container {
		width: 100% !important;
	}

	.skin6.common-home .main-content {
		padding-bottom: 0;
	}

/* @end */

/* @group Header type 6 */

.header55 {
	background: url(../images/page_header.jpg) top center repeat;
	min-height: 175px;
}

	.common-home .header55 {
		background: none !important;
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}

		.header55 .logo {
			text-align: center;
			padding-top: 19px;
			display: block;
			margin: 0px !important;
		}

			.header55 .logo img {
				position: relative;
				z-index: 999;
			}

	.header55 .left {
		position: absolute;
		width: 60%;
		left: 15px;
		top: 30px;
	}

	.header55 .megamenu-wrapper {
		background: none;
	}

	.header55 ul.megamenu > li {
		margin: 0px 5px 0px 0px;
	}

	.header55 ul.megamenu > li > a {
		height: 32px;
		padding: 7px 11px 0px 11px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
	}

		.header55 ul.megamenu > li > a:hover,
		.header55 ul.megamenu > li.active > a,
		.header55 ul.megamenu > li.home > a,
		.header55 ul.megamenu > li:hover > a {
			background: #fff;
			color: #777;
		}

			.header55 ul.megamenu > li.with-sub-menu:hover > a {
				border-radius: 6px 6px 0 0;
				-webkit-border-radius: 6px 6px 0 0;
				-moz-border-radius: 6px 6px 0 0;
			}

			.header55 ul.megamenu li .sub-menu .content {
				border-top: none;
			}

			.header55 ul.megamenu .megamenu-label {
				top: -5px;
			}

			.header55#header .mobile-toggle {
				left: 5px;
				top: -3px;
			}

	.header55 > .right {
		position: absolute;
		right: 30px;
		top: 30px;
		width: 40%;
		left: auto;
		text-align: right;
	}

		.header55 #mini-cart.minicart-inline {
			background: #fff !important;
			top: 0 !important;
			right: 0 !important;
		}

		.header55 #mini-cart.minicart-inline .cart-items {
			color: #777 !important;
		}

		.header55 #mini-cart .minicart-icon {
			color: #777;
		}

		.header55 .separator {
			display: none;
		}

		.header55#header .currency-switcher > li > a,
		.header55#header .view-switcher > li > a {
			background: #fff;
			border-radius: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			color: #777;
			margin-top: -1px;
			margin-right: 0;
			margin-left: 0;
		}

			.header55#header .currency-switcher > li:hover > a,
			.header55#header .view-switcher > li:hover > a  {
				border-radius: 6px 6px 0 0;
				-webkit-border-radius: 6px 6px 0 0;
				-moz-border-radius: 6px 6px 0 0;
			}

			.header55#header .currency-switcher li > ul,
			.header55#header .view-switcher li > ul {
				min-width: 120px;
				text-align: left;
				background: #fff;
			}

				.header55#header .currency-switcher li > ul a,
				.header55#header .view-switcher li > ul a {
					color: #777;
					background: none !important;
				}

					.header55#header .currency-switcher li > ul a:hover,
					.header55#header .view-switcher li > ul a:hover {
						background: #fafafa !important;
					}

		.header55#header .currency-switcher,
		.header55#header .view-switcher {
			margin-left: 6px;
		}

		@media (max-width: 991px) {
			.header55#header .currency-switcher,
			.header55#header .view-switcher {
				display: none;
			}
		}

/* @end */

/* @group Row no margin */

.row.no-margin {
	margin: 0px;
}

	.row.no-margin > div {
		padding: 0px;
	}

	.row.no-margin .banner,
	.row.no-margin .banner img,
	.row.no-margin .banner a:after {
		border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
		-moz-border-radius: 0 !important;
	}

/* @end */

/* @group Skin7 */

.skin7 .mfilter-heading,
.skin7 .mfilter-content > ul > li,
.skin7 a.quickview-icon,
.skin7 .product-filter .options .button-group button,
.skin7 .nav-tabs > li > a,
.skin7 .product-list .btn-add-cart,
.skin7 .product-list .product-btn.btn-wishlist,
.skin7 .product-list .product-btn.btn-quickview,
.skin7 .product-info .thumbnails li img,
.skin7 .product-info .thumbnails-carousel img,
.skin7 .btn-default,
.skin7 .btn,
.skin7 .button,
.skin7 select,
.skin7 input,
.skin7 textarea,
.skin7 .product-info .product-image,
.skin7 .well,
.skin7 .box,
.skin7 .box .box-heading,
.skin7 table.attribute,
.skin7 table.list,
.skin7 .wishlist-product table,
.skin7 .wishlist-info table,
.skin7 .compare-info,
.skin7 .cart-info table,
.skin7 .checkout-product table,
.skin7 .table,
.skin7 .center-column .panel-heading,
.skin7 .quickcheckout-heading,
body.skin7 .quickcheckout-content,
.skin7 .mfilter-heading .mfilter-head-icon,
.skin7 .box .box-heading .toggle,
.skin7 ul.megamenu li .sub-menu .content,
.skin7 .popup,
.skin7 .banner, .skin7 .banner img, .skin7 .banner a:after,
.skin7 .posts .post .post-media,
.skin7 .posts .button-more,
.skin7 #header .currency-switcher li > ul,
.skin7 #header .view-switcher li > ul,
.skin7 #scroll-top,
.skin7 .product-center .single-add-to-wishlist a:before,
.skin7 .product-center .add-to-cart .quantity #q_up,
.skin7 .product-center .add-to-cart .quantity #q_down,
.skin7 .product-center .add-to-cart .quantity input,
.skin7 .alert,
.skin7 ul.product_list_widget li .product-image img,
.skin7 .widget ul.product_list_widget li .product-image img,
.skin7 ul.product_list_widget li .product-image,
.skin7 .widget ul.product_list_widget li .product-image,
.skin7 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin7 #mini-cart .mini-cart-info table td.image img,
.skin7 .product-image,
.skin7 .product-image img,
.skin7 .product-action .product-btn,
.skin7 .prev-next-products .product-nav .product-pop,
.skin7 .post .tags a,
.skin7 .pagination > li > a,
.skin7 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin7.common-home .row {
	margin: 0px -4px;
}

	.skin7.common-home .row > div {
		padding: 0px 4px;
	}

/* @end */

/* @group Header type 7 */

#header.header6 {
	position: relative;
	z-index: 999;
}

.header6 ul.megamenu,
.header6 .container-megamenu .container,
.header6 .container-megamenu,
.header6 .megamenu-wrapper {
	position: static !important;
}

.header6 .horizontal {
	display: inline-block;
	vertical-align: middle;
}

	@media (max-width: 991px) {
		.header6 .horizontal {
			display: none;
		}
	}

	.header6.fixed-header .horizontal {
		display: block;
	}

		.header6.fixed-header .horizontal ul.megamenu {
			position: relative !important;
		}

	.header6 .megamenu-wrapper {
		background: #fff;
	}

	.header6 ul.megamenu > li {
		margin: 0px 2px 0px 0px;
	}

	.header6 ul.megamenu > li > a {
		color: #65829d;
		height: 37px;
		padding: 9px 12px 8px;
	}

	.header6 ul.megamenu > li > a:hover,
	.header6 ul.megamenu > li.active > a,
	.header6 ul.megamenu > li.home > a,
	.header6 ul.megamenu > li:hover > a {
		background: #65829d;
		color: #fff;
	}

	.header6 ul.megamenu .megamenu-label {
		top: -6px;
	}

/* @end */

/* @group Header type 8 */

.header7 .megamenu-wrapper {
	background: #fff;
}

	#header.header7 .menu {
		text-align: left !important;
	}

	.header7 ul.megamenu > li > a {
		color: #828385;
	}

		.header7 ul.megamenu > li > a:hover,
		.header7 ul.megamenu > li.active > a,
		.header7 ul.megamenu > li.home > a,
		.header7 ul.megamenu > li:hover > a {
			color: #e13b3f;
		}

.skin8 .mfilter-heading,
.skin8 .mfilter-content > ul > li,
.skin8 a.quickview-icon,
.skin8 .product-filter .options .button-group button,
.skin8 .nav-tabs > li > a,
.skin8 .product-list .btn-add-cart,
.skin8 .product-list .product-btn.btn-wishlist,
.skin8 .product-list .product-btn.btn-quickview,
.skin8 .product-info .thumbnails li img,
.skin8 .product-info .thumbnails-carousel img,
.skin8 .btn-default,
.skin8 .btn,
.skin8 .button,
.skin8 select,
.skin8 input,
.skin8 textarea,
.skin8 .product-info .product-image,
.skin8 .well,
.skin8 .box,
.skin8 .box .box-heading,
.skin8 table.attribute,
.skin8 table.list,
.skin8 .wishlist-product table,
.skin8 .wishlist-info table,
.skin8 .compare-info,
.skin8 .cart-info table,
.skin8 .checkout-product table,
.skin8 .table,
.skin8 .center-column .panel-heading,
.skin8 .quickcheckout-heading,
body.skin8 .quickcheckout-content,
.skin8 .mfilter-heading .mfilter-head-icon,
.skin8 .box .box-heading .toggle,
.skin8 ul.megamenu li .sub-menu .content,
.skin8 .popup,
.skin8 .banner, .skin8 .banner img, .skin8 .banner a:after,
.skin8 .posts .post .post-media,
.skin8 .posts .button-more,
.skin8 #header .currency-switcher li > ul,
.skin8 #header .view-switcher li > ul,
.skin8 #scroll-top,
.skin8 .product-center .single-add-to-wishlist a:before,
.skin8 .product-center .add-to-cart .quantity #q_up,
.skin8 .product-center .add-to-cart .quantity #q_down,
.skin8 .product-center .add-to-cart .quantity input,
.skin8 .alert,
.skin8 ul.product_list_widget li .product-image img,
.skin8 .widget ul.product_list_widget li .product-image img,
.skin8 ul.product_list_widget li .product-image,
.skin8 .widget ul.product_list_widget li .product-image,
.skin8 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin8 #mini-cart .mini-cart-info table td.image img,
.skin8 .product-image,
.skin8 .product-image img,
.skin8 .product-action .product-btn,
.skin8 .prev-next-products .product-nav .product-pop,
.skin8 .post .tags a,
.skin8 .pagination > li > a,
.skin8 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin8 .page-top {
	background: #f7f7f7;
	color: #777;
}

	.skin8 .breadcrumb > li > a,
	.skin8 .breadcrumb > .active {
		color: #777;
	}

	.skin8 .breadcrumb > li + li:before {
		color: #777;
	}

/* @end */

/* @group Skin9 */

.skin9 .mfilter-heading,
.skin9 .mfilter-content > ul > li,
.skin9 a.quickview-icon,
.skin9 .product-filter .options .button-group button,
.skin9 .nav-tabs > li > a,
.skin9 .product-list .btn-add-cart,
.skin9 .product-list .product-btn.btn-wishlist,
.skin9 .product-list .product-btn.btn-quickview,
.skin9 .product-info .thumbnails li img,
.skin9 .product-info .thumbnails-carousel img,
.skin9 .btn-default,
.skin9 .btn,
.skin9 .button,
.skin9 select,
.skin9 input,
.skin9 textarea,
.skin9 .product-info .product-image,
.skin9 .well,
.skin9 .box,
.skin9 .box .box-heading,
.skin9 table.attribute,
.skin9 table.list,
.skin9 .wishlist-product table,
.skin9 .wishlist-info table,
.skin9 .compare-info,
.skin9 .cart-info table,
.skin9 .checkout-product table,
.skin9 .table,
.skin9 .center-column .panel-heading,
.skin9 .quickcheckout-heading,
body.skin9 .quickcheckout-content,
.skin9 .mfilter-heading .mfilter-head-icon,
.skin9 .box .box-heading .toggle,
.skin9 ul.megamenu li .sub-menu .content,
.skin9 .popup,
.skin9 .banner, .skin9 .banner img, .skin9 .banner a:after,
.skin9 .posts .post .post-media,
.skin9 .posts .button-more,
.skin9 #header .currency-switcher li > ul,
.skin9 #header .view-switcher li > ul,
.skin9 #scroll-top,
.skin9 .product-center .single-add-to-wishlist a:before,
.skin9 .product-center .add-to-cart .quantity #q_up,
.skin9 .product-center .add-to-cart .quantity #q_down,
.skin9 .product-center .add-to-cart .quantity input,
.skin9 .alert,
.skin9 ul.product_list_widget li .product-image img,
.skin9 .widget ul.product_list_widget li .product-image img,
.skin9 ul.product_list_widget li .product-image,
.skin9 .widget ul.product_list_widget li .product-image,
.skin9 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin9 #mini-cart .mini-cart-info table td.image img,
.skin9 .product-image,
.skin9 .product-image img,
.skin9 .product-action .product-btn,
.skin9 .prev-next-products .product-nav .product-pop,
.skin9 .post .tags a,
.skin9 .pagination > li > a,
.skin9 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin9 .main-content {
	background: #fff;
	padding: 15px;
	margin-bottom: 15px;
}

	.skin9 .main-content .container {
		width: auto !important;
		padding-left: 0;
		padding-right: 0;
	}

.common-home.skin9 .main-content {
	padding: 0px;
	background: none;
}

/* @end */

/* @group Header type 9 */

.skin9 .page-top {
	background: #fff;
	margin-bottom: 10px;
}

	.skin9 .breadcrumb > li > a,
	.skin9 .breadcrumb > .active,
	.skin9 .breadcrumb > li + li:before {
		color: #999;
	}

.header8 .menu-container {
	background: #fff;
	padding: 0px 15px;
	border-bottom: 1px solid #eeeeee;
}

	@media (max-width: 991px) {
		.header8 .menu-container {
			display: none;
		}
	}

	.header8 .vertical {
		padding: 0px !important;
		margin: 0px 0px -1px 0px !important;
		width: 100% !important;
		position: relative;
		border: none;
		background: none;
	}

		.header8 .vertical #menuHeading {
			display: block !important;
		}

			.header8 .vertical .megamenuToogle-wrapper .container {
				height: 40px;
				color: #fff;
				cursor: pointer;
				background: #303030;
				font-size: 0.8571em;
				font-weight: 700;
				line-height: 20px;
				padding: 10px 15px !important;
				margin: 0;
				text-transform: uppercase;
				position: relative;
				-webkit-transition: all 0.25s ease 0s;
				transition: all 0.25s ease 0s;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
			}

		.header8 .vertical .container {
			display: block !important;
			width: auto !important;
		}

		.header8 .vertical .megamenu-wrapper {
			position: absolute !important;
			left: 0;
			right: 0;
			background: #fff !important;
			border-bottom: 5px solid #303030;
			box-shadow: 0 0 20px rgba(0, 0, 0, .4);
			-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .4);
			-moz-box-shadow: 0 0 20px rgba(0, 0, 0, .4);
			display: none !important;
		}

			.common-home .fixed-header.header8 .vertical .megamenu-wrapper {
				display: none !important;
			}

			.common-home .fixed-header.header8 .vertical:hover .megamenu-wrapper {
				display: block !important;
			}

			.common-home .header8 .vertical .megamenu-wrapper,
			.header8 .vertical:hover .megamenu-wrapper {
				display: block !important;
			}

.header8 .megamenu-wrapper {
	background: none;
}

	.header8 .megamenu-wrapper .container {
		padding: 0px !important;
		width: auto !important;
		display: block !important;
	}

	.header8 ul.megamenu > li {
		margin: 0px 20px 0px 0px;
	}

	.header8 ul.megamenu > li > a {
		color: #777;
		height: 39px;
		padding: 10px 0px 0px 6px;
	}

		.header8 ul.megamenu > li > a:hover,
		.header8 ul.megamenu > li.active > a,
		.header8 ul.megamenu > li.home > a,
		.header8 ul.megamenu > li:hover > a {
			color: #d0205e;
		}

/* @end */

/* @group Skin9 slider */

.skin9 .camera_slider {
	margin: 0px;
	padding-left: 25.8%;
	padding-right: 17px;
	background: #fff;
}

	@media (max-width: 991px) {
		.skin9 .camera_slider {
			padding-left: 0;
			padding-right: 0;
		}
	}

/* @end */

/* @group Skin9 box */

.skin9 .carousel-wrapper  {
	background: #fff;
	margin: 15px 0px 0px 0px;
	padding: 30px 15px;
}

	.skin9 .center-column .carousel-wrapper {
		padding: 0px;
	}

	.skin9.common-home .box {
		padding: 0px;
		margin: 0px;
		border: none;
		background: none;
	}

		.skin9.common-home .box .box-content {
			padding: 0px;
			margin: 0px;
			border: none;
			background: none;
		}

/* @end */

/* @group Header 10 */

.header_10 {
	position: absolute !important;
	top: 0;
	width: 256px;
	left: 0;
	margin-left: -256px;
	bottom: 0;
	background: #383734;
	z-index: 99;
	padding: 10px;
	text-align: center;
}

	@media (max-width: 991px) {
		body #wrapper .header_10 {
			width: 100%;
			margin-left: 0;
			bottom: auto;
			right: auto;
			position: relative !important;
		}

		body.common-home #wrapper .header_10 {
			position: absolute !important;
		}
	}

	.common-home .header_10 {
		background: rgba(0,0,0,0.4);
	}

	.with-fixed .header_10 {
		position: fixed !important;
		margin-left: 0;
	}

body.body-header-type-10 {
	padding-left: 256px;
}

	@media (max-width: 991px) {
		body.body-header-type-10 {
			padding-left: 0;
		}
	}

.header_10 .separator,
.header_10 .mobile-toggle {
	display: none;
}

@media (max-width: 991px) {
	.header_10 .mobile-toggle {
		display: block;
		position: absolute;
		color: #fff;
		top: 36px;
		left: 20px;
	}
}

body.body-header-type-10.common-home .main-content {
	min-height: 0px;
	padding-bottom: 0;
}

body.body-header-type-10.common-home #footer {
	display: none;
}

	.header_10 .accordion-menu {
		padding: 0px;
		margin: 0px 2px 4px 2px;
		list-style: none;
	}


		@media (max-width: 991px) {
			.header_10 .accordion-menu {
				display: none;
			}
		}

		.header_10 .accordion-menu > li > a {
			background-color: #101010;
			color: #fff;
			text-align: center;
			height: 26px;
			padding: 0 10px 0 10px;
			border: 1px solid #101010;
			border-radius: 3px;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    font-size: 11px;
		    text-transform: uppercase;
		    line-height: 24px;
		    margin: 0px;
		}

			.header_10 .accordion-menu li > a i {
				margin-left: 4px;
			}

			.header_10 .accordion-menu > li:hover ul {
				display: block;
			}

			.header_10 .accordion-menu > li ul {
				position: absolute;
				background: #fff;
				border-radius: 6px;
				-webkit-border-radius: 6px;
				-moz-border-radius: 6px;
				color: #777;
				z-index: 99;
				padding: 5px 0;
				margin: 0px;
				text-align: left;
				min-width: 120px;
			}

				.header_10 .accordion-menu > li ul a {
					text-decoration: none;
					display: block;
					padding: 0 10px;
					background-color: transparent;
					border: 0;
					border-radius: 0;
					font-size: 11px;
					color: #777;
					line-height: 1.5;
					text-transform: uppercase;
				}

					.header_10 .accordion-menu > li ul a:hover {
						background: #dadada;
					}

	.header_10 #mini-cart.minicart-inline .cart-items {
		text-transform: uppercase;
	}

		.header_10 #mini-cart.dropdown .dropdown-menu {
			left: 0;
			right: auto;
		}

		.header_10 #mini-cart.dropdown .dropdown-menu:before {
			left: 17px;
			right: auto;
		}

		@media (max-width: 991px) {
			.header_10 form {
				display: none;
			}

			.header_10 #mini-cart {
				position: absolute;
				top: 32px;
				right: 20px;
			}

			.header_10 #mini-cart.dropdown .dropdown-menu {
				left: auto;
				right: 0;
			}

			.header_10 #mini-cart.dropdown .dropdown-menu:before {
				left: auto;
				right: 17px;
			}
		}

	.header_10 .logo {
		padding-top: 30px;
	}

		@media (max-width: 991px) {
			.header_10 .logo {
				padding-bottom: 5px;
				padding-top: 5px;
			}
		}

	.header_10 .container {
		width: auto !important;
	}

	.header_10 .megamenu-wrapper {
		background: none;
		text-align: left;
		padding-top: 28px;
	}


		@media (max-width: 991px) {
			.header_10 .megamenu-wrapper {
				display: none;
			}
		}

		.header_10 .megamenu-wrapper .container {
			padding-left: 10px;
			padding-right: 10px;
		}

		.header_10 ul.megamenu > li {
			float: none;
			width: 100%;
		}

			.header_10 ul.megamenu > li > a {
				height: 42px;
				border-top: 1px solid #575757;
				padding: 11px 5px 7px 5px;
			}

				.header_10 ul.megamenu > li.with-sub-menu  > a:before {
					position: absolute;
				    top: 0;
				    bottom: 0;
				    right: 5px;
				    width: 30px;
				    height: 30px;
				    line-height: 30px;
				    margin: auto;
				    text-align: center;
				    content: "";
				    display: inline-block;
				    width: 0;
				    height: 0;
				    border-top: 4px solid transparent;
				    border-bottom: 4px solid transparent;
				    border-left: 5px solid #fff;
				    margin-left: 5px;
				    margin-right: 0;
				}

				.header_10 ul.megamenu > li:hover > a {
					color: #fff;
					background: #3b3b3b;
				}

				.header_10 ul.megamenu > li:first-child > a {
					border: none;
				}

				.header_10 ul.megamenu > li.with-sub-menu > a strong:after {
					display: none;
				}

				.header_10 .horizontal ul.megamenu > li > .sub-menu {
					max-width: 900px;
					left: 100%;
					right: auto;
					margin-top: -42px;
				}

				.header_10 ul.megamenu li .sub-menu .content {
					border-top: none;
					border-left: 5px solid #9ac93d;
					border-radius: 0px 5px 5px 0px;
					-webkit-border-radius: 0px 5px 5px 0px;
					-moz-border-radius: 0px 5px 5px 0px;
				}

				.header_10 ul.megamenu .megamenu-label {
					display: inline-block;
					position: relative;
					margin-left: 15px;
				}

					.header_10 ul.megamenu ul .megamenu-label {
						margin-left: 9px;
					}

					.header_10 ul.megamenu .megamenu-label span:before {
						transform:rotate(90deg);
						-webkit-transform:rotate(90deg);
						-moz-transform:rotate(90deg);
						-o-transform:rotate(90deg);
						margin-left: -7px;
						top: auto;
						margin-top: 2px;
						bottom: auto;
					}

						.header_10 ul.megamenu ul .megamenu-label span:before {
							margin-left: 0;
						}

	.header_10 .search-toggle {
		display: none;
	}

		@media (max-width: 991px) {
			.header_10 .search-toggle {
				display: block;
				color: #fff;
				position: absolute;
				top: 36px;
				left: 50px;
			}
		}

	.header_10 .search_form {
		padding: 20px 10px 0px 10px;
		position: relative;
	}

		.header_10 .search-query {
			display: block;
			width: 100%;
			border: 0;
			height: 38px;
			background-color: #fff;
			padding: 10px 15px;
			color: #999;
			font-family: Arial;
			width: 100%;
			margin: 0;
			font-size: 13px;
			line-height: 20px;
			border-radius: 5px;
		}

		.header_10 .button-search {
			background: none;
			border: none;
			position: absolute;
			top: 25px;
			right: 17px;
		}

	@media (max-width: 991px) {
		.header_10 .search_form {
			display: none;
		}
	}

	.header_10 .top-links {
		padding: 0px 10px;
		margin: 20px 0px 0px 0px;
		list-style: none;
		text-align: left;
	}

		@media (max-width: 991px) {
			.header_10 .top-links {
				display: none;
			}
		}

		.header_10 .top-links li {
			font-size: 11px;
			text-transform: uppercase;
			line-height: 19px;
			display: block;
			padding: 0;
			border: 0;
			padding: 10px 5px;
			border-top: 1px solid #575757;
		}

			.header_10 .top-links li:first-child {
				border: none;
			}

			.header_10 .top-links li a {
				color: #fff;
			}

				.header_10 .top-links li a:hover {
					text-decoration: underline;
				}

	.header_10 .custom-block {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 20px 10px;
	}

		.header_10 .social-icons a {
			background: url(../images/social-icons-sprite.png) 0 0 no-repeat;
			width: 30px;
			height: 30px;
			background-color: #000;
			display: inline-block;
			text-indent: -9999px;
			border-radius: 3px;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			margin-left: 10px;
			line-height: 30px;
			background-color: #9e9e9e;
		    border-radius: 50%;
		}

			.header_10 .icon1-class:hover {
				background-color: #3c599b;
			}

			.header_10 .icon2-class:hover {
				background-color: #1ca8e3;
			}

			.header_10 .icon3-class:hover {
				background-color: #4b76b5;
			}

		.header_10 address {
			display: block;
			text-align: center;
			font-size: 12px;
			color: #777;
			margin-bottom: 0;
		}

		@media (max-width: 991px) {
			.header_10 .custom-block {
				display: none;
			}
		}

.body-header-type-10 .page-top {
	background: #fff;
}

	.body-header-type-10 .breadcrumb > li > a,
	.body-header-type-10 .breadcrumb > .active,
	.body-header-type-10 .breadcrumb > li + li:before {
		color: #000;
	}

	.body-header-type-10 #footer {
		background: #d7dae0;
		color: #828385;
	}

	.body-header-type-10 #footer a,
	.body-header-type-10 #footer a:hover {
		color: #828385;
	}

	.body-header-type-10 #footer .widget-title {
		color: #000000;
	}

	.body-header-type-10 #footer #footer-bottom {
		background: #e1e3e8;
		color: #777;
	}

.index10-slide-btn {
	display: inline-block;
	    font-size: 16px;
	    line-height: 20px;
	    padding: 9px 25px;
	    font-weight: normal;
	    color: #777 !important;
	    background-color: #fefefe;
	    border-radius: 5px;
}

.body-header-type-10 .container {
	max-width: 100%;
}

/* @end */

/* @group Header type 11 */

.body-header-type-11 .megamenu-wrapper {
	background: #fff;
	padding-bottom: 10px;
}

	.body-header-type-11 .fixed-header .megamenu-wrapper {
		padding-bottom: 0;
	}

	.header10 ul.megamenu {
		text-align: center;
	}

	.header10.fixed-header ul.megamenu {
		text-align: left;
	}

	.header10 ul.megamenu > li {
		display: inline-block !important;
		float: none !important;
		margin: 0px !important;
		margin-right: 3px !important;
		text-align: left;
	}

	.header10 ul.megamenu > li > a {
		color: #333;
		padding: 9px 14px;
		font-size: 0.8571em;
	    font-weight: bold;
	    line-height: 20px;
	    text-transform: uppercase;
	    border-radius: 5px;
	    -webkit-border-radius: 5px;
	    -moz-border-radius: 5px;
	    height: 38px;
	}

	.header10 ul.megamenu > li > a:hover,
	.header10 ul.megamenu > li.active > a,
	.header10 ul.megamenu > li.home > a,
	.header10 ul.megamenu > li:hover > a {
		color: #fff;
		background: #7aa93c;
	}

		.header10 ul.megamenu > li.with-sub-menu:hover > a {
			border-radius: 5px 5px 0px 0px;
			-webkit-border-radius: 5px 5px 0px 0px;
			-moz-border-radius: 5px 5px 0px 0px;
		}

		.header10 ul.megamenu .megamenu-label {
			right: 10px;
			top: -7px;
		}

			.header10.fixed-header ul.megamenu .megamenu-label {
				top: -1px;
			}

	.header10#header .currency-switcher li > ul,
	.header10#header .view-switcher li > ul {
		background: #f7f7f7;
	}

		.header10#header .currency-switcher li > ul li:hover a,
		.header10#header .view-switcher li > ul li:hover a {
			background: #fafafa;
		}

/* @end */

/* @group Header type 12 */

.header11 .megamenu-wrapper {
	background: #393f4f;
}

	.header11 ul.megamenu > li > a:hover,
	.header11 ul.megamenu > li.active > a,
	.header11 ul.megamenu > li.home > a,
	.header11 ul.megamenu > li:hover > a {
		color: #ee3d43;
	}

	.header11#header .currency-switcher li > ul,
	.header11#header .view-switcher li > ul {
		background: #ee3d43;
	}

		.header11#header .currency-switcher li ul li:hover a,
		.header11#header .view-switcher li ul li:hover a {
			background: #d92b34;
		}

.skin12 .banner,
.skin12 .banner img,
.skin12 .banner a:after {
	border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
}

/* @end */

/* @group Index 12 slider */

.index12-slider .col-sm-9 {
	width: 72.34%;
}

.index12-slider .camera_slider {
	margin-bottom: 0;
}

@media (max-width: 767px) {
	.index12-slider .camera_slider {
		margin-bottom: 10px;
	}
}

.index12-slider .col-sm-3 {
	width: 27%;
}

@media (max-width: 767px) {

	.index12-slider .col-sm-9 {
		width: 100%;
	}

	.index12-slider .col-sm-3 {
		width: 100%;
	}
}

/* @end */

/* @group Header type 13 */

.header12 .megamenu-wrapper {
	background: #54555e;
	border-bottom: 4px solid #3f4048;
	margin-bottom: 16px;
}

	.header12 ul.megamenu > li {
		margin: 0px 2px 0px 0px;
	}

		.header12 ul.megamenu .megamenu-label {
			top: -7px;
			right: 10px;
		}

		.header12.fixed-header ul.megamenu .megamenu-label {
			top: 0;
		}

		.header12 ul.megamenu > li.pull-right {
			margin: 0px 0px 0px 2px;
		}

		.header12 ul.megamenu > li > a {
			padding: 17px 20px;
			height: 54px;
		}

		.header12 ul.megamenu > li > a:hover,
		.header12 ul.megamenu > li.active > a,
		.header12 ul.megamenu > li.home > a,
		.header12 ul.megamenu > li:hover > a {
			background: #000;
			color: #fff;
			position: relative;
			margin-bottom: -4px;
			height: 58px;
			border-bottom: 4px solid #000;
		}

/* @end */

/* @group Skin13 */

.skin13 .mfilter-heading,
.skin13 .mfilter-content > ul > li,
.skin13 a.quickview-icon,
.skin13 .product-filter .options .button-group button,
.skin13 .nav-tabs > li > a,
.skin13 .product-list .btn-add-cart,
.skin13 .product-list .product-btn.btn-wishlist,
.skin13 .product-list .product-btn.btn-quickview,
.skin13 .product-info .thumbnails li img,
.skin13 .product-info .thumbnails-carousel img,
.skin13 .btn-default,
.skin13 .btn,
.skin13 .button,
.skin13 select,
.skin13 input,
.skin13 textarea,
.skin13 .product-info .product-image,
.skin13 .well,
.skin13 .box,
.skin13 .box .box-heading,
.skin13 table.attribute,
.skin13 table.list,
.skin13 .wishlist-product table,
.skin13 .wishlist-info table,
.skin13 .compare-info,
.skin13 .cart-info table,
.skin13 .checkout-product table,
.skin13 .table,
.skin13 .center-column .panel-heading,
.skin13 .quickcheckout-heading,
body.skin13 .quickcheckout-content,
.skin13 .mfilter-heading .mfilter-head-icon,
.skin13 .box .box-heading .toggle,
.skin13 ul.megamenu li .sub-menu .content,
.skin13 .popup,
.skin13 .banner, .skin13 .banner img, .skin13 .banner a:after,
.skin13 .posts .post .post-media,
.skin13 .posts .button-more,
.skin13 #header .currency-switcher li > ul,
.skin13 #header .view-switcher li > ul,
.skin13 #scroll-top,
.skin13 .product-center .single-add-to-wishlist a:before,
.skin13 .product-center .add-to-cart .quantity #q_up,
.skin13 .product-center .add-to-cart .quantity #q_down,
.skin13 .product-center .add-to-cart .quantity input,
.skin13 .alert,
.skin13 ul.product_list_widget li .product-image img,
.skin13 .widget ul.product_list_widget li .product-image img,
.skin13 ul.product_list_widget li .product-image,
.skin13 .widget ul.product_list_widget li .product-image,
.skin13 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin13 #mini-cart .mini-cart-info table td.image img,
.skin13 .product-image,
.skin13 .product-image img,
.skin13 .product-action .product-btn,
.skin13 .prev-next-products .product-nav .product-pop,
.skin13 .pagination > li > a,
.skin13 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin13 #header .currency-switcher li > ul,
.skin13 #header .view-switcher li > ul {
	background: #fff;
}

	.skin13 #header .currency-switcher li > ul li:hover a,
	.skin13 #header .view-switcher li > ul li:hover a {
		color: #777 !important;
	}

.skin13 .page-top {
	background: none;
	margin: 0px;
}

	.skin13 .breadcrumb > li > a,
	.skin13 .breadcrumb > .active,
	.skin13 .breadcrumb > li + li:before {
		color: #777;
	}

/* @end */

/* @group Index3 */

.index13-slide-btn {
	display:inline-block;
	font-size:14px;
	line-height: 20px;
	padding:7.5px 25px;
	font-weight: 600;
	background-color: #fefefe !important;
	color: #777 !important;
}

	.index13-slide-btn:hover {
		background-color: rgba(250, 250, 250, 0.9);
		color:#000;
	}

/* @end */

/* @group Header type 14 */

.common-home.body-header-type-14 .main-content {
	padding-bottom: 0;
	min-height: 0px;
}

.common-home.body-header-type-14 #footer > .container {
	display: none;
}

.body-header-type-14 #footer #footer-bottom {
	background: #fff;
}

.body-header-type-14.common-home #header.header2 {
	position: relative;
	background: #fff;
}

#header.header13 .currency-switcher li > ul,
#header.header13 .view-switcher li > ul {
	min-width: 100px;
}

#header.header13 .currency-switcher li:hover > a,
#header.header13 .view-switcher li:hover > a {
	background: #fafafa !important;
}

.common-home .header13 ul.megamenu > li:first-child,
.header13 ul.megamenu > li:hover {
	background: #9ac93d;
}

.header13 ul.megamenu > li > a {
	color: #9ac93d;
}

	.common-home .header13 ul.megamenu > li:first-child > a,
	.header13 ul.megamenu > li:hover > a {
		color: #fff;
	}

		.header13 ul.megamenu > li.with-sub-menu:hover {
			border-radius: 5px 5px 0px 0px;
			-webkit-border-radius: 5px 5px 0px 0px;
			-moz-border-radius: 5px 5px 0px 0px;
		}

.header13 .megamenu-wrapper {
	background: #fff;
}

	.common-home .header13.fixed-header ul.megamenu > li:first-child,
	.header13.fixed-header ul.megamenu > li:hover {
		background: none;
	}

	.common-home .header13.fixed-header ul.megamenu > li:first-child > a,
	.header13.fixed-header ul.megamenu > li:hover > a {
		color: #9ac93d;
	}

/* @end */

.btn-dark {
 	background: #333;
}

.btn-white {
	background-color: #fff;
	color: #333;
}

/* @group Header type 15 */

.body-header-type-15 .container-megamenu {
	margin-bottom: 15px;
}

.body-header-type-15 .megamenu-wrapper {
	background: none;
}

.body-header-type-15 ul.megamenu:before,
.body-header-type-15 ul.megamenu:after{
  display: table;
  content: " ";
}
.body-header-type-15 ul.megamenu:after {
  clear: both;
}

.body-header-type-15 ul.megamenu {
	background: #f0f0ed;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}

.body-header-type-15 ul.megamenu > li {
	margin: 0px 2px 0px 0px;
}

.body-header-type-15 ul.megamenu > li.pull-right {
	margin: 0px 0px 0px 2px;
}

.body-header-type-15 ul.megamenu > li > a {
	height: 40px;
	padding: 11px 13px 9px 13px;
	color: #9ac93d;
}

	.body-header-type-15 ul.megamenu > li.home > a,
	.body-header-type-15 ul.megamenu > li:hover > a {
		background: #9ac93d;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		color: #fff;
	}

	.body-header-type-15 ul.megamenu > li:hover > a{
		border-radius: 6px 6px 0 0;
		-webkit-border-radius: 6px 6px 0 0;
		-moz-border-radius: 6px 6px 0 0;
	}

.body-header-type-15 ul.megamenu .megamenu-label {
	top: -4px;
	right: 13px;
}

	.body-header-type-15 .fixed-header ul.megamenu .megamenu-label {
		top: 0;
	}

.body-header-type-15 #header.header3 ul.megamenu .menu {
	background: none;
}

.tp-caption .index15-slide-btn {
	display:inline-block;
	font-size:16px;
	line-height: 30px;
	padding:8px 20px;
	font-weight: 600;
	color:#fff;
	background-color: #08c;
	border-radius: 7px;
	opacity:1;
	min-width: 170px;
	text-transform: uppercase;
	text-align: center;
}

.tp-caption .index15-slide-btn:hover {
	opacity:0.9;
	color:#fff;
}

.body-header-type-15 .overflow-menu {
	position: relative;
	display: block !important;
}

	.body-header-type-15 .overflow-menu .container {
		padding: 0px !important;
		width: auto !important;
		display: block !important;
	}

	.body-header-type-15 .overflow-menu #mini-cart {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0px;
		height: 40px;
		z-index: 1000;
		background: #08c !important;
		padding: 7px 0;
	}

		.body-header-type-15 .overflow-menu #mini-cart .minicart-icon {
			color: #fff !important;
		}

		.body-header-type-15 .overflow-menu #mini-cart .cart-head {
			text-transform: uppercase;
			color: #fff;
			font-size: 12px;
		}

	@media (max-width: 991px) {
		.body-header-type-15 .overflow-menu #mini-cart {
			top: -70px;
		}
	}

	@media (max-width: 767px) {
		.body-header-type-15 .overflow-menu #mini-cart {
			top: -60px !important;
		}
	}

	@media (max-width: 480px) {
		.body-header-type-15 #header.header3 #mini-cart .cart-items {
			color: #fff;
		}
	}

/* @end */

/* @group Header type 16 */

.header15 .megamenu-wrapper {
	background: none;
}

	.header15.fixed-header .megamenu-wrapper {
		background: #f3f5f4;
	}

.header15 ul.megamenu > li > a {
	color: #796848;
}

.header15 ul.megamenu > li > a:hover,
.header15 ul.megamenu > li.active > a,
.header15 ul.megamenu > li.home > a,
.header15 ul.megamenu > li:hover > a {
	color: #39333f;
}

.skin16 .mfilter-heading,
.skin16 .mfilter-content > ul > li,
.skin16 a.quickview-icon,
.skin16 .product-filter .options .button-group button,
.skin16 .nav-tabs > li > a,
.skin16 .product-list .btn-add-cart,
.skin16 .product-list .product-btn.btn-wishlist,
.skin16 .product-list .product-btn.btn-quickview,
.skin16 .product-info .thumbnails li img,
.skin16 .product-info .thumbnails-carousel img,
.skin16 .btn-default,
.skin16 .btn,
.skin16 .button,
.skin16 select,
.skin16 input,
.skin16 textarea,
.skin16 .product-info .product-image,
.skin16 .well,
.skin16 .box,
.skin16 .box .box-heading,
.skin16 table.attribute,
.skin16 table.list,
.skin16 .wishlist-product table,
.skin16 .wishlist-info table,
.skin16 .compare-info,
.skin16 .cart-info table,
.skin16 .checkout-product table,
.skin16 .table,
.skin16 .center-column .panel-heading,
.skin16 .quickcheckout-heading,
body.skin16 .quickcheckout-content,
.skin16 .mfilter-heading .mfilter-head-icon,
.skin16 .box .box-heading .toggle,
.skin16 ul.megamenu li .sub-menu .content,
.skin16 .popup,
.skin16 .banner, .skin16 .banner img, .skin16 .banner a:after,
.skin16 .posts .post .post-media,
.skin16 .posts .button-more,
.skin16 #header .currency-switcher li > ul,
.skin16 #header .view-switcher li > ul,
.skin16 #scroll-top,
.skin16 .product-center .single-add-to-wishlist a:before,
.skin16 .product-center .add-to-cart .quantity #q_up,
.skin16 .product-center .add-to-cart .quantity #q_down,
.skin16 .product-center .add-to-cart .quantity input,
.skin16 .alert,
.skin16 ul.product_list_widget li .product-image img,
.skin16 .widget ul.product_list_widget li .product-image img,
.skin16 ul.product_list_widget li .product-image,
.skin16 .widget ul.product_list_widget li .product-image,
.skin16 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin16 #mini-cart .mini-cart-info table td.image img,
.skin16 .product-image,
.skin16 .product-image img,
.skin16 .product-action .product-btn,
.skin16 .prev-next-products .product-nav .product-pop,
.skin16 .post .tags a,
.skin16 .pagination > li > a,
.skin16 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin16 .main-content {
	padding-bottom: 25px;
}

.skin16.common-home .main-content {
	padding-bottom: 0;
}

.skin16 .footer-wrapper {
	background: #e7e8e9;
	padding: 45px 30px 10px 30px;
}

@media (max-width: 767px) {
	.skin16 .footer-wrapper {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
		padding-bottom: 0;
	}
}

/* @end */

/* @group Slider 16 */

.slider16 .rev-subtitle {
    font-size: 18px;
    color: #666;
    text-align: left;
    margin: 0;
    font-family: Georgia,  sans-serif !important;
}

.slider16 .rev-title {
	font-size: 44px;
	color: #141414;
	margin: 0;
	font-family: Georgia,  sans-serif !important;
}

.slider16 .rev-hr {
	display: inline-block;
	width: 50%;
	border-top: 1px solid #151515;
	background: none;
	margin: 8px 0;
	text-align: right;
}

.slider16 .rev-link {
	display: block;
	text-align: right;
}

	.slider16 .rev-link a {
		display: inline-block;
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		color: #2b262f;
		font-family: Georgia !important;
	}

/* @end */

/* @group Header type 17 */

.fixed-header17 .megamenu-wrapper {
	background: #5c616e;
}

	.fixed-header17 ul.megamenu > li > a:hover,
	.fixed-header17 ul.megamenu > li.active > a,
	.fixed-header17 ul.megamenu > li.home > a,
	.fixed-header17 ul.megamenu > li:hover > a {
		color: #fff;
	}

.header17 {
	background: #5c616e !important;
}

	.header17 > .container {
		position: relative;
	}

		.common-home .header17 > .container {
			width: auto;
			padding-left: 45px;
			padding-right: 30px;
			border-bottom: 1px solid rgba(255,255,255,.25);
		}

			@media (max-width: 991px) {
				.common-home .header17 > .container {
					padding-left: 15px;
					padding-right: 15px;
				}
			}

	.common-home .header17 {
		background: none !important;
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}

		.header17 .logo {
			padding-top: 27px;
			padding-bottom: 27px;
			display: block;
			margin: 0px !important;
			float: left;
		}

			.header17 .logo img {
				position: relative;
			}

	.header17 .left {
		float: left;
		padding-left: 24px;
		padding-top: 36px;
		padding-bottom: 36px;
	}

		@media (max-width: 991px) {
			.header17 .left {
				padding-top: 38px;
			}
		}

	.header17 .megamenu-wrapper {
		background: none;
	}

	.header17 ul.megamenu,
	.header17 .container-megamenu,
	.header17 .container-megamenu .container,
	.header17 .megamenu-wrapper {
		position: static;
	}

	.header17 ul.megamenu > li {
		margin: 0px 0px 0px 0px;
	}

	.header17 ul.megamenu > li > a {
		height: 32px;
		padding: 7px 11px 0px 11px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
	}

		.header17 ul.megamenu > li > a:hover,
		.header17 ul.megamenu > li.active > a,
		.header17 ul.megamenu > li.home > a,
		.header17 ul.megamenu > li:hover > a {
			color: #fff;
		}

			.header17 ul.megamenu .megamenu-label {
				top: -5px;
			}

			.header17#header .mobile-toggle {
				left: 5px;
				top: -3px;
			}

	.header17 .container > div > .right {
		float: right;
		padding-top: 37px;
		padding-bottom: 35px;
	}

		.header17 #mini-cart.minicart-inline {
			background: #fff !important;
			top: 0 !important;
			right: 0 !important;
		}

		.header17 #mini-cart.minicart-inline .cart-items {
			color: #101010 !important;
		}

		.header17 #mini-cart .minicart-icon {
			color: #101010;
		}

		.header17 .separator {
			display: none;
		}

		.header17#header .currency-switcher > li > a,
		.header17#header .view-switcher > li > a {
			background: #fff;
			border-radius: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			color: #101010;
			margin-top: -1px;
			margin-right: 0;
			margin-left: 0;
		}

			.header17#header .currency-switcher > li:hover > a,
			.header17#header .view-switcher > li:hover > a  {
				border-radius: 6px 6px 0 0;
				-webkit-border-radius: 6px 6px 0 0;
				-moz-border-radius: 6px 6px 0 0;
				background: #fff !important;
			}

			.header17#header .currency-switcher li > ul,
			.header17#header .view-switcher li > ul {
				min-width: 120px;
				text-align: left;
				background: #fff !important;
			}

				.header17#header .currency-switcher li > ul a,
				.header17#header .view-switcher li > ul a {
					color: #101010;
					background: none !important;
				}

					.header17#header .currency-switcher li > ul a:hover,
					.header17#header .view-switcher li > ul a:hover {
						background: #fafafa !important;
					}

		.header17#header .currency-switcher,
		.header17#header .view-switcher {
			margin-left: 6px;
		}

		@media (max-width: 991px) {
			.header17#header .currency-switcher,
			.header17#header .view-switcher {
				display: none;
			}
		}

		#header.header17 .searchform-popup .search-toggle {
			display: block;
		}

		@media (max-width: 480px) {
			#header.header17 .mobile-toggle {
				position: static;
			}

			#header.header17 .searchform-popup {
				position: relative;
				top: auto;
				left: auto;
				right: auto !important;
				bottom: auto;
			}

			#header.header17 #mini-cart {
				position: relative;
			}
		}

.skin17 .banner,
.skin17 .banner img,
.skin17 .banner a:after {
	border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
}

.skin17.common-home .product-action {
	display: none;
}

.skin17 .slider-title .line {
	display: none;
}

.skin17 .carousel-wrapper .owl-controls {
	display: none;
}

.slide17-btn {
	display: inline-block;
	font-size: 16px;
	line-height: 23px;
	font-weight: 600;
	padding: 9.5px 33.5px;
	font-weight: 600;
	color: #333 !important;
	background-color: #fefefe;
	border-radius: 5px;
}

	.slide17-btn:hover {
		background: rgba(255, 255, 255, .9);
	}

.slide17-link {
	color: #fff !important;
	font-size: 16px;
	line-height: 1;
}

	.slide17-link:hover {
		text-decoration: underline !important;
	}

/* @end */

/* @group Skin17 */

.skin17-2 .mfilter-heading,
.skin17-2 .mfilter-content > ul > li,
.skin17-2 a.quickview-icon,
.skin17-2 .product-filter .options .button-group button,
.skin17-2 .nav-tabs > li > a,
.skin17-2 .product-list .btn-add-cart,
.skin17-2 .product-list .product-btn.btn-wishlist,
.skin17-2 .product-list .product-btn.btn-quickview,
.skin17-2 .product-info .thumbnails li img,
.skin17-2 .product-info .thumbnails-carousel img,
.skin17-2 .btn-default,
.skin17-2 .btn,
.skin17-2 .button,
.skin17-2 select,
.skin17-2 input,
.skin17-2 textarea,
.skin17-2 .product-info .product-image,
.skin17-2 .well,
.skin17-2 .box,
.skin17-2 .box .box-heading,
.skin17-2 table.attribute,
.skin17-2 table.list,
.skin17-2 .wishlist-product table,
.skin17-2 .wishlist-info table,
.skin17-2 .compare-info,
.skin17-2 .cart-info table,
.skin17-2 .checkout-product table,
.skin17-2 .table,
.skin17-2 .center-column .panel-heading,
.skin17-2 .quickcheckout-heading,
body.skin17-2 .quickcheckout-content,
.skin17-2 .mfilter-heading .mfilter-head-icon,
.skin17-2 .box .box-heading .toggle,
.skin17-2 ul.megamenu li .sub-menu .content,
.skin17-2 .popup,
.skin17-2 .banner, .skin17-2 .banner img, .skin17-2 .banner a:after,
.skin17-2 .posts .post .post-media,
.skin17-2 .posts .button-more,
.skin17-2 #header .currency-switcher li > ul,
.skin17-2 #header .view-switcher li > ul,
.skin17-2 #scroll-top,
.skin17-2 .product-center .single-add-to-wishlist a:before,
.skin17-2 .product-center .add-to-cart .quantity #q_up,
.skin17-2 .product-center .add-to-cart .quantity #q_down,
.skin17-2 .product-center .add-to-cart .quantity input,
.skin17-2 .alert,
.skin17-2 ul.product_list_widget li .product-image img,
.skin17-2 .widget ul.product_list_widget li .product-image img,
.skin17-2 ul.product_list_widget li .product-image,
.skin17-2 .widget ul.product_list_widget li .product-image,
.skin17-2 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin17-2 #mini-cart .mini-cart-info table td.image img,
.skin17-2 .prev-next-products .product-nav .product-pop,
.skin17-2 .product-image,
.skin17-2 .product-image img,
.skin17-2 .product-action .product-btn,
.skin17-2 .prev-next-products .product-nav .product-pop,
.skin17-2 .post .tags a,
.skin17-2 .pagination > li > a,
.skin17-2 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.header18#header #header-top {
	background: #f9f7f7;
	z-index: 1000;
}

.header18#header {
	background: #f9f9f9;
}

.common-home .header18#header {
	position: absolute;
	z-index: 99;
	left: 0;
	right: 0;
	background: rgba(249,249,249, 0.75);
}

	@media (max-width: 991px) {
		.common-home .header18#header {
			position: relative;
			background: #f9f9f9;
		}
	}

.header18#header #header-inner .container .header-left {
	text-align: center;
	display: block;
	padding: 0px;
}

	.header18#header #header-inner .container .header-left h1 {
		position: absolute;
		left: 40%;
		right: 40%;
		padding: 32px 0px;
		z-index: 1000;
	}

.header18#header #header-inner .container {
	min-height: 0;
}

.header18 .megamenu-wrapper {
	background: none;
	padding: 34px 0px;
}

.header18.fixed-header .megamenu-wrapper {
	background: #f9f9f9;
	padding: 0px;
}

.header18 ul.megamenu > li > a {
	color: #2c3232;
}

	.header18 ul.megamenu > li > a:hover,
	.header18 ul.megamenu > li.active > a,
	.header18 ul.megamenu > li.home > a,
	.header18 ul.megamenu > li:hover > a {
		color: #b74173;
	}

.header18 ul.megamenu > li {
	margin-right: 35px;
}

.header18 ul.megamenu > li.pull-right {
	margin-left: 35px;
}

#header.header18 .currency-switcher a,
.header18#header .view-switcher a,
.header18#header .top-links a,
.header18#header {
	color: #777;
}

.header18#header .currency-switcher li > ul,
.header18#header .view-switcher li > ul {
	background: #b74173;
}

	.header18#header .currency-switcher li > ul a,
	.header18#header .view-switcher li > ul a {
		color: #fff;
	}

#header.header18 #header-top .currency-switcher > li:hover > a,
#header.header18 #header-top .view-switcher > li:hover > a {
	background: #b74173;
}

#header.header18 .currency-switcher li ul li:hover a,
#header.header18 .view-switcher li ul li:hover a {
	background: #ce5691;
}

.header18#header .searchform-popup .search-toggle {
	color: #777;
	display: block;
	width: auto;
}

.header18#header .searchform-popup {
	padding-top: 3px;
	height: 30px;
	vertical-align: top;
	padding-right: 4px;
}

.header18 #mini-cart.minicart-inline {
	background: none !important;
}

.header18 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
}

.header18 #mini-cart .minicart-icon,
.header18 #mini-cart.minicart-inline .cart-items {
	color: #b74173;
	font-size: 11px;
	text-transform: uppercase;
}

.header18 #mini-cart.minicart-inline .dropdown-menu {
	border-color: #b74173;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.header18 #mini-cart .dropdown-cart-content {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.header18 #mini-cart.dropdown.minicart-inline .dropdown-menu:before {
	border-bottom-color: #b74173;
}

@media (max-width: 991px) {
	.header18#header #header-inner .container .header-left h1 {
		position: relative;
		left: auto;
		right: auto;
		z-index: 1;
	}

	.header18#header .mobile-toggle {
		color: #b74173;
		padding-top: 2px;
		vertical-align: top;
		font-size: 14px;
		padding-right: 5px;
		position: relative !important;
		left: auto !important;
		top: auto !important;
		right: auto !important;
		bottom: auto !important;
	}

	.header18#header .searchform-popup {
		padding-top: 2px;
		position: relative !important;
		left: auto !important;
		top: auto !important;
		right: auto !important;
		bottom: auto !important;
	}

	.header18 .overflow-currency-language {
		display: none !important;
	}

	.header18#header #header-inner .container .header-left h1 {
		padding: 15px 0px 30px 0px;
		margin: 0px!important;
	}

	.header18#header #header-top {
		margin: 0px -7px;
	}
}

.header18 .overflow-currency-language {
	display: inline-block;
}

@media (max-width: 480px) {
	.header18#header #mini-cart .cart-items {
		color: #777;
	}

	.header18#header #mini-cart {
		position: relative !important;
		left: auto !important;
		top: 1px !important;
		right: auto !important;
		bottom: auto !important;
	}

	.header18#header #header-inner .container {
		padding: 0px;
	}
}

.skin17-2 #footer #footer-bottom {
	background: none;
}

.skin17-2 #footer {
	  background: url(../../../../../image/catalog/magic/bg.jpg) bottom  repeat-x;
	color: #fff;
}


.skin17-2 #footer a,
.skin17-2 #footer a:hover {
	color: #fff;
}


.skin17-2 #footer .newsletter-widget .input-box input[type="text"],
.skin17-2 .newsletter-widget .input-box  input[type="email"] {
	background: none;
	max-width: none;
}

.skin17-2 .newsletter-widget input[type="submit"] {
	background: none !important;
	border: 1px solid #ccc !important;
	color: #777;
}

.skin17-2 #footer .info-boxes-container {
	background: none;
	border: none;
	padding: 0px;
	margin: 0px -10px;
}

.skin17-2 #footer .info-box-container {
	border-color: #515656;
	color: #fff;
}

.skin17-2 #footer  .info-box-icon {
	color: #fff;
}

.skin17-2 #footer  .info-box-content .info-box-title {
	color: #fff;
}

@media (max-width: 767px) {
	.skin17-2 #footer .info-box {
		text-align: left;
	}

	.skin17-2 #footer .info-boxes-container {
		margin: 0px -5px;
	}

}

.skin17-2 .product-grid > .row > div .product-action {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	opacity: 0;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
}

	.skin17-2 .product-grid > .row > div:hover .product-action {
		opacity: 1;
	}

	.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-wishlist {
		margin: 0;
		background-color: #e6e6e6;
		color: #000;
		border: 0;
		font-size: 13px;
		right: -4px;
		visibility: visible;
		opacity: 1;
		filter: alpha(opacity=100);
		width: 40px;
		height: 40px;
		line-height: 40px;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
	}

	.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-quickview {
		margin: 0;
		background-color: #e6e6e6;
		color: #000;
		border: 0;
		font-size: 13px;
		left: -4px;
		visibility: visible;
		opacity: 1;
		filter: alpha(opacity=100);
		width: 40px;
		height: 40px;
		line-height: 40px;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
	}

	.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-add-cart {
		height: 40px;
		border: none;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		margin: 0px;
		background: #9ac93d;
		color: #fff;
		vertical-align: top;
		padding: 0 20px 0 15px;
		font-size: 13px;
		line-height: 40px;
		text-transform: uppercase;
		font-weight: 600;
	}

		@media (max-width: 991px) {
			.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-add-cart {
				width: 40px;
				text-align: center;
				padding: 0px 13px;
			}

			.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-add-cart.button-enquiry {
				display: none;
			}

			.skin17-2 .product-grid > .row > div .product-action .product-btn.btn-add-cart span {
				display: none;
			}
		}

.skin17-2 .product-grid > .row > div {
	border-right: 1px solid #e3e4e4;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	position: relative;
	margin-top: -1px;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	padding-bottom: 0 !important;
}

	.skin17-2 .product-grid > .row > div:hover {
		border-top: 1px solid #e3e4e4;
		border-bottom: 1px solid #e3e4e4;
		box-shadow:0 0 40px rgba(0,0,0,0.1);
		-webkit-box-shadow:0 0 40px rgba(0,0,0,0.1);
		-moz-box-shadow:0 0 40px rgba(0,0,0,0.1);
	}

	.skin17-2 .product-grid > .row > div:last-child {
		border-right: none;
	}

.skin17-2 .product-grid .product {
	padding-bottom: 30px !important;
	padding-top: 50px;
	border-top: 1px solid #e3e4e4;
	margin-top: -1px;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
}

	.skin17-2 .product-grid > .row > div:hover .product {
		padding-top: 10px;
		padding-bottom: 70px !important;
	}

	.skin17-2 .product-grid .row:first-child .product {
		border-top: none;
	}

	@media (max-width: 767px) {
		.skin17-2 .product-grid .row > div:nth-child(odd) {
			clear: both;
		}
		.skin17-2 .product-grid .row:first-child .product {
			border-top: 1px solid #e3e4e4;
		}
		.skin17-2 .product-grid .row:first-child > div:first-child .product,
		.skin17-2 .product-grid .row:first-child > div:nth-child(2) .product {
			border-top: none;
		}

		.skin17-2 .product-grid .row > div:nth-child(2n) {
			border-right: none;
		}
	}

#mfilter-content-container .product-grid {
	padding-bottom: 0px;
}

.skin17-2 #mfilter-content-container .product-grid {
	padding-bottom: 30px;
}

.skin17-2 .product-grid .product .product-image {
	border: none;
	padding: 0px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

	.skin17-2 .product-grid .product .product-image img,
	.skin17-2 .product-grid .product .onhot,
	.skin17-2 .product-grid .product .onsale,
	.skin17-2 .product-image,
	.skin17-2 .product-image img {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
/*
.skin17-2.common-home .slider-title {
	display: none;
}   */

.skin17-2 .carousel-wrapper > div {
	margin-bottom: -3px !important;
}

.skin17-2.common-home .main-content {
	padding-bottom: 0;
}

.skin17-brands > div {
	padding-top: 10px;
	border-right: 1px solid #e3e3e3;
	text-align: center;
}

	.skin17-brands > div:last-child {
		border-right: none;
	}

	@media (max-width: 767px) {
		.skin17-brands > div:nth-last-child(2),
		.skin17-brands > div:last-child {
			border-top: 1px solid #e3e3e3;
		}
	}

.skin17-button {
	color: #fff !important;
	background: #b74173;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	display: block;
	padding: 10px 35px 10px 35px;
}

/* @end */

@media (max-width: 767px) {
	.tp-caption.rev-subtitle.subtitle2 {
		font-size: 17px;
		line-height: 1.3;
	}
}

@media (max-width: 480px) {
	.tp-caption.rev-subtitle.subtitle2 {
		font-size: 12px;
		line-height: 1.3;
	}
}

@media (max-width: 767px) {
	.tp-caption.index3_text_1 {
		font-size: 15px !important;
		line-height: 1.3 !important;
	}
}

@media (max-width: 480px) {
	.tp-caption.index3_text_1 {
		font-size: 10px !important;
		line-height: 1.3 !important;
	}
}

/* @group Header type 19 */

.skin18 .mfilter-heading,
.skin18 .mfilter-content > ul > li,
.skin18 a.quickview-icon,
.skin18 .product-filter .options .button-group button,
.skin18 .nav-tabs > li > a,
.skin18 .product-list .btn-add-cart,
.skin18 .product-list .product-btn.btn-wishlist,
.skin18 .product-list .product-btn.btn-quickview,
.skin18 .product-info .thumbnails li img,
.skin18 .product-info .thumbnails-carousel img,
.skin18 .btn-default,
.skin18 .btn,
.skin18 .button,
.skin18 select,
.skin18 input,
.skin18 textarea,
.skin18 .product-info .product-image,
.skin18 .well,
.skin18 .box,
.skin18 .box .box-heading,
.skin18 table.attribute,
.skin18 table.list,
.skin18 .wishlist-product table,
.skin18 .wishlist-info table,
.skin18 .compare-info,
.skin18 .cart-info table,
.skin18 .checkout-product table,
.skin18 .table,
.skin18 .center-column .panel-heading,
.skin18 .quickcheckout-heading,
body.skin18 .quickcheckout-content,
.skin18 .mfilter-heading .mfilter-head-icon,
.skin18 .box .box-heading .toggle,
.skin18 ul.megamenu li .sub-menu .content,
.skin18 .popup,
.skin18 .banner, .skin18 .banner img, .skin18 .banner a:after,
.skin18 .posts .post .post-media,
.skin18 .posts .button-more,
.skin18 #header .currency-switcher li > ul,
.skin18 #header .view-switcher li > ul,
.skin18 #scroll-top,
.skin18 .product-center .single-add-to-wishlist a:before,
.skin18 .product-center .add-to-cart .quantity #q_up,
.skin18 .product-center .add-to-cart .quantity #q_down,
.skin18 .product-center .add-to-cart .quantity input,
.skin18 .alert,
.skin18 ul.product_list_widget li .product-image img,
.skin18 .widget ul.product_list_widget li .product-image img,
.skin18 ul.product_list_widget li .product-image,
.skin18 .widget ul.product_list_widget li .product-image,
.skin18 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin18 #mini-cart .mini-cart-info table td.image img,
.skin18 .prev-next-products .product-nav .product-pop,
.skin18 .product-image,
.skin18 .product-image img,
.skin18 .product-action .product-btn,
.skin18 .prev-next-products .product-nav .product-pop,
.skin18 .post .tags a,
.skin18 #mini-cart.dropdown .dropdown-menu,
.skin18 #mini-cart .dropdown-cart-content,
.skin18 .product-image .onhot,
.skin18 .product-image .onsale,
.skin18 .pagination > li > a,
.skin18 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

#header.header19 {
	position: relative;
	z-index: 999;
	background: #fff;
}

	.common-home #header.header19 {
		background: none;
		position: absolute;
		left: 0;
		right: 0;
	}

.header19 ul.megamenu,
.header19 .container-megamenu .container,
.header19 .container-megamenu,
.header19 .megamenu-wrapper {
	position: static !important;
}

.header19 .horizontal {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}

	@media (max-width: 991px) {
		.header19 .horizontal {
			display: none;
		}
	}

	.header19.fixed-header .horizontal {
		display: block;
		float: right;
	}

		.header19.fixed-header .horizontal ul.megamenu {
			position: static !important;
		}

	.header19 .megamenu-wrapper {
		background: none;
	}

	.header19 ul.megamenu > li {
		margin: 0px 4px 0px 0px;
	}

	.header19 ul.megamenu > li > a {
		color: #3d3734;
		height: 30px;
		padding: 6px 15px 6px;
	}

	.header19 ul.megamenu > li > a:hover,
	.header19 ul.megamenu > li.active > a,
	.header19 ul.megamenu > li.home > a,
	.header19 ul.megamenu > li:hover > a {
		background: #3d3734;
		color: #fff;
	}

	.header19 ul.megamenu .megamenu-label {
		top: -6px;
		right: 15px;
	}

		.header19 ul.megamenu ul .megamenu-label {
			right: auto;
		}

#header.header19 .searchform {
	display: none;
}

#header.header19 .searchform-popup .search-toggle {
	display: inline-block;
	color: #ea6a4a;
}

#header.header19 .searchform-popup {
    display: inline-block;
    vertical-align: middle;
}

.header19#header #header-top {
	background: #3d3734;
}

.header19#header #header-top .currency-switcher > li > a,
.header19#header #header-top .view-switcher > li > a {
	background: none !important;
}

.header19#header .header-right {
	position: static !important;
}

.skin18 .product-image {
	padding: 0px;
	border: none;
	background: none;
}

.skin18 .popup-gallery .product-image {
	padding: 2px;
	border: 1px solid #ddd;
	background: #fff;
}

.skin18 #footer #footer-bottom {
	background: #f1f1f1;
	border-top: 1px solid #dddbdd;
	position: relative;
}

	.skin18 #footer #footer-bottom:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 1px;
		background: #ecebec;
	}

.skin18 #footer {
	background: #fbfbfb;
	color: #777777;
}

.skin18 #footer .widget-title {
	color: #000;
}

.skin18 #footer a,
.skin18 #footer a:hover {
	color: #777777;
}

.skin18 #footer .widget .links li > i,
.skin18 .widget.contact-info li i {
	color: #777777 !important;
}

.skin18 #footer .newsletter-widget .input-box input[type="text"],
.skin18 .newsletter-widget .input-box  input[type="email"] {
	background: none;
	max-width: none;
}

.skin18 .newsletter-widget input[type="submit"] {
	background: none !important;
	border: 1px solid #ccc !important;
	color: #777;
}

.skin18 .btn-add-cart {
	background: #fff;
}

.header19 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	min-width: 1px !important;
}

	body #wrapper .header19 #mini-cart.minicart-inline {
		background: none !important;
	}

.header19 #mini-cart.minicart-inline .cart-items {
	color: #ea6a4a;
	text-transform: uppercase;
}

.header19 #mini-cart .minicart-icon {
	color: #ea6a4a;
}

.header19#header .mobile-toggle {
	color: #ea6a4a;
	vertical-align: top;
	padding-top: 1px;
}

@media (max-width: 480px) {
	.header19#header .mobile-toggle {
		left: 7px;
	}

	.header19#header .searchform-popup {
		left: 36px;
		top: 7px;
	}

	.header19#header #mini-cart .cart-head {
		padding: 0px;
	}

	.header19#header #mini-cart .cart-items {
		color: #ea6a4a;
	}
}

.header19#header #header-inner .container .header-left,
.header19#header #header-inner .container .header-right {
	padding: 26px 0px;
}

.header19.fixed-header {
	background: #fff;
	padding: 6px 0px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.skin18 .main-content {
	min-height: 0px;
}

.skin18.common-home .product-action {
	display: none;
}

.skin18 .home-products-carousel .owl-controls,
.skin18 .brands-carousel-index18 .owl-controls {
	display: none;
}

.skin18 .slider-title {
	text-align: center;
}

.skin18 .slider-title .line,
.skin18 .title-group .line {
	background: #d8d8d8;
	width: 100%;
	bottom: 0.8em;
}

.skin18 .slider-title .inline-title {
	padding-left: 20px !important;
	padding-right: 20px !important;
	color: #3d3734;
	font-size: 19px;
	font-weight: 600;
}

.header19.fixed-header h1.logo {
	float: left;
	font-size: 10px;
	line-height: 1;
}

	.header19.fixed-header h1.logo img {
		max-height: 30px;
	}

.header19.fixed-header #mini-cart.minicart-inline {
	float: right;
	margin-top: 1px;
}

.header19.fixed-header #mini-cart .minicart-icon {
	font-size: 31px;
	padding-right: 9px;
}

.fixed-header.header19 #mini-cart.minicart-inline .cart-items {
	position: absolute;
}

.fixed-header.header19 #mini-cart.minicart-inline .dropdown-menu {
	margin-top: 13px;
}

.fixed-header.header19 #mini-cart.minicart-inline .cart-items {
	color: #fff;
	margin: 0px 9px 0px 0px;
	font-size: 12px;
	font-weight: 600;
	line-height: 3;
}

	.fixed-header.header19 #mini-cart.minicart-inline .cart-items .hidden-xs {
		display: none !important;
	}

@media (max-width: 767px) {
	.header19 #mini-cart.dropdown .dropdown-menu:before {
		right: 12px !important;
	}
}

/* @end */

/* @group Banenr 18 */

.banner-content-18{padding:292px 0 98px}
.banner-content-18 em{font-size:26.26px;margin-left:5px;margin-bottom:7px}
.banner-content-18 .text-content h2{font-size:79.12px;letter-spacing:-4px}
.banner-content-18 .text-content p{margin:0}
.banner-content-18 .text-content p span{font-size:23.64px;padding-left:5px;line-height:2}
.banner-content-18 .text-content p a{font-size:16px;padding:10px 35px;margin-left:5px;margin-top:7px;margin-bottom:125px}

.filter-title-type-2{font-size:19px;margin:0;font-weight:300;margin-bottom:22px;display:table;width:100%}
.filter-title-type-2 span{display:table-cell;position:relative}
.filter-title-type-2 span.title-line:after{content:"";width:100%;border-top:1px solid #d8d8d8;display:block;position:absolute;top:50%;margin-top:-1px}

.skin18.common-home .banner-content-18 .owl-controls {
	display: block;
}

@media (max-width: 767px) {
	.skin18.common-home .banner-content-18 .owl-controls {
		display: none;
	}
}

.banner-content-18 .owl-controls .owl-nav {
	top: 50%;
	left: -55px;
	right: -55px;
}

.banner-content-18 .owl-controls .owl-prev,
.banner-content-18 .owl-controls .owl-next {
	font-size: 28px;
	color: #3d3734 !important;
	position: absolute;
	margin-top: -30px;
}

.banner-content-18 .owl-controls .owl-next {
	right: 0;
}

/* @end */

.account-login .well {
	min-height: 453px;
}

.buttons {
	padding-bottom: 20px;
}

.buttons:before,
.buttons:after {
  display: table;
  content: " ";
}
.buttons:after {
  clear: both;
}

/* @group Fixed header type 1 */

.header1.fixed-header h1.logo {
	float: left;
	font-size: 10px;
	line-height: 1;
	position: relative;
	z-index: 9999;
	padding: 8px 40px 8px 0px;
	min-width: 0px;
}

	.header1.fixed-header h1.logo img {
		max-height: 30px;
	}

.common-home.body-header-type-1 .header1.fixed-header {
	display: none;
}

.header1.fixed-header {
	background: #950707;
}

.header1.fixed-header #mini-cart {
	float: right;
	position: relative;
	margin: 0px;
	z-index: 9999;
	padding: 4px 0px 4px 30px;
}

.header1.fixed-header #mini-cart .minicart-icon {
	font-size: 31px;
	line-height: 36px;
}

.header1.fixed-header #mini-cart.dropdown .dropdown-menu {
	margin-top: 7px;
}

/* @end */

/* @group Fixed header type 3 */

.header1.fixed-header.type2 {
	background: #1e1e1e;
}

.header1.fixed-header.type2 #mini-cart.minicart-inline {
	background: none !important;
}

.header1.fixed-header.type2 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	margin: 0px;
	height: auto;
}

.header1.fixed-header.type2 #mini-cart.minicart-inline .cart-items {
	position: absolute;
	font-size: 15px;
	margin: 0px;
	top: 8px;
	color: #000;
}

.header1.fixed-header.type2 #mini-cart.minicart-inline .cart-items .hidden-xs {
	display: none !important;
}

/* @end */

/* @group Fixed header type 3 */

.header1.fixed-header.type3 {
	background: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type3 .container-megamenu {
	margin: 0px;
}

.header1.fixed-header.type3 ul.megamenu {
	background: none;
	padding-top: 3px;
}

.header1.fixed-header.type3 #mini-cart .minicart-icon {
	color: #9ac93d;
}

.header1.fixed-header.type3 #mini-cart .cart-items {
	color: #fff;
}

/* @end */

/* @group Fixed header type 6 */

.header1.fixed-header.type6 {
	background: #1e1e1e;
}

.header1.fixed-header.type6 .megamenu-wrapper {
	background: none;
}

.header1.fixed-header.type6 ul.megamenu > li > a:hover,
.header1.fixed-header.type6 ul.megamenu > li.active > a,
.header1.fixed-header.type6 ul.megamenu > li.home > a,
.header1.fixed-header.type6 ul.megamenu > li:hover > a {
	color: #fff;
}

.header1.fixed-header.type6 #mini-cart.minicart-inline {
	background: none !important;
}

.header1.fixed-header.type6 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	margin: 0px;
	height: auto;
}

.header1.fixed-header.type6 #mini-cart.minicart-inline .cart-items {
	position: absolute;
	font-size: 15px;
	margin: 0px;
	top: 8px;
	color: #000;
}

.header1.fixed-header.type6 #mini-cart.minicart-inline .cart-items .hidden-xs {
	display: none !important;
}

/* @end */

/* @group Fixed header type 7 */

.header1.fixed-header.type7 {
	background: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type7 .megamenu-wrapper {
	background: none;
	padding-top: 5px;
}

.header1.fixed-header.type7 #mini-cart .minicart-icon {
	color: #65829d;
}

.header1.fixed-header.type7 #mini-cart .cart-items {
	color: #fff;
}

/* @end */

/* @group Fixed header type 8 */

.header1.fixed-header.type8 {
	background: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type8 #mini-cart .minicart-icon {
	color: #e13b3f;
}

.header1.fixed-header.type8 #mini-cart .cart-items {
	color: #fff;
}

/* @end */

/* @group Fixed header type  11 */

.header1.fixed-header.type11 {
	background: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type11 #mini-cart .minicart-icon {
	color: #7aa93c;
}

.header1.fixed-header.type11 .megamenu-wrapper {
	background: none;
	padding-top: 4px;
}

.header1.fixed-header.type11 #mini-cart .cart-items {
	color: #fff;
}

/* @end */

/* @group Fixed header type  11 */

.header1.fixed-header.type12 {
	background: #393f4f;
}

.header1.fixed-header.type12 .megamenu-wrapper {
	background: none;
}

/* @end */

/* @group Fixed header type 14 */

.header1.fixed-header.type14 {
	background: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type14 .megamenu-wrapper {
	background: none;
}

.header1.fixed-header.type14 #mini-cart.minicart-inline {
	background: none !important;
}

.header1.fixed-header.type14 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	margin: 0px;
	height: auto;
}

.header1.fixed-header.type14 #mini-cart .minicart-icon {
	color: #9ac93d;
}

.header1.fixed-header.type14 #mini-cart.minicart-inline .cart-items {
	position: absolute;
	font-size: 15px;
	margin: 0px;
	top: 8px;
	color: #fff;
}

.header1.fixed-header.type14 #mini-cart.minicart-inline .cart-items .hidden-xs {
	display: none !important;
}

/* @end */

/* @group Fixed header type  15 */

.header1.fixed-header.type15 {
	background: #f3f5f4;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.header1.fixed-header.type15 #mini-cart .minicart-icon {
	color: #39333f;
}

.header1.fixed-header.type15 .megamenu-wrapper {
	background: none;
}

.header1.fixed-header.type15 #mini-cart .cart-items {
	color: #fff;
}

/* @end */

/* @group Fixed header type 16 */

.header1.fixed-header.type16 {
	background: #5c616e;
}

.header1.fixed-header.type16 .megamenu-wrapper {
	background: none;
}

.header1.fixed-header.type16 ul.megamenu > li > a:hover,
.header1.fixed-header.type16 ul.megamenu > li.active > a,
.header1.fixed-header.type16 ul.megamenu > li.home > a,
.header1.fixed-header.type16 ul.megamenu > li:hover > a {
	color: #fff;
}

.header1.fixed-header.type16 #mini-cart.minicart-inline {
	background: none !important;
}

.header1.fixed-header.type16 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	margin: 0px;
	height: auto;
}

.header1.fixed-header.type16 #mini-cart .minicart-icon {
	color: #000;
}

.header1.fixed-header.type16 #mini-cart.minicart-inline .cart-items {
	position: absolute;
	font-size: 15px;
	margin: 0px;
	top: 8px;
	color: #fff;
}

.header1.fixed-header.type16 #mini-cart.minicart-inline .cart-items .hidden-xs {
	display: none !important;
}

/* @end */

/* @group Fixed header type 16 */

.header1.fixed-header.type17 {
	background: #f9f9f9;
}

.header1.fixed-header.type17 .megamenu-wrapper {
	background: none;
}

.header1.fixed-header.type17 #mini-cart.minicart-inline {
	background: none !important;
}

.header1.fixed-header.type17 #mini-cart.minicart-inline .cart-head {
	padding: 0px;
	margin: 0px;
	height: auto;
}

.header1.fixed-header.type17 #mini-cart .minicart-icon {
	color: #b74173;
}

.header1.fixed-header.type17 #mini-cart.minicart-inline .cart-items {
	position: absolute;
	font-size: 15px;
	margin: 0px;
	top: 8px;
	color: #fff;
}

.header1.fixed-header.type17 #mini-cart.minicart-inline .cart-items .hidden-xs {
	display: none !important;
}

/* @end */

/* @group Wide width */

@media (min-width: 767px) {
	.wide-width .container {
	    width: 100%;
	    padding: 0px 100px;
	}

	.wide-width .container .container {
		padding: 0px !important;
	}
}

@media (min-width: 767px) and (max-width: 1199px) {
	.wide-width .container {
	    padding: 0px 50px;
	}
}

/* @end */

/* @group Skin 19 */

@media (min-width: 767px) {
	.skin19 .wide-width .container {
	    padding: 0px 100px 0px 180px;
	}
}

@media (min-width: 767px) and (max-width: 1199px) {
	.skin19 .wide-width .container {
	    padding: 0px 50px 0px 130px;
	}
}

.skin19 .product-grid .product-image,
.skin19 .carousel-wrapper .product-image,
.skin19 ul.product_list_widget li .product-image,
.skin19 .widget ul.product_list_widget li .product-image {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	padding: 0px;
	border: none;
}

.skin19 .product-grid .product-image img,
.skin19 .carousel-wrapper .product-image img,
.skin19 ul.product_list_widget li .product-image img,
.skin19 .widget ul.product_list_widget li .product-image img {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

.skin19 .product-list .product-image {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	padding: 0px;
	border: none;
}

.skin19 .product-list .product-image img {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

/* @end */

/* @group Header type 20 */

.header20 {
	background: #fff;
	padding: 25px 0px;
	position: relative;
	z-index: 999;
}

.header20.active.with-fixed {
	background: #000 !important;
	position: fixed !important;
	top: 0;
	left: 0;
	right: 0;
}

.common-home .header20 {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: none;
}

.common-home .header20-before {
	display: none;
}

.header20 .logo {
	float: left;
}

	.header20.active.with-fixed .logo img {
		display: none;
	}

	.header20.active.with-fixed .logo .hide {
		display: block !important;
	}

.header20 .cart-area {
	float: right;
}

.header20 .top-links-area {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

	.header20 .top-links-area .top-links-icon {
		display: none;
	}

	.header20 .top-links-area .links {
		display: inline-block;
		vertical-align: top;
		padding: 14px 31px 0px 0px;
		margin: 0px;
	}

		.header20 .top-links-area .links li {
			display: inline-block;
			vertical-align: top;
			padding: 0 14px;
			font-size: 11px;
			font-weight: 700;
		}

			.header20 .top-links-area .links li:first-child {
				padding-left: 0;
			}

			.header20 .top-links-area .links li:last-child {
				padding-right: 0;
			}

			.header20 .top-links-area .links li a {
				color: #000;
				text-transform: uppercase;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
			}

				.header20 .top-links-area .links li a:hover {
					text-decoration: underline;
				}

				.header20.active.with-fixed .top-links-area .links li a  {
					color: #fff;
				}

	@media (max-width: 1580px) {
		.header20 .top-links-area .links {
			position: absolute;
			left: -11px;
			top: 100%;
			text-align: left;
			padding: 10px 0;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 5px;
			display: none;
			z-index: 999;
		}

			.header20 .top-links-area .links:hover,
			.header20 .top-links-area .top-links-icon:hover + .links {
				display: block;
			}

			.header20 .top-links-area .links li {
			    display: block;
			    padding: 0 10px !important;
			    font-weight: 400;
			    font-size: 11px;
			    line-height: 19px;
			}

				.header20 .top-links-area .links li a {
					color: #000 !important;
				}

		.header20 .top-links-area .top-links-icon {
			display: inline-block;
			margin: 13px 27px -2px 0px;
			vertical-align: top;
			padding-bottom: 5px;
			position: relative;
		}

			.header20 .top-links-area .top-links-icon a {
				font-size: 11px;
				color: #000;
				text-transform: uppercase;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
			}

				.header20.active.with-fixed .top-links-area .top-links-icon > a {
					color: #fff;
				}

				.header20 .top-links-area .top-links-icon a i {
					margin-left: 4px;
				}
	}

	.header20 .top-links-area .custom-block {
		display: inline-block;
		vertical-align: top;
		padding: 14px 29px 0px 0px;
	}

		.header20 .top-links-area .custom-block a {
			font-size: 17px;
			padding: 0;
			margin: 0 8px 0px 9px;
			color: #000;
			transition: all 0.2s ease-in;
			-webkit-transition: all 0.2s ease-in;
			-moz-transition: all 0.2s ease-in;
		}

			.header20.active.with-fixed .top-links-area .custom-block a {
				color: #fff;
			}

.header20 .mobile-toggle {
	display: none;
}

.header20 .searchform-popup {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding: 5px 0px 5px 0px;
	margin: 0px 28px 0px 0px;
}

	.header20 .searchform-popup .search-toggle {
		display: none;
	}

	.header20 .searchform-popup input {
		font-size: 12px;
		font-weight: 700;
		min-width: 200px;
		font-family: Arial;
		color: #000;
		border-radius: 20px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border: 1px solid #000;
		height: 38px;
		padding: 10px 15px;
		background: none;
	}

		.header20.active.with-fixed .searchform-popup input {
			color: #fff;
			border: 1px solid #fff;
		}

		.header20 .searchform-popup input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #000;
		}
		.header20 .searchform-popup input::-moz-placeholder { /* Firefox 19+ */
		  color: #000;
		}
		.header20 .searchform-popup input:-ms-input-placeholder { /* IE 10+ */
		  color: #000;
		}
		.header20 .searchform-popup input:-moz-placeholder { /* Firefox 18- */
		  color: #000;
		}

			.header20.active.with-fixed .searchform-popup input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #fff;
			}
			.header20.active.with-fixed .searchform-popup input::-moz-placeholder { /* Firefox 19+ */
			  color: #fff;
			}
			.header20.active.with-fixed .searchform-popup input:-ms-input-placeholder { /* IE 10+ */
			  color: #fff;
			}
			.header20.active.with-fixed .searchform-popup input:-moz-placeholder { /* Firefox 18- */
			  color: #fff;
			}

	.header20 .searchform-popup .btn {
		width: 40px;
		height: 42px;
		color: #000;
		background-color: transparent;
		font-size: 14px;
		position: absolute;
		right: 0;
		top: 0;
		border-radius: 0 20px 20px 0;
		outline: none;
		padding: 6px 1px 0px 0px;
	}

		.header20.active.with-fixed .searchform-popup .btn {
			color: #fff;
		}

	@media (max-width: 1100px) {
		.header20 .searchform-popup input,
		.header20 .searchform-popup .btn {
			display: none;
		}

		.header20 .searchform-popup {
			margin: 0px;
			padding: 13px 10px 0px 0px;
		}

		.header20 .searchform-popup .search-toggle {
			display: block;
			color: #000;
		}

			.header20.active.with-fixed .searchform-popup .search-toggle {
				color: #fff;
			}
	}

.header20 .switcher-area {
	display: inline-block;
	vertical-align: top;
	padding: 10px 0px 0px 0px;
}

	.header20 .switcher-area .separator {
		display: none;
	}

	.header20 .switcher-area .accordion-menu {
		padding: 0px;
		margin: 0px 0px 0px 5px;
		display: inline-block;
		vertical-align: top;
	}

		.header20 .switcher-area .accordion-menu > li > a {
			padding: 0 10px 0 10px;
			margin: 0px;
			box-shadow: 1px 2px 1px rgba(0,0,0,0.3);
			background: #fff;
			border-radius: 3px;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			color: #000;
			height: 26px;
			font-size: 11px;
			line-height: 24px;
			font-weight: 700;
			text-transform: uppercase;
		}

			.header20 .switcher-area .accordion-menu > li > a i {
				margin-left: 7px;
			}

			.header20 .switcher-area .accordion-menu > li > a img {
				display: inline-block;
				vertical-align: top;
				margin: 7px 4px 0px 0px;
			}

		.header20 .switcher-area .accordion-menu > li ul {
			display: none;
			position: absolute;
			background-color: #fff !important;
			border: 1px solid #ccc;
			border-radius: 3px;
			box-shadow: 0 0 2px rgba(0,0,0,0.1);
			margin: 0px;
			padding: 4px 0px;
			min-width: 100%;
		}

			.header20 .switcher-area .accordion-menu > li:hover ul {
				display: block;
			}

			.header20 .switcher-area .accordion-menu > li ul li {
				display: block;
				padding: 0 9px;
				line-height: 17px;
				white-space: nowrap;
			}

				.header20 .switcher-area .accordion-menu > li ul li:hover {
					background: #ccc;
				}

				.header20 .switcher-area .accordion-menu > li ul li a {
					padding: 0;
					line-height: 17px;
					color: #777;
					background: none !important;
					font-size: 11px;
					text-transform: uppercase;
				}

					.header20 .switcher-area .accordion-menu > li ul li:hover a {
						color: #fff;
					}

					.header20 .switcher-area .accordion-menu > li ul li a img {
						display: inline-block;
						margin: 3px 5px 0 0;
						vertical-align: top;
					}

	.header20 .switcher-area #mini-cart.minicart-inline {
		box-shadow: 1px 2px 1px rgba(0,0,0,0.3) !important;
		background: #fff !important;
		border-radius: 3px !important;
		-webkit-border-radius: 3px !important;
		-moz-border-radius: 3px !important;
		margin: 1px 0px 0px 5px;
		height: 26px;
	}

		.header20 .switcher-area #mini-cart.minicart-inline .minicart-icon {
			color: #000;
		}

		.header20 .switcher-area #mini-cart.minicart-inline .cart-items {
			color: #000;
			font-size: 11px;
			text-transform: uppercase;
		}

	@media (max-width: 991px) {
		.header20 .top-links-area {
			display: none;
		}

		.header20 .currency_language_form {
			display: none;
		}

		.header20 .mobile-toggle {
			display: inline-block;
			vertical-align: top;
			color: #000;
			margin: 13px 15px 0px 0px;
		}

			.header20.active.with-fixed .mobile-toggle {
				color: #fff;
			}
	}

/* @end */

/* @group Skin19 footer */

.skin19-footer .row {
	display: flex;
	margin: 0px;
}

.skin19-footer .row > div {
	border-left: 1px solid #0f0f0f;
	padding: 10px 50px;
}

	.skin19-footer .row > div.col-sm-2 {
		width: 20%;
	}

	.skin19-footer .row > div:first-child {
		border-left: none;
		padding-left: 0;
	}

.skin19-footer .block {
	line-height: 1.5;
}

	.skin19-footer .block .block-title {
		margin: 5px 0 30px 0;
		line-height: 1.2;
		font-size: 14px;
		font-weight: 700;
		color: #fff;
		text-transform: uppercase;
	}

	.skin19-footer .block ul.links {
		margin: -5px 0px 0px 0px;
		padding: 0px;
		position: relative;
		font-size: 14px;
	}

		.skin19-footer .block ul.links li {
			padding: 10px 0;
			line-height: 1;
		}

			#footer .skin19-footer .block a {
				color: #777;
			}

	.skin19-footer .block .rside-form {
		max-width: 281px;
		position: relative;
	}

		.skin19-footer .block .rside-form input {
			height: 60px;
			width: 100%;
			border: 1px solid #999;
			color: #777;
			line-height: 58px;
			padding: 0px 30px;
			border-radius: 30px;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			background: none;
			font-size: 14px;
		}

		.skin19-footer .block .rside-form .subscribe {
			position: absolute;
			top: 0;
			right: 0;
			height: 58px;
			background: none;
			border: none;
			font-size: 12px;
			line-height: 58px;
			color: #fff;
			font-weight: bold;
			padding: 0px 25px 0px 25px;
		}

@media (max-width: 1400px) {
	.skin19-footer .row > div {
		border: none;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (max-width: 767px) {
	.skin19-footer .row {
		display: block;
	}

		.skin19-footer .row > div {
			width: 100% !important;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 25px;
		}
}

.skin19 #footer {
	background: #000;
}

.skin19 #footer #footer-bottom {
	background: #000;
}

.footer-home19-logo {
	padding-bottom: 15px;
}

/* @end */

/* @group Skin 19 category list */

.skin19 .main-content {
	min-height: 1000px;
}

@media (max-width: 767px) {
	.skin19 .main-content {
		min-height: 400px;
	}
}

.skin19-category-list {
	position: absolute;
	background: #000;
	width: 80px;
	left: 0;
	top: 0;
	bottom: 0;
}

	.common-home .skin19-category-list {
		display: none;
	}

	.skin19-category-list ul {
		position: absolute;
		top: 142px;
		left: 0;
		padding-top: 76px;
		z-index: 99;
		margin: 0px;
	}

		.skin19-category-list ul li a {
			color: #fff;
			width: auto;
			line-height: 78px;
			display: inline-block;
			white-space: nowrap;
			position: relative;
			transition: all .3s;
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
		}

			.skin19-category-list ul li a > em{font-size:25px;width:80px;display:inline-block;text-align:center;vertical-align:middle}
			.skin19-category-list ul li a > em > img{vertical-align:middle;max-width:80px}
			.skin19-category-list ul li a > span{display:inline-block;vertical-align:middle;opacity:0;visibility:hidden;width:0;transition:all .3s;font-size:16px;font-weight:600;position:relative;overflow:hidden}
			.skin19-category-list ul li a:after{content:"";width:16px;height:16px;position:absolute;display:block;right:-15px;top:50%;margin-top:-8px;border:8px solid transparent;transition:all .3s}
			.skin19-category-list ul li a:hover, .skin19-category-list ul li a.active{background-color:#00d59d}
			.skin19-category-list ul li a:hover > span{opacity:1;visibility:visible;width:auto;padding-right:50px}
			.skin19-category-list ul li a:hover:after,.skin19-category-list ul li a.active:after{border-left-color:#00d59d}

@media (max-width: 767px) {
	.skin19-category-list {
		position: relative !important;
		height: auto !important;
		width: 100% !important;
	}

	.skin19-category-list:before,
	.skin19-category-list:after {
		  display: table;
		  content: " ";
	}

	.skin19-category-list:after {
		clear: both;
	}

	.skin19-category-list ul {
		position: relative !important;
		top: 0 !important;
		bottom: 0 !important;
		left: 0 !important;
		right: 0 !important;
		padding: 0px !important;
	}

	.skin19-category-list ul li {
		float: left;
	}

	.skin19-category-list ul li a > span,
	.skin19-category-list ul li a:after {
		display: none !important;
	}
}

/* @end */

/* @group Skin19 slider */

.skin19 .tp-caption.marginleft {
	left: 180px !important;
	right: 180px !important;
}

@media (max-width: 1199px) {
	.skin19 .tp-caption.marginleft {
		left: 130px !important;
		right: 130px !important;
	}
}

@media (max-width: 767px) {
	.skin19 .tp-caption.marginleft {
		left: 15px !important;
		right: 15px !important;
	}
}

.home19-slider-button {
	color: #000 !important;
	font-size: 14px;
	font-weight: 700;
	border: 2px solid #000;
	padding: 0 34px;
	display: inline-block;
	line-height: 53px;
	border-radius: 8px;
	text-decoration: none;
}

	.home19-slider-button:hover {
		background: #000;
		color: #fff !important;
	}

.skin19 .tp-bullets {
	left: 180px !important;
	right: 180px !important;
	bottom: 8% !important;
}

@media (max-width: 1199px) {
	.skin19 .tp-bullets {
		left: 130px !important;
		right: 130px !important;
	}
}

@media (max-width: 767px) {
	.skin19 .tp-bullets {
		left: 15px !important;
		right: 15px !important;
	}
}

.skin19 .tp-bullets.simplebullets.round .bullet {
	margin-left: 0;
	margin-right: 14px;
	width: 12px;
	height: 12px;
	border: 2px solid #000;
	background: none;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
}

.skin19 .tp-bullets.simplebullets.round .bullet.selected {
	background: #000;
}

/* @end */

/* @group Skin19 category */

.skin19-category {
	padding-top: 70px;
}

.skin19-category:last-child {
	margin-bottom: 104px;
}

@media (max-width: 767px) {
	.skin19-category:last-child {
		margin-bottom: 40px;
	}
}

@media (max-width: 1199px) {
	.skin19-category {
		padding-top: 46px;
	}
}


.skin19-category .category_link {
	float: right;
	display: block;
    position: relative;
    z-index: 2;
    height: 40px;
    border: 2px solid #000;
    color: #000;
    border-radius: 5px;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
   	padding: 0px 24px 0px 24px;
   	transition: all 0.2s ease-in;
   	-webkit-transition: all 0.2s ease-in;
   	-moz-transition: all 0.2s ease-in;
   	font-size: 13px;
   	margin-left: -200px;
   	margin-top: 33px;
}

	.skin19-category .category_link:hover {
		background: #000;
		color: #fff;
	}

.skin19-category .title {
	padding: 0px 0px 10px 0px;
	margin: 0px;
}

	.skin19-category .title p {
		padding: 0px;
		margin: 0px;
		line-height: 100px;
		font-size: 26px;
		color: #000;
		font-weight: 300;
		cursor: pointer;
		display: inline-block;
	}

		@media (max-width: 767px) {
			.skin19-category .title p {
				font-size: 22px;
			}
		}

		.skin19-category .title.with_submenu p:after {
			content: "\f107";
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 20px;
			margin-left: 18px;
		}

			.skin19-category .title.with_submenu p.active:after {
				content: "\f106";
			}

	.skin19-category .title .submenu {
		position: absolute;
		background: #fff;
		z-index: 9;
		left: -15px;
		right: -15px;
		border: 1px solid #bebebe;
		border-radius: 5px;
		padding: 50px 0;
		display: none;
	}

		.skin19-category .title p.active + .submenu {
			display: block;
		}

		.skin19-category .title .submenu:before {
			content: "";
			border: 8px solid transparent;
			width: 16px;
			height: 16px;
			display: block;
			border-bottom-color: #bebebe;
			position: absolute;
			top: -16px;
			left: 90px;
		}

		.skin19-category .title .submenu:after {
			content: "";
			border: 7px solid transparent;
			width: 14px;
			height: 14px;
			display: block;
			border-bottom-color: #fff;
			position: absolute;
			top: -13px;
			left: 91px;
		}

		.skin19-category .title .submenu .row {
			display: flex;
			margin: 0px;
		}

			.skin19-category .title .submenu .row > div {
				padding: 30px 4% 30px 4%;
				margin: 10px 0;
				border-left: 1px solid #bebebe;
			}

				.skin19-category .title .submenu .row > div:first-child {
					border-left: none;
				}

			.skin19-category .title .submenu .row > div ul {
				padding: 0px;
				margin: 0px;
				list-style: none;
			}

			.skin19-category .title .submenu li {
				line-height: 1;
			}

			.skin19-category .title .submenu a {
				color: #000;
				font-size: 16px;
				line-height: 2.5;
				text-transform: uppercase;
				font-weight: 700;
			}

				.skin19-category .title .submenu a:hover {
					text-decoration: underline;
				}

				.skin19-category .title .submenu ul ul a {
					text-transform: none;
					font-weight: normal;
				}

	@media (max-width: 1199px) {
		.skin19-category .title .submenu {
			padding: 10px 0px;
		}

		.skin19-category .title .submenu .row > div {
			padding: 10px 2%;
		}

		.skin19-category .title .submenu a {
			font-size: 11px;
		}
	}

	@media (max-width: 767px) {
		.skin19-category .title .submenu {
			left: 0;
			right: 0;
		}

		.skin19-category .title .submenu .row {
			display: block;
		}

			.skin19-category .title .submenu .row > div {
				border: none;
			}

		.skin19-category .title .submenu {
			padding: 10px 5px;
		}

		.skin19-category .title .submenu .row > div {
			padding: 10px 15px;
			float: left;
			width: 50%;
		}

		.skin19-category .title .submenu a {
			font-size: 14px;
		}
	}

.skin19-category .product-image {
	margin: 0px;
	padding: 0px;
	border: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

	.skin19-category .product-image img {
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		display: block;
	}

	.skin19-category  .product-image-area:before{content:"";display:block;background:#000;width:100%;height:100%;position:absolute;left:0;top:0;z-index:1;opacity:0;visibility:hidden;transition:all .3s}
	.skin19-category  .item-area:hover .product-image-area:before{opacity:.7;visibility:visible}

	.skin19-category a.quickview-icon{position:absolute;top:50%;margin-top:-18px;text-align:center;width:100%;background:none;text-transform:uppercase;color: #fff;
	}
		.skin19-category a.quickview-icon:hover {
			color: rgba(255, 255, 255, .85);
			text-decoration: underline;
		}
	.skin19-category a.quickview-icon span{margin-left:8px;font-weight:600}

	.skin19-category .item-area:hover a.quickview-icon {
		opacity: 1;
		visibility: visible;
	}

	.skin19-category .actions {
		padding: 0 15px;
		position: absolute;
		width: 100%;
		bottom: 15px;
		left: 0;
		text-align: left;
		visibility: hidden;
		opacity: 0;
		transition: opacity .2s;
		z-index: 3;
	}

		.skin19-category .item-area:hover .actions {
			opacity: 1;
			visibility: visible;
		}

		.skin19-category .actions a{
			background-color:transparent;
			color:#fff;
			width:50px;
			height:50px;
			line-height:48px;
			font-size:18px;
			margin:0 1px;
			display: inline-block;
			border: 1px solid #fff;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			text-align: center;
		}

			.skin19-category .actions a span {
				display: none;
			}

	.skin19-category .product-label {
		position: absolute;
		font-size: 14px;
		font-weight: 700;
		color: #fff;
		line-height: 1;
		top: 10px;
		z-index: 3;
		right: 10px;
	}

		.skin19-category .product-label span {
			position: relative;
			padding: 7px 10px;
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			border-radius: 4px;
		}

			.skin19-category .product-label span.sale-product-icon {
			    background-color: #e27c7c;
			    display: block;
			    text-align: center;
			}

			.skin19-category .product-label span.new-product-icon {
			    background-color: #62b959;
			    display: block;
			    text-align: center;
			}

	.skin19-category .details-area {
		padding: 15px 0;
	}

	.skin19-category .product-name {
		margin: 0px 0px 3px 0px;
		padding: 0px;
		line-height: 1.35;
	}

		.skin19-category .product-name a {
			font-size: 14px;
			font-weight: 600;
			color: #000;
			letter-spacing: 0;
		}

	.skin19-category .price-box {
		margin: 10px 0 2px;
	}

		.skin19-category .price-box .price {
			font-size: 30px;
			font-weight: 300;
			line-height: 1;
			margin: 0px;
		}

		.skin19-category .price-box .old-price,
		.skin19-category .price-box .special-price {
			padding: 0px;
			margin: 0px;
			display: inline-block;
			vertical-align: top;
		}

			.skin19-category .price-box .old-price .price {
				color: #999;
				font-size: 20px;
				text-decoration: line-through;
				vertical-align: middle;
				line-height: 30px;
				margin-right: 7px;
			}

.skin19-category .owl-dot {
	width: 8px;
	height: 8px;
	background: #fff !important;
	border: 1px solid #000 !important;
}

	.skin19-category .owl-dot.active {
		background: #000 !important;
	}

.skin19-category .additional-content {

}

	.skin19-category .additional-content img {
		margin-top: 53px;
	}

/* @end */

/* @group Skin 20 */

.skin20 .product-grid .product-image,
.skin20 .carousel-wrapper .product-image,
.skin20 ul.product_list_widget li .product-image,
.skin20 .widget ul.product_list_widget li .product-image {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	padding: 0px;
	border: none;
}

.skin20 .product-grid .product-image img,
.skin20 .carousel-wrapper .product-image img,
.skin20 ul.product_list_widget li .product-image img,
.skin20 .widget ul.product_list_widget li .product-image img {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

.skin20 .product-list .product-image {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	padding: 0px;
	border: none;
}

.skin20 .product-list .product-image img {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

.skin20 .mfilter-heading,
.skin20 .mfilter-content > ul > li,
.skin20 a.quickview-icon,
.skin20 .product-filter .options .button-group button,
.skin20 .nav-tabs > li > a,
.skin20 .product-list .btn-add-cart,
.skin20 .product-list .product-btn.btn-wishlist,
.skin20 .product-list .product-btn.btn-quickview,
.skin20 .product-info .thumbnails li img,
.skin20 .product-info .thumbnails-carousel img,
.skin20 .btn-default,
.skin20 .btn,
.skin20 .button,
.skin20 select,
.skin20 input,
.skin20 textarea,
.skin20 .product-info .product-image,
.skin20 .well,
.skin20 .box,
.skin20 .box .box-heading,
.skin20 table.attribute,
.skin20 table.list,
.skin20 .wishlist-product table,
.skin20 .wishlist-info table,
.skin20 .compare-info,
.skin20 .cart-info table,
.skin20 .checkout-product table,
.skin20 .table,
.skin20 .center-column .panel-heading,
.skin20 .quickcheckout-heading,
body.skin20 .quickcheckout-content,
.skin20 .mfilter-heading .mfilter-head-icon,
.skin20 .box .box-heading .toggle,
.skin20 ul.megamenu li .sub-menu .content,
.skin20 .popup,
.skin20 .banner, .skin20 .banner img, .skin20 .banner a:after,
.skin20 .posts .post .post-media,
.skin20 .posts .button-more,
.skin20 #header .currency-switcher li > ul,
.skin20 #header .view-switcher li > ul,
.skin20 #scroll-top,
.skin20 .product-center .single-add-to-wishlist a:before,
.skin20 .product-center .add-to-cart .quantity #q_up,
.skin20 .product-center .add-to-cart .quantity #q_down,
.skin20 .product-center .add-to-cart .quantity input,
.skin20 .alert,
.skin20 ul.product_list_widget li .product-image img,
.skin20 .widget ul.product_list_widget li .product-image img,
.skin20 ul.product_list_widget li .product-image,
.skin20 .widget ul.product_list_widget li .product-image,
.skin20 ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
.skin20 #mini-cart .mini-cart-info table td.image img,
.skin20 .prev-next-products .product-nav .product-pop,
.skin20 .product-image,
.skin20 .product-image img,
.skin20 .product-action .product-btn,
.skin20 .prev-next-products .product-nav .product-pop,
.skin20 .post .tags a,
.skin20 #mini-cart.dropdown .dropdown-menu,
.skin20 #mini-cart .dropdown-cart-content,
.skin20 .product-image .onhot,
.skin20 .product-image .onsale,
.skin20 .pagination > li > a,
.skin20 .pagination > li > span {
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
}

.skin20 #footer,
.skin20 #footer #footer-bottom {
	background: #000;
}

.skin20 #footer #footer-bottom address {
	border-top: 1px solid #4d4d4d;
	color: #fff;
	font-size: 13px;
	padding: 24px 0px 10px 0px;
}

.skin20 #footer > .container {
	padding-top: 90px;
	padding-bottom: 50px;
}

.skin20 #footer .block {
	line-height: 1.5;
	border: 0;
	margin: 0;
	background-color: transparent;
	float: none;
	width: auto;
}

.skin20 #footer .block .block-title {
	margin: 5px 0 20px;
	line-height: 1.2;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
}

.skin20 #footer .block .block-title strong {
	font-weight: 400;
	    padding: 0;
	    font-size: 16px;
	    line-height: inherit;
	    color: #fff;
	    text-transform: none;
	    -webkit-font-smoothing: subpixel-antialiased;
	    -moz-osx-font-smoothing: auto;
}

.skin20 #footer .block .block-content {
	color: #a3a3a3;
}

.skin20 #footer .block ul.links {
	padding: 0px;
	margin: -5px 0px -10px 0px;
	position: relative;
	list-style: none;
}

.skin20 #footer .block ul.links li {
	position: relative;
	padding: 10px 0;
	line-height: 1;
	display: block;
}

.skin20 #footer .block a {
	color: #a3a3a3;
}

.skin20 #footer .block .block-content p {
	line-height: 1.5;
	margin: 0px 0px 15px 0px;
}

@media (max-width: 767px) {
	.skin20 #footer > .container {
		padding-top: 40px;
		padding-bottom: 10px;
	}
}

.skin20 #footer .block .email {
	background: #fff;
	border: 1px solid #ccc;
	height: 26px;
	color: #a3a3a3;
	font-size: 13px;
	background: transparent;
	border-radius: 0;
	padding: 20px;
	display: block;
	width: 100%;
	max-width: 300px;
}

.skin20 #footer .block .subscribe {
    font-size: 12px;
    font-weight: 700;
    line-height: 38px;
    text-transform: uppercase;
    background-color: #fff;
    color: #000;
    padding: 0;
    letter-spacing: 3px;
    transition: all 0.3s;
    box-shadow: 3px 3px 0 rgba(255,255,255,0.5);
    margin: 20px 0 0 0;
    display: block;
    width: 100%;
    border: none;
    max-width: 300px;
}

.skin20 #footer .block .subscribe:hover {
	opacity: 0.9;
}

.skin20.common-home .main-content {
	padding-bottom: 0;
}

.skin20 .tp-bullets.tp-thumbs .bullet {
	opacity: 1;
	border: 3px solid transparent;
	-moz-background-clip: content;     /* Firefox 3.6 */
	-webkit-background-clip: content;  /* Safari 4? Chrome 6? */
	background-clip: content-box;
}

.skin20 .tp-bullets.tp-thumbs .bullet.selected {
	border: 3px solid rgba(255, 255, 255, .5);
}

.skin20 .tp-bullets.tp-thumbs {
	padding: 3px;
	background: none;
}

/* @end */

/* @group Header Type 21 */

@font-face {
  font-family: 'ecommerce';
  src: url('../fonts/ecommerce90c6.html?5510888');
  src: url('../fonts/ecommerce90c6.html?5510888#iefix') format('embedded-opentype'),
       url('../fonts/ecommerce90c6-2.html?5510888') format('woff'),
       url('../fonts/ecommerce90c6-3.html?5510888') format('truetype'),
       url('../fonts/ecommerce90c6-4.html?5510888#revicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon90c6.html?5510888');
  src: url('../fonts/icomoon90c6.html?5510888#iefix') format('embedded-opentype'),
       url('../fonts/icomoon90c6-2.html?5510888') format('woff'),
       url('../fonts/icomoon90c6-3.html?5510888') format('truetype'),
       url('../fonts/icomoon90c6-4.html?5510888#revicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.header21 {
	background: #000;
	padding: 25px 0px;
	position: relative;
	z-index: 999;
}

@media (max-width: 767px) {
	.header21 {
		padding: 15px 0px;
	}
}

.header21.active.with-fixed {
	position: fixed !important;
	top: 0;
	left: 0;
	right: 0;
	background: #000;
}

.common-home .header21 {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: none;
}

.common-home .header21-before {
	display: none;
}

.header21 .logo {
	float: left;
}

.header21 .cart-area {
	float: right;
}

.header21 .top-links-area {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

	.header21 .top-links-area .top-links-icon {
		display: none;
	}

	.header21 .top-links-area .links {
		display: inline-block;
		vertical-align: top;
		padding: 14px 21px 0px 0px;
		margin: 0px;
	}

		.header21 .top-links-area .links li {
			display: inline-block;
			vertical-align: top;
			padding: 0 10px;
			font-size: 11px;
			font-weight: 700;
		}

			.header21 .top-links-area .links li:first-child {
				padding-left: 0;
			}

			.header21 .top-links-area .links li:last-child {
				padding-right: 0;
			}

			.header21 .top-links-area .links li a {
				color: #fff;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 11px;
				letter-spacing: 2px;
				text-transform: uppercase;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
				-webkit-font-smoothing: subpixel-antialiased;
				-moz-osx-font-smoothing: auto;
			}

				.header21 .top-links-area .links li a:hover {
					text-decoration: underline;
				}

	@media (max-width: 1480px) {
		.header21 .top-links-area .links {
			position: absolute;
			left: -11px;
			top: 100%;
			text-align: left;
			padding: 10px 0;
			background-color: #fff;
			border: 1px solid #ddd;
			display: none;
			z-index: 999;
		}

			.header21 .top-links-area .links:hover,
			.header21 .top-links-area .top-links-icon:hover + .links {
				display: block;
			}

			.header21 .top-links-area .links li {
			    display: block;
			    padding: 0 10px !important;
			    font-weight: 400;
			    font-size: 11px;
			    line-height: 19px;
			}

				.header21 .top-links-area .links li a {
					color: #777 !important;
					letter-spacing: 0.5px;
					white-space: nowrap;
					font-weight: normal;
				}

		.header21 .top-links-area .top-links-icon {
			display: inline-block;
			margin: 13px 20px -2px 0px;
			vertical-align: top;
			padding-bottom: 5px;
			position: relative;
		}

			.header21 .top-links-area .top-links-icon a {
				font-size: 11px;
				-webkit-font-smoothing: subpixel-antialiased;
				-moz-osx-font-smoothing: auto;
				color: #fff;
				letter-spacing: 2px;
				text-transform: uppercase;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
			}

				.header21 .top-links-area .top-links-icon a i {
					margin-left: 4px;
				}
	}

.header21 .mobile-toggle {
	display: none;
}

.header21 .searchform-popup {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding: 15px 39px 0 33px;
	margin: 0px 0px 0px 0px;
}

	.header21 .searchform-popup .search-toggle {
		color: #fff;
		font-size: 20px;
	}

		.header21 .searchform-popup .search-toggle i:before {
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e900";
		}

.header21 .switcher-area {
	display: inline-block;
	vertical-align: top;
	padding: 13px 0px 0px 0px;
}

	.header21 .switcher-area .separator {
		display: none;
	}

	.header21 .switcher-area .accordion-menu {
		padding: 0px;
		margin: 0px 20px 0px 0px;
		display: inline-block;
		vertical-align: top;
	}

		.header21 .switcher-area .accordion-menu > li > a {
			padding: 0;
			margin: 0px;
			color: #fff;
			height: 26px;
			font-size: 11px;
			line-height: 24px;
			font-weight: 600;
			-webkit-font-smoothing: subpixel-antialiased;
			-moz-osx-font-smoothing: auto;
			letter-spacing: 2px;
			text-transform: uppercase;
			background: none;
		}

			.header21 .switcher-area .accordion-menu > li > a i {
				margin-left: 7px;
			}

				.header21 .switcher-area .accordion-menu > li > a i:before {
					content: "\f107";
				}

			.header21 .switcher-area .accordion-menu > li > a img {
				display: inline-block;
				vertical-align: top;
				margin: 7px 4px 0px 0px;
			}

		.header21 .switcher-area .accordion-menu > li ul {
			display: none;
			position: absolute;
			background-color: #fff !important;
			border: 1px solid #ccc;
			box-shadow: 0 0 2px rgba(0,0,0,0.1);
			margin: 0px;
			padding: 4px 0px;
			min-width: 100%;
		}

			.header21 .switcher-area .accordion-menu > li:hover ul {
				display: block;
			}

			.header21 .switcher-area .accordion-menu > li ul li {
				display: block;
				padding: 0 9px;
				line-height: 17px;
				white-space: nowrap;
			}

				.header21 .switcher-area .accordion-menu > li ul li:hover {
					background: #ccc;
				}

				.header21 .switcher-area .accordion-menu > li ul li a {
					padding: 0;
					line-height: 17px;
					color: #777;
					background: none !important;
					font-size: 11px;
					text-transform: uppercase;
				}

					.header21 .switcher-area .accordion-menu > li ul li:hover a {
						color: #fff;
					}

					.header21 .switcher-area .accordion-menu > li ul li a img {
						display: inline-block;
						margin: 3px 5px 0 0;
						vertical-align: top;
					}

	.header21 #mini-cart {
		display: inline-block;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		margin: 12px 0px 0px 0px;
		padding: 0px 0px 10px 0px;
		vertical-align: top;
		height: auto;
	}

		.header21 #mini-cart .cart-head {
			min-width: 1px;
		}

		.header21 #mini-cart .minicart-icon {
			font-size: 25px;
		}

		.header21 .minicart-icon:before {
			font-family: 'ecommerce' !important;
			content: "\61" !important;
		}

		.header21 #mini-cart .cart-items {
			background: #fff;
			border-radius: 100px;
			-webkit-border-radius: 100px;
			-moz-border-radius: 100px;
			width: 15px;
			height: 15px;
			position: absolute;
			top: auto;
			left: auto;
			right: -5px;
			bottom: -6px;
			font-weight: 400;
			font-size: 10px;
			line-height: 15px;
		}

		.header21 #mini-cart.dropdown .dropdown-menu {
			margin: 0px -13px;
		}

	.header21 .container-megamenu {
		display: inline-block;
		vertical-align: top;
		padding-top: 1px !important;
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}

		.header21 ul.megamenu,
		.header21 .container-megamenu,
		.header21 .container-megamenu .container,
		.header21 .megamenu-wrapper {
			position: static;
		}

		.header21 .container-megamenu .megamenu-wrapper {
			background: none;
		}

		.header21 ul.megamenu > li {
			margin-right: 18px;
		}

		.header21 ul.megamenu > li > a {
			letter-spacing: 2px;
			color: #fff !important;
			font-size: 11px;
		}

		.header21 ul.megamenu > li > a strong {
			font-weight: 600;
		}

		.header21 ul.megamenu > li.with-sub-menu > a strong:after {
			display: none;
		}

		.header21 .horizontal ul.megamenu > li > .sub-menu {
			left: auto !important;
			right: auto !important;
			margin: -7px -13px 0px -13px;
		}

		.header21 ul.megamenu li .sub-menu .content .hover-menu a {
			font-size: 11px;
		}

	@media (max-width: 991px) {
		.header21 .top-links-area {
			display: none;
		}

		.header21 .currency_language_form {
			display: none;
		}

		.header21 .mobile-toggle {
			display: inline-block;
			vertical-align: top;
			color: #fff;
			margin: 13px 5px 0px 0px;
		}

		.header21 .searchform-popup .search-toggle {
			font-size: 14px;
		}

		.header21 #mini-cart .minicart-icon {
			font-size: 18px;
		}

		.header21 .searchform-popup {
			padding: 14px 19px 0 13px;
		}
	}

.slider20-button {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    background-color: #fff;
    color: #000 !important;
    padding: 20px 45px;
    letter-spacing: 3px;
    transition: all .3s;
    box-shadow: 3px 3px 0 rgba(255,255,255,0.5);
    margin: 20px 0;
    display: inline-block;
}

.slider20-button:hover {
	opacity: 0.85;
}

/* @end */

/* @group Full width image */

.full-width-image{text-align:center;padding:200px 0;background-size:cover}
.full-width-image > h3{margin: 0 0 5px;line-height: 1.35;font-size:90px;color:#fff;font-weight:600;letter-spacing:-5px}
.full-width-image > a{display:inline-block;font-size:12px;font-weight:600;line-height:1;text-transform:uppercase;background-color:#fff;color:#000;padding:20px 45px;letter-spacing:3px;transition:all .3s;box-shadow:3px 3px 0 rgba(255,255,255,0.5);margin:20px 0}
.full-width-image > a:hover{text-decoration:none;opacity:.85}

@media (max-width: 767px) {
	.full-width-image{padding:100px 0}
	.full-width-image > h3{font-size:35px;letter-spacing:-1px}
	.full-width-image > a {
	    font-size: 8px;
	    padding: 10px 20px;
	    margin: 10px 0;
	}
}

/* @end */

/* @group Skin 20 home products */

@media (min-width: 767px) {
	.skin20-products {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.skin20-products .product-image {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	padding: 0px;
	border: none;
}

.skin20-products .product-image img {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

.skin20-products {
	padding-top: 90px;
	padding-bottom: 51px;
}

.skin20-products .title-box {
	background: none;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	color: #000;
	text-transform: uppercase;
	padding: 0px 0px 70px 0px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

.skin20-products .owl-dots {
	margin-top: 34px;
}

.skin20-products .owl-dot {
	background: #fff !important;
	border: 2px solid #000 !important;
	opacity: 1 !important;
}

.skin20-products .owl-dot.active {
	background: #000 !important;
}

@media (max-width: 767px) {
	.skin20-products {
		padding-top: 40px;
	}

	.skin20-products .title-box {
		font-size: 25px;
		padding: 0px 0px 40px 0px;
	}
}

/* @end */

/* @group Skin 20 products tabs */

.index20-products-tabs {
	display: flex;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

.index20-products-tabs .category-list {
	border-right: 1px solid #dcdcdc;
	padding-top: 70px;
	padding-bottom: 70px;
}

.index20-products-tabs .category-list .description {
	font-size: 20px;
	font-weight: 600;
	color: gray;
	margin-bottom: 10px;
}

.index20-products-tabs .category-list ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.index20-products-tabs .category-list ul li {
	padding: 10px 0;
	line-height: 20px;
}

.index20-products-tabs .category-list ul li a {
	display: block;
	text-transform: uppercase;
	color: #000;
	font-weight: 700;
	font-size: 12px;
	position: relative;
	padding-left: 20px;
	background: url(../images/tab.html) 0 4px no-repeat;
}

.index20-products-tabs .category-list ul li.active a {
	background: url(../images/tab-active.html) 0 4px no-repeat;
}

.index20-products-tabs .category-detail {
	padding-left: 100px;
	padding-top: 70px;
	padding-bottom: 70px;
}

@media (max-width: 1200px) {
	.index20-products-tabs .category-detail {
		padding-left: 50px;
	}
}

.index20-products-tabs .tab-content {
	padding: 0px;
	background: none;
	border-radius: 0;
	margin: 0px;
	border: none;
	box-shadow: none;
}

.index20-products-tabs .tab-content .product-action {
	display: none;
}

.index20-products-tabs .tab-content .rating-wrap {
	display: none;
}

.index20-products-tabs .products .product {
	margin: 10px 0px 0px 0px;
}

.index20-products-tabs .products .hidden-product {
	display: none;
}

.index20-products-tabs .products .row {
	margin: 0px -5px;
}

.index20-products-tabs .products .row > div {
	padding-left: 5px;
	padding-right: 5px;
}

.index20-products-tabs .products .row > div:nth-child(5n+1) {
	clear: both;
}

@media (max-width: 767px) {
	.index20-products-tabs .products .row > div {
		clear: none !important;
	}

	.index20-products-tabs .products .row > div:nth-child(2n+1) {
		clear: both !important;
	}
}

.index20-products-tabs .product-image {
	margin: 0px 0px 11px 0px;
}

.index20-products-tabs .product h3 {
	margin: 0px 0px 11px 0px;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}

.index20-products-tabs .product h3 a {
	color: #000;
}

.index20-products-tabs .price {
	padding: 0px;
	margin: 0px 0px 15px 0px;
	font-size: 18px;
}

@media (max-width: 767px) {
	.index20-products-tabs .category-list {
		padding-bottom: 20px;
		padding-top: 50px;
	}

	.index20-products-tabs {
		display: block;
	}

	.index20-products-tabs .category-list {
		border: none;
	}

	.index20-products-tabs .category-detail {
		padding-left: 10px;
		padding-top: 0;
		padding-bottom: 50px;
	}

	.index20-products-tabs .category-list ul li {
		display: inline-block;
		margin-right: 30px;
	}
}

.skin20-load-more-products {
	display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    background-color: #000;
    color: #fff !important;
    padding: 20px 45px;
    letter-spacing: 3px;
    transition: all .3s;
    box-shadow: 3px 3px 0 rgba(0,0,0,0.5);
    margin: 30px auto;
}

.skin20-load-more-products:hover {
	opacity: 0.9;
}


@media (max-width: 767px) {
	.skin20-load-more-products {
	    font-size: 8px;
	    padding: 10px 20px;
	}
}

.skin20-loader-products {
	background: url(../images/loader.gif) 50% center no-repeat;
	height: 112px;
	display: none;
}

.skin20-loader-products.active {
	display: block;
}

@media (max-width: 767px) {
	.skin20-loader-products {
		height: 88px;
	}
}

/* @end */

/* @group Skin20 parallax */

.skin20-parallax {
	padding-left: 50%;
	padding-right: 5%;
	padding-bottom: 97px;
	padding-top: 95px;
}

.skin20-parallax h3 {
	padding: 0px 0px 20px 0px;
	margin: 0px;
	color: #fff;
	font-size: 60px;
	line-height: 1.35;
	font-weight: 600;
	letter-spacing: -4px;
}

.skin20-parallax p {
	padding: 0px 0px 35px 0px;
	margin: 0px;
}

.skin20-parallax .button-parallax {
	font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    background-color: #fff;
    color: #000;
    padding: 20px 45px;
    letter-spacing: 3px;
    transition: all .3s;
    box-shadow: 3px 3px 0 rgba(255,255,255,0.5);
    margin: 0;
    display: inline-block;
}

.skin20-parallax .button-parallax:hover {
	opacity: 0.85;
}

.background-size-cover > div {
	background-size: cover;
}

@media (max-width: 1100px) {
	.skin20-parallax h3 {
		font-size: 40px;
	}
}

@media (max-width: 767px) {
	.skin20-parallax h3 {
		font-size: 30px;
		padding-bottom: 10px;
	}

	.skin20-parallax .button-parallax {
	    font-size: 8px;
	    padding: 10px 20px;
	}

	.skin20-parallax p {
		padding-bottom: 20px;
	}

	.skin20-parallax {
		padding-left: 35%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

/* @end */

/* @group Vertical product tabs */

@media (min-width: 767px) {
	.vertical-tabs .nav-tabs {
		width: 150px;
		float: left;
		bottom: 0;
		right: -1px;
		text-align: right;
		border: none;
		position: relative;
	}

	.vertical-tabs .nav-tabs > li {
		float: none;
		margin: 0px 0px 1px 0px !important;
		padding: 0 !important;
	}

	.vertical-tabs .nav-tabs > li > a {
		display: block;
		margin: 0px;
		border-top: 1px solid #eee !important;
		border-left: 3px solid #eee;
		border-right: none;
		border-bottom: 1px solid #eee;
		border-radius: 5px 0 0 5px;
		-webkit-border-radius: 5px 0 0 5px;
		-moz-border-radius: 5px 0 0 5px;
		padding: 9px 15px !important;
		line-height: 1;
		font-size: 14px;
	}

	.vertical-tabs .nav-tabs > li > a:hover,
	.vertical-tabs .nav-tabs > li > a:focus {
		padding: 9px 15px !important;
	}

	.vertical-tabs .nav-tabs > li.active > a {
		border-top: 1px solid #eee !important;
		border-left: 3px solid #08c;
		border-right: none;
		border-bottom: 1px solid #eee;
	}

	.vertical-tabs .tab-content {
		margin-left: 150px;
		border-top: 1px solid #eee;
		border-radius: 0 4px 4px 4px;
		-webkit-border-radius: 0 4px 4px 4px;
		-moz-border-radius: 0 4px 4px 4px;
	}
}

/* @end */

/* @group Accordion product tabs */

.accordion-product-tab {
	padding: 44px 0px 68px 0px;
}

.accordion-product-tab .panel {
	margin: 0px;
	padding: 0px;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.accordion-product-tab .panel-heading {
	margin: 5px 0px 0px 0px;
	padding: 0px;
	border: none;
	background: none;
}

.accordion-product-tab .panel-heading a {
	background: #08c;
	color: #fff;
	display: block;
	position: relative;
	padding: 12px 20px 12px 10px;
	font-size: 15px;
	min-height: 20px;
	border: 0;
	border-left: 3px solid #08c;
	border-radius: 5px;
	font-weight: 400;
	text-transform: none;
	transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
}

.accordion-product-tab .panel-heading a.collapsed {
	background: #f4f4f4;
	color: #08c;
}

.accordion-product-tab .panel-heading a:after {
	content: "\f0d7";
	position: absolute;
	top: 15px;
	right: 18px;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.accordion-product-tab .panel-heading a.collapsed:after {
	content: "\f0da";
}

.accordion-product-tab .panel-body {
	margin: 0px;
	background: none;
	border: none;
	padding: 20px 15px 0px 15px;
}

/* @end */

/* @group Sticky tabs */

.sticky-tabs {

}

.sticky-tabs .tab-content {
	padding: 0px;
}

.sticky-tabs .tab-content > div {
	display: block;
	visibility: visible;
	position: relative;
	opacity: 1;
	top: auto;
	border-bottom: 1px solid #eee;
	padding: 30px 15px 10px 15px;
}

.sticky-tabs .tab-content > div:last-child {
	border-bottom: none;
}

.sticky-nav-tabs {
	position: fixed;
	top: 46px;
	left: 0;
	right: 0;
	padding: 10px 0;
	z-index: 99;
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	display: none;
}

@media (max-width: 1159px) {
	.sticky-nav-tabs {
		top: 0 !important;
	}
}

@media (max-width: 767px) {
	.sticky-nav-tabs {
		display: none !important;
	}
}

.sticky-nav-tabs .nav {
	margin: 0px -10px;
	position: relative;
}

.sticky-nav-tabs .nav li {
	display: inline-block;
	padding: 0px 10px;
}

.sticky-nav-tabs .nav li a {
	padding: 0px;
	background: none;
	border: none;
	font-size: 13px;
}

/* @end */

/* @group Sticky product */

.sticky-product{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:100;
	background-color:#fff;
	box-shadow:0 3px 5px rgba(0,0,0,0.08);
	padding:15px 0 15px;
	display: none;
	z-index: 99;
}

@media (max-width: 767px) {
	.sticky-product {
		display: none !important;
	}
}

@media (max-width: 1159px) {
	.sticky-product {
		top: 0 !important;
	}
}

.sticky-product > .container{
	position:relative
}

.sticky-product .sticky-image,
.sticky-product .sticky-detail{
	display:inline-block;
	vertical-align:middle;
	margin-right:15px
}

.sticky-product .sticky-image img {
	max-width: 50px;
}

.sticky-product .sticky-detail .product-name-area,
.sticky-product .ratings,
.sticky-product .sticky-detail .availability{
	display:inline-block;
	vertical-align:top;
	margin:0;
	margin-right:15px
}

.sticky-product .sticky-detail .product-name-area .product-name,
.sticky-product .ratings,
.sticky-product .sticky-detail .availability{
	line-height:25px;
}

.sticky-product .sticky-detail .product-name-area .product-name {
	margin: 0px;
	padding: 0px 0px 5px 0px;
	color: #777;
	font-size:16px;
	font-weight:600
}

.sticky-product .sticky-detail .availability span{
	font-size:14px;
	font-weight:400
}

.sticky-product .price-box {
	padding: 2px 0px 5px 0px;
}

.sticky-product .price-box p {
	padding: 0px 5px 0px 0px;
	margin: 0px;
	display: inline-block;
	vertical-align: middle;
}

.sticky-product .price-box .price {
	margin: 0px;
}

.sticky-product .price-box .old-price .price {
    color: #999;
    text-decoration: line-through;
    font-size: 14px;
    vertical-align: middle;
}

.sticky-product .add-to-cart{
	position:absolute;
	right:0;
	top:50%;
	margin-top:-19px
}

.sticky-product .add-to-cart button {
	display: inline-block;
	height: 38px;
	width: 160px;
	min-width: 110px;
	vertical-align: middle;
}

.sticky-product .add-to-cart button i {
	margin-right: 10px;
}

/* @end */



/* @group 1. MegaMenu Horizontal
------------------*/

#menu {
	position: relative;
	overflow: visible;
}

	#menu .background-menu {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	.megaMenuToggle {
		display: none !important;
	}

	.container-megamenu,
	.container-megamenu .container {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		min-height: 1px !important;
		z-index: 999;
		position: relative;
    width: auto;
	}

  .container .container {
    padding-left: 0;
    padding-right: 0;
  }

		.container-megamenu.vertical {
			z-index: 998;
		}

		.container-megamenu.container .container {
			width: auto !important;
			display: block !important;
		}

	.megamenu-wrapper {
		position: relative;
		z-index: 25;
		/*background: #9AC93D;*/
        border-top: 1px solid #cacaca;
	}

		.header2 .megamenu-wrapper {
			background: none;
		}

		ul.megamenu {
			margin: 0px;
			padding: 0px;
			list-style: none;
			position: relative;
			z-index: 0;
			opacity: 1;
		}

			ul.megamenu > li {
				float: left;
				width: auto;
			/*	margin-right: 26px;*/
			}

				ul.megamenu > li:first-child {
					background: none;
				}

				ul.megamenu > li.pull-right {
					float: right;
					margin-right: 0;
					margin-left: 26px;
				}

				ul.megamenu > li .close-menu,
				ul.megamenu > li .open-menu {
					display: none;
				}

				/* @group Search */

				ul.megamenu > li.search {
					background: none;
				}

					#top ul.megamenu > li.search .search_form, ul.megamenu > li.search .search_form {
						padding: 0px;
						margin: 8px;
					}

						#top ul.megamenu > li.search .search_form input, ul.megamenu > li.search .search_form input {
							height: 29px;
							min-height: 29px;
							border: none;
						}

						ul.megamenu > li.search .button-search2 {
							top: 6px;
							right: 8px;
						}

				/* @end */

				ul.megamenu > li > a {
					font-size: 1em;
					font-weight: bold;
					line-height: 2em;
					padding: 0.5em 0.5em 0.5em 1em;
					text-transform: uppercase;
					display: block;
					height: 46px;
					color: #84ac34;
					position: relative;
				}

					ul.megamenu > li > a strong {
						font-weight: bold;
					}

					ul.megamenu > li.with-sub-menu > a strong:after {
						content: "\f107";
						display: inline-block;
						font: normal normal normal 14px/1 FontAwesome;
						font-size: inherit;
						text-rendering: auto;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						transform: translate(0, 0);
						margin-left: 5px;
					}

					ul.megamenu .megamenu-label {
					     position: absolute;
					     top: -1px;
					     right: 0;
					     height: 13px;
					     padding: 3px 2px 1px;
					     font-size: 9px;
					     line-height: 1;
					     font-family: Arial;
					     color: #fff;
					     border-radius: 2px;
					     -webkit-border-radius: 2px;
					     -moz-border-radius: 2px;
					}

						ul.megamenu .megamenu-label span:before {
							display: inline-block;
							font-family: "Elusive-Icons";
							font-size: 5px;
							position: absolute;
							width: 4px;
							height: 6px;
							margin: auto;
							content: "\f126";
							left: -4px;
							text-align: right;
							top: 0;
							bottom: 0;
							content: "\f125";
							left: 2px;
							bottom: -3px;
							top: auto;
							width: auto;
							height: auto;
						}

					     ul.megamenu ul .megamenu-label {
					          display: inline-block;
					          position: relative;
					          top: -1px !important;
					          margin-left: 9px;
					          left: 0 !important;
					          right: 0 !important;
					     }

					     	ul.megamenu ul .megamenu-label span:before {
					     		left: -3px;
					     		top: 5px;
					     		bottom: auto;
					     		-ms-transform: rotate(90deg); /* IE 9 */
					     		-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
					     		transform: rotate(90deg);
					     	}

					ul.megamenu > li > a:hover,
					ul.megamenu > li.active > a,
					ul.megamenu > li.home > a,
					ul.megamenu > li:hover > a {
						color: #99cee8;
					}

					ul.megamenu > li > a > .fa-home {
						display: block;
						font-size: 16px;
						margin-top: 1px;
					}

					ul.megamenu > li > a img {
						margin-right: 10px;
						height: 27px;
						vertical-align: middle;
					}

					/* @group Description */

					ul.megamenu > li > a.description {
						font-size: 14px;
						padding: 10px 20px 11px 21px;
						line-height: 1;
					}

						ul.megamenu > li > a.description strong {
							font-size: 14px;
						}

						ul.megamenu > li > a.description img {
							float: left;
						}

						ul.megamenu > li > a.description .description {
							font-size: 11px;
							opacity: 0.9;
						}

						ul.megamenu > li > a.description .description-left {
							float: left;
							width: auto;
						}

					/* @end */

					/* @group Submenu */

					ul.megamenu > li > .sub-menu {
						position: absolute;
						z-index: -20;
						visibility: hidden;
						right: 0;
					}

						ul.megamenu > li.active > .sub-menu {
							z-index: 20;
							right: auto;
							visibility: visible;
						}

						ul.megamenu > li > .sub-menu > .content {
							display: none;
						}

							ul.megamenu > li.active > .sub-menu > .content {
								display: block;
							}

							ul.megamenu > li > .sub-menu > .content > .arrow {
								display: none;
							}

						/* @group Transitions */

						/* @group Transition fade */

						ul.megamenu.fade > li > .sub-menu > .content {
							display: block;
							opacity: 0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
						}

							ul.megamenu.fade > li.active > .sub-menu > .content {
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
							}

						/* @end */

						/* @group Transition shift up */

						ul.megamenu.shift-up > li > .sub-menu > .content {
							display: block;
							opacity: 0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							margin-top: 25px;
						}
							ul.megamenu.shift-up > li.active > .sub-menu > .content {
								margin-top: 0px;
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
							}

						/* @end */

						/* @group Transition shift down */

						ul.megamenu.shift-down > li > .sub-menu > .content {
							display: block;
							opacity: 0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							margin-top: -25px;
						}
							ul.megamenu.shift-down > li.active > .sub-menu > .content {
								margin-top: 0px;
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
							}

					     /* @end */

						/* @group Transition shift left */

						ul.megamenu.shift-left > li > .sub-menu > .content {
							display: block;
							opacity: 0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							left: 30px;
						}
							ul.megamenu.shift-left > li.active > .sub-menu > .content {
								left: 0px;
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
							}

						/* @end */

						/* @group Transition shift right */

						ul.megamenu.shift-right > li > .sub-menu > .content {
							display: block;
							opacity: 0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							left: -30px;
						}
							ul.megamenu.shift-right > li.active > .sub-menu > .content {
								left: 0px;
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
							}

						/* @end */

						/* @group Transition flipping */

						ul.megamenu.flipping > li > .sub-menu > .content {
							display: block;
							filter: alpha(opacity=0);
							visibility: hidden;
							opacity: 0;
							-webkit-transform-origin: 50% 0%;
							-o-transform-origin: 50% 0%;
							transform-origin: 50% 0%;
							-webkit-transform: perspective(350px) rotateX(-90deg);
							-o-transform: perspective(350px) rotateX(-90deg);
							transform: perspective(350px) rotateX(-90deg);
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
						}

							ul.megamenu.flipping > li.active > .sub-menu > .content {
								display: block;
								opacity: 1;
								filter: alpha(opacity=100);
								visibility: visible;
								-webkit-transform: perspective(350px) rotateX(0deg);
								-o-transform: perspective(350px) rotateX(0deg);
								transform: perspective(350px) rotateX(0deg);
								-webkit-transition:0.2s linear 0s;
								-o-transition:0.2s linear 0s;
								transition:0.2s linear 0s;
							}

						/* @end */

						/* @group Transition slide */

						ul.megamenu.slide > li > .sub-menu > .content {
							display: block;
							opacity:0;
							filter: alpha(opacity=0);
							visibility: hidden;
							-webkit-transform-origin: top;
							-moz-transform-origin: top;
							-o-transform-origin: top;
							-ms-transform-origin: top;
							transform-origin: top;

							-webkit-animation-fill-mode: forwards;
							-moz-animation-fill-mode: forwards;
							-ms-animation-fill-mode: forwards;
							-o-animation-fill-mode: forwards;
							animation-fill-mode: forwards;

							-webkit-transform: scale(1, 0);
							-moz-transform: scale(1, 0);
							-o-transform: scale(1, 0);
							-ms-transform: scale(1, 0);
							transform: scale(1, 0);

							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
						}

							ul.megamenu.slide > li.active > .sub-menu > .content {
								-webkit-transform: scale(1, 1);
								-moz-transform: scale(1, 1);
								-o-transform: scale(1, 1);
								-ms-transform: scale(1, 1);
								transform: scale(1, 1);
								opacity:1 !important;
								filter: alpha(opacity=100) !important;
								visibility: visible !important;
							}

						/* @end */

						/* @end */

						.horizontal ul.megamenu > li > .sub-menu {
							max-width: 100%;
						}

						ul.megamenu li .sub-menu .content {
							padding: 20px;
							background: #fff;
							border-top: 1px solid #9ac93d;
							border-radius: 0px;
							-webkit-border-radius: 0px ;
							-moz-border-radius: 0px ;
							box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
							-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
							-moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
							box-sizing: content-box !important;
							-moz-box-sizing: content-box !important;
							-webkit-box-sizing: content-box !important;
						}

							ul.megamenu li.type2 .sub-menu .content {
								background: #b70505;
								border: none !important;
							}

							ul.megamenu .sub-menu .content > .row {
								padding-top: 11px;
							}

							ul.megamenu .sub-menu .content > .border {
								padding-top: 7px;
							}

								ul.megamenu .sub-menu .content > .row:first-of-type {
									padding: 0px;
								}

							/* @group Product */

							ul.megamenu li .product {
								text-align: center;
								position: relative;
							}

								ul.megamenu li .product .image {
									padding: 0px;
								}

									ul.megamenu li .product .image img {
										max-width: 100%;
									}

								ul.megamenu li .product .name {
									padding: 7px 0px 8px 0px;
								}

								ul.megamenu li .product .price {
									font-size: 1.4286em;
									color: #444;
									font-weight: 600;
								}

							/* @end */

                              /* @group Products */

                              .products-carousel-overflow {
                                   overflow: hidden;
                                   margin: -2px;
                                   padding: 2px;
                                   position: relative;
                              }

                                   .products-carousel-overflow .box-heading {
                                        float: left;
                                        margin: 5px 15px 10px 0px;
                                        padding: 0px;
                                        font-size: 18px;
                                        line-height: 20px;
                                        font-weight: 400;
                                        position: relative;
                                        z-index: 1;
                                   }

                                   .products-carousel-overflow .strip-line {
                                        background: url(../img/patterns/heading/01.html) 0px 50% repeat-x;
                                        height: 35px;
                                        overflow: hidden;
                                   }

                                   .products-carousel-overflow > .prev,
                                   .products-carousel-overflow > .next {
                                   	float: right;
                                   	margin: 2px 0px 0px 5px;
                                   	width: 28px;
                                   	height: 28px;
                                   	border-radius:         2px;
                                   	-moz-border-radius:    2px;
                                   	-webkit-border-radius: 2px;
                                   	background: #43c6bc; /* Old browsers */
                                   	background: -moz-linear-gradient(top, #43c6bc 0%, #59d4cc 0%, #43c6bc 99%); /* FF3.6+ */
                                   	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#43c6bc), color-stop(0%,#59d4cc), color-stop(99%,#43c6bc)); /* Chrome,Safari4+ */
                                   	background: -webkit-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* Chrome10+,Safari5.1+ */
                                   	background: -o-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* Opera 11.10+ */
                                   	background: -ms-linear-gradient(top, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* IE10+ */
                                   	background: linear-gradient(to bottom, #43c6bc 0%,#59d4cc 0%,#43c6bc 99%); /* W3C */
                                   	cursor: pointer;
                                   	position: relative;
                                   	z-index: 1;
                                   }

                                   	.products-carousel-overflow > .prev {
                                   		margin-left: 15px;
                                   	}

                                   	.products-carousel-overflow > .prev span,
                                   	.products-carousel-overflow > .next span {
                                   		display: block;
                                   		width: 8px;
                                   		height: 14px;
                                   		margin: 8px auto 0px auto;
                                   		background: url(../img/left.html) no-repeat;
                                   	}

                                   		.products-carousel-overflow > .next span {
                                   			background: url(../img/right.html) no-repeat;
                                   		}

                                   .products-carousel-overflow .clear {
                                        clear: left;
                                        width: 100%;
                                        height: 16px;
                                   }

                              /* @end */

                              /* @group Manufacturer */

							ul.manufacturer {
								list-style: none;
								margin: -10px 0px 0px 0px !important;
								padding: 0px;
								position: relative;
							}

								ul.manufacturer li {
									display: inline-block;
									margin: 10px 5px 0px 0px;
								}

									ul.manufacturer li a {
										display: block;
										font-size: 14px;
										padding: 2px 8px;
										border: 1px solid #e6e6e6;
										border-radius:         2px;
										-moz-border-radius:    2px;
										-webkit-border-radius: 2px;
									}

							/* @end */

							/* @group Open Categories */

							ul.megamenu li .sub-menu .content .open-categories,
							ul.megamenu li .sub-menu .content .close-categories {
								display: none;
							}

							/* @end */

							/* @group Static Menu */

							ul.megamenu li .sub-menu .content .static-menu a.main-menu {
								font-size: 1em;
								line-height: 24px;
								font-weight: 600;
								text-transform: uppercase;
								display: block;
								position: relative;
								margin-top: -5px;
							}

								ul.megamenu li .sub-menu .content .static-menu a.main-menu:hover {
									text-decoration: underline;
								}

							ul.megamenu li .sub-menu .content .static-menu .left-categories-image-right {
							     float: left;
							     width: 54%;
							     padding-right: 6%;
							}

							ul.megamenu li .sub-menu .content .static-menu .right-categories-image-right {
							     float: left;
							     width: 40%;
							     text-align: right;
							     padding-top: 5px;
							}

							ul.megamenu li .sub-menu .content .static-menu .left-categories-image-left {
							     float: left;
							     width: 40%;
							     padding-right: 4%;
							     padding-top: 5px;
							}

							ul.megamenu li .sub-menu .content .static-menu .right-categories-image-left {
							     float: left;
							     width: 56%;
							}

							ul.megamenu li .sub-menu .content .static-menu .menu ul {
								list-style: none;
								margin: 0px;
								padding: 0px;
							}

								ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
									padding-top: 20px;
								}

									ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li:first-child {
										padding-top: 0px;
									}

								ul.megamenu li .sub-menu .content .static-menu .menu ul ul {
									padding: 3px 0px 0px 0px;
									position: relative;
									margin-bottom: -7px;
								}

								     ul.megamenu li .sub-menu .content .static-menu .menu ul .categories-image-right ul a {
								          display: block;
								     }

									ul.megamenu li .sub-menu .content .static-menu .menu ul ul li a {
										display: block;
										    font-size: 0.9em;
										    line-height: 20px;
										    padding: 1px 0;
										    color: #777777;
										    font-weight: 400;
										    margin: 0 ;
									}

										ul.megamenu li .sub-menu .content .static-menu .menu ul ul li a:hover {
											text-decoration: underline;
										}

							/* @end */

							/* @group Hover Menu */

							ul.megamenu li .sub-menu .content .hover-menu a.main-menu {
								display: block;
							}

							ul.megamenu li .sub-menu .content .hover-menu .menu ul {
								list-style: none;
								margin: -15px;
								padding: 0px;
								position: relative;
							}

								ul.megamenu li .sub-menu .content .hover-menu .menu ul ul {
									display: none;
									margin: -38px 0px 0px 0px;
									min-width: 170px;
									padding: 5px;
									position: absolute;
									z-index: 10;
									left: 100%;
									background: #fff;
									border-radius: 6px;
									-webkit-border-radius: 6px;
									-moz-border-radius: 6px;
									box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
									-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
									-moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
								}

									ul.megamenu li.type2 .sub-menu .content .hover-menu .menu ul ul {
										background: #b70505;
										min-width: 160px;
									}

									ul.megamenu li .sub-menu .content .hover-menu .menu ul ul.active {
										display: block;
									}

									ul.megamenu li .sub-menu .content .hover-menu .menu ul ul li {
										position: relative;
									}

								ul.megamenu li .sub-menu .content .hover-menu a {
									font-weight: 400;
									padding: 6px 14px 6px 7px;
									font-size: 0.9em;
									line-height: 20px;
									color: #777;
									border-bottom: 1px solid rgba(255, 255, 255, 0.2);
									white-space: nowrap;
									display: block;
								}

									ul.megamenu li.type2 .sub-menu .content .hover-menu a {
										color: #fff;
									}

										ul.megamenu li.type2 .sub-menu .content .hover-menu li:last-of-type > a {
											border: none;
										}

									ul.megamenu li .sub-menu .content .hover-menu a:hover {
										background: #f4f4f4;
									}

										ul.megamenu li.type2 .sub-menu .content .hover-menu a:hover {
											background: rgba(255, 255, 255, .1);
										}

						     /* @end */

					/* @end */

/* @end */

	/* @group 2. MegaMenu Mobile Heading */

	.megaMenuToggle {
		display: none !important;
		cursor: pointer;
	}

		.megamenuToogle-wrapper {
			position: relative;
			z-index: 26;
			background: #006fa4;
		}

			.vertical .megamenuToogle-wrapper {
				background: #f5f5f5;
				border-radius: 6px 6px 0px 0px;
				-moz-border-radius: 6px 6px 0px 0px;
				-webkit-border-radius: 6px 6px 0px 0px;
			}

			.megamenuToogle-wrapper .container {
				color: #fff;
				font-size: 16px;
				padding: 10px 20px 9px 14px !important;
			}

				.vertical .megamenuToogle-wrapper .container {
					background-color: #f5f5f5;
					color: #777;
					font-size: 13px;
					font-weight: 700;
					line-height: 1;
					padding: 14px 15px !important;
					border-radius: 5px 5px 0 0;
					margin: 0;
					border-bottom: 1px solid #ddd;
				}

				.megamenuToogle-wrapper .container > div {
					display: none;
				}

	/* @end */

	/* @group 3. MegaMenu Vertical */

	.vertical {
		border: 1px solid #ddd;
		background: #fbfbfb;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
	}

	.vertical #menuHeading {
	}

		.vertical .megamenuToogle-wrapper {
			z-index: 24;
		}

		.vertical .megamenu-wrapper {
			display: block !important;
			background: none !important;
			position: relative;
			z-index: 23;
		}

			.vertical ul.megamenu > li {
				display: block;
				float: none !important;
				width: auto;
				background: none;
				border-top: 1px solid #ddd;
				/* JH position: relative; */
				margin: 0px 10px;
			}

				.vertical ul.megamenu > li:first-child {
					border: none;
				}

				.vertical ul.megamenu > li.home,
				.vertical ul.megamenu > li.search {
					display: none;
				}

				.vertical ul.megamenu > li > a {
					color: #777;
					text-shadow: none;
					background: none !important;
					height: auto !important;
					margin: 0px -10px;
					display: block;
					/*padding: 10px 15px 11px 15px;*/
					padding: 5px 20px 5px 15px;
					position: relative;
					font-size: 1em;
					font-weight: normal;
					text-transform: uppercase;
				}

					.vertical ul.megamenu > li.with-sub-menu > a strong:after {
						display: none;
					}

					.vertical ul.megamenu > li > a strong {
						font-weight: normal;
					}

				     .vertical ul.megamenu .megamenu-label {
				          display: inline-block;
				          position: relative;
				          top: 0;
				          margin-left: 10px;
				     }

				     	.vertical ul.megamenu .megamenu-label span:before {
				     		left: -4px;
				     		top: 4px;
				     		bottom: auto;
				     		-ms-transform: rotate(90deg); /* IE 9 */
				     		-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
				     		transform: rotate(90deg);
				     	}

					.vertical ul.megamenu > li.active > a,
					.vertical ul.megamenu > li:hover > a {
						background: #d3d0d0 !important;
						color: #222;
					}

				.vertical ul.megamenu > li.click > a:before,
				.vertical ul.megamenu > li.hover > a:before {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 12px;
					width: 30px;
					height: 30px;
					line-height: 30px;
					margin: auto;
					text-align: center;
					content: "";
					display: inline-block;
					width: 0;
					height: 0;
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;
					border-left: 5px solid #b70505;
					margin-left: 5px;
					margin-right: 5px;
				}

					.vertical ul.megamenu > li:hover > a:before {
						border-left: 5px solid #fff;
					}

					@media (max-width: 991px) {
						.vertical ul.megamenu > li.click > a:before,
						.vertical ul.megamenu > li.hover > a:before {
							display: none;
						}
					}

				/* SubMenu */

				.vertical ul.megamenu > li > .sub-menu {
					left: 100%;
					padding-top: 0px;
					top: 0;
				}

					.vertical ul.megamenu > li > .sub-menu > .content {
						/* JH margin-left: 10px; */
						position: relative !important;
						border-top: 0;
						border-left: 5px solid #b70505;
						border-radius: 0px 6px 6px 6px;
						-webkit-border-radius: 0px 6px 6px 6px;
						-moz-border-radius: 0px 6px 6px 6px;
					}

/* @group 4. Responsive
------------------*/

@media (max-width: 767px) {

     /* @group Image category */

     .responsive ul.megamenu li .sub-menu .content .static-menu .categories-image-right,
     .responsive ul.megamenu li .sub-menu .content .static-menu .categories-image-top {
          display: none;
     }

          .responsive ul.megamenu li .sub-menu .content .static-menu .categories-image-right > div > ul {
               display: block !important;
          }

     .responsive ul.megamenu li .sub-menu .content .static-menu .left-categories-image-right {
          float: none;
          width: 100%;
          padding: 0px;
     }

     .responsive ul.megamenu li .sub-menu .content .static-menu .right-categories-image-right {
          display: none;
     }

     .responsive ul.megamenu li .sub-menu .content .static-menu .left-categories-image-left {
          display: none;
     }

     .responsive ul.megamenu li .sub-menu .content .static-menu .right-categories-image-left {
          float: none;
          width: 100%;
          padding: 0px;
     }

     /* @end */

     /* @group Submenu */

	.responsive ul.megamenu .sub-menu .content .border {
		display: none;
	}

	.responsive ul.megamenu .sub-menu .content .row {
		margin: 0px -20px;
		padding: 0px;
	}

		.responsive ul.megamenu .sub-menu .content .row > div {
			margin-top: 20px;
			border-top: 1px solid #e6e6e6;
			padding-top: 20px;
			padding-left: 20px;
			padding-right: 20px;
		}

			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div:first-child {
				margin-top: 0px;
				border-top: none;
				padding-top: 0px;
			}

			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div.mobile-disabled + .mobile-enabled {
				margin-top: 0px;
				border-top: none;
				padding-top: 0px;
			}

			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div.mobile-enabled + .mobile-disabled + .mobile-enabled,
			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div.mobile-enabled + .mobile-disabled  + .mobile-disabled + .mobile-enabled,
			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div.mobile-enabled + .mobile-disabled  + .mobile-disabled + .mobile-disabled + .mobile-enabled,
			.responsive ul.megamenu .sub-menu .content .row:first-of-type > div.mobile-enabled + .mobile-disabled  + .mobile-disabled + .mobile-disabled + .mobile-disabled + .mobile-enabled  {
				margin-top: 20px;
				border-top: 1px solid #e6e6e6;
				padding-top: 20px;
			}

	/* @end */

	/* @group Open Categories */

	.responsive ul.megamenu li .menu > ul > li {
		position: relative;
	}

		.responsive ul.megamenu .menu > ul > li.active > .close-categories {
			display: block !important;
			position: absolute;
			top: 0;
			right: 0;
			height: 45px;
			cursor: pointer;
			color: #777;
		}

			.responsive ul.megamenu .menu > ul > li.active > .close-categories:before {
				content: "-";
				font-family: Arial;
				font-weight: 300;
				font-size: 36px;
				line-height: 46px;
				padding: 0px 18px;
			}

		.responsive ul.megamenu .menu > ul > li > .open-categories {
			display: block !important;
			position: absolute;
			top: 0;
			right: 0;
			height: 45px;
			cursor: pointer;
		}

			.responsive ul.megamenu .menu > ul > li.active > .open-categories {
				display: none !important;
			}

			.responsive ul.megamenu li .open-categories:before {
				content: "+";
				font-family: Arial;
				font-weight: 300;
				font-size: 24px;
				line-height: 50px;
				color: #777;
				padding: 0px 17px;
			}

			.responsive #main ul.megamenu li .menu > ul > li .visible,
			.responsive #main ul.megamenu li .menu > ul > li > ul {
				display: none;
			}

				.responsive #main ul.megamenu li .menu > ul > li.active .visible,
				.responsive #main ul.megamenu li .menu > ul > li.active > ul {
				}

	/* @end */

	/* @group Categories -> Hover Menu & Static Menu */

	.responsive ul.megamenu li .sub-menu .content .hover-menu .menu,
	.responsive ul.megamenu li .sub-menu .content .static-menu .menu {
		position: relative;
		margin: -20px;
		overflow: hidden;
	}

		.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul,
		.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul {
			margin: 0px;
			padding: 0px;
		}

		.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li,
		.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li {
			border-top: 1px solid #e5e5e5;
		}

			.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li:before {
				display: none;
			}

			.responsive ul.megamenu li .sub-menu .content .hover-menu .menu > ul:first-child > li:first-child,
			.responsive ul.megamenu li .sub-menu .content .static-menu .menu > ul:first-child > li:first-child {
				border-top: none;
			}

			.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li a,
			.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li a {
				display: block;
				padding: 13px 20px 10px 20px;
				font-size: 14px;
				font-weight: 300;
				background: #fff;
				text-transform: none;
				margin: 0px;
			}

				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li ul li a,
				.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li ul li a {
					font-size: 12px;
				}

				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li a:hover,
				.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li a:hover,
				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li.active > a,
				.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li.active > a {
					background: #f7f7f7;
				}

				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul li a:before,
				.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul li a:before {
					display: none;
				}

			.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul,
			.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul ul {
				margin: 0px !important;
				padding: 0px !important;
				display: none;
			}

				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul li a,
				.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul ul li a {
					padding-left: 40px;
				}

					.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul ul li a,
					.responsive ul.megamenu li .sub-menu .content .static-menu .menu ul ul ul li a {
						padding-left: 60px;
					}

			.responsive ul.megamenu .sub-menu .content .static-menu .row > div {
				padding: 0px 20px !important;
			}

			.responsive .static-menu .visible {
				padding: 0px !important;
			}

			.responsive ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
				padding: 0px;
			}

	/* @end */
}

@media (max-width: 991px) {

	/* @group Submenu background image */

	.responsive ul.megamenu li .sub-menu .content {
		background-image: none !important;
	}

	/* @end */

	/* @group MegaMenu Vertical */

	.responsive .vertical #menuHeading {
	}

		.responsive .vertical ul.megamenu > li.click:before,
		.responsive .vertical ul.megamenu > li.hover:before {
			display: none;
		}

	/* @end */

	/* @group MegaMenu Horizontal */

	.responsive .megaMenuToggle {
		display: block !important;
	}

	.responsive .megamenu-wrapper {
		display: none;
		position: relative;
	}

		.responsive .horizontal .megamenu-wrapper {
			background: #fff !important;
			border: 1px solid #e6e6e6;
			margin-top: -2px;
		}

		.responsive ul.megamenu {
			background: none;
		}

		.responsive ul.megamenu > li {
			display: block;
			float: none !important;
			width: auto;
			background: none;
			border-top: 1px solid #e6e6e6;
			position: relative;
			margin: 0px;
		}

			.responsive ul.megamenu > li.home,
			ul.megamenu > li.search {
				display: none;
			}

			.responsive ul.megamenu > li > a {
				color: #777;
				text-shadow: none;
				background: none !important;
				margin: 0px;
				padding: 14px 0px 12px 0px !important;
				height: auto !important;
			}

				.responsive ul.megamenu > li:hover > a {
					color: #777;
				}

				.responsive ul.megamenu > li.with-sub-menu > a strong:after {
					display: none;
				}

			     .responsive ul.megamenu .megamenu-label {
			          display: inline-block;
			          position: relative;
			          top: 0px;
			          left: 10px;
			     }

					.responsive ul.megamenu .megamenu-label span:before {
							left: -3px;
							top: 5px;
							bottom: auto;
							-ms-transform: rotate(90deg); /* IE 9 */
							-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
							transform: rotate(90deg);
					}

				.responsive ul.megamenu > li > .sub-menu > .content {
					padding: 20px;
				}

				.responsive ul.megamenu > li > .sub-menu > .content > .arrow {
					display: none;
				}

				.responsive ul.megamenu > li:hover,
				.responsive ul.megamenu > li.active {
				}

				.responsive ul.megamenu > li.with-sub-menu .open-menu {
					position: absolute;
					right: 0;
					top: 0;
					z-index: 10;
					padding: 12px 17px 10px 50px;
					display: block;
					cursor: pointer;
					height: 45px;
					color: #333;
				}

					.responsive ul.megamenu > li.active .open-menu {
						display: none;
					}

						.responsive ul.megamenu > li.with-sub-menu .open-menu:before {
							content: "+";
							font-family: Arial;
							font-weight: 300;
							font-size: 24px;
						}

				.responsive ul.megamenu > li.active .close-menu {
					display: block;
					position: absolute;
					z-index: 10;
					top: 0;
					right: 0;
					width: 85px;
					height: 45px;
					padding: 0px 3px 0px 40px;
					text-align: center;
					cursor: pointer;
					color: #333;

				}

					.responsive ul.megamenu > li.active .close-menu:before {
						content: "-";
						font-family: Arial;
						font-weight: 300;
						font-size: 36px;
						line-height: 1.3;
					}

			/* @group Submenu */

			.responsive ul.megamenu > li > .sub-menu {
				width: 100% !important;
				padding-top: 0px;
				position: relative;
				display: none;
				-webkit-transition: none !important;
				-moz-transition: none !important;
				transition: none !important;
				z-index: 2 !important;
				visibility: visible !important;
				left: 0 !important;
			}

				.responsive ul.megamenu li .sub-menu .content {
					box-shadow: none;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					border: none;
					border-top: 1px solid #e6e6e6;
					border-radius: 0px;
					-moz-border-radius: 0x;
					-webkit-border-radius: 0px;
					display: block !important;
					visibility: visible !important;
					opacity: 1 !important;
					filter: alpha(opacity=100) !important;
					margin-top: 0px !important;
					left: 0px !important;
					margin-left: 0px !important;
					-webkit-transition: none !important;
					-moz-transition: none !important;
					transition: none !important;
					-webkit-transform: scale(1, 1) !important;
					-moz-transform: scale(1, 1) !important;
					-o-transform: scale(1, 1) !important;
					-ms-transform: scale(1, 1) !important;
					transform: scale(1, 1) !important;
				}

				/* @group Hover Menu */

				.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul {
					margin: 5px 10px;
					width: auto;
					padding: 0px;
					position: static;
					left: 0;
					background: none;
					border: none;
					border-radius:         0px;
					-moz-border-radius:    0px;
					-webkit-border-radius: 0px;
					box-shadow:            none;
					-moz-box-shadow:       none;
					-webkit-box-shadow:    none;
				}

					.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul:before {
						display: none;
					}

					.responsive ul.megamenu li .sub-menu .content .hover-menu .menu ul ul:after {
						display: none;
					}

					.responsive .visible {
					}

						.responsive .visible > div {
							margin: 0px !important;
							border: none !important;
							padding: 0px 0px 0px 10px !important;
						}

				/* @end */

			/* @end */

	/* @end */
}

@media (min-width: 992px) {
	.megamenu-wrapper {
		display: block !important;
	}
}

/* @end */


.categories-image-top {
	padding-bottom: 14px;
	margin-top: -3px;
	position: relative;.owl-nav .owl-next, .owl-nav .owl-prev {
                           align-items: center;
                           background-color: var(--fc-gray-200);
                           border: 1px solid var(--fc-gray-200);
                           border-radius: 50px;
                           color: var(--fc-gray-500);
                           cursor: pointer;
                           display: block;
                           display: inline-flex;
                           flex-shrink: 0;
                           font-size: 20px;
                           font-size: 24px;
                           height: 32px;
                           justify-content: center;
                           left: 97%;
                           padding: 0;
                           position: absolute;
                           top: 0;
                           transform: translateY(-50%);
                           transition: .3s ease-in-out;
                           width: 32px;
                       }
}

