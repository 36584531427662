@import "~bootstrap/scss/bootstrap";

@import 'cookieconsent.css';
@import 'blog.css';
@import 'aukce.css';
@import 'animate.css';
@import 'font-icons.css'; //font-awesome and another fonts
@import 'stylesheet.css';
@import 'stylesheet_sass.css';
@import 'custom.css';

.test1{
    background-color: aqua;
}

.test2{
    background-color: red;
}