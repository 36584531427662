
/* Posts */
.blog-posts:not(.blog-posts-no-margins) article {
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
	margin-bottom: 50px;
	padding-bottom: 20px;
}

.blog-posts:not(.blog-posts-no-margins) .pagination {
	margin: -10px 0 20px;
}

.blog-posts.blog-posts-no-margins .pagination {
	margin-top: 28.8px;
	margin-top: 1.8rem;
}

/* Post */
article.post h2 a {
	text-decoration: none;
}

article.post .post-meta {
	font-size: 0.9em;
	margin-bottom: 7px;
}

article.post .post-meta > span {
	display: inline-block;
	padding-right: 8px;
}

article.post .post-meta i {
	margin-right: 3px;
}

article.post .post-date {
	float: left;
	margin-right: 10px;
	text-align: center;
}

article.post .post-date .month {
	display: block;
	background: #CCC;
	border-radius: 0 0 2px 2px;
	color: #FFF;
	font-size: 0.8em;
	line-height: 1.8;
	padding: 1px 10px;
	text-transform: uppercase;
}

article.post .post-date .day {
	background: #f7f7f7;
	border-radius: 2px 2px 0 0;
	color: #CCC;
	display: block;
	font-size: 18px;
	font-weight: 900;
	padding: 10px;
}

article.post .post-image .owl-carousel {
	width: 100.1%;
}

article .post-video {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 0;
	background-color: #FFF;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	display: block;
	height: auto;
	position: relative;
	margin: 0 0 30px 0;
	padding-bottom: 61%;
}

article .post-video iframe {
	bottom: 0;
	height: auto;
	left: 0;
	margin: 0;
	min-height: 100%;
	min-width: 100%;
	padding: 4px;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

article .post-audio {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 0;
	background-color: #FFF;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	display: block;
	height: auto;
	position: relative;
	margin: 0 0 30px 0;
	padding-bottom: 35%;
	min-height: 160px;
}

article .post-audio iframe {
	bottom: 0;
	height: auto;
	left: 0;
	margin: 0;
	min-height: 100%;
	min-width: 100%;
	padding: 4px;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

article.post-medium .post-image .owl-carousel {
	width: 100.2%;
}

article.post-large {
	margin-left: 60px;
}

article.post-large h2 {
	margin-bottom: 5px;
}

article.post-large .post-image, article.post-large .post-date {
	margin-left: -60px;
}

article.post-large .post-image {
	margin-bottom: 25px;
}

article.post-large .post-image.single {
	margin-bottom: 30px;
}

article.post-large .post-video {
	margin-left: -60px;
}

article.post-large .post-audio {
	margin-left: -60px;
}

/* Single Post */
.single-post article {
	border-bottom: 0;
	margin-bottom: 0;
}

article.blog-single-post .post-meta {
	margin-bottom: 20px;
}

/* Post Block */
.post-block h3 {
	font-size: 1.8em;
	font-weight: 200;
	margin: 0 0 20px;
	text-transform: none;
}

.post-block h3 i {
	margin-right: 7px;
}

/* Post Author */
.post-author img {
	max-height: 80px;
	max-width: 80px;
}

.post-author p {
	font-size: 0.9em;
	line-height: 22px;
	margin: 0;
	padding: 0;
}

.post-author p .name {
	font-size: 1.1em;
}

.post-author .img-thumbnail {
	display: inline-block;
	float: left;
	margin-right: 20px;
}

/* Post Comments */
ul.comments {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.comments li {
	clear: both;
	padding: 10px 0 0 70px;
}

ul.comments li img.avatar {
	height: 48px;
	width: 48px;
}

ul.comments li ul.reply {
	margin: 0;
}

ul.comments li a {
	text-decoration: none;
}

ul.comments li .img-thumbnail {
	margin-left: -70px;
	position: absolute;
}

ul.comments li .comment {
	margin-bottom: 10px;
}

ul.comments .comment-arrow {
	border-bottom: 12px solid transparent;
	border-right: 12px solid #f7f7f7;
	border-top: 12px solid transparent;
	height: 0;
	left: -12px;
	position: absolute;
	top: 12px;
	width: 0;
}

ul.comments .comment-block {
	background: #f7f7f7;
	border-radius: 5px;
	padding: 20px 20px 30px;
	position: relative;
}

ul.comments .comment-block p {
	font-size: 0.9em;
	line-height: 21px;
	margin: 0;
	padding: 0;
}

ul.comments .comment-block .comment-by {
	display: block;
	font-size: 1em;
	line-height: 21px;
	margin: 0;
	padding: 0 0 5px 0;
}

ul.comments .comment-block .date {
	color: #999;
	font-size: 0.9em;
}

/* Leave a Comment */
.post-leave-comment h3 {
	margin: 0 0 40px;
}

/* Recent Posts */
.recent-posts h4 {
	margin-bottom: 7px;
}

.recent-posts article.recent-post h4 {
	margin: 0 0 3px 0;
}

.recent-posts article.recent-post h4 a {
	display: block;
}

.recent-posts .date {
	margin-right: 10px;
	text-align: center;
}

.recent-posts .date .month {
	background: #CCC;
	color: #FFF;
	font-size: 0.9em;
	padding: 3px 10px;
	position: relative;
	top: -2px;
}

.recent-posts .date .day {
	background: #F7F7F7;
	color: #CCC;
	display: block;
	font-size: 18px;
	font-weight: 500;
	font-weight: bold;
	padding: 8px;
}

section.section .recent-posts .date .day {
	background: #FFF;
}

/* Simple Post List */
ul.simple-post-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.simple-post-list li {
	border-bottom: 1px dotted #E2E2E2;
	padding: 15px 0;
}

ul.simple-post-list li::after {
	clear: both;
	content: "";
	display: block;
}

ul.simple-post-list li:last-child {
	border-bottom: 0;
}

ul.simple-post-list .post-image {
	float: left;
	margin-right: 12px;
}

ul.simple-post-list .post-meta {
	color: #888;
	font-size: 0.8em;
}

ul.simple-post-list .post-info {
	line-height: 20px;
}

/* Responsive */
@media (max-width: 575px) {
	ul.comments li {
		border-left: 8px solid rgba(0, 0, 0, 0.06);
		clear: both;
		padding: 0 0 0 10px;
	}

	ul.comments li .img-thumbnail {
		display: none;
	}

	ul.comments .comment-arrow {
		display: none;
	}
}

/* Nav List */
ul.nav-list li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list li a {
	-webkit-transition: background 0.1s;
	transition: background 0.1s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 0 8px 22px;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
}

ul.nav-list li a:before {
	font-family: 'FontAwesome';
	content: "\f054";
	font-weight: 900;
	position: absolute;
	top: 8px;
	font-size: 7.2px;
	font-size: 0.45rem;
	opacity: 0.7;
	-webkit-transform: translate3d(-1px, 0, 0);
	transform: translate3d(-1px, 0, 0);
	left: 12px;
}

ul.nav-list li a:hover {
	text-decoration: none;
}

ul.nav-list li a:hover:before,
ul.nav-list li a:hover i[class*="fa-chevron"] {
	-webkit-animation-name: arrowLeftRight;
	animation-name: arrowLeftRight;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

ul.nav-list li a i[class*="fa-chevron"] {
	position: relative;
	top: -1px;
	font-size: 8px;
	font-size: 0.5rem;
	opacity: 0.8;
}

ul.nav-list li > a.active {
	font-weight: bold;
}

ul.nav-list li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.nav-list ul li:last-child a {
	border-bottom: 0;
}

ul.nav-list.hide-not-active li ul {
	display: none;
}

ul.nav-list.hide-not-active li.active ul {
	display: block;
}

ul.nav-list.show-bg-active .active > a,
ul.nav-list.show-bg-active a.active {
	background-color: #f5f5f5;
}

ul.nav-list.show-bg-active .active > a:hover,
ul.nav-list.show-bg-active a.active:hover {
	background-color: #eee;
}

ul.nav-list.no-arrows li a:before {
	content: none;
}

ul.nav-list-simple li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list-simple li a {
	-webkit-transition: background 0.1s;
	transition: background 0.1s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	padding: 8px 0;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
	-webkit-transition: ease padding 300ms;
	transition: ease padding 300ms;
}

ul.nav-list-simple li > a.active {
	font-weight: bold;
}

ul.nav-list-simple li:hover a {
	padding-left: 4px;
}

ul.nav-list-simple li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.narrow li a {
	padding: 4px 0;
}

ul.nav-pills > li > a.active {
	color: #FFF;
	background-color: #CCC;
}

ul.nav-pills > li > a.active:hover, ul.nav-pills > li > a.active:focus {
	color: #FFF;
	background-color: #CCC;
}

/* Nav Pills */
.nav-pills-center {
	text-align: center;
}

.nav-pills-center > li {
	display: inline-block;
	float: none;
}

.nav-pills-sm > li > a {
	padding: 7px 12px;
}

.nav-pills-xs > li > a {
	padding: 5px 10px;
	font-size: 0.9em;
}

.nav-pills .nav-link.active {
	background-color: transparent;
}

.show > .nav-pills .nav-link {
	background-color: transparent;
}

 .blogsearch .buton {
     display: block;
     border: 0;
         border-left-color: currentcolor;
         border-left-style: none;
         border-left-width: 0px;
     padding: 0 15px;
     font-weight: normal;
     font-size: 14px;
     text-align: center;
     white-space: nowrap;
     color: #fff;
     line-height: 38px;
     position: absolute;
     top: 0;
     right: 0;
     height: 34px;
     color: #555;
     line-height: 34px;
     border-left: 1px solid #ccc;
 }
 .float-right {
     float: right !important;
 }
 .float-left {
     float: left !important;
 }

 .img-thumbnail-no-borders {
    display: inline-block;
    min-width: 100%;
    height: auto;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 0px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

}

.clear
{
	clear:both;
}
.eblog a
{
	color:#878787;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;

}
.eblog a:hover
{
	color:#0db9f0;
	text-decoration:none;
}

/* blog */
.eblog
{
    padding: 30px 0;
	font-family: 'Montserrat', sans-serif;
	font-size:14px;
	color:#878787;
	line-height:26px;
	font-weight:500;
}

.latest_blog_wrapper .swiper-viewport {
    margin: 0;
    background: #0000;
    z-index: 1;
    border: 0;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    position: relative;
    overflow: visible;
}
.blog_categories .search h4, .search.popular_posts h4, .latest_blog_wrapper h4, .search.tags h4 {
    color: #555566;
    margin: 0;
    padding: 0px;
    font-weight: 500;
    text-transform: uppercase;
	font-size: 18px;
	font-family: 'Montserrat', sans-serif;
}
.latest_blog_wrapper h4 {
    padding: 0px 0px 15px 0px;
}

.blog_categories h4:after, .search.popular_posts h4:after,  .latest_blog_wrapper h4:after, .search.tags h4:after {
    content: " ";
    display: block;
	background: #0db9f0;
    height: 2px;
    width:60px;
    margin: 12px 0 0;
}
#blogsearch
{
    padding-top: 12px;
}
#blogsearch input[type="text"]
{
    border-radius: 0;
    border: 1px solid #ddd;
    padding: 8px 15px;
    height: auto;
	outline: 0;
	font-size: 15px;
}
#blogsearch button[type="button"]
{
    border: 0;
    border-radius: 0;
    background: #0db9f0;
    color: #fff;
    padding:11px 15px;
	outline: 0;
}
.form-control:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#column-left .blog_categories .search
{
	padding-bottom:25px;
}
.eblog .blog_categories .search:last-child
{
	padding-bottom:0;
}
.blog_categories .search ul

{
    padding: 0;
    list-style: none;
    margin: 0;
	padding-top: 10px;
}
.blog_categories .search ul li
{
    padding-bottom: 5px;
}
.blog_categories .search ul li a
{
	color:#878787
}
.blog_categories .search ul li a i
{
    color: #0db9f0;
	margin-right: 5px;
}

.blog_categories .search ul li a:hover
{
	 color: #0db9f0;
}
.eblog .blog_categories .popular_posts_section
 {
    padding-top: 12px;
}
.popular_posts .popular_posts_images
{
    width: 30%;
    float: left;
}
.eblog .openthumbs img
{
    width: 100%;
}
.popular_posts .popular_posts_text
{
    display: inline-block;
    width: 66%;
    margin-left: 10px;
}
.popular_posts  .popular_posts_text h5 {
    font-size: 12px;
    line-height: normal;
    margin: 0;
    padding: 0 0 2px 0;
    text-transform: uppercase;
	font-weight: 600;
}

.popular_posts  .popular_posts_text span {
	margin: 2px 0 5px 0;
    font-size: 11px;
    padding: 0;
    line-height: normal;
    display: grid;
}
.popular_posts .popular_posts_section
{
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}
.popular_posts .popular_posts_section:last-child
{
    border-bottom: 0;
}
.search.tags ul li {
	padding-bottom: 5px;
    float: none;
    margin-right: 10px;
    display: inline-block;
}
.search.tags ul
{
    padding: 0;
    list-style: none;
    margin: 0;
	padding-top: 10px;
}
.search.tags ul li a
{
    color: #fff;
    background: #556;
    padding: 3px 8px;
	margin-bottom: 5px;
    display: inline-block;
}
.search.tags {
    padding-top: 25px;
	padding-bottom: 25px;
}

/* right section */

.eblog .update_post .update_post_text
{
    background: #f2f2f2;
    padding: 15px;
}

.eblog .update_post .update_post_text h3:after {
    content: " ";
    display: block;
	background: #0db9f0;
    height: 2px;
    width:80px;
    margin: 12px 0 0;
}
.eblog .update_post .update_post_text span a
{
    padding-top: 12px;
    display: inline-block;
    font-style: italic;
    font-weight: 600;
}

.eblog .update_post .update_post_text p {
    margin: 7px 0 10px;
    font-size: 14px;
    text-align: justify;

}
.eblog .update_post .blogcomments i
{
    color: #0db9f0;
}

.eblog .update_post a.base_button
{
    text-transform: uppercase;
    color: #0db9f0;
    font-weight: 600;
}
.eblog .update_post a.base_button:hover
{
    color: #000000;
}

.eblog .update_post
{
	padding-bottom:25px;
}
.eblog .update_post:last-child
{
	padding-bottom:0;
}



/* full page */

.eblog ul.fullblogcomments, .social_icons ul{
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0;
    list-style: none;
}
.eblog ul.fullblogcomments li
{
    margin-right: 15px;
    display: inline-block;
    padding-top: 12px;
}

.social_icons
{
    position: fixed;
    top: 0;
    right: 0;
	bottom:0;
	height:127px;
	margin:auto;
}
.social_icons ul li a
{
    font-size: 28px;
}

.social_icons ul li.facebook i
{
	 color: #3b5998;
}
.social_icons ul li.twitter i
{
	 color: #1dcaff;
}
.social_icons ul li.google-plus i
{
	 color: #d34836;
}
.social_icons ul li.linkedin i
{
	 color: #0077b5;
}


.eblog .subimages .images_silde {
    padding: 0;
}

.eblog .subimages .images_silde img {
    width:100%;
}

.eblog .update_post h3, .eblog h1 {
    font-size: 18px;
    color: #555566;
    margin: 0;
    padding: 0px;
    font-weight: 500;
    text-transform: uppercase;
}
h3.mail_page_hadding
{
    font-size: 24px;
    text-transform: uppercase;
    padding: 15px 0 5px 0;
	margin:0;

}
.eblog .update_post h3{
	line-height: 30px;
    min-height: 74px;
}
.eblog h1
{
	font-size:28px;
	padding-bottom: 5px;
}
.update_post_text h3
{
	padding-top: 15px;
	font-size: 24px;
}
.eblog .update_post .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
}
.eblog .auther_des
{
    margin-bottom: 40px;
}
.eblog .auther_des img.auther_images
{
    border-radius: 100%;
    max-width: 200px;
    margin: auto;
}
.update_post .video-image
{
    position: relative;
    /*padding-bottom: 55.97%; */
    height: 100%;
    overflow: hidden;
    margin-bottom: -0.535%;
}
.update_post .video-image iframe
 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.shear_social_media
{
    text-align: right;
    padding: 14px 0 0px;
}
.shear_social_media .addthis_default_style .at300b
{
	float:none;
	margin: 0;
}
.eblog .search.popular_posts
{
    margin-bottom: 30px;
}
/* responsive */

@media screen and (max-width: 1200px)
{
.popular_posts .popular_posts_text {
    width: 64%;
    margin-left: 10px;
}
.eblog .update_post h3 {
    line-height: 30px;
    min-height: 104px;
}
.eblog {
    padding: 30px 10px;
}
}


@media screen and (max-width: 992px)
{
.popular_posts .popular_posts_text {
    width: 61%;
    margin-left: 10px;
}
.blog_categories .search h4, .search.popular_posts h4, .latest_blog_wrapper h4, .search.tags h4 {
    font-size: 15px;
}
.eblog .update_post h3 {
    line-height: 26px;
    font-size: 17px;
	min-height: auto;
}
.eblog .update_post a.base_button {
    font-size: 12px;
}
.eblog .update_post .update_post_text span a {
    font-size: 11px;
}
}

@media screen and (max-width: 768px)
{
	.eblog {
    padding: 30px 15px;
}
}

