.productdeals .product-thumb .caption {
    min-height:315px;

}

.auction.products-col {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.productdeals .product .banner {
    background: #b70505;
    position: relative;
    overflow: hidden;
    margin-bottom: 35px;

  }
.productdeals .product .banner:hover {
    background: #000;
    position: relative;
    overflow: hidden;

  }


.productdeals .product .banner .footer-deals {
  color: white;
  text-align: center;

}

.productdeals .product .banner .list-inline {
  padding: 0 0 0 0 !important;
  height: 2.5em;
}

.item-countdown li {
  margin: 0;
  padding: 10px 0;
  position: relative;
  width: 40px;
  display: inline-block;
  font-weight: 900;
  cursor: pointer;
}

.productdeals img.img-responsive.aukce {
    background-color: white;
}

@media (max-width: 767px){
.productdeals .product .banner {
    max-width: 270px;
    margin-right: auto;
    margin-left: auto;
}
}
/* Countdown */
.productdeals .timeleft {
    background-color: #3b5871;
    background-image: linear-gradient(to bottom, #3b5871, #0A263D);
    background-image: -webkit-linear-gradient(to bottom, #3b5871, #0A263D);
    background-image:    -moz-linear-gradient(to bottom, #3b5871, #0A263D);
    background-image:     -ms-linear-gradient(to bottom, #3b5871, #0A263D);
    background-image:      -o-linear-gradient(to bottom, #3b5871, #0A263D);
    min-height: 40px;
    border-radius: 5px 5px 0px 0px;
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    text-align: center;
}
.productdeals .timeleft h3{
    color: #FFFFFF!important;
    padding:0;
    margin:0;
    line-height:40px;
    font-family: verdana,arial;
    font-size: 19px;
    font-weight: normal;
}



/* .productdeals .item-countdown ul li{display: inline-table; line-height: inherit!important; } */
.productdeals .item-countdown ul li.first{}
.productdeals .item-countdown ul li.last{ margin: 0px;}
/*.productdeals .item-countdown ul li div{text-transform: uppercase; clear: both;text-align:center;width:100%;font-size:10px;}
.productdeals .item-countdown ul li div.countdown_num{font-size: 18px; color: #fff; margin: 0px 0px 3px 0px;}
/*.productdeals .item-countdown ul li div.countdown_num span{background: #000; padding: 1px 3px; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; margin: 0px 1px;}


.productdeals .timeleft{background-color: #6B9E1B; background-image: linear-gradient(to bottom, #7aa832, #4b6f13);}
.productdeals .item-countdown ul li div.countdown_num span{background: #6B9E1B;} */
.productdeals .item-countdown ul li div.countdown_num span.mw_dld_detail_num{color: #CC3333}
